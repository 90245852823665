import React, { useCallback, useEffect, useMemo, useState } from 'react';

import './Individual.scss';
import { Button } from 'react-bootstrap';
import { CustomSelect } from '../../../../components/General/CustomSelect/Select';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { nounsDecl } from '../../../../common/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../../redux/store';
import { FetchStatus } from '../../../../common/types';
import Skeleton from 'react-loading-skeleton';
import {
    bookCourseScheduleInitial,
    bookCourseScheduleStart,
    loadCourseScheduleStart,
} from '../../../../redux/actions/courses';
import { CourseScheduleStatus } from '../../../../redux/types/courses';
import { getSortedDates } from '../../../../components/CourseTimePicker/utils';
import { getLessonNo, timeComparison } from '../../../../helpers/courses';
import { TimePickerWrapper } from './TimePickerWrapper';
import { ITimePickerItem } from '../../../../components/CourseTimePicker/TimePicker';
import { useRedirect } from 'hooks/useRedirect';
import { BookCourseError } from '../BookCourseError';
import { CourseSchedule } from '../../../../components/CourseSchedule';

interface IIndividualCoursesProps {
    preselectedCourseId?: number;
}

export const IndividualCourses: React.FC<IIndividualCoursesProps> = ({ preselectedCourseId }) => {
    const history = useHistory();

    const [lessonPrice, setLessonPrice] = useState(500);
    const [selectedTimeList, setSelectedTimeList] = useState([]);
    const [isDisabled, setIsDisabled] = useState(true);
    const [teacherName, setTeacherName] = useState(null);
    const [datesArr, setDatesArr] = useState([]);
    const [remainingLessons, setRemainingLessons] = useState([]);
    const [needForConsultation, setNeedForConsultation] = useState<boolean>(false);
    const [selectedConsultationLesson, setSelectedConsultationLesson] = useState<ITimePickerItem>(null);
    const [selectedCourseId, setSelectedCourseId] = useState(preselectedCourseId || null);
    const [scheduleMonths, setScheduleMonths] = useState([]);
    const [selectedMonthIndex, setSelectedMonthIndex] = useState(0);

    const coursesState = useSelector((state: State) => state.individualCourses);
    const bookCourseScheduleState = useSelector((state: State) => state.bookCourseSchedule);
    const studentsState = useSelector((state: State) => state.profile);
    const courseScheduleState = useSelector((state: State) => state.courseSchedule);

    const dispatch = useDispatch();

    const isCoursesFetched: boolean = useMemo(() => coursesState.fetchStatus === FetchStatus.FETCHED, [
        coursesState.fetchStatus,
    ]);
    const isCourseScheduleFetched: boolean = useMemo(() => courseScheduleState.fetchStatus === FetchStatus.FETCHED, [
        courseScheduleState.fetchStatus,
    ]);
    const selectedLessonsNum = useMemo(() => selectedTimeList.length, [selectedTimeList]);
    const totalPrice = useMemo(() => lessonPrice * selectedLessonsNum, [lessonPrice, selectedLessonsNum]);
    const selectedCourse = useMemo(() => {
        if (isCoursesFetched) return coursesState?.data.find((course) => +course.course_id === selectedCourseId);
    }, [selectedCourseId, coursesState.data]);

    const mainClassName = useMemo(() => (preselectedCourseId ? 'buyInAddition-individual' : 'individual-courses'), [
        preselectedCourseId,
    ]);

    const handleChangeSelect = useCallback(
        (el) => {
            setSelectedCourseId(+coursesState.data.find((obj) => obj.fullname === el)?.course_id);
        },
        [coursesState],
    );

    const [flagRedirect, setFlagRedirect] = useState(false);

    useRedirect('/payment', bookCourseScheduleState.fetchStatus, flagRedirect);

    const handleSignUp = useCallback(() => {
        const sortedDates = {
            dates: getSortedDates(datesArr.map((el) => el.date)),
            lessonNumberArr: datesArr.map((el) => el.lesson_no),
        };

        dispatch(
            bookCourseScheduleStart({
                student_id: studentsState.selectedProfile.id,
                course_id: selectedCourse.course_id,
                action: 'validate',
                cost: totalPrice,
                prepay: true,
                dates: selectedTimeList.map((el) => {
                    return {
                        date: el.date.split(' ').reverse().join('-'),
                        lesson_no: getLessonNo(sortedDates, el),
                    };
                }),
                education_program: selectedCourse.fullname,
            }),
        );
        setFlagRedirect(true);
    }, [
        isCoursesFetched,
        coursesState.data,
        selectedCourseId,
        datesArr,
        dispatch,
        studentsState.selectedProfile.id,
        totalPrice,
        selectedTimeList,
    ]);

    const handleCancel = useCallback(() => {
        if (preselectedCourseId) history.push('/courses');
        else history.push('/');
    }, [history, preselectedCourseId]);

    const handleMonthChange = useCallback(
        (value: number) => {
            setSelectedMonthIndex((prevState) => {
                if (prevState + value <= scheduleMonths.length - 1 && prevState + value >= 0) return prevState + value;
                return prevState;
            });
        },
        [selectedMonthIndex, scheduleMonths],
    );

    useEffect(() => {
        if (needForConsultation && datesArr?.length) {
            const dateObj = moment(datesArr[0]?.date).toObject();
            const consultationLesson = {
                date: `${dateObj.date} ${dateObj.months + 1} ${dateObj.years}`,
                time: {
                    hours: dateObj.hours,
                    minutes: dateObj.minutes,
                },
            };
            setSelectedConsultationLesson(consultationLesson);
            setSelectedTimeList((prevState) => {
                return prevState.findIndex((el) => timeComparison(el, consultationLesson)) !== -1
                    ? [...prevState]
                    : [...prevState, consultationLesson];
            });
        }
    }, [datesArr, needForConsultation]);

    useEffect(() => {
        let monthsArr = [];
        const newScheduleMonths = remainingLessons
            .filter((el) => {
                const month = parseInt(el.date.split('-')[1]);
                if (!monthsArr.includes(month) && el.status === CourseScheduleStatus.this) {
                    monthsArr.push(month);
                    return true;
                } else return false;
            })
            .map((el) => parseInt(el.date.split('-')[1]));
        setScheduleMonths(newScheduleMonths);
    }, [remainingLessons]);

    useEffect(() => {
        const selectTeacher = document.getElementById('select-teacher') as HTMLDivElement;
        const timePicker = document.getElementById('courses-time-picker') as HTMLDivElement;
        setDatesArr([]);
        setRemainingLessons([]);
        setSelectedTimeList([]);
        setSelectedMonthIndex(0);
        setSelectedConsultationLesson(null);
        setNeedForConsultation(false);
        if (isCoursesFetched) setTeacherName(null);
        if ((isCoursesFetched && coursesState.data.length !== 0 && selectedCourse) || preselectedCourseId) {
            setTeacherName(`${selectedCourse?.teacher_firstname} ${selectedCourse?.teacher_lastname}`);
            setLessonPrice(selectedCourse?.cost);
            if (selectedCourseId && !preselectedCourseId) {
                timePicker.style.display = 'block';
                selectTeacher.style.display = 'block';
            }
            dispatch(
                loadCourseScheduleStart({
                    student_id: studentsState.selectedProfile.id,
                    course_id: selectedCourse.course_id,
                }),
            );
        }
    }, [
        selectedCourseId,
        studentsState.selectedProfile,
        coursesState.fetchStatus,
        isCoursesFetched,
        coursesState.data,
    ]);

    useEffect(() => {
        dispatch(bookCourseScheduleInitial());
    }, []);

    const handleTimeClick = useCallback(
        (value) => {
            const flag = selectedTimeList.some((el) => timeComparison(el, value));
            let newSelectedTimeList = selectedTimeList;
            flag
                ? (newSelectedTimeList = selectedTimeList.filter((el) => !timeComparison(el, value)))
                : newSelectedTimeList.push(value);
            setSelectedTimeList([...newSelectedTimeList]);
        },
        [selectedTimeList],
    );

    useEffect(() => {
        const signUpBtn = document.getElementById('signup') as HTMLButtonElement;
        if (selectedLessonsNum) {
            signUpBtn.innerText = `Записаться на ${selectedLessonsNum} ${nounsDecl(selectedLessonsNum, [
                'занятие',
                'занятия',
                'занятий',
            ])} за ${totalPrice}₽`;
            signUpBtn.style.width = '332px';
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
            signUpBtn.innerText = 'Записаться';
            signUpBtn.style.width = '220px';
        }
    }, [selectedTimeList, selectedLessonsNum, totalPrice]);

    useEffect(() => {
        if (courseScheduleState.fetchStatus === FetchStatus.FETCHING) setDatesArr([]);
        if (isCourseScheduleFetched) {
            Object.keys(courseScheduleState.data.list).map((key1) => {
                Object.keys(courseScheduleState.data.list[key1]).map((key2) => {
                    const obj = courseScheduleState.data.list[key1][key2];
                    if (courseScheduleState.data.list[key1][key2].status === CourseScheduleStatus.free) {
                        if (!datesArr.find((date) => date.date === `${moment(`${obj.date} ${obj.time}`).toDate()}`)) {
                            setDatesArr((oldDatesArr) => [
                                ...oldDatesArr,
                                { date: `${moment(`${obj.date} ${obj.time}`).toDate()}`, lesson_no: obj.lesson_no },
                            ]);
                        }
                    } else if (courseScheduleState.data.list[key1][key2].status === CourseScheduleStatus.this) {
                        if (!remainingLessons.find((date) => date.date === obj.date && date.time === obj.time)) {
                            setRemainingLessons((prevState) => [...prevState, obj]);
                        }
                    }
                });
            });
        }
    }, [courseScheduleState, isCourseScheduleFetched, preselectedCourseId]);

    return (
        <div className={mainClassName.concat('__wrapper')}>
            <div className="individual-courses__form-container">
                <div className="individual-courses-form">
                    <div className="individual-courses-form__subject">
                        {preselectedCourseId ? (
                            <div className="courses-header-text">Индивидуальное образование</div>
                        ) : (
                            <div className="individual-courses-form__subject-text">Предмет:</div>
                        )}
                        {isCoursesFetched ? (
                            <>
                                <CustomSelect
                                    placeholder="Выберите предмет"
                                    options={preselectedCourseId ? [] : coursesState.data.map((el) => el.fullname)}
                                    availableOptions={coursesState.data.filter((el) => el.available === 'True')}
                                    value={selectedCourse?.fullname}
                                    onChange={handleChangeSelect}
                                />
                                {preselectedCourseId && (
                                    <div className="individual-courses-form__teacher-price">
                                        Стоимость 1 занятия {lessonPrice}₽
                                    </div>
                                )}
                            </>
                        ) : (
                            <Skeleton width={343} height={56} />
                        )}
                    </div>
                    {!preselectedCourseId && (
                        <div className="individual-courses-form__teacher" id="select-teacher">
                            <div className="individual-courses-form__subject-text">Преподаватель:</div>
                            {teacherName !== null ? (
                                <div>
                                    <CustomSelect options={[teacherName]} value={null} onChange={() => {}} />
                                    <div className="individual-courses-form__teacher-price">
                                        Стоимость 1 занятия {lessonPrice}₽
                                    </div>
                                </div>
                            ) : (
                                <Skeleton width={343} height={56} />
                            )}
                        </div>
                    )}
                </div>
            </div>
            {preselectedCourseId && (
                <CourseSchedule
                    courseSchedule={remainingLessons}
                    isCourseScheduleFetched={isCourseScheduleFetched}
                    selectedMonth={scheduleMonths[selectedMonthIndex]}
                    handleMonthChange={handleMonthChange}
                    title={'Оставшиеся занятия'}
                    displayStatus={CourseScheduleStatus.this}
                />
            )}
            <div className={mainClassName.concat('__time-picker')} id="courses-time-picker">
                {isCourseScheduleFetched ? (
                    <TimePickerWrapper
                        preselectedCourseId={preselectedCourseId}
                        needForConsultation={needForConsultation}
                        handleNeedForConsultation={setNeedForConsultation}
                        handleClassesTimeClick={handleTimeClick}
                        dateStart={moment().toString()}
                        datesAvailable={datesArr.map((el) => el.date)}
                        selectedConsultationLesson={selectedConsultationLesson}
                        comments={selectedCourse?.comments}
                    />
                ) : (
                    <Skeleton width={350} height={150} />
                )}
            </div>
            <div className={mainClassName.concat('-buttons')}>
                {bookCourseScheduleState.fetchStatus === FetchStatus.ERROR && (
                    <BookCourseError
                        title={'Ошибка'}
                        reason={bookCourseScheduleState.error}
                        studentId={studentsState?.selectedProfile?.id}
                    />
                )}
                <div className="courses-buttons__signup">
                    <Button
                        variant="primary"
                        className="signup-btn"
                        id="signup"
                        disabled={isDisabled}
                        onClick={handleSignUp}
                    >
                        Записаться
                    </Button>
                    <Button variant="danger" className="cancel-btn" id="cancel" onClick={handleCancel}>
                        Отмена
                    </Button>
                </div>
            </div>
        </div>
    );
};
