import axios, { AxiosError, AxiosResponse } from 'axios';

import { CreateMeetingPayload, IPostInitiatedMeetingPayload, TeacherMeetingSlot } from '../redux/types/meetings';

import { BaseRestDataProvider } from './BaseRestDataProvider';

export class MeetingsDataProvider extends BaseRestDataProvider {
    loadTeachers = () => {
        return Promise.reject('its ok!')
    };

    loadTeacherSchedule = (externalId: string): Promise<TeacherMeetingSlot[]> => {
        return axios
            .get(`${this.host}/lms/proxy/?path=user-meetings-intervals/external/${externalId}/free/`)
            .then((res: AxiosResponse) => {
                return res?.data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    };

    loadScheduleById = (id: string): Promise<any> => {
        return axios
            .get(`${this.host}/initiated_meeting/${id}/free_time_for_meeting/`)
            .then((res: AxiosResponse) => {
                return res?.data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    };

    postInitiatedMeeting = (payload: IPostInitiatedMeetingPayload): Promise<any> => {
        return axios
            .post(`${this.host}/initiated_meeting/${payload.id}/set_meeting_time/`, {
                start_time: payload.start_time,
                date: payload.date,
            })
            .then((res: AxiosResponse) => {
                return res?.data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    };

    createMeeting = (payload: CreateMeetingPayload) => {
        return axios
            .post(`${this.host}/meeting/create/`, {
                start_time: payload.startTime,
                duration: payload.duration,
                teacher_external_id: payload.teacherExternalId,
                subject: payload.subject,
                student: payload.studentId,
                request_id: payload?.requestId,
            })
            .then((res) => res.data);
    };

    changeMeeting = (newTime: string, selectedMeeting: any, subject: string) => {
        return axios
            .patch(`${this.host}/meeting/partial-update/`, {
                meeting_id: selectedMeeting.id,
                start_time: newTime,
                subject,
            })
            .then((res) => res.data);
    };

    deleteMeeting = (id: string) => {
        return axios.post(`${this.host}/meetings/${id}/cancel/`, {}).then((res) => res.data);
    };

    loadMeetingsRequests = (): Promise<any> => {
        return axios
            .get(`${this.host}/meetings/requests/`)
            .then((res: AxiosResponse) => {
                return res?.data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    };
}
