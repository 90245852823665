import React, { useCallback, useState } from 'react';

import './DocumentListItem.scss';
import { connect } from 'react-redux';
import { DownloadButton } from 'components';
import { CustomSelect } from 'components/General/CustomSelect/Select';

interface IDocumentListItemProps {
    index: number;
    img: string;
    text: string;
    type: string;
    typeDoc?: string;
    size: string;
    status: string;
    icon: any;
    showStatus: boolean;
    url: string;
    thumbnail: any;
    valid?: boolean;
    delete: any;
    needInputName?: boolean;
    needDropdown?: boolean;
    needDownload?: boolean;
    showType?: boolean;
    dropdownOptions?: string[];
    deleteDocument: () => void;
    onChangeInput?: (value: string) => void;
    onChangeDropdown?: (value: string) => void;
}

const DocumentListItem: React.FC<IDocumentListItemProps> = ({
    index,
    img,
    text,
    type,
    typeDoc,
    size,
    status,
    icon,
    showStatus,
    url,
    thumbnail,
    valid = true,
    delete: deleteDoc,
    needDownload = false,
    needDropdown = false,
    dropdownOptions = [],
    onChangeDropdown,
    deleteDocument,
    needInputName = false,
    onChangeInput,
    showType = true,
}) => {
    const [focus, setFocus] = useState(false);

    const handleSetFocus = useCallback(() => {
        setFocus((prev) => !prev);
    }, []);

    return (
        <li
            className={`list-group-item d-flex align-items-center ${
                !needDownload && !deleteDoc && !icon ? 'only-title' : ''
            }`}
        >
            {/*<div className="document-list__img"><img src={!thumbnail ? "" : thumbnail} alt=""/></div>*/}
            {needInputName ? (
                <input
                    className={`document-name ${!valid ? 'document-name__not-valid' : ''}`}
                    value={text}
                    name={`name${index}${text}`}
                    placeholder="Введите имя файла"
                    onChange={(e) => onChangeInput(e.currentTarget.value)}
                />
            ) : (
                <div className="document-list__text" title={text}>
                    {text}
                </div>
            )}
            {type && showType && (
                <div className="document-list__type">
                    <span className="shape-purple">{type}</span>
                </div>
            )}
            {size && <div className="document-list__size">{size}</div>}
            {showStatus && <div className="document-list__status">{status}</div>}
            {needDropdown && (
                <div className="document-list__doc-type">
                    <CustomSelect
                        options={dropdownOptions}
                        value={typeDoc}
                        onChange={onChangeDropdown}
                        onFocus={handleSetFocus}
                    />
                </div>
            )}
            {needDownload && (
                <div className="document-list__ico">
                    <div>
                        <DownloadButton url={url} />
                    </div>
                </div>
            )}
            {deleteDoc && (
                <div className="document-list__ico">
                    <div onClick={deleteDocument}>{icon}</div>
                </div>
            )}
            {icon && (
                <div className="document-list__ico">
                    <div>{icon}</div>
                </div>
            )}
        </li>
    );
};

export default connect(
    () => ({}),
    (dispatch) => ({}),
)(DocumentListItem);
