import React, { useMemo } from 'react';
import { Select } from '../../../components/General/Select/Select';

export const MeetingTeacher: React.FC<{
    content: Array<any>;
    value: any;
    onChange: (value: any) => void;
}> = ({ content, value, onChange }) =>
    useMemo(() => {
        return (
            <div style={{ zIndex: 1000 }}>
                <div className="meeting__form-title">Выберите преподавателя:</div>
                <Select
                    placeholder="Выберите преподавателя"
                    onChange={(teacher) => onChange(teacher)}
                    value={value}
                    options={content}
                    title="Преподаватель"
                />
            </div>
        );
    }, [content, value]);
