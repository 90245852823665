import moment from 'moment';
import React, { useMemo } from 'react';
import { MeetingSlotTime } from '../../redux/types/meetings';

export const MeetingsTimeRadio: React.FC<{
    title: string;
    onChange: (value: any) => void;
    selectedDate: string;
    radioName: string;
    selectedMeetingStartTime?: string;
    timeSlots: MeetingSlotTime[];
    value: MeetingSlotTime;
}> = ({ title, onChange, selectedMeetingStartTime, radioName, selectedDate, timeSlots, value }) => {
    const renderTimeRadio = useMemo(() => {
        return timeSlots.map((timeSlot, index) => {
            return (
                <li onClick={() => onChange(timeSlot)} key={`timeslot-${index}`}>
                    <label className="meeting-radio">
                        <input
                            type="radio"
                            name={radioName}
                            checked={
                                value?.start_time === timeSlot?.start_time && value?.end_time === timeSlot?.end_time
                            }
                        />
                        <span
                            className={`meeting-radio__checkmark ${
                                selectedMeetingStartTime &&
                                timeSlot.start_time === moment(selectedMeetingStartTime).format('HH:mm') &&
                                selectedDate === moment(selectedMeetingStartTime).format('YYYY-MM-DD')
                                    ? 'meeting-radio__selected'
                                    : ''
                            }`}
                        >
                            {timeSlot.startMoscowTime}
                        </span>
                    </label>
                </li>
            );
        });
    }, [timeSlots, selectedMeetingStartTime, selectedDate, value]);

    return (
        <>
            <div className="meeting__form-title">{title}</div>
            <ul className="meeting__date-list">
                {selectedDate == moment(selectedMeetingStartTime).format('YYYY-MM-DD') && (
                    <li onClick={() => onChange(selectedMeetingStartTime)} key={selectedMeetingStartTime}>
                        <label className="meeting-radio">
                            <input type="radio" name={radioName} />
                            <span className={`meeting-radio__checkmark meeting-radio__selected`}>
                                {moment(selectedMeetingStartTime).format('HH:mm')}
                            </span>
                        </label>
                    </li>
                )}
                {renderTimeRadio}
            </ul>
        </>
    );
};
