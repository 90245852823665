import { BaseRestDataProvider } from './BaseRestDataProvider';
import moment from 'moment';
import axios, { AxiosError, AxiosResponse } from 'axios';
import {
    AssignmentProgressPreview,
    FilterTypes,
    GetHomeworkBetweenDatesParams,
    IHomeworkItem,
    LoadLmsSchedulePayload,
} from '../redux/types/schedule';
import { getFilterParams } from '../helpers/getFilterParams';
import { AssignmentProgressStatus } from '@lms-elements/atomic';
import { GetHWPreviewParams } from '../redux/types/homework';

export class ShceduleDataProvider extends BaseRestDataProvider {
    loadSchedule = (student_id: string, date: Date) => {
        let from = moment(date.setHours(0, 0, 0, 0)).day(1);
        let to = moment(date.setHours(23, 59, 0, 0)).day(7);
        return axios
            .get(
                `${
                    this.host
                }/schedule/?student_id=${student_id}&from=${from.toDate().toJSON()}&to=${to.toDate().toJSON()}`,
                {
                    withCredentials: true,
                },
            )
            .then((res) => res.data);
    };
    loadShortSchedule = (student_id: string) => {
        return axios
            .get(`${this.host}/short_schedule/?student_id=${student_id}`, {
                withCredentials: true,
            })
            .then((res) => res.data);
    };
    loadLongSchedule = (student_id: string) => {
        return axios
            .get(`${this.host}/long_schedule/?student_id=${student_id}`, {
                withCredentials: true,
            })
            .then((res) => res.data);
    };

    loadLmsSchedule = (payload: LoadLmsSchedulePayload) => {
        const searchParam = getFilterParams(payload?.query);
        return axios
            .get(
                `${this.host}/lms/proxy/?path=schedule/external/user/${payload?.external_id}/${
                    searchParam ? '&' + searchParam.slice(1) : ''
                }`,
            )
            .then((res: AxiosResponse) => {
                return res?.data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    };

    getHomeworksBetweenDates = async (
        externalId: string,
        filterType: FilterTypes,
        dateStart: string,
        dateEnd: string,
        statusFilters?: AssignmentProgressStatus[],
    ): Promise<IHomeworkItem[]> => {
        let params: GetHomeworkBetweenDatesParams;

        switch (filterType) {
            case FilterTypes.DEADLINE_FOR_COMPLETE:
                params = {
                    deadline_for_complete_after: dateStart,
                    deadline_for_complete_before: dateEnd,
                };
                break;
            case FilterTypes.DEADLINE_FOR_CHECK:
                params = {
                    deadline_for_check_after: dateStart,
                    deadline_for_check_before: dateEnd,
                };
                break;
            case FilterTypes.ASSIGNED:
            default:
                params = {
                    assigned_date_after: dateStart,
                    assigned_date_before: dateEnd,
                };
                break;
        }

        if (statusFilters && statusFilters.length > 0) {
            params.status = statusFilters.join(',');
        }

        params.external_id = externalId;

        return await axios
            .get<never, AxiosResponse<IHomeworkItem[]>>(`${this.host}/lms/proxy/?path=assignment-progress/external/`, {
                params,
            })
            .then((res) => res.data || [])
            .catch((err: AxiosError<Record<string, string>>) => {
                throw JSON.stringify(err.response?.data);
            });
    };

    getAssignmentProgressPreview = (params: GetHWPreviewParams): Promise<AssignmentProgressPreview[]> => {
        return axios
            .get(`${this.host}/lms/proxy/?path=assignment-progress/external/preview/`, { params })
            .then((res: AxiosResponse) => {
                return res?.data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    };
}
