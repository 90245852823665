import { getDocumentExt } from 'helpers/documentExt';
import { ContractStatus, IContract } from 'redux/types/contracts';
import { DocReportTypeEnum, DocStatusEnum } from './Documents.types';
import { IGymnasiumFromManager } from '../../redux/types/documents';

export const fileSize = (fileSize: number) => {
    let sizeStr = '';
    let size = fileSize;
    let counter = 0;
    while (size > 1024) {
        let buf = size / 1024;
        if (buf > 1024) {
            buf = Math.floor(buf);
        }
        size = buf;
        counter++;
    }
    const bufSize = size.toFixed(1);
    if (counter === 0) {
        sizeStr = bufSize + ' B';
    }
    if (counter === 1) {
        sizeStr = bufSize + ' KB';
    }
    if (counter === 2) {
        sizeStr = bufSize + ' MB';
    }
    if (counter === 3) {
        sizeStr = bufSize + ' GB';
    }
    return sizeStr;
};

export const normalizeContracts = (contracts: IContract[], selectedProfile: number) => {
    return contracts
        .filter(
            (contract) =>
                contract.contract_ready &&
                contract.student.id === selectedProfile &&
                contract.report?.pdf_url &&
                contract.kontur_sign_url,
        )
        .map((contract) => {
            const url = contract.report.pdf_url;
            const type = DocReportTypeEnum.PDF;

            return {
                file_name: `Договор с гимназией ${contract.number}`,
                file_link: url || undefined,
                file_format: type,
            };
        });
};

export const normalizeGymnasiumDocs = (
    gymnasium: any[],
    own: any[],
    gymnasiumFromManager: IGymnasiumFromManager[],
    selectedProfile: number,
) => {
    const uploadGymnasium = [];
    if (own) {
        own.forEach((doc) => {
            doc.files.forEach((file) => {
                uploadGymnasium.push({
                    id: file.id,
                    file_name: file.file_name || doc.upload_doc_type.description,
                    file_link: file.file,
                });
            });
        });
    }
    if (gymnasiumFromManager) {
        gymnasiumFromManager.forEach((doc) => {
            uploadGymnasium.push({
                id: doc.id,
                file_name: doc.file_name,
                file_link: doc.file,
            });
        });
    }
    if (gymnasium) {
        return gymnasium
            .filter((doc) => doc.student?.id === selectedProfile && doc.report?.pdf_url)
            .map((doc) => {
                const url = doc.report.pdf_url;
                const type = DocReportTypeEnum.PDF;

                return {
                    file_name: `${doc.type_rus} №${doc.id}`,
                    file_link: url,
                    file_format: type,
                };
            })
            .concat(uploadGymnasium);
    }
    return [];
};

export const normalizeOwnDocs = (own: any[]) => {
    if (own) {
        const array = [];
        own.filter((doc) => doc.state === 'recognized').forEach((doc) => {
            doc.files.forEach((file) => {
                const type = getDocumentExt(file.file_name);
                array.push({
                    id: file.id,
                    file_name: doc.upload_doc_type.name,
                    file_link: file.file,
                    file_format: type,
                });
            });
        });
        return array;
    }
    return [];
};

export const normalizeLatestDocs = (latest: any[]) => {
    if (latest) {
        const array = [];
        latest
            .filter((doc) => doc.state !== 'recognized')
            .forEach((doc) => {
                doc.files.forEach((file) => {
                    const type = getDocumentExt(file.file_name);
                    const status =
                        file.status === 'need_correction'
                            ? file.comment || DocStatusEnum[file.status]
                            : DocStatusEnum[file.status];
                    array.push({
                        id: file.id,
                        file_name: file.file_name,
                        doc_type: doc.upload_doc_type,
                        file_link: file.file,
                        file_status: status,
                        file_format: type,
                    });
                });
            });
        return array;
    }
    return [];
};

export const getTypesNotApproved = (own, types) => {
    if (own) {
        return types.filter((type) => own.find((doc) => doc.upload_doc_type.id === type.id)?.state !== 'recognized');
    }
    return types;
};

export const getParentTypesNotApproved = (parentDocuments, parentTypes) => {
    if (parentDocuments) {
        return parentTypes.filter(
            (type) => parentDocuments.find((doc) => doc.upload_doc_type.id === type.id)?.state !== 'recognized',
        );
    }
    return parentTypes;
};

export const enrollmentCertReqCondition = (
    contracts: IContract[],
    educationCosts: Record<string, string>,
    studentId,
) => {
    return contracts
        .filter(
            (contract) =>
                educationCosts[contract.education_cost].indexOf('Аттестационный') == -1 &&
                contract?.student?.id === studentId,
        )
        .some((contract) => contract.status !== ContractStatus.TERMINATED);
};
