import moment from 'moment';

export const convertDateToHtmlDateString = (date?: Date | string): string => moment(date).format('YYYY-MM-DD');

export const getStartOfMonth = (date?: Date | string): string => {
    return moment(date).startOf('month').format('YYYY-MM-DD');
};

export const getEndOfMonth = (date?: Date | string): string => {
    return moment(date).endOf('month').format('YYYY-MM-DD');
};

export const getStartOfWeek = (date?: Date | string): string => {
    return moment(date).startOf('week').format('YYYY-MM-DD');
};

export const getEndOfWeek = (date?: Date | string): string => {
    return moment(date).endOf('week').format('YYYY-MM-DD');
};

export const getEarliestDate = (dates: string[]): string => {
    return dates.reduce((closestDate, date) => {
        return moment(closestDate).isBefore(date) ? moment(closestDate).format() : moment(date).format();
    }, moment().format());
};
