import moment from 'moment';
import { useMemo } from 'react';

export const hoursOffset = new Date().getTimezoneOffset() / 60 + 3;

export const getMoscowDate = () => {};

export const nounsDecl = (number, words) => {
    return words[
        number % 100 > 4 && number % 100 < 20 ? 2 : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? Math.abs(number) % 10 : 5]
    ];
};

export const normalizeDate = (dateStr: string): string => {
    return dateStr.split('-').reverse().join('.');
};

export const convertToDateStr = (receivedDateStr) => {
    if (receivedDateStr && receivedDateStr.indexOf('.') !== -1) {
        return receivedDateStr.split('.').reverse().join('-');
    }
    return receivedDateStr;
};

export const checkForPassport = (birthday) => moment().diff(convertToDateStr(birthday), 'years') >= 14;

export const getLocalHours = (hours: number) => {
    let result: Array<any> = [];
    let newHours = Number(hours);
    if (hoursOffset >= 0) {
        newHours = newHours - Math.abs(hoursOffset);
        result.push(Math.trunc(newHours));
        if (newHours % 1 === 0) {
            result.push(0);
        } else {
            result.push(30);
        }
    } else {
        newHours = newHours + Math.abs(hoursOffset);
        result.push(Math.trunc(newHours));
        if (newHours % 1 === 0) {
            result.push(0);
        } else {
            result.push(30);
        }
    }

    result.push(0);

    return result;
};

export const formatTeacherSchedule = (schedule: Array<any>) => {
    return schedule.reduce((acc: any, curr) => {
        let newAcc = JSON.parse(JSON.stringify(acc));
        let [startHours, startMinutes] = curr.start_time.split(':');
        let [endHours, endMinutes] = curr.end_time.split(':');

        let localStartDate = new Date(
            moment().set('weekday', curr.weekday).toDate().setHours(startHours, startMinutes, 0, 0),
        );
        let localEndDate = new Date(
            moment().set('weekday', curr.weekday).toDate().setHours(endHours, endMinutes, 0, 0),
        );

        if (!newAcc[localStartDate.getDay()]) {
            newAcc[localStartDate.getDay()] = [];
        }

        if (!newAcc[localEndDate.getDay()]) {
            newAcc[localEndDate.getDay()] = [];
        }

        let weekday = curr.weekday === 6 ? 0 : curr.weekday + 1;

        if (localStartDate.getDay() < weekday && localEndDate.getDay() < weekday) {
            newAcc[localStartDate.getDay()].push({
                ...curr,
                start_time: moment(localStartDate).format('HH:mm:ss'),
                end_time: moment(localEndDate).format('HH:mm:ss'),
                weekday: localStartDate.getDay(),
            });
            return newAcc;
        }

        if (localStartDate.getDay() < weekday && localEndDate.getDay() == weekday) {
            newAcc[localStartDate.getDay()].push({
                ...curr,
                start_time: moment(localStartDate).format('HH:mm:ss'),
                end_time: '24:00:00',
                weekday: localStartDate.getDay(),
            });
            newAcc[localEndDate.getDay()].push({
                ...curr,
                start_time: '00:00:00',
                end_time: moment(localEndDate).format('HH:mm:ss'),
                weekday: localEndDate.getDay(),
            });
            return newAcc;
        }

        if (localStartDate.getDay() == weekday && localEndDate.getDay() == weekday) {
            newAcc[localStartDate.getDay()].push({
                ...curr,
                start_time: moment(localStartDate).format('HH:mm:ss'),
                end_time: moment(localEndDate).format('HH:mm:ss'),
                weekday: localStartDate.getDay(),
            });
            return newAcc;
        }

        if (localStartDate.getDay() == weekday && localEndDate.getDay() > weekday) {
            newAcc[localStartDate.getDay()].push({
                ...curr,
                start_time: moment(localStartDate).format('HH:mm:ss'),
                end_time: '24:00:00',
                weekday: localStartDate.getDay(),
            });
            newAcc[localEndDate.getDay()].push({
                ...curr,
                start_time: '00:00:00',
                end_time: moment(localEndDate).format('HH:mm:ss'),
                weekday: localEndDate.getDay(),
            });
            return newAcc;
        }

        if (localStartDate.getDay() > weekday && localEndDate.getDay() > weekday) {
            newAcc[localStartDate.getDay()].push({
                ...curr,
                start_time: moment(localStartDate).format('HH:mm:ss'),
                end_time: moment(localEndDate).format('HH:mm:ss'),
                weekday: localStartDate.getDay(),
            });
            return newAcc;
        }

        if (localStartDate.getDay() == weekday && localEndDate.getDay() === 0) {
            newAcc[localStartDate.getDay()].push({
                ...curr,
                start_time: moment(localStartDate).format('HH:mm:ss'),
                end_time: '24:00:00',
                weekday: localStartDate.getDay(),
            });
            newAcc[localEndDate.getDay()].push({
                ...curr,
                start_time: '00:00:00',
                end_time: moment(localEndDate).format('HH:mm:ss'),
                weekday: localEndDate.getDay(),
            });
            return newAcc;
        }

        if (localStartDate.getDay() == 6 && localEndDate.getDay() === weekday) {
            newAcc[localStartDate.getDay()].push({
                ...curr,
                start_time: moment(localStartDate).format('HH:mm:ss'),
                end_time: '24:00:00',
                weekday: localStartDate.getDay(),
            });
            newAcc[localEndDate.getDay()].push({
                ...curr,
                start_time: '00:00:00',
                end_time: moment(localEndDate).format('HH:mm:ss'),
                weekday: localEndDate.getDay(),
            });
            return newAcc;
        }

        return newAcc;
    }, {});
};
