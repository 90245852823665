import React, { useCallback } from 'react';
import { default as placeholderImg } from 'assets/img/img_placeholder.png';
import { ReactComponent as XIcon } from 'assets/img/x-icon.svg';

import { INotificationCardProps } from './NotificationCard.types';

import './NotificationCard.scss';

export const NotificationCard: React.FC<INotificationCardProps> = ({
    type = 'action',
    size = 'm',
    closeButton = false,
    title = '',
    titleLarge = false,
    textLarge = false,
    text = '',
    imageSrc = '',
    qrLink = '',
    handleCloseButtonClick,
    children,
}) => {
    const closeButtonClick = useCallback(() => {
        if (handleCloseButtonClick) {
            handleCloseButtonClick();
        }
    }, [handleCloseButtonClick]);

    return (
        <div
            className={`notification notification__${type} notification__${size}${
                qrLink || imageSrc ? ' flex-center' : ''
            }`}
        >
            {closeButton && (
                <div className="notification__icon">
                    <XIcon onClick={closeButtonClick} />
                </div>
            )}
            {imageSrc && <img src={imageSrc} />}
            <div className={'notification__main-container'}>
                {title && <p className={`title${titleLarge ? ' title__large' : ''}`}>{title}</p>}
                {text && <p className={`text${textLarge ? ' text__large' : ''}`}>{text}</p>}
                {children}
            </div>
            {type === 'placeholder' && <img src={placeholderImg} className="placeholder_img" />}
        </div>
    );
};
