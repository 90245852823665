import React from 'react';

import { getMarkColor } from 'helpers/getMarksColor';
import MarkItem from 'components/Mark/MarkItem';
import { Explanation } from 'components/Explanation';

import './EmptyMarks.scss';

const marksMock = [
    {
        mark: '5',
        subject: 'Английский язык',
        goal: 'Самоподготовка',
        markDate: '22.05.2020 | 13:45',
    },
    {
        mark: '3',
        subject: 'Информатика',
        goal: 'Контрольная работа',
        markDate: '22.05.2020 | 13:45',
    },
    {
        mark: '2',
        subject: 'Обществознание',
        goal: 'Урок',
        markDate: '22.05.2020 | 13:45',
    },
    {
        mark: '4',
        subject: 'Русский язык',
        goal: 'Домашняя работа',
        markDate: '22.05.2020 | 13:45',
    }
];

export const EmptyMarks: React.FC = () => {
    return (
        <div className="empty-marks">
            <Explanation
                title="Последние оценки"
                description="Когда будут получены первые оценки, они будут показаны ниже"
                withGrayScale
            >
                <div className="empty-marks__marks">
                    {marksMock.map((markInfo, index) => (
                        <MarkItem
                            key={index}
                            className={getMarkColor(markInfo.mark)}
                            {...markInfo}
                        />
                    ))}
                </div>
            </Explanation>
        </div>
    );
}
