import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { getType } from 'typesafe-actions';
import { PageStatus } from '../../common/types';
import { loadPaymentInfo, loadPaymentInfoError, loadPaymentInfoSuccess } from '../actions/paymentInfo';
import { Action } from '../store';
import { PaymentInfoState } from '../types/paymentInfo';

const defaultPaymentInfoState = { pageStatus: PageStatus.LOADING, info: [], error: null };

export const paymentInfo = (state: PaymentInfoState = defaultPaymentInfoState, action: Action) => {
    switch (action.type) {
        case getType(loadPaymentInfo): {
            return {
                ...state,
                pageStatus: PageStatus.LOADING,
            };
        }

        case getType(loadPaymentInfoSuccess): {
            return {
                ...state,
                pageStatus: PageStatus.LOADED,
                info: action.payload,
            };
        }

        case getType(loadPaymentInfoError): {
            return {
                ...state,
                pageStatus: PageStatus.ERROR,
                error: action.payload,
            };
        }

        default: {
            return state;
        }
    }
};
