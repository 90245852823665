import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { getType } from 'typesafe-actions';

import { VERSION } from '../../common/constants';
import { FetchStatus, PageStatus } from '../../common/types';
import { normalizeTeacherSlots } from '../../pages/Meeting/Meeting.utils';
import {
    changeMeetingSuccess,
    createMeeting,
    createMeetingError,
    createMeetingSuccess,
    loadMeetingsRequests,
    loadMeetingsRequestsError,
    loadMeetingsRequestsSuccess,
    loadScheduleByIdError,
    loadScheduleByIdStart,
    loadScheduleByIdSuccess,
    loadTeachers,
    loadTeacherSchedule,
    loadTeacherScheduleError,
    loadTeacherScheduleSuccess,
    loadTeachersError,
    loadTeachersSuccess,
    postInitiatedMeetingError,
    postInitiatedMeetingStart,
    postInitiatedMeetingSuccess,
} from '../actions/meetings';
import { Action } from '../store';
import { MeetingsState } from '../types/meetings';

const defaultMeetingsState: MeetingsState = {
    pageStatus: PageStatus.INITIAL,
    teachers: null,
    error: null,
    created: false,
    createStatus: 'NOT_CREATED',
    changeStatus: 'NOT_CHANGED',
    selectedTeacher: null,
    initiatedMeetingSchedule: null,
    initiatedMeetingStatus: FetchStatus.FETCHING,
    duration: null,
    meetingSlots: null,
    meetingsRequests: null,
    meetingsRequestsError: null,
    meetingsRequestsStatus: FetchStatus.INITIAL,
};

const reducer = (state: MeetingsState = defaultMeetingsState, action: Action) => {
    switch (action.type) {
        case getType(loadTeachers): {
            return {
                ...state,
                pageStatus: PageStatus.LOADING,
                created: false,
                createStatus: 'NOT_CREATED',
                changeStatus: 'NOT_CHANGED',
            };
        }

        case getType(loadTeachersSuccess): {
            return {
                ...state,
                pageStatus: PageStatus.LOADED,
                teachers: action.payload,
            };
        }
        case getType(loadTeachersError): {
            return {
                ...state,
                pageStatus: PageStatus.ERROR,
                error: action.payload,
            };
        }

        case getType(createMeeting): {
            return {
                ...state,
                createStatus: 'FETCHING',
                created: false,
            };
        }

        case getType(createMeetingSuccess): {
            return {
                ...state,
                createStatus: 'CREATED',
                created: true,
            };
        }

        case getType(createMeetingError): {
            return {
                ...state,
                createStatus: 'ERROR',
                created: false,
                error: action.payload,
            };
        }

        case getType(changeMeetingSuccess): {
            return {
                ...state,
                changeStatus: 'CHANGED',
            };
        }

        case getType(loadTeacherSchedule): {
            return {
                ...state,
                pageStatus: PageStatus.LOADING,
            };
        }

        case getType(loadTeacherScheduleSuccess): {
            return {
                ...state,
                meetingSlots: normalizeTeacherSlots(action.payload),
                pageStatus: PageStatus.LOADED,
            };
        }

        case getType(loadTeacherScheduleError): {
            return {
                ...state,
                pageStatus: PageStatus.ERROR,
                error: action.payload,
            };
        }

        case getType(loadScheduleByIdStart): {
            return {
                ...state,
                pageStatus: PageStatus.LOADING,
            };
        }

        case getType(loadScheduleByIdSuccess): {
            return {
                ...state,
                pageStatus: PageStatus.LOADED,
                initiatedMeetingSchedule: action.payload.schedules,
                duration: action.payload.duration,
            };
        }

        case getType(loadScheduleByIdError): {
            return {
                ...state,
                pageStatus: PageStatus.ERROR,
                error: action.payload,
            };
        }

        case getType(postInitiatedMeetingStart): {
            return {
                ...state,
                initiatedMeetingStatus: FetchStatus.FETCHING,
            };
        }

        case getType(postInitiatedMeetingSuccess): {
            return {
                ...state,
                initiatedMeetingStatus: FetchStatus.FETCHED,
            };
        }

        case getType(postInitiatedMeetingError): {
            return {
                ...state,
                initiatedMeetingStatus: FetchStatus.ERROR,
            };
        }

        case getType(loadMeetingsRequests): {
            return {
                ...state,
                meetingsRequestsStatus: FetchStatus.FETCHING,
            };
        }

        case getType(loadMeetingsRequestsSuccess): {
            return {
                ...state,
                meetingsRequestsStatus: FetchStatus.FETCHED,
                meetingsRequests: action.payload,
                meetingsRequestsError: null,
            };
        }

        case getType(loadMeetingsRequestsError): {
            return {
                ...state,
                meetingsRequestsStatus: FetchStatus.ERROR,
                meetingsRequestsError: action.payload,
            };
        }

        default: {
            return state;
        }
    }
};

export default persistReducer(
    {
        key: `meetings::${VERSION}`,
        storage: storage,
        blacklist: ['selectedTeacher'],
    },
    reducer,
);
