import axios from 'axios';
import { BaseRestDataProvider } from './BaseRestDataProvider';

class PaymentDataProvider extends BaseRestDataProvider {
    loadContracts = (userId: string) => {
        return axios
            .get(`${this.host}/payment/schedules/?paid=false&frozen=false&page_size=100&user=${userId}`, {
                headers: {},
            })
            .then((res) => res.data);
    };

    loadPaidContracts = (userId: string) => {
        return axios
            .get(`${this.host}/payment/schedules/?paid=true&frozen=false&page_size=100&user=${userId}`, {
                headers: {},
            })
            .then((res) => res.data);
    };

    createPayment = (paymentsArea: Array<number>) => {
        return axios
            .post(
                `${this.host}/sber/payment/`,
                {
                    payments_area: paymentsArea,
                },
                {
                    withCredentials: true,
                },
            )
            .then((res) => res.data);
    };

    loadPaymentInfo = (order: string) => {
        return axios
            .get(`${this.host}/sber/payment/info/${order}/`, {
                headers: {},
                withCredentials: true,
            })
            .then((res) => res.data);
    };

    async fetchUpcomingPayments(userId) {
        return await axios.get(`${this.host}/payment/schedules/?upcoming=True&frozen=false&user=${userId}`);
    }

    async fetchDebtPayments(userId) {
        return await axios.get(`${this.host}/payment/schedules/?debt=True&frozen=false&user=${userId}`);
    }
}

export { PaymentDataProvider };
