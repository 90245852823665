import { FetchStatus, IReport, ISchool } from 'common/types';

export interface IContractStudent {
    age: number;
    birth_certificate: string;
    birthday: string;
    city: string;
    education_cost: number;
    education_cost_words: string;
    first_name: string;
    full_name_genetive: string;
    grade: number;
    id: number;
    last_name: string;
    learning_hours: number;
    learning_program: string;
    living_place: string;
    moodle_id: number;
    patronymic: string;
    phone: string;
    photo: string;
    school: ISchool;
}

export enum ContractStatus {
    ACTIVE = 'active',
    TERMINATED = 'terminated',
    PROLONG = 'prolong',
}

export interface IContract {
    id: number;
    payments_area: any[];
    order_number: number;
    number: string;
    agreed: string;
    start: string;
    end: string;
    report_contract_payment_schedule: any;
    note: string;
    kontur_sign_url: string;
    kontur_signed: boolean;
    courier_paid: boolean;
    courier_price: number;
    courier_tracking: string;
    parent_seen: boolean;
    signed_with: string;
    ready_to_sign: boolean;
    delivery_address: string;
    want_to_sign_by_edc: boolean;
    want_to_sign_by_courier: boolean;
    contract_ready: boolean;
    counterparty: number;
    student: IContractStudent;
    payment_schedule: any;
    courier_bill_file: any;
    report: IReport;
    education_cost?: number;
    status?: ContractStatus;
}

export interface ContractsState {
    getStatusContracts: FetchStatus;
    getStatusSchoolInfo: FetchStatus;
    patchStatusContractSeen: FetchStatus;
    postStatusRefuseToSign: FetchStatus;
    patchStatusContractReadyToSign: FetchStatus;
    postStatusMissingKonturUrl: FetchStatus;
    patchStatusContractMethodSign: FetchStatus;
    postStatusCourierPaid: FetchStatus;
    postStatusHaveQuestions: FetchStatus;
    postStatusAdress: FetchStatus;
    contracts: {
        results: IContract[];
    };
    error: any;
}

export interface IGetContractsPayload {
    userId: number;
}
