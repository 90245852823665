import axios, { AxiosError, AxiosResponse } from 'axios';

import { LoadMeetingUrlPayload } from '../redux/types/near';
import { IAddParentPayload, ILoadParentsPayload } from '../redux/types/profile';

import { BaseRestDataProvider } from './BaseRestDataProvider';

export class ProfileDataProvider extends BaseRestDataProvider {
    loadProfile = () => {
        return axios
            .get(`${this.host}/students/`, {
                withCredentials: true,
            })
            .then((res: AxiosResponse) => res?.data)
            .catch((error: AxiosError) => {
                throw error;
            });
    };

    addParent = (payload: IAddParentPayload) => {
        return axios
            .post(`${this.host}/students/${payload.student_id}/add_parent/`, payload.data)
            .then((res) => res.data);
    };

    loadParents = (payload: ILoadParentsPayload) => {
        return axios
            .get(`${this.host}/parents_by_student/${payload.student_id}/`)
            .then((res: AxiosResponse) => res?.data)
            .catch((error: AxiosError) => {
                throw error;
            });
    };

    loadNearest = async () => {
        return axios
            .get(`${this.host}/meeting/list-from-lms/`)
            .then((res: AxiosResponse) => res?.data)
            .catch((error: AxiosError) => {
                throw error;
            });
    };

    loadMeetingUrl = async (payload: LoadMeetingUrlPayload) => {
        return axios
            .post(`${this.host}/lms/proxy/?path=meeting/join/external/`, payload)
            .then((res: AxiosResponse) => res?.data)
            .catch((error: AxiosError) => {
                throw error;
            });
    };

    loadTeachers = async (teachers: any) => {
        const result = [];
        for (const teacherId of teachers) {
            const teacher = await axios
                .get(`${this.host}/teachers/${teacherId}/`)
                .then((res) => res.data)
                .catch((err) => ({}));

            result.push(teacher);
        }
        return result;
    };

    loadSelectedProfile = async (profile: any) => {
        return await axios.get(`${this.host}/students/${profile.id}/`).then((res) => res.data);
    };

    loadUser = async () => {
        return await axios.get(`${this.host}/users/`).then((res) => res.data);
    };
}
