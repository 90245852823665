import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { cn } from '@bem-react/classname';

import './NavBarItem.scss';
import { INavbarItemProps } from './NavbarItem.types';
const CnNavbarItem = cn('nav-bar-item');

export const NavbarItem: React.FC<INavbarItemProps> = ({
    index,
    currentPath,
    path,
    text,
    icon,
    isMobile,
    onNavbarItemClick,
    isOpened,
    getActiveElementData,
    style,
    setHover,
    isPrompt,
}) => {
    const navItemRef = useRef<HTMLDivElement>();
    const handleNavBarItemClick = useCallback(
        (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();

            onNavbarItemClick(path);
        },
        [onNavbarItemClick, path],
    );
    const [isActive, setActive] = useState(false);
    const returnActiveData = () => {
        getActiveElementData?.({
            positionX: navItemRef?.current?.getBoundingClientRect()?.top ?? 0,
            index: index,
        });
    };

    const onEnterElement = () => {
        setHover?.(true);
        setActive(true);
        returnActiveData();
    };

    const onLeaveElement = () => {
        setHover?.(false);
        setActive(false);
    };

    const scrollHandler = () => {
        if (isActive) {
            returnActiveData();
        }
    };

    useEffect(() => {
        if (isActive) {
            window.addEventListener('scroll', scrollHandler, true);
            return () => {
                window.removeEventListener('scroll', scrollHandler, true);
            };
        }
    }, [isActive]);

    const isChecked = useMemo(() => currentPath === path, [currentPath, path]);
    const isHidden = !isOpened && !isMobile;
    return (
        <div style={style} ref={navItemRef} className={CnNavbarItem('container', { isPrompt })}>
             {isHidden && !isPrompt && (
              <a onClick={handleNavBarItemClick}>
                <div
                  onMouseEnter={onEnterElement}
                  onMouseLeave={onLeaveElement}
                  className={CnNavbarItem('hover-area')}
                />
              </a>
             )}
            <a
                className={CnNavbarItem({ isChecked, isMobile, isHidden, isPrompt })}
                href={path}
                tabIndex={-1}
                onClick={handleNavBarItemClick}
            >
                <span className={CnNavbarItem('icon', { isMobile })}>{icon}</span>
                <span className={CnNavbarItem('text')}>{text}</span>
            </a>
        </div>
    );
};
