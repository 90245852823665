import { AxiosError } from 'axios';
import { combineEpics, ofType } from 'redux-observable';
import { fetchLoginError, fetchLoginStart, fetchLoginSuccess, logout } from '../actions/login';
import { from, of } from 'rxjs';
import { catchError, switchMap, mapTo } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';
import { FuncEpic } from '../../common/types';
import { purgeProfile, purgeSelectedProfile } from '../actions/profile';
import Cookies from 'universal-cookie';
import { setAccessToken, setRefreshToken } from '../../common/token';

const fetchloginEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(fetchLoginStart)),
        switchMap(({ payload }: ActionType<typeof fetchLoginStart>) => {
            return from(deps.loginDataProvider.fetchLogin(payload)).pipe(
                switchMap((response: any) => {
                    setAccessToken(response.access);
                    setRefreshToken(response.refresh);
                    return of(fetchLoginSuccess(response));
                }),
                catchError((err: AxiosError) => {
                    return of(fetchLoginError(err));
                }),
            );
        }),
    );
};

const fetchLogoutEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(logout)),
        switchMap(() => {
            return of(purgeSelectedProfile(), purgeProfile());
        }),
    );
};

export const loginEpics = combineEpics(fetchloginEpic, fetchLogoutEpic);
