import React, { useEffect, useMemo } from 'react';
import { Col } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { PageStatus } from '../../../common/types';
import { loadMeetingUrl, loadNear } from '../../../redux/actions/near';
import { State, useAppSelector } from '../../../redux/store';
import { Meeting } from '../../../redux/types/near';

import { HeaderMeetingList } from './HeaderMeetingList/HeaderMeetingList';

import './HeaderMeeting.scss';
import { clearCourseGroups, loadCourseGroupsStart } from '../../../redux/actions/courses';
import { useHorizontalScroll } from '../../../hooks/useHorizontalScroll';

const HeaderMeeting: React.FC<{
    pageStatus: PageStatus;
    event: Meeting[];
    error: any;
    loadNearest: (pageStatus: PageStatus) => void;
    profilePageStatus: PageStatus;
}> = ({ pageStatus, loadNearest, profilePageStatus, event }) => {
    const dispatch = useDispatch();
    const user = useAppSelector((store) => store.user);
    const { meetingsRequests } = useAppSelector((store) => store.meetings);
    const { profile } = useAppSelector((store) => store.selectedProfile);

    useEffect(() => {
        if (profile?.lms_id) dispatch(loadCourseGroupsStart({ students: [profile.lms_id] }));
        else {
            dispatch(clearCourseGroups());
        }
    }, [dispatch, profile?.lms_id]);

    useEffect(() => {
        if (profilePageStatus === PageStatus.LOADED) {
            loadNearest(pageStatus);
        }
    }, [profilePageStatus]);

    useEffect(() => {
        if (pageStatus === PageStatus.LOADED && event?.length && user?.user?.id) {
            event.forEach((meet) => {
                const timeDiff = moment(meet.datetimeStart).diff(moment(), 'minutes');
                if (timeDiff <= 10)
                    dispatch(
                        loadMeetingUrl({
                            firstName: user?.user?.first_name,
                            lastName: user?.user?.last_name,
                            patronymic: user?.user?.patronymic,
                            assembly: meet.id,
                        }),
                    );
            });
        }
    }, [dispatch, pageStatus, user?.user?.first_name, user?.user?.id, user?.user?.last_name, user?.user?.patronymic]);

    const actualMeetings = useMemo(
        () => (event ? event?.filter((meet) => moment(meet.datetimeEnd).diff(moment()) > 0) : []),
        [event],
    );

    const scrollRef = useHorizontalScroll();

    return (
        <div className="row overflow-hidden">
            <div className="meeting-panel">
                <div className="meeting-panel__col meeting-panel__btn">
                    <Link to="/meeting" className="meeting-card meeting-card--link">
                        <header className="meeting-card__header">
                            <i className="icon-meeting"></i> Запись на консультацию
                        </header>
                    </Link>
                </div>
                <div style={{ alignItems: 'center', display: 'flex', overflow: 'hidden', width: '100%' }}>
                    <>
                        <div className="meeting-panel__col ml-3">
                            <span className="meeting-panel__title">
                                {!actualMeetings?.length
                                    ? 'Запланированных консультаций нет'
                                    : 'Ближайшие консультации:'}
                            </span>
                        </div>

                        <Col className="col-9 meeting-panel__col meeting-panel__events meeting-panel__column-list">
                            <div
                                className="custom-scroll meeting-panel__container"
                                data-scroll-pos="outside"
                                data-scroll="x"
                                id={'horizontal-scroll-container'}
                                ref={scrollRef}
                            >
                                {pageStatus === PageStatus.LOADED && (
                                    <ul className="meeting-panel__event-list">
                                        <HeaderMeetingList event={actualMeetings} requests={meetingsRequests} />
                                    </ul>
                                )}
                            </div>
                        </Col>
                    </>
                </div>
            </div>
        </div>
    );
};

export default connect(
    ({ near, profile }: State) => ({ ...near, profilePageStatus: profile.pageStatus }),
    (dispatch) => {
        return {
            loadNearest: (pageStatus: PageStatus) => {
                return dispatch(loadNear(pageStatus));
            },
        };
    },
)(HeaderMeeting);
