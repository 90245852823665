import { Action } from '../store';
import { PageStatus } from '../../common/types';
import { RatingState } from '../types/rating';
import { getType } from 'typesafe-actions';
import { loadRating, loadRatingError, loadRatingSuccess } from '../actions/rating';

const defaultProfileState = { pageStatus: PageStatus.LOADING, rating: null, error: null, courses: null }

const reducer = (state: RatingState = defaultProfileState, action: Action) => {
    switch(action.type) {
        case getType(loadRating): {
            return {
                ...state,
                pageStatus: PageStatus.LOADING
            }
        }

        case getType(loadRatingSuccess): {
            return {
                ...state,
                pageStatus: PageStatus.LOADED,
                rating: action.payload.rating,
                courses: action.payload.courses
            }
        }

        case getType(loadRatingError): {
            return {
                ...state,
                pageStatus: PageStatus.ERROR,
                error: action.payload
            }
        }
        default: {
            return state
        }
    }
}

export default reducer;

// persistReducer({
//   key: `rating::${VERSION}`,
//   storage: storage,
//   blacklist: [],
//   version: VERSION,
// }, reducer)
