import { ActionType, createAction } from 'typesafe-actions';

import { IRegistration, IRegistrationStudent, IStudentInfo, IUserInfo } from '../types/registration';

export const regUserStart = createAction('registration/regUser/start')<IRegistration>();
export const regUserSuccess = createAction('registration/regUser/success')<any>();
export const regUserError = createAction('registration/regUser/error')<any>();
export const patchUserInfoStart = createAction('registration/patchUserInfo/start')<IUserInfo | FormData>();
export const patchUserInfoSuccess = createAction('registration/patchUserInfo/success')<any>();
export const patchUserInfoError = createAction('registration/patchUserInfo/error')<any>();
export const regStudentStart = createAction('registration/regStudent/start')<IRegistrationStudent>();
export const regStudentSuccess = createAction('registration/regStudent/success')<any>();
export const regStudentError = createAction('registration/regStudent/error')<any>();
export const patchStudentInfoStart = createAction('registration/patchStudentInfo/start')<IStudentInfo | FormData>();
export const patchStudentInfoSuccess = createAction('registration/patchStudentInfo/success')<any>();
export const patchStudentInfoError = createAction('registration/patchStudentInfo/error')<any>();

export type RegistrationActions =
    | ActionType<typeof regUserStart>
    | ActionType<typeof regUserSuccess>
    | ActionType<typeof regUserError>
    | ActionType<typeof patchUserInfoStart>
    | ActionType<typeof patchUserInfoSuccess>
    | ActionType<typeof patchUserInfoError>
    | ActionType<typeof regStudentStart>
    | ActionType<typeof regStudentSuccess>
    | ActionType<typeof regStudentError>
    | ActionType<typeof patchStudentInfoStart>
    | ActionType<typeof patchStudentInfoSuccess>
    | ActionType<typeof patchStudentInfoError>;
