import React from 'react'

import {TeacherReviewLisItem} from '../..'

import './TeacherReviewList.scss'

const TeacherReviewList: React.FC<{ review: any }> = ({ review }) => {

    return (
        
        <div className="rating-table__commit">
            <h6 className="rating-table__commit-title">Примечание</h6>
            {
                !review ?
                <p>Примечание отсутствует</p>
                :
                <>
                {
                    review.map((el: any, index:number) => (
                        <TeacherReviewLisItem key={index} review={el} />
                    ))
                }
                </>
            }
        </div>
    )
}

export default TeacherReviewList
