import { FuncEpic } from '../../common/types';
import { combineEpics, ofType } from 'redux-observable';
import { ActionType, getType } from 'typesafe-actions';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { AxiosError } from 'axios';
import {
    bookCourseScheduleError,
    bookCourseScheduleStart,
    bookCourseScheduleSuccess,
    loadAdditionalCoursesError,
    loadAdditionalCoursesStart,
    loadAdditionalCoursesSuccess,
    loadCourseGroupsError,
    loadCourseGroupsStart,
    loadCourseGroupsSuccess,
    loadCourseScheduleError,
    loadCourseScheduleStart,
    loadCourseScheduleSuccess,
    loadIndividualCoursesError,
    loadIndividualCoursesStart,
    loadIndividualCoursesSuccess,
    loadStudentCoursesError,
    loadStudentCoursesStart,
    loadStudentCoursesSuccess,
} from '../actions/courses';

const loadAdditionalCoursesEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(loadAdditionalCoursesStart)),
        switchMap(({ payload }: ActionType<typeof loadAdditionalCoursesStart>) => {
            return from(deps.coursesDataProvider.loadAdditionalCourses(payload)).pipe(
                switchMap((response: any) => {
                    return of(loadAdditionalCoursesSuccess(response));
                }),
                catchError((err: AxiosError) => {
                    return of(loadAdditionalCoursesError(err));
                }),
            );
        }),
    );
};

const loadIndividualCoursesEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(loadIndividualCoursesStart)),
        switchMap(({ payload }: ActionType<typeof loadIndividualCoursesStart>) => {
            return from(deps.coursesDataProvider.loadIndividualCourses(payload)).pipe(
                switchMap((response: any) => {
                    return of(loadIndividualCoursesSuccess(response));
                }),
                catchError((err: AxiosError) => {
                    return of(loadIndividualCoursesError(err));
                }),
            );
        }),
    );
};

const loadStudentCoursesEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(loadStudentCoursesStart)),
        switchMap(({ payload }: ActionType<typeof loadStudentCoursesStart>) => {
            return from(deps.coursesDataProvider.loadStudentCourses(payload)).pipe(
                switchMap((response: any) => {
                    return of(loadStudentCoursesSuccess(response));
                }),
                catchError((err: AxiosError) => {
                    return of(loadStudentCoursesError(err));
                }),
            );
        }),
    );
};

const loadCourseScheduleEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(loadCourseScheduleStart)),
        switchMap(({ payload }: ActionType<typeof loadCourseScheduleStart>) => {
            return from(deps.coursesDataProvider.loadCourseSchedule(payload)).pipe(
                switchMap((response: any) => {
                    return of(loadCourseScheduleSuccess(response));
                }),
                catchError((err: AxiosError) => {
                    return of(loadCourseScheduleError(err));
                }),
            );
        }),
    );
};

const bookCourseScheduleEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(bookCourseScheduleStart)),
        switchMap(({ payload }: ActionType<typeof bookCourseScheduleStart>) => {
            return from(deps.coursesDataProvider.bookCourseSchedule(payload)).pipe(
                switchMap((response: any) => {
                    const url = response?.pdf_url;
                    const link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.download = 'Договор';
                    document.body.appendChild(link);
                    link.click();
                    return of(bookCourseScheduleSuccess(response));
                }),
                catchError((err: AxiosError) => {
                    return of(bookCourseScheduleError(err));
                }),
            );
        }),
    );
};

const loadCourseGroupsEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(loadCourseGroupsStart)),
        switchMap(({ payload }: ActionType<typeof loadCourseGroupsStart>) => {
            return from(deps.coursesDataProvider.loadCourseGroups(payload)).pipe(
                switchMap((response) => {
                    return of(loadCourseGroupsSuccess(response));
                }),
                catchError((err: AxiosError) => {
                    return of(loadCourseGroupsError(err));
                }),
            );
        }),
    );
};

export const coursesEpics = combineEpics(
    loadAdditionalCoursesEpic,
    loadIndividualCoursesEpic,
    loadCourseScheduleEpic,
    bookCourseScheduleEpic,
    loadStudentCoursesEpic,
    loadCourseGroupsEpic,
);
