import { FuncEpic } from '../../common/types';
import { combineEpics, ofType } from 'redux-observable';
import { ActionType, getType } from 'typesafe-actions';
import { loadConstantsError, loadConstantsStart, loadConstantsSuccess } from '../actions/constants';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';

const loadConstantsEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(loadConstantsStart)),
        switchMap(({}: ActionType<typeof loadConstantsStart>) => {
            return from(deps.constantsDataProvider.loadConstants()).pipe(
                switchMap((response) => {
                    return of(loadConstantsSuccess(response));
                }),
                catchError((err) => {
                    return of(loadConstantsError(err));
                }),
            );
        }),
    );
};

export const constantsEpics = combineEpics(loadConstantsEpic);
