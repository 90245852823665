import React from 'react';
import { cn } from '@bem-react/classname';

import './MobileButton.scss';
import { IMobileButtonProps } from './MobileButton.types';
const CnNBMButton = cn('nav-bar-mobile-button');

export const MobileButton: React.FC<IMobileButtonProps> = ({
    icon,
    label,
    onClick,
    labelClassName = '',
    customClassName = '',
}) => {
    return (
        <div className={`${customClassName} ${CnNBMButton({ withoutIcon: !icon })}`} onClick={onClick}>
            {icon && <div className={CnNBMButton('icon')}>{icon}</div>}
            {label && <div className={`${labelClassName} ${CnNBMButton('label')}`}>{label}</div>}
        </div>
    );
};
