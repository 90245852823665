export const mocks = {
    children: {
        first_name: 'Андрей',
        last_name: 'Михайлов',
        patronymic: 'Сергеевич',
    },
    childrenBirthDate: '31.05.1999',
    parent: 'Петров Андрей Алексеевич',
    parentGender: 'm',
    parentPhone: '+7(923)462-23-35',
    foreignLanguages: ['Английский язык', 'Немецкий язык', 'Французский язык', 'Испанский язык'],
    ruleUrl: 'https://storage.yandexcloud.net/public-storage/lk/ustav.pdf',
    timezones: [
        'MSK-12',
        'MSK-11',
        'MSK-10',
        'MSK-9:30',
        'MSK-9',
        'MSK-8:30',
        'MSK-8',
        'MSK-7',
        'MSK-6',
        'MSK-5',
        'MSK-4:30',
        'MSK-4',
        'MSK-3:30',
        'MSK-3',
        'MSK-2:30',
        'MSK-2',
        'MSK-1',
        'MSK-0:25:21',
        'MSK+0',
        'MSK+0:20',
        'MSK+0:30',
        'MSK+1',
        'MSK+2',
        'MSK+3',
        'MSK+3:30',
        'MSK+4',
        'MSK+4:30',
        'MSK+5',
        'MSK+5:30',
        'MSK+5:40',
        'MSK+5:45',
        'MSK+6',
        'MSK+6:30',
        'MSK+7',
        'MSK+7:20',
        'MSK+7:30',
        'MSK+8',
        'MSK+8:30',
        'MSK+8:45',
        'MSK+9',
        'MSK+9:30',
        'MSK+10',
        'MSK+10:30',
        'MSK+11',
        'MSK+11:30',
        'MSK+12',
        'MSK+12:45',
        'MSK+13',
        'MSK+13:45',
        'MSK+14',
    ],
};
