import { BaseRestDataProvider } from './BaseRestDataProvider';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { IApplicationEnrollment } from '../redux/types/applicationEnrollment';

export class ApplicationEnrollmentDataProvider extends BaseRestDataProvider {
    postApplicationForm = (value: IApplicationEnrollment): Promise<any> => {
        return axios
            .post(`${this.host}/api/statement/new/`, value)
            .then((res: AxiosResponse) => {
                return res?.data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    };
}
