import React, { useCallback, useRef, useState } from 'react';
import { useOuterClickField } from 'hooks/useOuterClickField';

import './Select.scss';

export const CustomSelect: React.FC<{
    options: Array<any>;
    value?: any;
    onChange: (value: any) => void;
    onFocus?: () => void;
    placeholder?: string;
    error?: boolean;
    availableOptions?: Array<any>;
    withSearch?: boolean;
}> = ({ options, value, onChange, placeholder, onFocus, error, availableOptions, withSearch }) => {
    const [isOpened, setIsOpened] = useState(false);
    const [selectValue, setSelectValue] = useState(value);
    const ref = useRef(null);

    const handleOpenCallback = useCallback(() => {
        setIsOpened((prev) => !prev);
        if (onFocus) {
            onFocus();
        }
    }, [onFocus]);

    const selectChangeCallback = useCallback(
        (el: any) => {
            handleOpenCallback();
            setSelectValue(el);
            onChange(el);
        },
        [handleOpenCallback, onChange],
    );

    const isAvailable = useCallback(
        (option) => {
            if (availableOptions) return !!availableOptions.find((el) => el.fullname === option);
            return true;
        },
        [availableOptions],
    );

    useOuterClickField(ref, handleOpenCallback, isOpened);

    const handleChangeSearch = useCallback(
        (value) => {
            setSelectValue(value);
            onChange(value);
            if (!isOpened) setIsOpened(true);
        },
        [isOpened, onChange],
    );

    const getFilteredOptions = useCallback(
        (options) => options.filter((option) => option.toLowerCase().indexOf(selectValue.toLowerCase()) != -1),
        [selectValue],
    );

    return (
        <div
            className="select__wrapper jcf-custom-select-wrapper meeting__form-select jcf-select-wrapper-active"
            ref={ref}
        >
            <div className={`form-group form-group--not-empty ${isOpened && 'form-group--opened'}`}>
                <div className={`jcf-select${error ? ' select-error' : ''}`} onClick={handleOpenCallback}>
                    <div className={`jcf-select-opener ${isOpened && 'jcf-select-opener-active'}`} />
                    {withSearch ? (
                        <input
                            value={selectValue || ''}
                            className="select-input"
                            id="select-value"
                            onChange={(e) => handleChangeSearch(e.target.value)}
                            autoComplete="off"
                            placeholder={placeholder}
                        />
                    ) : (
                        <div className="span select-value-style" id="select-value">
                            {value || selectValue || placeholder || options[0]}
                        </div>
                    )}
                    <select name="select" className="jcf-hidden" id="select" placeholder={placeholder}>
                        {(withSearch ? getFilteredOptions(options) : options).map((el, index) => (
                            <option onClick={() => selectChangeCallback(el)} key={index} selected value={el}>
                                {el}
                            </option>
                        ))}
                    </select>
                </div>
                {isOpened && (
                    <div className="jcf-select-drop jcf-unselectable">
                        <div className="jcf-select-drop-content">
                            <span className="jcf-list">
                                <span className="jcf-list-content custom-scroll">
                                    <ul>
                                        {(withSearch ? getFilteredOptions(options) : options).map((el, index) => (
                                            <li
                                                key={index}
                                                onClick={() => selectChangeCallback(el)}
                                                className={`jcf-option ${el === value && 'jcf-selected'} ${
                                                    index + 1 ===
                                                        (withSearch ? getFilteredOptions(options) : options).length &&
                                                    'jcf-list__last-child'
                                                } ${!isAvailable(el) && 'unavailable'}`}
                                            >
                                                <div className="select__value jcf-select-text">{el}</div>
                                            </li>
                                        ))}
                                    </ul>
                                </span>
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
