import { FetchStatus } from '../../common/types';
import { Action } from '../store';
import { getType } from 'typesafe-actions';
import {
    BookCourseScheduleState,
    CourseGroupsState,
    CourseScheduleState,
    CoursesState,
    StudentCoursesState,
} from '../types/courses';
import {
    bookCourseScheduleError,
    bookCourseScheduleInitial,
    bookCourseScheduleStart,
    bookCourseScheduleSuccess,
    clearCourseGroups,
    loadAdditionalCoursesError,
    loadAdditionalCoursesStart,
    loadAdditionalCoursesSuccess,
    loadCourseGroupsError,
    loadCourseGroupsStart,
    loadCourseGroupsSuccess,
    loadCourseScheduleError,
    loadCourseScheduleStart,
    loadCourseScheduleSuccess,
    loadIndividualCoursesError,
    loadIndividualCoursesStart,
    loadIndividualCoursesSuccess,
    loadStudentCoursesError,
    loadStudentCoursesStart,
    loadStudentCoursesSuccess,
} from '../actions/courses';

const coursesInitialState: CoursesState = {
    fetchStatus: FetchStatus.FETCHING,
    error: null,
    data: null,
};

const courseScheduleInitialState: CourseScheduleState = {
    fetchStatus: FetchStatus.FETCHING,
    error: null,
    data: null,
};

const bookCourseScheduleInitialState: BookCourseScheduleState = {
    fetchStatus: FetchStatus.FETCHING,
    error: null,
    data: null,
};

const courseGroupsInitialState: CourseGroupsState = {
    fetchStatus: FetchStatus.INITIAL,
    error: null,
    courseGroups: null,
    teachers: null,
};

export const courseGroups = (state: CourseGroupsState = courseGroupsInitialState, action: Action) => {
    switch (action.type) {
        case getType(loadCourseGroupsStart): {
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHING,
                error: null,
            };
        }

        case getType(loadCourseGroupsSuccess): {
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHED,
                courseGroups: action.payload,
                teachers: action.payload?.length
                    ? action.payload
                          .map(
                              (courseGroup) =>
                                  courseGroup?.teachers?.map((teacher) => ({
                                      ...teacher,
                                      courseTitle: courseGroup?.course?.title + ' ' + courseGroup?.title,
                                  })) || [],
                          )
                          .flat()
                    : [],
                error: null,
            };
        }

        case getType(loadCourseGroupsError): {
            return {
                ...state,
                fetchStatus: FetchStatus.ERROR,
                error: action.payload,
            };
        }

        case getType(clearCourseGroups): {
            return {
                ...state,
                fetchStatus: FetchStatus.INITIAL,
                courseGroups: null,
                teachers: null,
            };
        }

        default: {
            return state;
        }
    }
};

export const additionalCourses = (state: CoursesState = coursesInitialState, action: Action) => {
    switch (action.type) {
        case getType(loadAdditionalCoursesStart): {
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHING,
            };
        }

        case getType(loadAdditionalCoursesSuccess): {
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHED,
                data: action.payload,
                error: null,
            };
        }

        case getType(loadAdditionalCoursesError): {
            return {
                ...state,
                fetchStatus: FetchStatus.ERROR,
                error: action.payload,
            };
        }

        default: {
            return state;
        }
    }
};

export const individualCourses = (state: CoursesState = coursesInitialState, action: Action) => {
    switch (action.type) {
        case getType(loadIndividualCoursesStart): {
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHING,
            };
        }

        case getType(loadIndividualCoursesSuccess): {
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHED,
                error: null,
                data: action.payload,
            };
        }

        case getType(loadIndividualCoursesError): {
            return {
                ...state,
                fetchStatus: FetchStatus.ERROR,
                error: action.payload,
            };
        }

        default: {
            return state;
        }
    }
};

export const studentCourses = (state: StudentCoursesState = coursesInitialState, action: Action) => {
    switch (action.type) {
        case getType(loadStudentCoursesStart): {
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHING,
            };
        }

        case getType(loadStudentCoursesSuccess): {
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHED,
                error: null,
                data: action.payload,
            };
        }

        case getType(loadStudentCoursesError): {
            return {
                ...state,
                fetchStatus: FetchStatus.ERROR,
                error: action.payload,
            };
        }

        default: {
            return state;
        }
    }
};

export const courseSchedule = (state: CourseScheduleState = courseScheduleInitialState, action: Action) => {
    switch (action.type) {
        case getType(loadCourseScheduleStart): {
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHING,
            };
        }

        case getType(loadCourseScheduleSuccess): {
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHED,
                data: action.payload,
                error: null,
            };
        }

        case getType(loadCourseScheduleError): {
            return {
                ...state,
                fetchStatus: FetchStatus.ERROR,
                error: action.payload,
            };
        }

        default: {
            return state;
        }
    }
};

export const bookCourseSchedule = (state: BookCourseScheduleState = bookCourseScheduleInitialState, action: Action) => {
    switch (action.type) {
        case getType(bookCourseScheduleInitial): {
            return {
                ...state,
                fetchStatus: FetchStatus.INITIAL,
                error: null,
                data: null,
            };
        }

        case getType(bookCourseScheduleStart): {
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHING,
            };
        }

        case getType(bookCourseScheduleSuccess): {
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHED,
                data: action.payload,
                error: null,
            };
        }

        case getType(bookCourseScheduleError): {
            return {
                ...state,
                fetchStatus: FetchStatus.ERROR,
                error: action.payload.response.data.empty_field_error || 'Ошибка сервера',
            };
        }

        default: {
            return state;
        }
    }
};
