import { AxiosError } from 'axios';
import { FuncEpic } from 'common/types';
import { checkAuthError } from 'helpers/checkAuthError';
import {
    getPenDocumentsAction,
    getPenDocumentsActionError,
    getPenDocumentsActionSuccess,
    postChangePenDocumentsAction,
    postChangePenDocumentsActionError,
    postChangePenDocumentsActionSuccess,
    postRefusePenDocumentsAction,
    postRefusePenDocumentsActionError,
    postRefusePenDocumentsActionSuccess
} from 'redux/actions/penDocument';
import { IPenDocument } from 'redux/types/penDocument';
import { combineEpics, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

const getPenDocumentsEpic: FuncEpic = (action$: any, _store$, deps) => {
    return action$.pipe(
        ofType(getPenDocumentsAction),
        switchMap((value: { payload: { user: number; student: number }; type: 'string' }) => {
            return from(deps.penDocumentsDataProvider.getPenDocuments(value.payload)).pipe(
                switchMap((value: IPenDocument[]) => {
                    return of(getPenDocumentsActionSuccess(value));
                }),
                catchError((err: AxiosError) => {
                    return of(getPenDocumentsActionError(err));
                }),
            );
        }),
    );
};

const postChangePenDocumentsEpic: FuncEpic = (action$: any, _store$, deps) => {
    return action$.pipe(
        ofType(postChangePenDocumentsAction),
        switchMap((value: { payload: number; type: 'string' }) => {
            return from(deps.penDocumentsDataProvider.postChangePenDocument(value.payload)).pipe(
                switchMap(() => {
                    return of(postChangePenDocumentsActionSuccess(value.payload));
                }),
                catchError((err: AxiosError) => {
                    return of(postChangePenDocumentsActionError(err));
                }),
            );
        }),
    );
};

const postRefusePenDocumentsEpic: FuncEpic = (action$: any, _store$, deps) => {
    return action$.pipe(
        ofType(postRefusePenDocumentsAction),
        switchMap((value: { payload: number; type: 'string' }) => {
            return from(deps.penDocumentsDataProvider.postRefusePenDocument(value.payload)).pipe(
                switchMap(() => {
                    return of(postRefusePenDocumentsActionSuccess(value.payload));
                }),
                catchError((err: AxiosError) => {
                    return of(postRefusePenDocumentsActionError(err));
                }),
            );
        }),
    );
};

export const penDocumentsEpic = combineEpics(
    getPenDocumentsEpic,
    postChangePenDocumentsEpic,
    postRefusePenDocumentsEpic,
);
