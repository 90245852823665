import { AxiosError } from 'axios';
import { ActionType, createAction } from 'typesafe-actions';

import { IDirectorAnswer } from 'components/DirectorModal/DirectorModal.types';

export const postDirectorAnswerAction = createAction('directorAnswer/postAnswerStart')<IDirectorAnswer>();
export const postDirectorAnswerActionSuccess = createAction('directorAnswer/postAnswerSuccess')();
export const postDirectorAnswerActionError = createAction('directorAnswer/postAnswerError')<AxiosError>();

export const resetPostDirectorAnswerStatus = createAction('directorAnswer/resetStatus')();

export type DirectorAnswerActions =
    | ActionType<typeof postDirectorAnswerAction>
    | ActionType<typeof postDirectorAnswerActionSuccess>
    | ActionType<typeof postDirectorAnswerActionError>
    | ActionType<typeof resetPostDirectorAnswerStatus>;
