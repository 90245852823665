import { FetchStatus } from 'common/types';
import {
    postDirectorAnswerAction,
    postDirectorAnswerActionError,
    postDirectorAnswerActionSuccess,
    resetPostDirectorAnswerStatus,
} from 'redux/actions/directorAnswer';
import { Action } from 'redux/store';
import { DirectorAnswerState } from 'redux/types/directorAnswer';
import { getType } from 'typesafe-actions';

const directorAnswerInitialState: DirectorAnswerState = {
    postDirectorAnswerStatus: FetchStatus.INITIAL,
    error: null,
};

export const directorAnswerReducer = (
    state: DirectorAnswerState = directorAnswerInitialState,
    action: Action,
): DirectorAnswerState => {
    switch (action.type) {
        case getType(postDirectorAnswerAction):
            return {
                ...state,
                postDirectorAnswerStatus: FetchStatus.FETCHING,
            };
        case getType(postDirectorAnswerActionSuccess):
            return {
                ...state,
                postDirectorAnswerStatus: FetchStatus.FETCHED,
            };
        case getType(postDirectorAnswerActionError):
            return {
                ...state,
                postDirectorAnswerStatus: FetchStatus.ERROR,
                error: action.payload,
            };
        case getType(resetPostDirectorAnswerStatus):
        default:
            return state;
    }
};
