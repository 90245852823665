import { FuncEpic } from '../../common/types';
import { combineEpics, ofType } from 'redux-observable';
import { ActionType, getType } from 'typesafe-actions';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { AxiosError } from 'axios';
import { checkAuthError } from '../../helpers/checkAuthError';
import {
    postApplicationFormError,
    postApplicationFormStart,
    postApplicationFormSuccess,
} from '../actions/applicationEnrollment';

const postApplicationFormEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(postApplicationFormStart)),
        switchMap(({ payload }: ActionType<typeof postApplicationFormStart>) => {
            return from(deps.applicationEnrollmentDataProvider.postApplicationForm(payload)).pipe(
                switchMap((response: any) => {
                    return of(postApplicationFormSuccess(response));
                }),
                catchError((err: AxiosError) => {
                    return of(postApplicationFormError(err));
                }),
            );
        }),
    );
};

export const applicationEnrollmentEpics = combineEpics(postApplicationFormEpic);
