import React from 'react';
import { MONTHS_RUS } from '../../common/constants';
import { CourseScheduleStatus } from '../../redux/types/courses';
import Skeleton from 'react-loading-skeleton';

import './CourseSchedule.scss';
import { getScheduleText } from '../../helpers/courses';

interface ICourseSchedule {
    handleMonthChange?: (count: number) => void;
    selectedMonth?: number;
    courseSchedule: any[];
    isCourseScheduleFetched: boolean;
    title: string;
    displayStatus?: CourseScheduleStatus;
}

export const CourseSchedule: React.FC<ICourseSchedule> = ({
    handleMonthChange,
    selectedMonth,
    isCourseScheduleFetched,
    courseSchedule,
    title,
    displayStatus = CourseScheduleStatus.free,
}) => {
    return (
        <div className="course-schedule__wrapper">
            <div className="course-schedule__header">
                <div className="course-schedule__header-text">{title}</div>
            </div>
            {isCourseScheduleFetched ? (
                <>
                    {selectedMonth && handleMonthChange && (
                        <div className="course-schedule__toggle">
                            <a onClick={() => handleMonthChange(-1)} className="icon-circle-arrow">
                                <i className="icon-arrow_left" />
                            </a>
                            <a onClick={() => handleMonthChange(1)} className="icon-circle-arrow">
                                <i className="icon-arrow_right" />
                            </a>
                            <p className="course-schedule__default-text">
                                <strong>{MONTHS_RUS[selectedMonth - 1]}</strong>
                            </p>
                        </div>
                    )}
                    <div className="course-schedule__dates">
                        {courseSchedule.map((el, index) => {
                            if (
                                el.status === displayStatus &&
                                (parseInt(el.date.split('-')[1]) === selectedMonth || !selectedMonth)
                            ) {
                                return (
                                    <p className="course-schedule__default-text" key={index}>
                                        {getScheduleText(el)}
                                    </p>
                                );
                            }
                            return null;
                        })}
                    </div>
                </>
            ) : (
                <Skeleton width={240} height={40} />
            )}
        </div>
    );
};
