import {ActionType, createAction} from "typesafe-actions";
import {IApplicationEnrollment} from "../types/applicationEnrollment";


export const postApplicationFormStart = createAction("applicationEnrollment/postApplicationForm/start")<IApplicationEnrollment>();
export const postApplicationFormSuccess = createAction("applicationEnrollment/postApplicationForm/success")<any>();
export const postApplicationFormError = createAction("applicationEnrollment/postApplicationForm/error")<any>();


export type ApplicationEnrollmentActions =
    ActionType<typeof postApplicationFormStart> |
    ActionType<typeof postApplicationFormSuccess> |
    ActionType<typeof postApplicationFormError>