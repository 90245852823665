import { FuncEpic } from '../../common/types';
import { combineEpics, ofType } from 'redux-observable';
import { ActionType, getType } from 'typesafe-actions';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { AxiosError } from 'axios';
import { checkAuthError } from '../../helpers/checkAuthError';
import {
    patchUserInfoError,
    patchUserInfoStart,
    patchUserInfoSuccess,
    regStudentStart,
    regStudentSuccess,
    regStudentError,
    regUserError,
    regUserStart,
    regUserSuccess,
    patchStudentInfoStart,
    patchStudentInfoSuccess,
    patchStudentInfoError,
} from '../actions/registration';
import {showAlert} from "../actions/alert";

const regUserEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(regUserStart)),
        switchMap(({ payload }: ActionType<typeof regUserStart>) => {
            return from(deps.registrationDataProvider.regUser(payload)).pipe(
                switchMap((response: any) => {
                    return of(regUserSuccess(response));
                }),
                catchError((err: AxiosError) => {
                    return of(regUserError(err));
                }),
            );
        }),
    );
};

const regStudentEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(regStudentStart)),
        switchMap(({ payload }: ActionType<typeof regStudentStart>) => {
            return from(deps.registrationDataProvider.regStudent(payload)).pipe(
                switchMap((response: any) => {
                    return of(regStudentSuccess(response));
                }),
                catchError((err: AxiosError) => {
                    return of(regStudentError(err));
                }),
            );
        }),
    );
};

const patchUserInfoEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(patchUserInfoStart)),
        switchMap(({ payload }: ActionType<typeof patchUserInfoStart>) => {
            return from(deps.registrationDataProvider.patchUserInfo(payload)).pipe(
                switchMap((response: any) => {
                    return of(patchUserInfoSuccess(response));
                }),
                catchError((err: AxiosError) => {
                    return of(patchUserInfoError(err), showAlert({
                        title: 'Ошибка!',
                        text: 'Проверьте введенные данные',
                        type: 'error',
                    }));
                }),
            );
        }),
    );
};

const patchStudentInfoEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(patchStudentInfoStart)),
        switchMap(({ payload }: ActionType<typeof patchStudentInfoStart>) => {
            return from(deps.registrationDataProvider.patchStudentInfo(payload)).pipe(
                switchMap((response: any) => {
                    return of(patchStudentInfoSuccess(response));
                }),
                catchError((err: AxiosError) => {
                    return of(patchStudentInfoError(err), showAlert({
                        title: 'Ошибка!',
                        text: 'Проверьте введенные данные',
                        type: 'error',
                    }));
                }),
            );
        }),
    );
};

export const registrationEpics = combineEpics(regUserEpic, patchUserInfoEpic, regStudentEpic, patchStudentInfoEpic);
