import {
    loadProfileSuccess,
    loadProfileError,
    LOAD_PROFILE,
    LOAD_SELECTED_PROFILE,
    loadSelectedProfileError,
    loadSelectedProfileSuccess,
    loadSelectedProfile,
} from '../actions/profile';
import { combineEpics, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap, mapTo } from 'rxjs/operators';
import { loadMarks } from '../actions/marks';
import { loadDocuments } from '../actions/documents';
import { FuncEpic } from '../../common/types';
import { checkAuthError } from '../../helpers/checkAuthError';
import { ActionType, getType } from 'typesafe-actions';
import { loadRating, loadRatingError, loadRatingSuccess } from '../actions/rating';
import { loadPaymentInfo, loadPaymentInfoError, loadPaymentInfoSuccess } from '../actions/paymentInfo';

const loadPaymentInfoEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(loadPaymentInfo)),
        switchMap(({ payload }: ActionType<typeof loadPaymentInfo>) => {
            return from(deps.paymentDataProvider.loadPaymentInfo(payload)).pipe(
                switchMap((info) => {
                    return of(loadPaymentInfoSuccess(info));
                }),
                catchError((err) => {
                    return of(loadPaymentInfoError(err));
                }),
            );
        }),
    );
};

export const paymentInfoEpics = combineEpics(loadPaymentInfoEpic);
