import React, { ReactElement, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';
import { PageStatus } from '../../common/types';
import { PageTitle } from '../../components';
import { loadRating } from '../../redux/actions/rating';
import { State } from '../../redux/store';
import { RatingState } from '../../redux/types/rating';
import { SelectedProfileState } from '../../redux/types/selectedProfile';
import './TotalRating.scss';
import { ErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary';
import { PerformanceTable, PeriodTypes } from '@lms-elements/performance-table';
import { Form } from 'react-final-form';
import moment from 'moment';
import { getFiles, getMarkType } from './TotalRating.utils';

interface TotalRatingProps extends RatingState {
    loadRating: () => void;
    selectedProfile: SelectedProfileState;
}

export const TotalRating: React.FC<TotalRatingProps> = ({ loadRating, selectedProfile, rating, courses }) => {
    useEffect(() => {
        if (selectedProfile.pageStatus === PageStatus.LOADED) {
            loadRating();
        }
    }, [selectedProfile.profile, selectedProfile.pageStatus, loadRating]);
    //TODO delete mocks

    const hideMarksWeight = () => {
        const marks = document.getElementsByClassName('mark');
        Array.prototype.forEach.call(marks, (el) =>
            ['mouseover', 'click'].forEach((event) =>
                el.addEventListener(event, () => {
                    const typeElements = document.getElementsByClassName('tooltip-meta-data');
                    Array.prototype.forEach.call(typeElements, (el) => {
                        if (el.innerHTML.indexOf('(вес') !== -1)
                            el.innerHTML = el.innerHTML.slice(0, el.innerHTML.indexOf('(вес') - 2);
                    });
                }),
            ),
        );
    };

    useEffect(() => {
        if (rating) {
            hideMarksWeight();
            const pageButtons = document.getElementsByClassName('tabsHeaderItem');
            Array.prototype.forEach.call(pageButtons, (el) =>
                el.addEventListener('click', () => setTimeout(() => hideMarksWeight(), 1)),
            );
        }
    }, [rating]);

    return (
        <div className="cabinet-content col custom-scroll total-rating">
            <div className="p-5 row">
                <div id="totalRatingContent" className="col-12 col-xxl mb-5">
                    <div className="row mb-3">
                        <PageTitle title="Журнал оценок" />
                    </div>
                    {rating ? (
                        Object.keys(rating).length !== 0 && (
                            <Form onSubmit={() => undefined}>
                                {({ handleSubmit }): ReactElement => (
                                    <form id={'performanceTable'} onSubmit={handleSubmit}>
                                        <PerformanceTable
                                            students={[]}
                                            courseAssignments={courses.map((course, index) => ({
                                                id: course.id,
                                                courseId: course.id,
                                                groupId: index,
                                                title: course.fullname,
                                            }))}
                                            course={[]}
                                            periodType={PeriodTypes.QUARTER}
                                            marks={rating.grades
                                                .map((mark, index) => ({
                                                    id: index,
                                                    subject: mark.courseid,
                                                    topic: 0,
                                                    quarter: Number(mark.gradecategory.split(' ')[0]) - 1,
                                                    lesson: 0,
                                                    student: 0,
                                                    date: mark.datetime.split(' ')[0],
                                                    withAttendanceTooltip: true,
                                                    score: Number(mark.grade),
                                                    attandenceData: null,
                                                    commentLabel:
                                                        mark.details.comment_text || mark.details.feedback_text
                                                            ? 'Комментарий учителя:'
                                                            : '',
                                                    comment: mark.details.comment_text || mark.details.feedback_text,
                                                    filesLabel: 'Прикрепленные работы:',
                                                    files: getFiles(mark.details.submissions),
                                                    weight: mark.aggregationcoef,
                                                    type: getMarkType(
                                                        mark.itemname,
                                                        moment(mark.datetime.split(' ')[0]).format('DD.MM'),
                                                    ),
                                                }))
                                                .filter((mark) => mark.score)}
                                            quarterMarks={rating.final_grades
                                                .map((mark, index) => ({
                                                    id: index + 1,
                                                    subject: mark.course_id,
                                                    quarter: +mark.chetvert_name.split(' ')[0] - 1,
                                                    student: 0,
                                                    score: +mark.final_grade,
                                                    approved: true,
                                                }))
                                                .concat(
                                                    rating.year_grades.map((mark, index) => ({
                                                        id: rating?.final_grades?.length + index + 1,
                                                        subject: mark.course_id,
                                                        quarter: 4,
                                                        student: 0,
                                                        score: Number(mark?.year_grade),
                                                        approved: true,
                                                    })) || [],
                                                )}
                                            isParent={true}
                                        />
                                    </form>
                                )}
                            </Form>
                        )
                    ) : (
                        <Skeleton width={200} height={60} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default connect(
    ({ rating, selectedProfile }: State) => ({ ...rating, selectedProfile: selectedProfile }),
    (dispatch) => {
        return {
            loadRating: () => {
                return dispatch(loadRating({}));
            },
        };
    },
)((p) => (
    <ErrorBoundary>
        <TotalRating {...p} />
    </ErrorBoundary>
));
