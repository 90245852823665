import React, { ReactNode } from 'react';
import { cn } from '@bem-react/classname';

import './TabsHeaderItem.scss';

const CnTabsHeaderItem = cn('tabsHeaderItem');

interface ITabsHeaderItemProps {
    isSelected: boolean;
    content: ReactNode;
    onClick: (value: number) => void;
    id: number;
}

export const TabsHeaderItem: React.FC<ITabsHeaderItemProps> = ({ content, isSelected, onClick, id }) => {
    return (
        <div onClick={() => onClick(id)} className={CnTabsHeaderItem({ selected: isSelected })}>
            {content}
        </div>
    );
};
