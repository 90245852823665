import React from 'react';

import { IAppText } from './AppTextTypes';
import './AppText.scss';

const AppText = (props: IAppText) => {
    const { text, textStyle, color, target, isError, style, isLink, href } = props;
    const textColor = color ? color : isLink ? '#0088e9' : '#252232';
    return (
        <div style={{ color: isError ? '#ea5959' : textColor }} className={`AppText ${textStyle}`}>
            <div style={style} className={`AppText ${textStyle}`}>
                {isLink ? (
                    <a href={href} target={target}>
                        {text}
                    </a>
                ) : (
                    text
                )}
            </div>
        </div>
    );
};

export default AppText;
