import React from 'react';

import './DocumentList.scss';
import { Col } from 'react-bootstrap';

import photoDoc from '../../../assets/img/photo_doc.jpg';
import { DocumentListItem } from '../..';

interface IDocumentListProps {
    title: string;
    icon?: any;
    showStatus?: boolean;
    documents: any;
    delete: any;
    onDelete?: (index?: number) => void;
    needInputName?: boolean;
    needDropdown?: boolean;
    dropdownOptions?: string[];
    onChangeField?: (index: number, type: string) => (value: string) => void;
    needDownload?: boolean;
    showType?: boolean;
}

const DocumentList: React.FC<IDocumentListProps> = ({
    title,
    icon,
    showStatus = true,
    documents,
    delete: deleteDoc,
    dropdownOptions = [],
    needDropdown = false,
    onDelete = () => '',
    needInputName = false,
    onChangeField = () => '',
    needDownload = false,
    showType = true,
}) => {
    return (
        <Col className={`${needDropdown ? 'z-index' : ''}`}>
            <h4 className="mb-3">{title}</h4>
            <ul className="list-group list-group-flush document-list document-list--search right-sidebar__list">
                {documents.map((el: any, index: number) => {
                    return (
                        <DocumentListItem
                            index={index}
                            delete={deleteDoc}
                            typeDoc={el.type}
                            thumbnail={el.thumbnail}
                            url={el.file_link}
                            key={index}
                            img={photoDoc}
                            text={!el.file_short_name ? el.file_name : el.file_short_name}
                            type={el.file_format}
                            size={el.file_size}
                            valid={el.valid}
                            showStatus={showStatus}
                            status={el.file_status}
                            dropdownOptions={dropdownOptions}
                            icon={icon}
                            onChangeInput={onChangeField.bind(null, index, 'name')()}
                            needDropdown={needDropdown}
                            onChangeDropdown={onChangeField.bind(null, index, 'type')()}
                            deleteDocument={deleteDoc && onDelete.bind(null, el.id ? el.id : index)}
                            needDownload={needDownload}
                            needInputName={needInputName}
                            showType={showType}
                        />
                    );
                })}
            </ul>
        </Col>
    );
};

export default DocumentList;
