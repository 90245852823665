import React from 'react';
import { DeleteIcon } from '@lms-elements/icons';

import './Popup.scss';
import { NoteActions } from '../../lms-elements/atomic/NoteActions';
import { PopupProps } from './Popup.types';

export const Popup: React.FC<PopupProps> = ({ isMobile, onCloseClick, onChangeSend }) => {
    return (
        <div className={'nav-popup'}>
            <div className={`note-wrapper ${isMobile ? 'note-wrapper_isMobile' : ''}`}>
                <div className={'close-button'} onClick={onCloseClick}>
                    <DeleteIcon />
                </div>
                <NoteActions
                    title={'Написать директору'}
                    isLetter={true}
                    name={'select'}
                    titlePlaceholder={'Тема'}
                    placeholder={'Сообщение'}
                    titleName={'title'}
                    id={1}
                    onChangeSend={onChangeSend}
                    onClickCancel={onCloseClick}
                />
            </div>
        </div>
    );
};
