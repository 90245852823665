import { FetchStatus } from '../../common/types';
import { Action } from '../store';
import { getType } from 'typesafe-actions';
import { ApplicationFormState } from '../types/applicationEnrollment';
import {
    postApplicationFormError,
    postApplicationFormStart,
    postApplicationFormSuccess,
} from '../actions/applicationEnrollment';

const applicationFormInitialState: ApplicationFormState = {
    fetchStatus: FetchStatus.INITIAL,
    error: null,
    data: null,
};

export const applicationForm = (state: ApplicationFormState = applicationFormInitialState, action: Action) => {
    switch (action.type) {
        case getType(postApplicationFormStart): {
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHING,
            };
        }

        case getType(postApplicationFormSuccess): {
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHED,
                data: action.payload,
                error: null,
            };
        }

        case getType(postApplicationFormError): {
            return {
                ...state,
                fetchStatus: FetchStatus.ERROR,
                error: action.payload,
            };
        }

        default: {
            return state;
        }
    }
};
