import { FetchStatus } from 'common/types'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router'

export const useRedirect = (
    path: string,
    status: FetchStatus,
    flag: boolean,
) => {
    const history = useHistory();
    useEffect(() => {
        if (status === FetchStatus.FETCHED && flag) {
            history.push(path);
        }
    }, [flag, history, path, status]);
}
