import React from 'react';
import { cn } from '@bem-react/classname';
import { ReactComponent as ToggleLeftIcon } from '../../assets/img/toggle-left.svg';
import { ReactComponent as ToggleRightIcon } from '../../assets/img/toggle-right.svg';

import './Toggle.scss';

const CnInput = cn('checkbox-ios');

interface ToggleProps {
    defaultChecked?: boolean;
    checked?: boolean;
    disabled: boolean;
    handleToggleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Toggle: React.FC<ToggleProps> = ({
    checked,
    defaultChecked,
    disabled,
    handleToggleChange,
}: ToggleProps) => {
    return (
        <label className={CnInput()}>
            <input
                type="checkbox"
                disabled={disabled}
                checked={checked}
                defaultChecked={defaultChecked}
                onChange={handleToggleChange}
            />
            <span className={CnInput('switch')}>
                <div className="icon">
                    <ToggleLeftIcon />
                </div>
                <div className="icon-right">
                    <ToggleRightIcon />
                </div>
            </span>
        </label>
    );
};
