export const LOAD_META = "@meta/LOAD_META";
export const LOAD_META_SUCCESS = "@meta/LOAD_META_SUCCESS";

export type LOAD_META = typeof LOAD_META;
export type LOAD_META_SUCCESS = typeof LOAD_META_SUCCESS;

export interface LoadMetaAction {
    type: LOAD_META;
}
export interface LoadMetaSuccessAction {
    type: LOAD_META_SUCCESS;
    meta: any;
}

export const loadMeta = (): LoadMetaAction => ({
    type: LOAD_META
});

export const loadMetaSuccess = (meta: any): LoadMetaSuccessAction => ({
    type: LOAD_META_SUCCESS,
    meta
});

export type MetaActions =  LoadMetaSuccessAction | LoadMetaAction
