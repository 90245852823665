import { AxiosError, AxiosResponse } from 'axios';
import { combineEpics, ofType } from 'redux-observable';
import { getType } from 'typesafe-actions';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { FuncEpic } from 'common/types';
import {
    fetchProcessesError,
    fetchProcessesStart,
    fetchProcessesSuccess,
    fetchTrialDays,
    fetchTrialDaysError,
    fetchTrialDaysSuccess,
    createTrialDay,
    createTrialDayError,
    createTrialDaySuccess,
    createContractExtension,
    createContractExtensionSuccess,
    createContractExtensionError,
} from 'redux/actions/processes';
import { IProcess } from '../../api/ProcessesDataProvider.types';

const fetchProcessesEpic: FuncEpic = (action$, store$, deps) =>
    action$.pipe(
        ofType(getType(fetchProcessesStart)),
        switchMap(() =>
            from(deps.processesDataProvider.fetchProcesses()).pipe(
                switchMap((response) => of(fetchProcessesSuccess(response.data))),
                catchError((error: AxiosError) => of(fetchProcessesError(error))),
            ),
        ),
    );

const fetchTrialDaysEpic: FuncEpic = (action$, store$, deps) =>
    action$.pipe(
        ofType(getType(fetchTrialDays)),
        switchMap(({ payload }) =>
            from(deps.processesDataProvider.fetchTrialDays(payload)).pipe(
                switchMap((response) => of(fetchTrialDaysSuccess(response.data))),
                catchError((error: AxiosError) => of(fetchTrialDaysError(error))),
            ),
        ),
    );

const createTrialDayEpic: FuncEpic = (action$, store$, deps) =>
    action$.pipe(
        ofType(getType(createTrialDay)),
        switchMap(({ payload }) =>
            from(deps.processesDataProvider.createTrialDay(payload?.studentId)).pipe(
                switchMap((response: AxiosResponse<IProcess>) => {
                    deps.processesDataProvider.fillTrialDay({
                        processId: response.data?.id,
                        timeslotKey: payload.timeslotKey,
                    });
                    deps.profileDataProvider.loadProfile();
                    return of(createTrialDaySuccess());
                }),
                catchError((error: AxiosError) => of(createTrialDayError(error))),
            ),
        ),
    );

const createContractExtensionEpic: FuncEpic = (action$, store$, deps) =>
    action$.pipe(
        ofType(getType(createContractExtension)),
        switchMap(({ payload }) =>
            from(deps.processesDataProvider.createContractExtension(payload?.studentId)).pipe(
                switchMap((response: AxiosResponse<IProcess>) => {
                    deps.processesDataProvider.fillContractExtension({
                        processId: response.data?.id,
                        ...payload?.fillContractData,
                    });
                    return of(
                        createContractExtensionSuccess(
                            response.data?.report_template?.pdf_url ? response.data?.report_template?.pdf_url : null,
                        ),
                    );
                }),
                catchError((error: AxiosError) => of(createContractExtensionError(error))),
            ),
        ),
    );

export const processesEpics = combineEpics(
    fetchProcessesEpic,
    fetchTrialDaysEpic,
    createTrialDayEpic,
    createContractExtensionEpic,
);
