import React, { useCallback } from 'react';
import { cn } from '@bem-react/classname';

import './NavBarStudent.scss';
import { NavBarStudentProps } from './NavBarStudent.types';
import AppText from '../../../ui/app-text';
import { TARIFF_CURIOUS_ID } from 'common/constants';
import { Setting } from '@lms-elements/icons';

const CnNavBarStudent = cn('nav-bar-student');

export const NavBarStudent: React.FC<NavBarStudentProps> = ({
    id,
    firstName,
    lastName,
    photoUrl,
    grade,
    selected,
    isMobile = false,
    isInHeader = false,
    onSettingClick,
    tariff,
    onStudentClick,
}) => {
    const isCurious = tariff == TARIFF_CURIOUS_ID;

    const handleSettingClick = useCallback(() => {
        if (onSettingClick) {
            onSettingClick(id);
        }
    }, [id, onSettingClick]);

    const handleStudentClick = useCallback(() => {
        if (onStudentClick) {
            onStudentClick(id);
        }
    }, [id, onStudentClick]);

    return (
        <div className={CnNavBarStudent()}>
            {isInHeader ? (
                <div className={CnNavBarStudent('container', { isInHeader })}>
                    <div className={CnNavBarStudent('text', { isInHeader })}>
                        <AppText color={selected && '#fff'} text={`${firstName}`} textStyle={'DesktopH4'} />
                        <AppText color={selected ? '#fff' : '#585c6f'} text={grade} textStyle={'Little'} />
                    </div>
                    <div className={CnNavBarStudent('image')}>
                        <img className={CnNavBarStudent('image-logo')} src={photoUrl} alt={firstName} />
                    </div>
                </div>
            ) : (
                <div className={CnNavBarStudent('container', { isSelect: selected })} onClick={handleStudentClick}>
                    <div className={CnNavBarStudent('content')}>
                        <div className={CnNavBarStudent('image')}>
                            <img className={CnNavBarStudent('image-logo')} src={photoUrl} alt={firstName} />
                        </div>
                        <div className={CnNavBarStudent('text', { isMobile })}>
                            <AppText
                                color={selected && '#fff'}
                                text={isCurious ? `Ученик - ${firstName}` : `${lastName} ${firstName}`}
                                textStyle={'DesktopH4'}
                            />
                            <AppText color={selected ? '#fff' : '#585c6f'} text={grade} textStyle={'Little'} />
                        </div>
                    </div>
                    {/* TODO: вернуть SETTINGS при необходимости */}
                    {/*{selected && (*/}
                    {/*    <div className={CnNavBarStudent('settings')} onClick={handleSettingClick}>*/}
                    {/*        <Setting className={CnNavBarStudent('settings-icon', { isSelected: selected })} />*/}
                    {/*    </div>*/}
                    {/*)}*/}
                </div>
            )}
        </div>
    );
};
