import moment from 'moment/moment';

export const schedulesMock = [
    {
        time: moment('Thu, 13 Jan 2022 10:30:00 GMT').valueOf(),
        subject: 'Алгебра',
        description: 'Формулы квадрата суммы и квадрата разности двух...',
    },
    {
        time: moment('Tue, 11 Jan 2022 10:30:00 GMT').valueOf(),
        subject: 'География',
        description: 'Формулы квадрата суммы и квадрата разности двух...',
    },
];
