import { AxiosError } from 'axios';
import { ActionType, createAction } from 'typesafe-actions';
import { IPayment } from '../types/payment';

export const loadPaymentContracts = createAction('@payment/LOAD/CONTRACTS/START')<any>();
export const loadPaymentContractsSuccess = createAction('@payment/LOAD/CONTRACTS/SUCCESS')<any>();
export const loadPaymentContractsError = createAction('@payment/LOAD/CONTRACTS/ERROR')<any>();

export const loadPaidPaymentContracts = createAction('@payment/LOAD/PAID_CONTRACTS/START')<any>();
export const loadPaidPaymentContractsSuccess = createAction('@payment/LOAD/PAID_CONTRACTS/SUCCESS')<any>();
export const loadPaidPaymentContractsError = createAction('@payment/LOAD/PAID_CONTRACTS/ERROR')<any>();

export const addToPayment = createAction('@payment/ADD')<any>();
export const removeFromPayment = createAction('@payment/REMOVE')<any>();

export const createPaymentStart = createAction('@payment/CREATE/START')<any>();
export const createPaymentSuccess = createAction('@payment/CREATE/SUCCESS')<any>();
export const createPaymentError = createAction('@payment/CREATE/ERROR')<any>();

export const fetchUpcomingPaymentsStart = createAction('@payment/fetchUpcoming/start')<string>();
export const fetchUpcomingPaymentsSuccess = createAction('@payment/fetchUpcoming/success')<
    Record<string, Array<IPayment>>
>();
export const fetchUpcomingPaymentsError = createAction('@payment/fetchUpcoming/error')<AxiosError>();

export const fetchDebtPaymentsStart = createAction('@payment/fetchDebt/start')<string>();
export const fetchDebtPaymentsSuccess = createAction('@payment/fetchDebt/success')<Record<string, Array<IPayment>>>();
export const fetchDebtPaymentsError = createAction('@payment/fetchDebt/error')<AxiosError>();

export type PaymentActions =
    | ActionType<typeof loadPaymentContracts>
    | ActionType<typeof loadPaymentContractsError>
    | ActionType<typeof loadPaymentContractsSuccess>
    | ActionType<typeof loadPaidPaymentContracts>
    | ActionType<typeof loadPaidPaymentContractsError>
    | ActionType<typeof loadPaidPaymentContractsSuccess>
    | ActionType<typeof addToPayment>
    | ActionType<typeof removeFromPayment>
    | ActionType<typeof createPaymentError>
    | ActionType<typeof createPaymentStart>
    | ActionType<typeof createPaymentSuccess>
    | ActionType<typeof fetchUpcomingPaymentsStart>
    | ActionType<typeof fetchUpcomingPaymentsSuccess>
    | ActionType<typeof fetchUpcomingPaymentsError>
    | ActionType<typeof fetchDebtPaymentsStart>
    | ActionType<typeof fetchDebtPaymentsSuccess>
    | ActionType<typeof fetchDebtPaymentsError>;
