import React, { useMemo } from 'react';

import './CourseCard.scss';
import { CoursePaymentStatus } from '../../redux/types/courses';
import { nounsDecl } from '../../common/helpers';

interface ICourseCardProps {
    courseName: string;
    teacherName: string;
    status?: CoursePaymentStatus;
    lessonsLeft?: number;
    onClick?: () => void;
}
export const CourseCard: React.FC<ICourseCardProps> = ({ courseName, teacherName, status, lessonsLeft, onClick }) => {
    const statusTextClass = useMemo(() => {
        switch (status) {
            case CoursePaymentStatus.NEED_TO_PAY:
                return 'needToPay';
            case CoursePaymentStatus.PAID:
                return 'paid';
            case CoursePaymentStatus.PROCESSING:
                return 'processing';
        }
    }, [status]);

    return (
        <div onClick={onClick} className={`course-card__wrapper ${onClick ? 'individual-courses-card' : ''}`}>
            <div className="course-card__info">
                <div className="course-card__info-text">
                    <strong>{courseName}</strong>
                </div>
                <div className="course-card__info-text">уч. {teacherName}</div>
            </div>
            {status && (
                <div className="course-card__addInfo">
                    <div className={`course-card__addInfo-text ${statusTextClass}`}>{status}</div>
                    {!!lessonsLeft && (
                        <div className="course-card__addInfo-text">
                            {lessonsLeft} {nounsDecl(lessonsLeft, ['занятие', 'занятия', 'занятий'])} осталось
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
