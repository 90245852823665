import React from 'react'
import { cn } from '@bem-react/classname'

import { NotificationCard } from 'components/Notification';
import { NotificationCardTypeEnum } from 'components/Notification/NotificationCard.types';

import { IDirectorModalNotificationProps } from './DirectorModalNotification.types'

import './DirectorModalNotification.scss';

const cnDirectorModalNotification = cn('director-modal-notification');

export const DirectorModalNotification: React.FC<IDirectorModalNotificationProps> = ({
    status,
    onCancel,
}) => {
    return (
        <>
            <div className={cnDirectorModalNotification()} />
            <div className={cnDirectorModalNotification('card')}>
                {
                    status === 'success' ? (
                        <NotificationCard
                            size='s'
                            title='Сообщение отправлено'
                            type={NotificationCardTypeEnum.feedback}
                            closeButton
                            handleCloseButtonClick={onCancel}
                        />
                    ) : (
                        <NotificationCard
                            size='s'
                            title='Ошибка!'
                            text='Сообщение не должно содержать ссылок или HTML!'
                            type={NotificationCardTypeEnum.alert}
                            closeButton
                            handleCloseButtonClick={onCancel}
                        />
                    )
                }
            </div>
        </>
    )
}
