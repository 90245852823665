import axios, { AxiosError, AxiosResponse } from 'axios';

import {
    IDocument,
    IEnrollmentCertificateRequestPayload,
    IFetchGymnasiumFromManagerPayload, LoadDocumentsResponse
} from '../redux/types/documents';

import { BaseRestDataProvider } from './BaseRestDataProvider';

export class DocumentsDataProvider extends BaseRestDataProvider {
    loadDocuments = async (user: number, student: number) => {
        return await axios
            .all([
                // this.fetchLastUploads(),
                this.fetchOwnDocuments(user, student),
                this.fetchGymnasiumDocuments(),
                this.fetchParentDocuments(user),
                this.fetchGymnasiumFromManager({ userId: user }),
            ])
            .then(([ownDocuments, gymnasium, parentDocuments, gymnasiumFromManager]) => {
                return {
                    ownDocuments,
                    gymnasium,
                    parentDocuments,
                    gymnasiumFromManager,
                };
            });
    };

    fetchOwnDocuments = (id: number, student: number): Promise<IDocument[]> => {
        return axios
            .get<LoadDocumentsResponse>(`${this.host}/upload/?student=${student}`)
            .then((res) => res.data.results);
    };

    fetchParentDocuments = (id: number): Promise<IDocument[]> => {
        return axios
            .get<LoadDocumentsResponse>(`${this.host}/upload/?user=${id}`)
            .then((res) => res.data.results);
    };

    fetchGymnasiumDocuments = () => {
        return axios.get(`${this.host}/report/generated_documents/?page_size=100`).then((res) => res.data?.results);
    };

    fetchTypeDocuments = (id: number): Promise<any> => {
        return axios
            .get(`${this.host}/document_types/student_document_types_list/${id}/`, {
                withCredentials: true,
            })
            .then((res) => res.data)
            .catch((err) => {
                throw err;
            });
    };

    fetchParentTypeDocuments = (id: number): Promise<any> => {
        return axios
            .get(`${this.host}/document_types/parent_document_types_list/${id}/`, {
                withCredentials: true,
            })
            .then((res) => res.data)
            .catch((err) => {
                throw err;
            });
    };

    uploadDocuments = async (
        files: any,
        student_id: string | undefined,
        user_id: string | undefined,
        upload_doc_type: number,
    ) => {
        return axios.all(
            files.map(async (el: any) => {
                const file = new FormData();

                file.append('file', el.file);
                if (user_id) {
                    file.append('user', user_id);
                }
                if (student_id) {
                    file.append('student', student_id);
                }
                file.append('upload_doc_type', String(upload_doc_type));
                file.append('file_name', el.name);
                file.append('uploaded_by', 'parent')

                return axios.post(`${this.host}/upload/`, file).then((res) => res.data);
            }),
        );
    };

    deleteDocument = (id: string) => {
        return axios.delete(`${this.host}/files/${id}/`);
    };

    enrollmentCertificateRequest = (payload: IEnrollmentCertificateRequestPayload): Promise<any> => {
        return axios
            .post(`${this.host}/processes/enrollment_certificate/`, {
                student_id: payload.studentId,
                contract_id: payload.contractId,
            })
            .then((res: AxiosResponse) => {
                return res?.data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    };

    fetchGymnasiumFromManager = (payload: IFetchGymnasiumFromManagerPayload): Promise<any> => {
        return axios
            .get(
                `${this.host}/files/?user=${payload.userId}&for_parent=true${
                    payload.documentId ? `&document=${payload.documentId}` : ''
                }`,
            )
            .then((res: AxiosResponse) => {
                return res?.data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    };
}
