import { DownloadButton } from 'components';
import DeleteButton from 'components/General/DeleteButton';
import { getDocumentExt } from 'helpers/documentExt';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { deleteDocument } from 'redux/actions/documents';
import { IMAGE_EXTENSIONS } from './constants';
import './DocumentTypeItem.scss';
import { DocStatusEnum } from '../../../../pages/Documents/Documents.types';

export const DocumentTypeItem: React.FC<any> = ({ file_name, file, state, comment, size, id }) => {
    const dispatch = useDispatch();

    const deleteDocumentHandler = () => {
        dispatch(deleteDocument(id));
    };

    const commentNeedCorrection = useMemo(() => comment || DocStatusEnum[state], [comment, state]);

    const fileExtension = useMemo(() => getDocumentExt(file_name), [file_name]);
    const isImage = useMemo(() => IMAGE_EXTENSIONS.includes(fileExtension.toLowerCase()), [fileExtension]);

    return (
        <div className="documentTypeItem">
            <div className={'documentTypeItem-row'}>
                <div className={'documentTypeItem-row documentTypeItem-row-header'}>
                    <div className="documentTypeItem-image">
                        {isImage && <img src={file} alt="doctype-image" />}
                        {!isImage && <img src={require('../../../../assets/img/photo_doc.jpg')} alt="doctype-image" />}
                    </div>
                    <div className="documentTypeItem-name">{file_name}</div>
                </div>
                <div className={'documentTypeItem-row'}>
                    {fileExtension && <div className="documentTypeItem-ext">{fileExtension}</div>}

                    <div className={'documentTypeItem-size'}>{size}</div>

                    <div className={`documentTypeItem-status ${state}`}>
                        {state === 'need_correction' && commentNeedCorrection}

                        {state === 'recognized' && (
                            <>
                                <svg
                                    width="13"
                                    height="14"
                                    viewBox="0 0 15 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M14.4641 5.00538L6.00564 13.4641L1.00012 8.45862"
                                        stroke="#00AF01"
                                        stroke-width="2"
                                    />
                                </svg>
                                <div>Документ принят</div>
                            </>
                        )}

                        {state === 'unrecognized' && 'Ожидает обработки менеджера'}
                    </div>
                </div>
            </div>

            <div className="documentTypeItem-buttons">
                <div className="documentTypeItem-download">
                    <DownloadButton url={file} />
                </div>
                <div onClick={deleteDocumentHandler} className="documentTypeItem-delete">
                    <DeleteButton />
                </div>
            </div>
        </div>
    );
};
