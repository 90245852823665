import { DeleteButton, DocumentUpload, DownloadButton } from 'components';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { uploadDocuments } from 'redux/actions/documents';
import DocumentListItem from '../DocumentListItem';
import './DocumentType.scss';
import { DocumentTypeHeader } from './DocumentTypeHeader';
import { DocumentTypeItem } from './DocumentTypeItem';

interface IDocumentTypeProps {
    name: string;
    description: string;
    document: any;
    id: number;
    refers_to_student: boolean;
}

const DocumentType: React.FC<IDocumentTypeProps> = ({ name, description, document, id: doc_type_id, refers_to_student }) => {
    const dispatch = useDispatch();

    const onUploadFile = (files) => {
        dispatch(
            uploadDocuments(
                files.map((file) => ({ file: file, name: file.name })),
                doc_type_id,
                !document?.student
            ),
        );
    };

    return (
        <div className="documentType">
            <DocumentTypeHeader name={name} description={description} />

            {document?.files.map((file) => (
                <DocumentTypeItem key={file.id} comment={file.comment} state={file.status} {...file} />
            ))}

            {document && Boolean(document.comment) && <div className="documentType-comment">{document.comment}</div>}

            <div>
                <DocumentUpload
                    title={document && document.files.length ? 'Загрузить еще' : undefined}
                    small={document && document.files.length}
                    uploadDocument={onUploadFile}
                />
            </div>
        </div>
    );
};

export default DocumentType;
