import { FetchStatus } from '../../common/types';
import { Action } from '../store';
import { getType } from 'typesafe-actions';
import { persistReducer } from 'redux-persist';
import localStorage from 'redux-persist/es/storage';
import { UsersListState } from '../types/users';
import { loadUsersListError, loadUsersListStart, loadUsersListSuccess } from '../actions/users';

const usersListInitialState: UsersListState = {
    fetchStatus: FetchStatus.FETCHING,
    error: null,
    users: null,
};

export const reducer = (state: UsersListState = usersListInitialState, action: Action) => {
    switch (action.type) {
        case getType(loadUsersListStart): {
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHING,
            };
        }

        case getType(loadUsersListSuccess): {
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHED,
                users: action.payload,
            };
        }

        case getType(loadUsersListError): {
            return {
                ...state,
                fetchStatus: FetchStatus.ERROR,
                error: action.payload,
            };
        }

        default: {
            return {
                ...state,
            };
        }
    }
};

export const users: any = persistReducer(
    {
        key: 'usersList',
        storage: localStorage,
        blacklist: [],
        version: 1,
    },
    reducer,
);
