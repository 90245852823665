import { Action } from '../store';
import { getType } from 'typesafe-actions';
import { FetchStatus } from '../../common/types';
import { fetchLoginError, fetchLoginSuccess, fetchLoginStart, logout } from '../actions/login';
import { LoginState } from '../types/login';

const initialState = {
    fetchStatus: FetchStatus.FETCHING,
    error: null,
    login: {
        access: '',
        refresh: '',
    },
};

export const login = (state: LoginState = initialState, action: Action) => {
    switch (action.type) {
        case getType(fetchLoginStart): {
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHING,
                error: null,
            };
        }

        case getType(fetchLoginSuccess): {
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHED,
                login: {
                    access: action.payload?.access,
                    refresh: action.payload?.refresh,
                },
            };
        }

        case getType(fetchLoginError): {
            const status = action.payload?.response?.status;
            let errorMsg;
            if (status === 500) errorMsg = ['Не удалось связаться с сервером'];
            else if (action.payload?.response?.data?.message !== 'Validation error')
                errorMsg = [action.payload?.response?.data?.message];
            else
                errorMsg = Object.entries(action.payload?.response?.data?.extra?.fields).reduce(
                    (prev, [key, value]) => {
                        if (key === 'password') return prev.concat(['Поле "пароль" не может быть пустым']);
                        else if (key === 'email') return prev.concat(['Поле "e-mail" не может быть пустым']);
                        else return prev.concat(value);
                    },
                    [],
                );
            return {
                ...state,
                fetchStatus: FetchStatus.ERROR,
                error: errorMsg,
            };
        }

        case getType(logout): {
            return { ...initialState };
        }

        default: {
            return state;
        }
    }
};
