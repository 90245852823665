import { FuncEpic } from '../../common/types';
import { combineEpics, ofType } from 'redux-observable';
import {  switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import {loadMetaSuccess,  LOAD_META} from '../actions/meta';

const loadMetaEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(LOAD_META),
        switchMap(() => {
            return from(deps.metaDataProvider.loadMetaData()).pipe(
                switchMap((meta) => {
                    return of(loadMetaSuccess(meta));
                }),
            );
        }),
    );
};

export const metaEpics = combineEpics(loadMetaEpic);
