import { ActionType, createAction } from 'typesafe-actions';
import { CourseGroup, IBookCourseSchedule, ICourses, ICourseSchedule, LoadCourseGroupsPayload } from '../types/courses';

export const loadAdditionalCoursesStart = createAction('courses/loadAdditionalCourses/start')<ICourses>();
export const loadAdditionalCoursesSuccess = createAction('courses/loadAdditionalCourses/success')<any>();
export const loadAdditionalCoursesError = createAction('courses/loadAdditionalCourses/error')<any>();

export const loadCourseScheduleStart = createAction('courses/loadCourseSchedule/start')<ICourseSchedule>();
export const loadCourseScheduleSuccess = createAction('courses/loadCourseSchedule/success')<any>();
export const loadCourseScheduleError = createAction('courses/loadCourseSchedule/error')<any>();

export const bookCourseScheduleInitial = createAction('courses/bookCourseSchedule/initial')();
export const bookCourseScheduleStart = createAction('courses/bookCourseSchedule/start')<IBookCourseSchedule>();
export const bookCourseScheduleSuccess = createAction('courses/bookCourseSchedule/success')<any>();
export const bookCourseScheduleError = createAction('courses/bookCourseSchedule/error')<any>();

export const loadIndividualCoursesStart = createAction('courses/loadIndividualCourses/start')<ICourses>();
export const loadIndividualCoursesSuccess = createAction('courses/loadIndividualCourses/success')<any>();
export const loadIndividualCoursesError = createAction('courses/loadIndividualCourses/error')<any>();

export const loadStudentCoursesStart = createAction('courses/loadStudentCourses/start')<ICourses>();
export const loadStudentCoursesSuccess = createAction('courses/loadStudentCourses/success')<any>();
export const loadStudentCoursesError = createAction('courses/loadStudentCourses/error')<any>();

export const loadCourseGroupsStart = createAction('courses/loadCourseGroups/start')<LoadCourseGroupsPayload>();
export const loadCourseGroupsSuccess = createAction('courses/loadCourseGroups/success')<CourseGroup[]>();
export const loadCourseGroupsError = createAction('courses/loadCourseGroups/error')<any>();
export const clearCourseGroups = createAction('courses/clearCourseGroups')();

export type CoursesActions =
    | ActionType<typeof loadAdditionalCoursesStart>
    | ActionType<typeof loadAdditionalCoursesSuccess>
    | ActionType<typeof loadAdditionalCoursesError>
    | ActionType<typeof loadCourseScheduleStart>
    | ActionType<typeof loadCourseScheduleSuccess>
    | ActionType<typeof loadCourseScheduleError>
    | ActionType<typeof bookCourseScheduleInitial>
    | ActionType<typeof bookCourseScheduleStart>
    | ActionType<typeof bookCourseScheduleSuccess>
    | ActionType<typeof bookCourseScheduleError>
    | ActionType<typeof loadIndividualCoursesStart>
    | ActionType<typeof loadIndividualCoursesSuccess>
    | ActionType<typeof loadIndividualCoursesError>
    | ActionType<typeof loadStudentCoursesStart>
    | ActionType<typeof loadStudentCoursesSuccess>
    | ActionType<typeof loadStudentCoursesError>
    | ActionType<typeof loadCourseGroupsStart>
    | ActionType<typeof loadCourseGroupsSuccess>
    | ActionType<typeof loadCourseGroupsError>
    | ActionType<typeof clearCourseGroups>;
