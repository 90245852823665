import { FetchStatus } from 'common/types';
import { LmsStudent } from './schedule';

export interface ICourses {
    student_id: number;
}

export interface LoadCourseGroupsPayload {
    students: string[];
}

export interface LmsTeacher {
    externalId: string;
    firstName: string;
    lastName: string;
    patronymic: string;
    photo: string;
    mainRole: string;
    online: boolean;
    lastSeen: string;
}

export interface CourseGroup {
    id?: number;
    title: string;
    tariff?: number;
    educationalPlan?: number;
    course: {
        id?: number;
        title: string;
    };
    teachers: LmsTeacher[];
    students: LmsStudent[];
    maxStudentsQuantity: number;
    lessonsQuantity: number;
    studentsQuantity: number;
    isOlTimeEditable?: boolean;
    evaluationType?: string;
}

export interface ICourseSchedule {
    student_id: number;
    course_id: number;
}

export interface IBookCourseSchedule extends ICourseSchedule {
    action: string;
    prepay: boolean;
    cost: number;
    dates: any;
    education_program: string;
}

export interface CoursesState {
    fetchStatus: FetchStatus;
    error: any;
    data: any;
}

export interface CourseTeacher extends LmsTeacher {
    courseTitle?: string;
}

export interface CourseGroupsState {
    fetchStatus: FetchStatus;
    error: any;
    courseGroups: CourseGroup[];
    teachers: CourseTeacher[];
}

export interface StudentCoursesState {
    fetchStatus: FetchStatus;
    error: any;
    data: any;
}

export interface CourseScheduleState {
    fetchStatus: FetchStatus;
    error: any;
    data: any;
}

export interface BookCourseScheduleState {
    fetchStatus: FetchStatus;
    error: any;
    data: any;
}

export enum CourseScheduleStatus {
    free = 'free',
    used = 'used',
    this = 'this',
}

export enum CoursePaymentStatus {
    PAID = 'Оплачено',
    PROCESSING = 'Частично оплачено',
    NEED_TO_PAY = 'Необходимо оплатить',
}
