import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { VERSION } from '../../common/constants';
import { FetchStatus, PageStatus } from '../../common/types';
import {
    DELETE_DOCUMENT,
    DELETE_DOCUMENT_ERROR,
    DELETE_DOCUMENT_SUCCESS,
    enrollmentCertificateRequestError,
    enrollmentCertificateRequestStart,
    enrollmentCertificateRequestSuccess,
    LOAD_DOCUMENTS,
    LOAD_DOCUMENTS_ERROR,
    LOAD_DOCUMENTS_SUCCESS,
    LOAD_DOCUMENTS_TYPES,
    LOAD_DOCUMENTS_TYPES_ERROR,
    LOAD_DOCUMENTS_TYPES_SUCCESS,
    LOAD_PARENT_DOCUMENTS_TYPES,
    LOAD_PARENT_DOCUMENTS_TYPES_ERROR,
    LOAD_PARENT_DOCUMENTS_TYPES_SUCCESS,
} from '../actions/documents';
import { Action } from '../store';
import { DocumentsState } from '../types/documents';
import { getType } from 'typesafe-actions';

const defaultDocumentsState = {
    pageStatus: PageStatus.LOADING,
    fetchStatusDocumentTypes: FetchStatus.FETCHING,
    fetchStatusParentDocumentTypes: FetchStatus.FETCHING,
    fetchStatusEnrollmentCertReq: FetchStatus.INITIAL,
    parentTypes: [],
    last: [],
    gymnasium: [],
    gymnasiumFromManager: [],
    own: [],
    error: null,
    types: [],
    deletedId: 0,
    parentDocuments: [],
};

const reducer = (state: DocumentsState = defaultDocumentsState, action: Action) => {
    switch (action.type) {
        case LOAD_DOCUMENTS: {
            return {
                ...state,
                pageStatus: PageStatus.LOADING,
            };
        }
        case LOAD_DOCUMENTS_SUCCESS: {
            return {
                ...state,
                pageStatus: PageStatus.LOADED,
                last: action.lastUploads || [],
                own: action.ownDocuments || [],
                gymnasium: action.gymnasium || [],
                gymnasiumFromManager: action.gymnasiumFromManager || [],
                parentDocuments: action.parentDocuments || [],
            };
        }
        case LOAD_DOCUMENTS_ERROR: {
            return {
                ...state,
                pageStatus: PageStatus.ERROR,
                error: action.error,
            };
        }
        case LOAD_DOCUMENTS_TYPES: {
            return {
                ...state,
            };
        }
        case LOAD_DOCUMENTS_TYPES_SUCCESS: {
            return {
                ...state,
                fetchStatusDocumentTypes: FetchStatus.FETCHED,
                types: action.typesDocuments || [],
            };
        }
        case LOAD_DOCUMENTS_TYPES_ERROR: {
            return {
                ...state,
                fetchStatusDocumentTypes: FetchStatus.ERROR,
                error: action.error,
            };
        }

        case LOAD_PARENT_DOCUMENTS_TYPES: {
            return {
                ...state,
            };
        }

        case LOAD_PARENT_DOCUMENTS_TYPES_SUCCESS: {
            return {
                ...state,
                fetchStatusParentDocumentTypes: FetchStatus.FETCHED,
                parentTypes: action.typesDocuments || [],
            };
        }

        case LOAD_PARENT_DOCUMENTS_TYPES_ERROR: {
            return {
                ...state,
                fetchStatusParentDocumentTypes: FetchStatus.ERROR,
                error: action.error,
            };
        }

        case DELETE_DOCUMENT: {
            return {
                ...state,
                deletedId: Number(action.id),
            };
        }

        case DELETE_DOCUMENT_SUCCESS: {
            return {
                ...state,
                own: state.own.map((document) => {
                    return { ...document, files: document.files.filter((file) => file.id !== state.deletedId) };
                }),
                deletedId: 0,
            };
        }

        case DELETE_DOCUMENT_ERROR: {
            return {
                ...state,
                deletedId: 0,
                error: action.error,
            };
        }

        case getType(enrollmentCertificateRequestStart): {
            return {
                ...state,
                fetchStatusEnrollmentCertReq: FetchStatus.FETCHING,
            };
        }

        case getType(enrollmentCertificateRequestSuccess): {
            return {
                ...state,
                fetchStatusEnrollmentCertReq: FetchStatus.FETCHED,
            };
        }

        case getType(enrollmentCertificateRequestError): {
            return {
                ...state,
                fetchStatusEnrollmentCertReq: FetchStatus.ERROR,
            };
        }

        default: {
            return state;
        }
    }
};

export default persistReducer(
    {
        key: `documents::${VERSION}`,
        storage: storage,
        blacklist: [],
        version: VERSION,
    },
    reducer,
);
