import React from 'react';
import moment from 'moment';

import ScheduleItem from 'components/Schedule/ScheduleItem';
import { Explanation } from 'components/Explanation';
import { schedulesMock } from './EmptySchedule.mock';

export const EmptySchedule: React.FC = () => (
    <div className="empty-schedule">
        <Explanation
            title="Расписание"
            description="Когда расписание будет сформировано, тут вы увидите все предстоящие уроки"
            withGrayScale
        >
            {schedulesMock.map((schedule, index) => (
                <ScheduleItem key={index} scheduleDate={moment(schedule.time).format()} {...schedule} />
            ))}
        </Explanation>
    </div>
);
