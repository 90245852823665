import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'pickmeup/css/pickmeup.css';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import './styles/index.scss';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { store, persistor } from './redux/store';
import moment from 'moment';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

moment.locale('ru');

Sentry.init({
    dsn:
        process.env.NODE_ENV === 'development'
            ? 'https://f40f2555dff54a72ba3f386cc2e7dca9@sentry.og1.ru/8'
            : 'https://90fb63cd0639476b902e3eabb567364c@sentry.og1.ru/7',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
});

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
