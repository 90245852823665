import moment from "moment";
import { monthsRu } from ".";
import { IDay } from "./TimePicker";

const time = ['10:00:00', '11:00:00','11:30:00','12:30:00','13:00:00'];

export const getMocksDatesCourseTimePicker = (days : number): string[] => {
    const arrayDates : string[] = [];

    for (let i = 0; i < days; i++){
        const date = moment().add(i, 'days').toString();
        time.forEach((time) => {
            arrayDates.push(date.slice(0,16) + time + date.slice(24));
        })
    }
    return arrayDates; 
}

export const getSortedDates = (dates : string[]) => {
    const returnValue = {};
    dates.forEach((date) => {
        const dateObj = moment(date).toObject();
        const keys = Object.keys(returnValue);
        const key = `${dateObj.date} ${dateObj.months + 1} ${dateObj.years}`;
        const value = {
            weekday: date.slice(0,3), 
            day : {
                date: dateObj.date,
                month: dateObj.months,
            },
            time : {
                hours: dateObj.hours,
                minutes: dateObj.minutes,
            }
        };
        if (!keys.includes(key)) {
            returnValue[key] = {day: value.day, weekday: value.weekday, time: [value.time]};
        }
        else {
            returnValue[key].time.push(value.time);
        }
    })
    return returnValue;
}


const daysShortEn = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const daysShortRu = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];

export const getDayTitle = (day: IDay, weekday: string) : string => {
    return `${day.date} ${monthsRu[day.month]}, ${daysShortRu[daysShortEn.indexOf(weekday)]}`;
}