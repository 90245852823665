import React from 'react';

export const DocumentTypeHeader: React.FC<{ name: string; description: string }> = ({ name, description }) => {
    return (
        <div className={'documentType-row documentType-header'}>
            <div className="documentType-title">{name}</div>
            <div className="documentType-description">{description}</div>
        </div>
    );
};
