import React from 'react';
import moment from 'moment';

import { FetchStatus } from '../../../../common/types';
import { useAppSelector } from '../../../../redux/store';
import { MeetingRequest } from '../../../../redux/types/meetings';
import { Meeting } from '../../../../redux/types/near';

export const HeaderMeetingList: React.FC<{ event: Meeting[]; requests: MeetingRequest[] }> = ({ event, requests }) => {
    const { teachers: lmsTeachers, fetchStatus: lmsTeacherFetchStatus } = useAppSelector((store) => store.courseGroups);

    return (
        <>
            {event.map((meet: Meeting, index: number) => {
                const moscow_offset = 3 * 60;
                // выводим московское время
                const moscowDate = moment(meet?.datetimeStart).utcOffset(moscow_offset).format('DD MMMM в HH:mm');
                return <li key={index}>
                    <div className="meeting-card">
                        <div>
                            {!!meet?.users?.length && (
                                <>
                                    <header className="meeting-card__header">{meet?.topic}:</header>
                                    <div className="meeting-card__name">{`${meet?.users[0]?.lastName} ${meet?.users[0]?.firstName} ${meet?.users[0]?.patronymic}`}</div>
                                </>
                            )}
                        </div>
                        {meet?.url ? (
                            <div className="meeting-card__text">
                                Консультация {moscowDate}.<br />
                                <a href={meet?.url} className="meeting-card__enter-link">
                                    Войти на консультацию
                                </a>
                            </div>
                        ) : (
                            <div className="meeting-card__text">
                                Консультация {moscowDate}.<br /> За 15 мин до
                                начала появится ссылка для входа
                            </div>
                        )}
                    </div>
                </li>
            })}
            {lmsTeacherFetchStatus === FetchStatus.FETCHED &&
                !!lmsTeachers?.length &&
                !!requests?.length &&
                requests?.map((req: MeetingRequest, index: number) => {
                    const requestTeacher = lmsTeachers.find(
                        (teacher) => teacher.externalId === req.teacher_external_id,
                    );
                    return (
                        <li key={index}>
                            <div className="meeting-card">
                                <div>
                                    <header className="meeting-card__header">{req?.subject}:</header>
                                    {!!requestTeacher && (
                                        <div className="meeting-card__name">{`${requestTeacher?.lastName} ${requestTeacher?.firstName} ${requestTeacher?.patronymic}`}</div>
                                    )}
                                </div>
                                <div className="meeting-card__text">
                                    Запрос на встречу от преподавателя
                                    <br />
                                    <a href={`/meeting/?requestID=${req?.uuid}`} className="meeting-card__enter-link">
                                        Назначить встречу
                                    </a>
                                </div>
                            </div>
                        </li>
                    );
                })}
        </>
    );
};
