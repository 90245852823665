import { getType } from 'typesafe-actions';
import { Action } from '../store';

import { LoaderState } from '../types/loader';
import { loaded, loading } from '../actions/loader';

const initialState = {
    isLoading: false,
};

export const loader = (state: LoaderState = initialState, action: Action) => {
    switch (action.type) {
        case getType(loading): {
            return {
                ...state,
                isLoading: true,
            };
        }

        case getType(loaded): {
            return {
                ...state,
                isLoading: false,
            };
        }

        default: {
            return {
                ...state,
            };
        }
    }
};
