import React from 'react'

import './TeacherReviewLisItem.scss'

const TeacherReviewLisItem: React.FC<{ review: string }> = ({ review }) => {

    if (review) {
        return (
            <p>
                {review}
            </p>
        )
    } else {
        return null
    }
}

export default TeacherReviewLisItem