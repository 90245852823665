import { getType } from 'typesafe-actions';
import { Action } from '../store';
import { AlertState } from '../types/alert';
import { closeAlert, showAlert } from '../actions/alert';
import { v1 as uuid } from 'uuid';

const initialState: AlertState = {
    alerts: [],
};

export const alert = (state: AlertState = initialState, action: Action) => {
    switch (action.type) {
        case getType(showAlert): {
            let newAlert = JSON.parse(JSON.stringify(state.alerts));
            newAlert.push({
                ...action.payload,
                show: true,
                uid: uuid(),
            });

            return {
                ...state,
                alerts: newAlert,
            };
        }

        case getType(closeAlert): {
            let newAlert = JSON.parse(JSON.stringify(state.alerts));

            return {
                ...state,
                alerts: newAlert.filter((alert: any) => alert.uid !== action.payload),
            };
        }

        default: {
            return {
                ...state,
            };
        }
    }
};
