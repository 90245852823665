import React from 'react';

import './LoadingSchedule.scss';

export const LoadingSchedule: React.FC = () => (
    <div className="loading-schedule">
        <div className="card schedule-card schedule-card--pl card-pl">
            <div className="app__card-body d-inline-flex flex-row">
                <div className="schedule-card__time card-pl__square" />
                <div className="schedule-card__content">
                    <div className="card-title schedule-card__title card-pl__title" />
                    <p className="card-text schedule-card__text card-pl__text" />
                    <footer className=" card-pl__footer card-footer schedule-card__footer" />
                </div>
            </div>
        </div>
    </div>
);
