import { FuncEpic } from '../../common/types';
import { combineEpics, ofType } from 'redux-observable';
import { getType } from 'typesafe-actions';
import { fetchProcessesError } from '../actions/processes';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { AxiosError } from 'axios';
import { getAssignmentProgressPreview, getAssignmentProgressPreviewSuccess } from '../actions/homework';

const getAssignmentProgressPreviewEpic: FuncEpic = (action$, store$, deps) =>
    action$.pipe(
        ofType(getType(getAssignmentProgressPreview)),
        switchMap(({ payload }) =>
            from(deps.scheduleDataProvider.getAssignmentProgressPreview(payload)).pipe(
                switchMap((response) => of(getAssignmentProgressPreviewSuccess(response))),
                catchError((error: AxiosError) => of(fetchProcessesError(error))),
            ),
        ),
    );

export const homeworkEpics = combineEpics(getAssignmentProgressPreviewEpic);
