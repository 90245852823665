import {
    loadProfileSuccess,
    loadProfileError,
    LOAD_PROFILE,
    LOAD_SELECTED_PROFILE,
    loadSelectedProfileError,
    loadSelectedProfileSuccess,
    loadSelectedProfile,
    addParentStart,
    addParentSuccess,
    addParentError,
    loadParentsStart,
    loadParentsSuccess,
    loadParentsError,
} from '../actions/profile';
import { combineEpics, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { FuncEpic } from '../../common/types';
import { checkAuthError } from '../../helpers/checkAuthError';
import { ActionType } from 'typesafe-actions';
import { profile } from 'console';

const profileEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(LOAD_PROFILE),
        switchMap(() => {
            return from(deps.profileDataProvider.loadProfile()).pipe(
                switchMap((profiles) => {
                    if (!store$.value.profile.selectedProfile?.id || !profiles.find((profile) => profile.id === store$.value.profile.selectedProfile?.id)) {
                        return of(
                            loadProfileSuccess(profiles),
                            loadSelectedProfile(store$.value.selectedProfile.pageStatus, profiles[0]),
                        );
                    } else {
                        return of(
                            loadProfileSuccess(profiles),
                            loadSelectedProfile(
                                store$.value.selectedProfile.pageStatus,
                                store$.value.profile.selectedProfile,
                            ),
                        );
                    }
                }),
                catchError((err) => of(loadProfileError(err))),
            );
        }),
    );
};

const addParentEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(addParentStart),
        switchMap(({ payload }: ActionType<typeof addParentStart>) => {
            return from(deps.profileDataProvider.addParent(payload)).pipe(
                switchMap((response) => {
                    return of(addParentSuccess(response));
                }),
                catchError((err) => {
                    return of(addParentError(err));
                }),
            );
        }),
    );
};

const loadParentsEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(loadParentsStart),
        switchMap(({ payload }: ActionType<typeof loadParentsStart>) => {
            return from(deps.profileDataProvider.loadParents(payload)).pipe(
                switchMap((response) => {
                    return of(loadParentsSuccess(response));
                }),
                catchError((err) => {
                    return of(loadParentsError(err));
                }),
            );
        }),
    );
};

const loadSelectedProfileEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(LOAD_SELECTED_PROFILE),
        switchMap(({ selectedProfile }) => {
            return from(deps.profileDataProvider.loadSelectedProfile(selectedProfile)).pipe(
                switchMap((profile) => {
                    return of(loadSelectedProfileSuccess(profile));
                }),
                catchError((err) => {
                    checkAuthError(err);
                    return of(loadSelectedProfileError(err));
                }),
            );
        }),
    );
};

export const profileEpics = combineEpics(profileEpic, loadSelectedProfileEpic, addParentEpic, loadParentsEpic);
