import { getType } from 'typesafe-actions';
import { loadConstantsError, loadConstantsStart, loadConstantsSuccess } from '../actions/constants';
import { ConstantsState } from '../types/constants';
import { FetchStatus } from '../../common/types';
import { Action } from '../store';

const initialState: ConstantsState = {
    fetchStatus: FetchStatus.INITIAL,
    constants: null,
    error: null,
};

export const constants = (state: ConstantsState = initialState, action: Action) => {
    switch (action.type) {
        case getType(loadConstantsStart): {
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHING,
            };
        }
        case getType(loadConstantsSuccess): {
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHED,
                constants: action.payload,
            };
        }
        case getType(loadConstantsError): {
            return {
                ...state,
                fetchStatus: FetchStatus.ERROR,
                error: action.payload,
            };
        }
        default: {
            return {
                ...state,
            };
        }
    }
};
