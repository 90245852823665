import React from 'react';
import { connect } from 'react-redux';

import { PageStatus } from 'common/types';
import { State } from 'redux/store';
import ScheduleItem from 'components/Schedule/ScheduleItem';
import { EmptySchedule } from 'components/EmptySchedule';
import { LoadingSchedule } from 'components/LoadingSchedule';

import './ScheduleList.scss';

const ScheduleList: React.FC<{
    pageStatus: PageStatus;
    schedule: Array<any> | null;
    error: any;
}> = ({ pageStatus, schedule }) => {
    if (pageStatus === PageStatus.LOADING) {
        return (
            <div className="dashboard__schedule dashboard__schedule--loading">
                <h5 className="mb-4">Расписание</h5>
                <div className="dashboard__schedule-wrapper">
                    {[...Array(2)].map((_, index) => (
                        <div className="dashboard__item dashboard__schedule-item" key={index}>
                            <LoadingSchedule />
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    if (!schedule?.length) {
        return (
            <div className="dashboard__schedule">
                {pageStatus === PageStatus.ERROR && (
                    <h5 className="mb-4">Не удалось загрузить ближайшее расписание</h5>
                )}
                <EmptySchedule />
            </div>
        )
    }

    return (
        <div className="dashboard__schedule">
            <h5 className="mb-4">Расписание</h5>
            <div className="dashboard__schedule-wrapper">
                {
                    schedule.map((el, index) => (
                        <ScheduleItem
                            key={index}
                            time={el.timestart}
                            subject={el.class}
                            scheduleDate={el.timestart}
                            description={el.class_topic}
                        />
                    ))
                }

            </div>
        </div>
    )
}

export default connect(
    ({ shortSchedule }: State) => ({ ...shortSchedule }),
    () => ({}),
)(ScheduleList)