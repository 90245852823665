import { ITimePickerItem } from 'components/CourseTimePicker/TimePicker';

export const getScheduleText = (el: any) => {
    const daysOfWeek = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'];
    const monthNames = [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря',
    ];
    const date = el.date.split('-')[2];
    const month = el.date.split('-')[1];
    const time_start = new Date(el.time_start * 1000);
    const time_end = new Date(el.time_end * 1000);
    return `
            ${date}
            ${monthNames[+month - 1]},
            ${daysOfWeek[parseInt(el.day_of_week) - 1]}
            с ${time_start.getHours()}:${time_start.getMinutes()}
            до ${time_end.getHours()}:${time_end.getMinutes()}
            `;
};

export const getLessonNo = (sortedDates, el) => {
    const index = Object.keys(sortedDates.dates).findIndex(
        (key) =>
            el.date === key &&
            el.time.hours === sortedDates.dates[key].time[0].hours &&
            el.time.minutes === sortedDates.dates[key].time[0].minutes,
    );
    return sortedDates.lessonNumberArr[index];
};

export const timeComparison = (a: ITimePickerItem, b: ITimePickerItem) => {
    return a.date === b.date && a.time.hours === b.time.hours && a.time.minutes === b.time.minutes;
};
