import { FetchStatus } from '../../common/types';
import { HomeworkState } from '../types/homework';

import { getType } from 'typesafe-actions';
import {
    getAssignmentProgressPreview,
    getAssignmentProgressPreviewError,
    getAssignmentProgressPreviewSuccess,
    HomeworkActions,
    resetHomeworkState,
} from '../actions/homework';

const initialState: HomeworkState = {
    fetchStatus: FetchStatus.INITIAL,
    homeworkPreview: [],
    error: null,
};

export const homework = (state: HomeworkState = initialState, action: HomeworkActions): HomeworkState => {
    switch (action.type) {
        case getType(resetHomeworkState):
            return {
                ...initialState,
            };

        case getType(getAssignmentProgressPreview):
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHING,
            };
        case getType(getAssignmentProgressPreviewSuccess):
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHED,
                homeworkPreview: action.payload,
                error: null,
            };
        case getType(getAssignmentProgressPreviewError):
            return {
                ...state,
                fetchStatus: FetchStatus.ERROR,
                error: action.payload,
            };

        default:
            return state;
    }
};
