export enum Grade {
    fifth = '5 класс',
    sixth = '6 класс',
    seventh = '7 класс',
    eighth = '8 класс',
    ninth = '9 класс',
    tenth = '10 класс',
}

export type TrialInputs = {
    last_name: string;
    first_name: string;
    patronymic: string;
    phone_number: string;
    email: string;
    grade?: Grade | '';
};

export interface Input {
    placeholder: string;
    value: string;
    label: string;
    type: string;
    key: keyof TrialInputs;
}

export interface TrialSignupProps {
    inputs: TrialInputs;
    onInputChange: (inputs: TrialInputs) => void;
    onSignUp: (inputs: TrialInputs) => void;
    isLoading?: boolean;
    parentInputs: TrialInputs;
    handleParentInputsChange: (inputs: TrialInputs) => void;
    schedule: string;
    handleScheduleChange: (newSchedule: string) => void;
    scheduleOptions: string[];
}

export enum FormTypes {
    PARENT = 'parent',
    STUDENT = 'student',
}
