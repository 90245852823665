import React from 'react';
import { LogoutIcon, SettingsBlackNoBg } from '@lms-elements/icons';

export const getTooltipItems = ({
    handleSettingsClick,
    handleLogoutClick,
}: {
    handleSettingsClick: () => void;
    handleLogoutClick: () => void;
}) => [
    // TODO: вернуть SETTINGS при необходимости
    // {
    //     icon: (
    //         <div>
    //             <SettingsBlackNoBg />
    //         </div>
    //     ),
    //     label: 'Настройки',
    //     onClick: handleSettingsClick,
    // },
    {
        icon: <LogoutIcon />,
        label: 'Выход из аккаунта',
        onClick: handleLogoutClick,
    },
];
