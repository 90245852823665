import React, { useCallback } from 'react';
import './SettingsButton.scss';
import { useHistory, Link } from 'react-router-dom';

interface ISettingsButtonProps {
    id: string;
}

export const SettingsButton: React.FC<ISettingsButtonProps> = ({ id }) => {
    const history = useHistory();

    const handleClick = useCallback(() => {
        history.push(`/settings/${id}`);
    }, [history]);

    return (
        <Link to={`/settings/${id}`}>
            <div className="settings-button__wrapper">
                <svg width="16" height="16" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M20.6362 13.4105L18.6524 11.8293C18.6777 11.5844 18.6987 11.3008 18.6987 11C18.6987 10.6992 18.6777 10.4113 18.6524 10.1707L20.6362 8.58945C21.0026 8.29297 21.1037 7.76445 20.872 7.32617L18.8124 3.68672C18.5934 3.27422 18.1091 3.06367 17.6205 3.24844L15.2829 4.20664C14.8365 3.87578 14.3647 3.59648 13.8804 3.37734L13.5224 0.842188C13.4634 0.360938 13.0549 0 12.5705 0H8.43869C7.95432 0 7.54578 0.360937 7.48681 0.837891L7.1288 3.37734C6.65708 3.59219 6.19378 3.86719 5.72626 4.20664L3.38027 3.24844C2.94224 3.07656 2.41576 3.26992 2.19675 3.67812L0.132946 7.32187C-0.11134 7.74297 -0.0102561 8.28867 0.364597 8.58945L2.34837 10.1707C2.31889 10.4801 2.30204 10.7465 2.30204 11C2.30204 11.2535 2.31889 11.5199 2.34837 11.8293L0.364597 13.4148C-0.00183243 13.7113 -0.102916 14.2398 0.132946 14.6781L2.19253 18.3133C2.41155 18.7215 2.8917 18.9363 3.38448 18.7516L5.72205 17.7934C6.16851 18.1242 6.64023 18.3992 7.12459 18.6227L7.47839 21.1535C7.53735 21.6348 7.9459 22 8.43026 22H12.5621C13.0464 22 13.455 21.6391 13.5139 21.1621L13.872 18.6227C14.3437 18.4035 14.8028 18.1328 15.2745 17.7891L17.6205 18.7516C17.7342 18.7945 17.8521 18.8203 17.9743 18.8203C18.3239 18.8203 18.644 18.627 18.804 18.3219L20.8762 14.6652C21.1079 14.2355 21.0068 13.7113 20.6362 13.4105ZM10.5025 14.6652C8.51871 14.6652 6.90979 13.0195 6.90979 11C6.90979 8.98047 8.52292 7.33477 10.5025 7.33477C12.482 7.33477 14.0952 8.98047 14.0952 11C14.0952 13.0195 12.4863 14.6652 10.5025 14.6652Z"
                        fill="#007EEC"
                    />
                </svg>
            </div>
        </Link>
    );
};
