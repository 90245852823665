import { ILoginState } from 'redux/types/login';
import { ActionType, createAction } from 'typesafe-actions';

export const fetchLoginStart = createAction("login/fetchLogin/start")<ILoginState>();
export const fetchLoginSuccess = createAction("login/fetchLogin/success")<{
    access: string;
    refresh: string;
}>();
export const fetchLoginError = createAction("login/fetchLogin/error")<any>();

export const logout = createAction("login/logout")();

export type LoginActions = ActionType<typeof fetchLoginStart> | ActionType<typeof fetchLoginSuccess> | ActionType<typeof fetchLoginError> | ActionType<typeof logout>;
