import { HomeworkItemProps } from '@lms-elements/atomic';
import moment from 'moment';
import {
    AssignmentProgressPreview,
    FilterTypes,
    IHomeworkItem,
    ScheduleData,
    ScheduleEvent,
} from 'redux/types/schedule';
import { getEarliestDate } from './date';

const normalizeHomeworkItem = (homework: IHomeworkItem | AssignmentProgressPreview): HomeworkItemProps => {
    const homeworkItem = Object.keys(homework).includes('type');
    const isMandatory = homeworkItem
        ? (homework as IHomeworkItem).type.isMandatory
        : (homework as AssignmentProgressPreview).assignment.type.isMandatory;
    return {
        ...homework,
        assignedDate: homework.assignedDate ?? '',
        title: homework.title ?? '',
        courseGroupId: homework.courseGroup?.id,
        lessonId: typeof homework.lesson === 'number' ? homework.lesson : homework.lesson?.id ?? 0,
        subjectId: typeof homework.subject === 'number' ? homework.subject : homework.subject?.id ?? 0,
        description: 'description' in homework ? homework.description ?? '' : '',
        taskId: Number(homework.id),
        extraInfo: {
            label: isMandatory ? 'Обязательное' : 'Рекомендованное',
        },
    };
};

export interface HomeworkObject {
    [key: string]: {
        date: string;
        data: HomeworkItemProps[];
        assigned: HomeworkItemProps[];
    };
}

export const combineHomeworks = (
    assignmentProgresses: IHomeworkItem[],
): Array<IHomeworkItem & { assignmentCount?: number }> => {
    return assignmentProgresses
        .reduce((homeworkItems, hwProgress) => {
            const hwProgressIndex = homeworkItems.findIndex(
                (item) =>
                    item.assignment.id === hwProgress.assignment.id &&
                    item.courseGroup?.id === hwProgress.courseGroup?.id,
            );

            if (hwProgressIndex >= 0) {
                const currentItem = { ...homeworkItems[hwProgressIndex] };
                const deadline = getEarliestDate([
                    currentItem.deadlineForComplete || '',
                    hwProgress.deadlineForComplete || '',
                ]);
                homeworkItems[hwProgressIndex] = {
                    ...currentItem,
                    id: `${currentItem.id}`,
                    deadlineForComplete: deadline,
                    assignmentCount: currentItem?.assignmentCount ? currentItem?.assignmentCount + 1 : 1,
                };
            } else {
                homeworkItems.push({
                    ...hwProgress,
                    id: `${hwProgress.id}`,
                    title: hwProgress.title,
                    assignmentCount: 1,
                });
            }

            return homeworkItems;
        }, [] as Array<IHomeworkItem & { assignmentCount?: number }>)
        .slice()
        .sort((a, b) => moment(a.deadlineForComplete).diff(b.deadlineForComplete));
};

export const getHomeworksObject = (
    homeworks: Record<FilterTypes, IHomeworkItem[]>,
    isStudent?: boolean,
): HomeworkObject => {
    const {
        [FilterTypes.ASSIGNED]: assignedTasks,
        [FilterTypes.DEADLINE_FOR_COMPLETE]: studentTasks,
        [FilterTypes.DEADLINE_FOR_CHECK]: teacherTasks,
    } = homeworks;

    const data = (isStudent ? studentTasks : combineHomeworks(teacherTasks)).reduce((result, homework) => {
        const date = moment(homework.deadlineForComplete).format('YYYY-MM-DD');

        if (result[date]) {
            result[date].data.push(normalizeHomeworkItem(homework));
        } else {
            result[date] = {
                data: [normalizeHomeworkItem(homework)],
                assigned: [],
                date,
            };
        }

        return result;
    }, {} as HomeworkObject);

    assignedTasks.forEach((task) => {
        const assignedDate = moment(task.assignedDate).format('YYYY-MM-DD');

        if (data[assignedDate]) {
            data[assignedDate].assigned.push(normalizeHomeworkItem(task));
        } else {
            data[assignedDate] = {
                assigned: [normalizeHomeworkItem(task)],
                data: [],
                date: assignedDate,
            };
        }
    });

    return data;
};

export const normalizeScheduleEvents = (data: any): ScheduleData => {
    const { lessonSchedule = [], eventRoomSchedule = [], assemblySchedule = [] } = data;
    return [...lessonSchedule, ...eventRoomSchedule, ...assemblySchedule]
        .sort((a, b) => moment(a.datetimeStart).diff(b.datetimeStart))
        .map((event) => {
            return {
                ...event,
                date: moment(event.datetimeStart).format('YYYY-MM-DD'),
            };
        })
        .reduce((result, lesson) => {
            if (!result[lesson.date]) {
                result[lesson.date] = {
                    date: lesson.date,
                    data: [lesson],
                };
            } else {
                result[lesson.date].data.push(lesson);
            }

            return result;
        }, {} as ScheduleData);
};

export const normalizeScheduleDayEvents = (data: any, date: string): ScheduleEvent[] => {
    const { lessonSchedule = [], eventRoomSchedule = [], assemblySchedule = [] } = data;
    return [...lessonSchedule, ...eventRoomSchedule, ...assemblySchedule]
        .sort((a, b) => moment(a.datetimeStart).diff(b.datetimeStart))
        .map((event) => {
            return {
                ...event,
                date: date,
            };
        });
};
