import { Action, EpicDeps, State } from 'redux/store';
import { Epic } from 'redux-observable';

export enum PageStatus {
    INITIAL = 'INITIAL',
    LOADED = 'LOADED',
    LOADING = 'LOADING',
    ERROR = 'ERROR',
}

export enum FetchStatus {
    INITIAL = 'INITIAL',
    FETCHING = 'FETCHING',
    FETCHED = 'FETCHED',
    ERROR = 'ERROR',
}

export type FuncEpic = Epic<Action, Action, State, EpicDeps>;

export type NonFunctionPropertyNames<T> = {
    [K in keyof T]: T[K] extends Function ? never : K;
}[keyof T];
export type FunctionPropertyNames<T> = {
    [K in keyof T]: T[K] extends Function ? K : T[K] extends Function | undefined ? K : never;
}[keyof T];

export type TStateProps<T, TOwnProps = {}> = Pick<
    T,
    Exclude<NonFunctionPropertyNames<T>, NonFunctionPropertyNames<TOwnProps>>
>;
export type TDispatchProps<T, TOwnProps = {}> = Pick<
    T,
    Exclude<FunctionPropertyNames<T>, FunctionPropertyNames<TOwnProps>>
>;

export interface ISchool {
    id: number;
    name: string;
    prefix: string;
    external_id: string;
    signable_with_edc: boolean;
    full_time: boolean;
    extramural: boolean;
    combined: boolean;
    family: boolean;
    hidden: boolean;
}

export interface IReport {
    id: number;
    docx_url: string;
    pdf_url: string;
}

export interface IPhoneNumber {
    phone_number: string;
    country_code: string;
    default: boolean;
}

export interface IFullName {
    lastName: string;
    firstName: string;
    patronymic: string;
}
