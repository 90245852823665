import React from 'react';
import './BookCourseError.scss';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

interface IBookCourseErrorProps {
    title: string;
    reason: string;
    studentId: number;
}

export const BookCourseError: React.FC<IBookCourseErrorProps> = ({ title, reason, studentId }) => {
    const history = useHistory();

    return (
        <div className="book-course-error__wrapper">
            <div className="text-result text-danger">
                <p>
                    <i className="icon-cancel text-result__icon" /> {title}
                </p>
            </div>

            <div className="text-block">
                <h6 className="text-block__title">Причина ошибки:</h6>
                <p className="text-secondary">{reason}</p>
            </div>

            <Button
                variant="primary"
                className="book-course-error__settings-button"
                onClick={() =>
                    reason.split(' ').slice(-1)[0].indexOf('учащемуся') !== -1
                        ? history.push(`/settings/${studentId}`)
                        : history.push('/settings/parent')
                }
            >
                Перейти к настройкам
            </Button>
        </div>
    );
};
