import React from 'react';

import arrowToRightDown from 'assets/img/arrow-to-right-down.svg';

import { IExplanationProps } from './Explanation.types';

import './Explanation.scss';

export const Explanation: React.FC<IExplanationProps> = ({ children, title, description, withGrayScale }) => (
    <div className="explanation">
        <h5 className="explanation__title">{title}</h5>
        <p className="explanation__description">
            {description}
            <img className="explanation__arrow" src={arrowToRightDown} alt="" />
        </p>
        <div className={`explanation__content${withGrayScale ? ' explanation__content_with-grayscale' : ''}`}>
            {children}
        </div>
    </div>
);
