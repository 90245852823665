import React from 'react';

import { Accordion, Card } from 'react-bootstrap';

import { PupilListItem, CircleArrow } from '../..';

import { connect } from 'react-redux';
import { loadProfile, selectProfile, loadSelectedProfile } from '../../../redux/actions/profile';

import './PupilList.scss';

import avatar from '../../../assets/img/load-photo-profile.svg';
import { PageStatus } from '../../../common/types';
import { State } from '../../../redux/store';
import { getAccessToken } from '../../../common/token';

const PupilList: React.FC<{
    pageStatus: PageStatus;
    user: Array<any>;
    error: any;
    isOpened?: boolean;
    loadProfile: (pageStatus: PageStatus) => void;
    selectedStudent: any;
    selectedProfilePageStatus: PageStatus;
    loadSelectedProfile: (pageStatus: PageStatus, profile: any) => void;
    selectProfile: (profile: any) => void;
}> = ({
    pageStatus,
    user,
    loadProfile,
    selectedStudent,
    selectedProfilePageStatus,
    loadSelectedProfile,
    selectProfile,
    isOpened = false,
}) => {
    React.useEffect(() => {
        if (getAccessToken()) loadProfile(pageStatus);
    }, []);

    const profileSelectHandler = (profile: any) => {
        selectProfile(profile);
        loadSelectedProfile(selectedProfilePageStatus, profile);
    };

    return (
        <Accordion className="cabinet-header__user-container">
            <Card>
                <Card.Header className="cabinet-header__user">
                    {pageStatus === PageStatus.LOADED && (
                        <>
                            {user.length <= 1 ? (
                                ''
                            ) : (
                                <div className="cabinet-header__user-opener">
                                    <CircleArrow eventKey="0" />
                                </div>
                            )}
                        </>
                    )}
                    {pageStatus === PageStatus.LOADED ? (
                        <PupilListItem
                            avatar={!selectedStudent?.photo ? avatar : selectedStudent?.photo}
                            title={`${selectedStudent?.last_name} ${selectedStudent?.first_name} ${selectedStudent?.patronymic}`}
                            pupilClass={selectedStudent?.grade}
                            pupilMark={selectedStudent?.point}
                            selected={true}
                            id={selectedStudent?.id}
                        />
                    ) : (
                        <div className="d-flex flex-row user-card__placeholder">
                            <div className="photo-placeholder colorful-circle shape-grey" />
                            {isOpened && (
                                <div className="d-flex flex-column">
                                    <div className="card-title grade-card__title card-pl__title card-pl__title" />
                                    <div className="card-text grade-card__text card-pl__text card-pl__text" />
                                </div>
                            )}
                        </div>
                    )}
                </Card.Header>
                {selectedProfilePageStatus === PageStatus.LOADED && pageStatus === PageStatus.LOADED && (
                    <>
                        {user.length <= 1 ? (
                            ''
                        ) : (
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <ul className="cabinet-header__user-list list-unstyled">
                                        {user
                                            .filter((el) => el.id !== selectedStudent.id)
                                            .map((el) => (
                                                <li key={el.id} className="cabinet-header__user-list-item">
                                                    <PupilListItem
                                                        id={el.id}
                                                        onSelect={() => profileSelectHandler(el)}
                                                        avatar={!el.photo ? avatar : el.photo}
                                                        title={`${el.last_name} ${el.first_name} ${el.patronymic}`}
                                                        selected={false}
                                                    />
                                                </li>
                                            ))}
                                    </ul>
                                </Card.Body>
                            </Accordion.Collapse>
                        )}
                    </>
                )}
            </Card>
        </Accordion>
    );
};

export default connect(
    ({ profile, selectedProfile }: State) => ({
        ...profile,
        selectedStudent: selectedProfile.profile,
        selectedProfilePageStatus: selectedProfile.pageStatus,
    }),
    (dispatch) => {
        return {
            loadProfile: (pageStatus: PageStatus) => {
                return dispatch(loadProfile(pageStatus));
            },
            selectProfile: (profile: any) => {
                return dispatch(selectProfile(profile));
            },
            loadSelectedProfile: (pageStatus: PageStatus, profile: any) => {
                return dispatch(loadSelectedProfile(pageStatus, profile));
            },
        };
    },
)(PupilList);
