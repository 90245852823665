import Cookies from 'universal-cookie';

export const isLocalHost = window.location.href.includes('localhost');
export const cookiesDomain = isLocalHost
    ? undefined
    : process.env.REACT_APP_HOST.includes('tech')
    ? 'og1.tech'
    : 'og1.ru';
/** */
export const getAccessToken = (): string => {
    if (typeof window !== 'undefined') {
        const cookie = new Cookies();
        const accessCookie = cookie.get('access_token');
        if (accessCookie) {
            return String(accessCookie);
        }
    }
    return '';
};

/** */
export const getRefreshToken = (): string => {
    if (typeof window !== 'undefined') {
        const cookie = new Cookies();
        const refreshCookie = cookie.get('refresh_token');

        if (refreshCookie) {
            return String(refreshCookie);
        }
    }
    return '';
};

/**
 *
 * @param token
 */
export const getDecodedTokenData = (token: string): Record<string, string | number> => {
    const tokenData = token.split('.')[1];
    return JSON.parse(window.atob(tokenData)) as Record<string, string | number>;
};

/**
 *
 * @param token
 */
export const setAccessToken = (token: string) => {
    const tokenData = getDecodedTokenData(token);
    const expires = tokenData?.exp ? new Date(+tokenData?.exp * 1000) : new Date();

    new Cookies().set('access_token', token, {
        secure: true,
        expires: expires,
        domain: cookiesDomain,
        sameSite: 'none',
        path: '/',
    });
};
/**
 *
 * @param token
 */
export const setRefreshToken = (token: string) => {
    const cookie = new Cookies();

    const tokenData = getDecodedTokenData(token);
    const expires = tokenData?.exp ? new Date(+tokenData?.exp * 1000) : new Date();

    new cookie.set('refresh_token', token, {
        secure: true,
        expires: expires,
        domain: cookiesDomain,
        sameSite: 'none',
        path: '/',
    });
};

export const removeAccessToken = () => {
    const cookie = new Cookies();
    cookie.remove('access_token');
};
export const removeRefreshToken = () => {
    const cookie = new Cookies();
    cookie.remove('refresh_token');
};
