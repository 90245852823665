import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Redirect, useHistory, useParams} from 'react-router-dom';
import moment from 'moment';

import { State, useAppSelector } from 'redux/store';
import { patchStudentInfoStart, patchUserInfoStart } from 'redux/actions/registration';
import { addParentStart, loadParentsStart, loadProfile } from 'redux/actions/profile';
import { loadConstantsStart } from 'redux/actions/constants';
import { loadUser } from 'redux/actions/user';
import { EMAIL_REG_EXP } from 'common/constants';
import { FetchStatus, PageStatus } from 'common/types';
import add_photo from 'assets/img/load-photo-profile.svg';

import { Checkbox } from 'components/Checkbox';
import { RadioButton } from 'components/RadioButton';
import { AddButton } from 'components/AddButton';

import { TrashButton } from './TrashButton';
import { checkCountryCode, getParentsData, getSettingsData, swapKeysToValues } from './utils';
import { IParentData, ISettingsData } from './Settings.types';
import { convertToDateStr } from '../../common/helpers';

import './Settings.scss';
import { TariffSelection } from '../TariffSelection';
import { educationalTariffsMocks } from '../TariffSelection/TariffSelection.mocks';
import { PageHeader } from '../../components/PageHeader';

export const Settings: React.FC = () => {
    // TODO: вернуть SETTINGS при необходимости
    return (
        <Redirect to='/new' />
    )

    // const { user: settingsUser, trialStudentId, tariffStudentId } = useParams();
    //
    // const { user: userData, pageStatus: userFetchStatus } = useSelector((state: State) => state.user);
    // const allStudents = useSelector((state: State) => state.profile.user);
    // const { userInfo, studentInfo } = useAppSelector((state) => state);
    // const [isSubmitted, setIsSubmitted] = useState(false);
    //
    // const studentData = useMemo(
    //     () => allStudents?.find((student) => student?.id === (+trialStudentId || +settingsUser)),
    //     [allStudents, trialStudentId, settingsUser],
    // );
    //
    // const {
    //     pageStatus: studentFetchStatus,
    //     selectedProfile: selectedStudentProfile,
    //     parents: parentsList,
    // } = useSelector((state: State) => state.profile);
    //
    // const constants = useSelector((state: State) => state.constants);
    //
    // const dispatch = useDispatch();
    // const history = useHistory();
    //
    // const avatarInput = useRef();
    //
    // const isParent = settingsUser === 'parent'
    //
    // const selectedData = isParent ? userData : studentData;
    //
    // const [settingsData, setSettingsData] = useState<ISettingsData>(getSettingsData(selectedData));
    // const [dataToApi, setDataToApi] = useState<ISettingsData>({});
    //
    // const [parentsData, setParentsData] = useState<IParentData[]>(
    //     getParentsData(parentsList, studentData?.parents, userData?.id),
    // );
    //
    // useEffect(() => {
    //     dispatch(loadConstantsStart());
    //     dispatch(loadUser());
    //     dispatch(loadProfile(PageStatus.LOADED));
    // }, []);
    //
    // useEffect(() => {
    //     if (selectedStudentProfile) dispatch(loadParentsStart({ student_id: selectedStudentProfile?.id }));
    // }, [selectedStudentProfile?.id]);
    //
    // useEffect(() => {
    //     setSettingsData(getSettingsData(selectedData));
    // }, [selectedData]);
    //
    // useEffect(() => {
    //     setParentsData(getParentsData(parentsList, studentData?.parents, userData?.id));
    // }, [parentsList, studentData, userData]);
    //
    // const isLoadedData: boolean = useMemo(
    //     () =>
    //         isParent
    //             ? userFetchStatus === PageStatus.LOADED
    //             : studentFetchStatus === PageStatus.LOADED,
    //     [studentFetchStatus, userFetchStatus],
    // );
    //
    // const isValidated: boolean = useMemo(
    //     () =>
    //         settingsData.last_name &&
    //         settingsData.first_name &&
    //         (settingsData.phone_numbers.some((receivedNumber) => {
    //             if (constants.fetchStatus === FetchStatus.FETCHED) {
    //                 const number = receivedNumber[0] === '+' ? receivedNumber.slice(1) : receivedNumber;
    //                 return checkCountryCode(number, constants.constants.COUNTRY_CODES);
    //             }
    //         }) ||
    //             (settingsData.phone_numbers?.length === 0 && !isParent)) &&
    //         !!settingsData.email.match(EMAIL_REG_EXP),
    //     [settingsData, constants],
    // );
    //
    // const handleSettingsDataChange = useCallback(
    //     (value, key) => {
    //         setSettingsData((prevState) => ({ ...prevState, [key]: value }));
    //         setDataToApi({...dataToApi, [key]: value})
    //     },
    //     [settingsData],
    // );
    //
    // const handleParentsDataChange = useCallback(
    //     (value, key, index) => {
    //         setParentsData((prevState) =>
    //             prevState.map((parent, parentIndex) => (index === parentIndex ? { ...parent, [key]: value } : parent)),
    //         );
    //     },
    //     [parentsData],
    // );
    //
    // const handleSubmit = useCallback(() => {
    //     if (isValidated && constants.fetchStatus === FetchStatus.FETCHED) {
    //         const img = (avatarInput.current as HTMLInputElement).files[0];
    //         let avatar = new FormData();
    //         const apiData = isParent ? settingsData : dataToApi
    //         const patchAction = isParent ? patchUserInfoStart : patchStudentInfoStart;
    //
    //         const dispatchedObj: any = {
    //             ...apiData,
    //             phone_numbers: apiData?.phone_numbers?.map((receivedNumber, index) => {
    //                 const number = receivedNumber[0] === '+' ? receivedNumber.slice(1) : receivedNumber;
    //                 const countryCode = checkCountryCode(number, constants.constants.COUNTRY_CODES);
    //                 const id = selectedData?.phone_numbers?.length
    //                     ? selectedData?.phone_numbers?.find(
    //                           (phoneNumberObj) =>
    //                               phoneNumberObj?.phone_number === number.slice(countryCode?.length) &&
    //                               phoneNumberObj?.country_code === countryCode,
    //                       )?.id
    //                     : undefined;
    //                 return {
    //                     country_code: countryCode,
    //                     phone_number: number.slice(countryCode?.length),
    //                     default: index === 0,
    //                     id,
    //                 };
    //             }),
    //             id: selectedData?.id.toString(),
    //         };
    //         if (img) {
    //             avatar.append('avatar', img, img?.name);
    //             dispatch(
    //                 patchAction(
    //                     isParent ? avatar : { id: selectedData?.id.toString(), photo: avatar },
    //                 ),
    //             );
    //         }
    //         delete dispatchedObj.avatar;
    //         dispatch(patchAction(dispatchedObj));
    //         if (!isParent) {
    //             parentsData
    //                 .filter((parent) => parent.email)
    //                 .forEach((parent) =>
    //                     dispatch(
    //                         addParentStart({ student_id: studentData?.id, data: { ...parent, registration: true } }),
    //                     ),
    //                 );
    //         }
    //
    //         setIsSubmitted(true);
    //     }
    // }, [settingsData, parentsData, constants, selectedData]);
    //
    // useEffect(() => {
    //     const patchInfoStatus = isParent ? userInfo.fetchStatus : studentInfo.fetchStatus;
    //
    //     if (patchInfoStatus === FetchStatus.FETCHED && isSubmitted) {
    //         if (isParent) {
    //             dispatch(loadUser());
    //         } else {
    //             dispatch(loadProfile(PageStatus.LOADED));
    //         }
    //         history.push('/');
    //     }
    // }, [userInfo.fetchStatus, studentInfo.fetchStatus, settingsUser, isSubmitted, dispatch]);
    //
    // const parentsDataInputs = useMemo(
    //     () => ({
    //         id: 'parentsData',
    //         data: parentsData
    //             ? parentsData.map((parent) =>
    //                   [
    //                       {
    //                           id: 'last_name',
    //                           placeholder: 'Введите фамилию',
    //                           labelText: 'Фамилия',
    //                           readOnly: parent.email === undefined,
    //                       },
    //                       {
    //                           id: 'first_name',
    //                           placeholder: 'Введите имя',
    //                           labelText: 'Имя',
    //                           readOnly: parent.email === undefined,
    //                       },
    //                       {
    //                           id: 'patronymic',
    //                           placeholder: 'Введите отчество',
    //                           labelText: 'Отчество',
    //                           readOnly: parent.email === undefined,
    //                       },
    //                       parent.email !== undefined
    //                           ? {
    //                                 id: 'email',
    //                                 placeholder: 'Введите e-mail',
    //                                 labelText: 'Адрес почты',
    //                                 readOnly: false,
    //                             }
    //                           : null,
    //                   ].filter((data) => data),
    //               )
    //             : [],
    //     }),
    //     [parentsData],
    // );
    //
    // const generalInfoInputs = useMemo(
    //     () => [
    //         {
    //             id: 'last_name',
    //             placeholder: 'Введите фамилию',
    //             labelText: 'Фамилия',
    //         },
    //         {
    //             id: 'first_name',
    //             placeholder: 'Введите имя',
    //             labelText: 'Имя',
    //         },
    //         {
    //             id: 'patronymic',
    //             placeholder: 'Введите отчество',
    //             labelText: 'Отчество',
    //         },
    //     ],
    //     [settingsData],
    // );
    //
    // const contactsInputs = useMemo(() => {
    //     return [
    //         {
    //             id: 'phones',
    //             data: settingsData.phone_numbers
    //                 ? settingsData.phone_numbers.map(() => ({
    //                       id: 'phone_numbers',
    //                       placeholder: 'Введите номер',
    //                       labelText: 'Номер телефона',
    //                   }))
    //                 : [],
    //         },
    //         {
    //             id: 'other',
    //             data: [
    //                 {
    //                     id: 'email',
    //                     placeholder: 'Введите e-mail',
    //                     labelText: 'Адрес почты',
    //                 },
    //             ],
    //         },
    //     ];
    // }, [settingsData]);
    //
    // const documentInfoInputs = useMemo(() => {
    //     return [
    //         {
    //             id: 'living_place',
    //             placeholder: 'Введите адрес',
    //             labelText: 'Место жительства',
    //         },
    //         isParent
    //             ? {
    //                   id: 'birthday',
    //                   placeholder: 'Введите дату',
    //                   labelText: 'Дата рождения',
    //                   type: 'date',
    //               }
    //             : {
    //                   id: 'birth_certificate',
    //                   placeholder: 'Введите номер',
    //                   labelText: 'Свидетельство о рождении',
    //               },
    //         settingsData.is_russian_citizen
    //             ? {
    //                   id: 'passport_series',
    //                   placeholder: 'Введите серию',
    //                   labelText: 'Серия паспорта',
    //               }
    //             : {
    //                   id: 'foreign_citizen_doc',
    //                   placeholder: 'Паспорт иностранного гражданина',
    //                   labelText: 'Паспорт иностранного гражданина',
    //               },
    //         settingsData.is_russian_citizen
    //             ? {
    //                   id: 'passport_number',
    //                   placeholder: 'Введите номер',
    //                   labelText: 'Номер паспорта',
    //               }
    //             : null,
    //         {
    //             id: 'passport_date_issue',
    //             placeholder: 'Введите дату',
    //             labelText: 'Дата выдачи',
    //             type: 'date',
    //         },
    //         {
    //             id: 'passport_authority',
    //             placeholder: 'Кем выдан',
    //             labelText: 'Кем выдан',
    //         },
    //         isParent
    //             ? null
    //             : {
    //                   id: 'birthday',
    //                   placeholder: 'Введите дату',
    //                   labelText: 'Дата рождения',
    //                   type: 'date',
    //               },
    //         !isParent
    //             ? null
    //             : {
    //                   id: 'delivery_address',
    //                   placeholder: 'Введите адрес',
    //                   labelText: 'Адрес для доставки писем',
    //               },
    //         settingsData.is_russian_citizen
    //             ? null
    //             : {
    //                   id: 'citizenship',
    //                   placeholder: 'Введите страну',
    //                   labelText: 'Резидент страны',
    //               },
    //     ].filter((data) => data);
    // }, [settingsData]);
    //
    // /**
    //  * @description форматирует дату для инпута в -> 'гггг-мм-дд'
    //  * @param {string} value
    //  * @return {string}
    //  */
    // const getFormattedDateInput = (value: string): string => {
    //     const date = new Date(value);
    //     const isValidDate = date instanceof Date && !isNaN(Number(date));
    //     if (isValidDate) {
    //         const year = date.getFullYear();
    //         const month = `0${date.getMonth() + 1}`.slice(-2);
    //         const dateOfMonth = `0${date.getDate()}`.slice(-2);
    //         return `${year}-${month}-${dateOfMonth}`;
    //     }
    //     return value;
    // };
    //
    // /**
    //  * @description форматирует занчение для инпута документа в зависимости от типа
    //  * @param {React.HTMLInputTypeAttribute} type
    //  * @param {string | any} value
    //  * @return {any}
    //  */
    // const getDocumentInputFormattedValue = (type: React.HTMLInputTypeAttribute, value: string | any) => {
    //     switch (type) {
    //         case 'date':
    //             return getFormattedDateInput(value);
    //         default:
    //             return value;
    //     }
    // };
    // const firstNameLetter = userData?.first_name ? ' ' + userData?.first_name[0] + '.' : '';
    // const patronymicLetter = userData?.patronymic ? ' ' + userData?.patronymic[0] + '.' : '';
    // const fullName = userData?.last_name
    //     ? `${userData?.last_name}${firstNameLetter}${patronymicLetter}`
    //     : `${userData?.first_name ?? ''}`;
    //
    // return (
    //     isLoadedData &&
    //     constants.fetchStatus === FetchStatus.FETCHED && (
    //         <div className="settings__pageWrapper">
    //             <div className="cabinet-content custom-scroll position-relative col">
    //                 <div className="settings__wrapper">
    //                     {tariffStudentId ? (
    //                         <TariffSelection
    //                             additionalTariffs={swapKeysToValues(constants.constants.ADD_STAFF_COST)}
    //                             educationalTariffs={swapKeysToValues(
    //                                 Object.fromEntries(
    //                                     Object.entries(constants.constants.EDUCATION_COST).filter(
    //                                         ([_, value]) =>
    //                                             Object.values(educationalTariffsMocks).indexOf(value) !== -1,
    //                                     ),
    //                                 ),
    //                             )}
    //                             studentId={tariffStudentId}
    //                         />
    //                     ) : (
    //                         <>
    //                             <div className="settings__pageHeader-container">
    //                                 {!!userData && (
    //                                     <PageHeader
    //                                         user={{
    //                                             fullName: fullName,
    //                                             img: userData?.avatar,
    //                                         }}
    //                                         title="Настройки"
    //                                     />
    //                                 )}
    //                             </div>
    //                             <div className="settings-photoGender__container">
    //                                 <div className="settings-photoGender-photo__container">
    //                                     <label htmlFor="formControlFile" className="settings-photoGender-photo__label">
    //                                         <img
    //                                             className="settings-photoGender-photo__img"
    //                                             src={settingsData?.avatar || add_photo}
    //                                             alt="Аватар"
    //                                         />
    //                                     </label>
    //                                     <input
    //                                         type="file"
    //                                         className="form-control-file file-input"
    //                                         id="formControlFile"
    //                                         onChange={(e) => {
    //                                             if (e.target.files.length !== 0) {
    //                                                 handleSettingsDataChange(
    //                                                     window.URL.createObjectURL(e.target.files[0] as File),
    //                                                     'avatar',
    //                                                 );
    //                                             }
    //                                         }}
    //                                         ref={avatarInput}
    //                                         hidden
    //                                     />
    //                                     <label htmlFor="formControlFile" className="settings-photoGender-photo__label">
    //                                         <div className="settings-photoGender-photo__button">
    //                                             <span>Изменить фото</span>
    //                                         </div>
    //                                     </label>
    //                                 </div>
    //                                 <div className="settings-photoGender-gender__container">
    //                                     <RadioButton
    //                                         text="Мужчина"
    //                                         checked={settingsData?.gender === 'm'}
    //                                         value="m"
    //                                         onChange={handleSettingsDataChange.bind(null, 'm', 'gender')}
    //                                     />
    //                                     <RadioButton
    //                                         text="Женщина"
    //                                         checked={settingsData?.gender === 'f'}
    //                                         value="f"
    //                                         onChange={handleSettingsDataChange.bind(null, 'f', 'gender')}
    //                                     />
    //                                 </div>
    //                             </div>
    //                             {!isParent && (
    //                                 <div className="settings-parentsInfo__container">
    //                                     <p className="settings__block-header">Родители</p>
    //                                     <div className="settings-parentsInfo-inputs__container">
    //                                         {parentsDataInputs.data.map((inputs, parentIndex) => (
    //                                             <div className="settings-generalInfo-fio__container">
    //                                                 {inputs.map((input, index) => (
    //                                                     <div
    //                                                         className={`form-group settings__formGroup ${
    //                                                             parentsData[parentIndex][input.id] ||
    //                                                             input.id !== 'email'
    //                                                                 ? ''
    //                                                                 : 'settings__formGroup-error'
    //                                                         }`}
    //                                                         key={`generalInfo-${index}`}
    //                                                     >
    //                                                         <input
    //                                                             onChange={
    //                                                                 !input.readOnly
    //                                                                     ? (e) =>
    //                                                                           handleParentsDataChange(
    //                                                                               e.target.value,
    //                                                                               input.id,
    //                                                                               parentIndex,
    //                                                                           )
    //                                                                     : null
    //                                                             }
    //                                                             type="text"
    //                                                             className="form-control"
    //                                                             id={input.id}
    //                                                             name={input.id}
    //                                                             aria-describedby={input.id}
    //                                                             placeholder={input.placeholder}
    //                                                             value={parentsData[parentIndex][input.id] || ''}
    //                                                         />
    //                                                         <label className="settings__input-label" htmlFor={input.id}>
    //                                                             {input.labelText}
    //                                                         </label>
    //                                                     </div>
    //                                                 ))}
    //                                             </div>
    //                                         ))}
    //                                     </div>
    //                                     <AddButton
    //                                         title="Добавить родителя"
    //                                         onAddClick={() =>
    //                                             setParentsData((prevState) => [
    //                                                 ...prevState,
    //                                                 { last_name: '', first_name: '', patronymic: '', email: '' },
    //                                             ])
    //                                         }
    //                                     />
    //                                 </div>
    //                             )}
    //                             <div className="settings-generalInfo__container">
    //                                 <p className="settings__block-header">Общие данные</p>
    //                                 <div className="settings-generalInfo-fio__container">
    //                                     {generalInfoInputs.map((input, index) => (
    //                                         <div
    //                                             className={`form-group settings__formGroup ${
    //                                                 settingsData[input.id] ? '' : 'settings__formGroup-error'
    //                                             }`}
    //                                             key={`generalInfo-${index}`}
    //                                         >
    //                                             <input
    //                                                 onChange={(e) => handleSettingsDataChange(e.target.value, input.id)}
    //                                                 type="text"
    //                                                 className="form-control"
    //                                                 id={input.id}
    //                                                 name={input.id}
    //                                                 aria-describedby={input.id}
    //                                                 placeholder={input.placeholder}
    //                                                 value={settingsData[input.id] || ''}
    //                                             />
    //                                             <label className="settings__input-label" htmlFor={input.id}>
    //                                                 {input.labelText}
    //                                             </label>
    //                                         </div>
    //                                     ))}
    //                                 </div>
    //                             </div>
    //                             <div className="settings-contacts__container">
    //                                 <p className="settings__block-header">Контакты</p>
    //                                 <div className="settings-contacts-inputs__container">
    //                                     {contactsInputs.map((input) =>
    //                                         input.id === 'phones' ? (
    //                                             <div
    //                                                 className="settings-contacts-inputs-phones__container"
    //                                                 key="contacts-phone"
    //                                             >
    //                                                 {input.data.map((data, phoneIndex) => (
    //                                                     <div
    //                                                         className={`form-group settings__formGroup ${
    //                                                             !settingsData[data.id][phoneIndex] ||
    //                                                             !checkCountryCode(
    //                                                                 settingsData[data.id][phoneIndex][0] === '+'
    //                                                                     ? settingsData[data.id][phoneIndex].slice(1)
    //                                                                     : settingsData[data.id][phoneIndex],
    //                                                                 constants.constants.COUNTRY_CODES,
    //                                                             )
    //                                                                 ? 'settings__formGroup-error'
    //                                                                 : ''
    //                                                         }`}
    //                                                         key={`contacts-phone-${phoneIndex}`}
    //                                                     >
    //                                                         <input
    //                                                             onChange={(e) =>
    //                                                                 handleSettingsDataChange(
    //                                                                     settingsData.phone_numbers.map(
    //                                                                         (number, numIndex) =>
    //                                                                             numIndex === phoneIndex
    //                                                                                 ? e.target.value
    //                                                                                 : number,
    //                                                                     ),
    //                                                                     data.id,
    //                                                                 )
    //                                                             }
    //                                                             type="text"
    //                                                             className="form-control"
    //                                                             id={data.id}
    //                                                             name={data.id}
    //                                                             aria-describedby={data.id}
    //                                                             placeholder={data.placeholder}
    //                                                             value={settingsData[data.id][phoneIndex] || ''}
    //                                                             maxLength={25}
    //                                                         />
    //                                                         <TrashButton
    //                                                             onClick={handleSettingsDataChange.bind(
    //                                                                 null,
    //                                                                 settingsData.phone_numbers.filter(
    //                                                                     (_, numIndex) => numIndex !== phoneIndex,
    //                                                                 ),
    //                                                                 'phone_numbers',
    //                                                             )}
    //                                                         />
    //                                                         <label className="settings__input-label" htmlFor={data.id}>
    //                                                             {phoneIndex > 0
    //                                                                 ? data.labelText
    //                                                                 : 'Основной номер телефона'}
    //                                                         </label>
    //                                                     </div>
    //                                                 ))}
    //                                                 {(isParent ||
    //                                                     settingsData?.phone_numbers?.length === 0) && (
    //                                                     <AddButton
    //                                                         title="Добавить телефон"
    //                                                         onAddClick={handleSettingsDataChange.bind(
    //                                                             null,
    //                                                             settingsData.phone_numbers.concat(['']),
    //                                                             'phone_numbers',
    //                                                         )}
    //                                                     />
    //                                                 )}
    //                                             </div>
    //                                         ) : (
    //                                             <div
    //                                                 className="settings-contacts-inputs-other__container"
    //                                                 key="contacts-other"
    //                                             >
    //                                                 {input.data.map((data, otherIndex) => (
    //                                                     <div
    //                                                         className={`form-group settings__formGroup ${
    //                                                             settingsData[data.id]?.match(EMAIL_REG_EXP)
    //                                                                 ? ''
    //                                                                 : 'settings__formGroup-error'
    //                                                         }`}
    //                                                         key={`contacts-other-${otherIndex}`}
    //                                                     >
    //                                                         <input
    //                                                             type="text"
    //                                                             className="form-control"
    //                                                             id={data.id}
    //                                                             name={data.id}
    //                                                             aria-describedby={data.id}
    //                                                             placeholder={data.placeholder}
    //                                                             value={settingsData[data.id] || ''}
    //                                                         />
    //                                                         <label className="settings__input-label" htmlFor={data.id}>
    //                                                             {data.labelText}
    //                                                         </label>
    //                                                     </div>
    //                                                 ))}
    //                                             </div>
    //                                         ),
    //                                     )}
    //                                 </div>
    //                             </div>
    //                             <div className="settings-documentInfo__container">
    //                                 <div className="settings-documentInfo-citizenCheck__container">
    //                                     <p className="settings__block-header">Паспортные данные</p>
    //                                     <div className="settings-documentInfo-citizenCheck-checkBox__container">
    //                                         <Checkbox
    //                                             checked={settingsData?.is_russian_citizen}
    //                                             onClick={(checked) =>
    //                                                 handleSettingsDataChange(checked, 'is_russian_citizen')
    //                                             }
    //                                         />
    //                                         <span className="settings-documentInfo-citizenCheck__checkBox-text">
    //                                             Гражданин Российской Федерации
    //                                         </span>
    //                                     </div>
    //                                 </div>
    //                                 <div className="settings-documentInfo-inputs__container">
    //                                     {documentInfoInputs.map((input, index) =>
    //                                         !(
    //                                             !isParent &&
    //                                             moment().diff(convertToDateStr(selectedData?.birthday), 'years') < 14 &&
    //                                             input.id.indexOf('passport') !== -1
    //                                         ) ? (
    //                                             <div
    //                                                 className="form-group settings__formGroup"
    //                                                 key={`documentInfo-${index}`}
    //                                             >
    //                                                 <input
    //                                                     onChange={(e) =>
    //                                                         handleSettingsDataChange(e.target.value, input.id)
    //                                                     }
    //                                                     type={input.type || 'text'}
    //                                                     className="form-control"
    //                                                     id={input.id}
    //                                                     name={input.id}
    //                                                     aria-describedby={input.id}
    //                                                     placeholder={input.placeholder}
    //                                                     value={getDocumentInputFormattedValue(
    //                                                         input.type,
    //                                                         settingsData[input.id] ?? '',
    //                                                     )}
    //                                                 />
    //                                                 <label className="settings__input-label" htmlFor={input.id}>
    //                                                     {input.labelText}
    //                                                 </label>
    //                                             </div>
    //                                         ) : null,
    //                                     )}
    //                                 </div>
    //                             </div>
    //                             <button className="btn btn-primary btn-lg" type="button" onClick={handleSubmit}>
    //                                 Сохранить
    //                             </button>
    //                         </>
    //                     )}
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // );
};
