import React from 'react';
import './Checkbox.scss';

export const Checkbox: React.FC<{ id?: string; checked: boolean; onClick: (value: boolean) => void }> = ({
    checked,
    onClick = (e) => {},
    id,
}) => {
    return (
        <div onClick={(e) => onClick(!checked)} className={`checkbox__wrapper ${checked ? 'checked' : ''}`}>
            <input id={id} checked={checked} type="checkbox" />
            <span className="checkmark">
                <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.0638 0.803349L4.80322 8.06396L0.999878 4.26062" strokeWidth="2" />
                </svg>
            </span>
        </div>
    );
};
