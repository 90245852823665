import { IResetPassword } from "api/ResetPasswordDataProvider";
import { ActionType, createAction } from "typesafe-actions";

export const sendResetEmail = createAction('@sendResetEmail/LOAD/START')<any>();
export const sendResetEmailSuccess = createAction('@sendResetEmail/LOAD/SUCCESS')<any>();
export const sendResetEmailError = createAction('@sendResetEmail/LOAD/ERROR')<any>();

export type SendResetEmailActions = ActionType<typeof sendResetEmail> | ActionType<typeof sendResetEmailSuccess> | ActionType<typeof sendResetEmailError>

export const changePassword = createAction('@changePassword/LOAD/START')<IResetPassword>();
export const changePasswordSuccess = createAction('@changePassword/LOAD/SUCCESS')<any>();
export const changePasswordError = createAction('@changePassword/LOAD/ERROR')<any>();

export type ChangePasswordActions = ActionType<typeof changePassword> | ActionType<typeof changePasswordSuccess> | ActionType<typeof changePasswordError>