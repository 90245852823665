import { combineEpics, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import {
    loadDocumentsError,
    loadDocumentsSuccess,
    UPLOAD_DOCUMENTS,
    uploadDocumentsSuccess,
    uploadDocumentsError,
    LOAD_DOCUMENTS,
    deleteDocumentSuccess,
    deleteDocumentError,
    loadDocuments,
    DELETE_DOCUMENT,
    UploadDocumentsAction,
    loadDocumentsTypesError,
    loadDocumentsTypesSuccess,
    LOAD_DOCUMENTS_TYPES,
    LOAD_PARENT_DOCUMENTS_TYPES,
    loadParentDocumentsTypesSuccess,
    loadParentDocumentsTypesError,
    enrollmentCertificateRequestStart,
    enrollmentCertificateRequestSuccess,
    enrollmentCertificateRequestError,
    fetchGymnasiumFromManagerStart,
    fetchGymnasiumFromManagerSuccess,
    fetchGymnasiumFromManagerError,
} from '../actions/documents';
import { FuncEpic } from '../../common/types';
import { AxiosError } from 'axios';
import { ActionType, getType } from 'typesafe-actions';

const documentsEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(LOAD_DOCUMENTS),
        switchMap(() => {
            return from(
                deps.documentsDataProvider.loadDocuments(
                    store$.value?.user?.user?.id,
                    store$.value?.profile?.selectedProfile?.id,
                ),
            ).pipe(
                switchMap((documents) => {
                    return of(loadDocumentsSuccess(documents));
                }),
                catchError((err: AxiosError) => {
                    return of(loadDocumentsError(err));
                }),
            );
        }),
    );
};

const documentsTypesEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(LOAD_DOCUMENTS_TYPES),
        switchMap(() => {
            return from(deps.documentsDataProvider.fetchTypeDocuments(store$.value.profile.selectedProfile?.id)).pipe(
                switchMap((types) => {
                    return of(loadDocumentsTypesSuccess({ typesDocuments: types }));
                }),
                catchError((err: AxiosError) => {
                    return of(loadDocumentsTypesError(err));
                }),
            );
        }),
    );
};

const documentsParentTypesEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(LOAD_PARENT_DOCUMENTS_TYPES),
        switchMap(() => {
            return from(deps.documentsDataProvider.fetchParentTypeDocuments(store$.value.user.user?.id)).pipe(
                switchMap((types) => {
                    return of(loadParentDocumentsTypesSuccess({ typesDocuments: types }));
                }),
                catchError((err: AxiosError) => {
                    return of(loadParentDocumentsTypesError(err));
                }),
            );
        }),
    );
};

const uploadDocumentsEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(UPLOAD_DOCUMENTS),
        switchMap(({ file, upload_doc_type, forParent }: UploadDocumentsAction) => {
            return from(
                deps.documentsDataProvider.uploadDocuments(
                    file,
                    forParent ? undefined : store$.value.profile.selectedProfile?.id,
                    store$.value.user.user?.id,
                    upload_doc_type,
                ),
            ).pipe(
                switchMap(() => {
                    return of(uploadDocumentsSuccess(), loadDocuments());
                }),
                catchError((err: AxiosError) => {
                    return of(uploadDocumentsError(err));
                }),
            );
        }),
    );
};

const deleteDocumentsEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(DELETE_DOCUMENT),
        switchMap(({ id }) => {
            return from(deps.documentsDataProvider.deleteDocument(id)).pipe(
                switchMap(() => {
                    return of(deleteDocumentSuccess(), loadDocuments());
                }),
                catchError((err) => {
                    return of(deleteDocumentError(err));
                }),
            );
        }),
    );
};

const enrollmentCertificateRequestEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(enrollmentCertificateRequestStart)),
        switchMap(({ payload }: ActionType<typeof enrollmentCertificateRequestStart>) => {
            return from(deps.documentsDataProvider.enrollmentCertificateRequest(payload)).pipe(
                switchMap((response: any) => {
                    if (response) {
                        const url = response?.pdf_url;
                        const link = document.createElement('a');
                        link.style.display = 'none';
                        link.href = url;
                        link.download = 'Справка о зачислении';
                        document.body.appendChild(link);
                        link.click();
                    }
                    return of(enrollmentCertificateRequestSuccess(response));
                }),
                catchError((err: AxiosError) => {
                    return of(enrollmentCertificateRequestError(err));
                }),
            );
        }),
    );
};

const fetchGymnasiumFilesEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(fetchGymnasiumFromManagerStart)),
        switchMap(({ payload }: ActionType<typeof fetchGymnasiumFromManagerStart>) => {
            return from(deps.documentsDataProvider.fetchGymnasiumFromManager(payload)).pipe(
                switchMap((response: any) => {
                    return of(fetchGymnasiumFromManagerSuccess(response));
                }),
                catchError((err: AxiosError) => {
                    return of(fetchGymnasiumFromManagerError(err));
                }),
            );
        }),
    );
};

export const documentsEpics = combineEpics(
    documentsEpic,
    uploadDocumentsEpic,
    deleteDocumentsEpic,
    documentsTypesEpic,
    documentsParentTypesEpic,
    enrollmentCertificateRequestEpic,
    fetchGymnasiumFilesEpic,
);
