import { BaseRestDataProvider } from './BaseRestDataProvider';
import axios from 'axios';
import { IUsersListPayload } from '../redux/types/users';

export class UsersDataProvider extends BaseRestDataProvider {
    loadUsersList = (payload: IUsersListPayload) => {
        const search = payload.search ? `?search=${payload.search}` : '';
        return axios
            .get(`${this.host}/api/users_list/${search}`, {
                withCredentials: true,
            })
            .then((res) => res.data);
    };
}
