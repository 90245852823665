import React from 'react';
import './AddButton.scss';

interface IAddButtonProps {
    onAddClick: () => void;
    title: string;
    icon?: string;
}

export const AddButton: React.FC<IAddButtonProps> = ({ onAddClick, title, icon = '+' }) => {
    return (
        <div className="add-button" onClick={onAddClick}>
            <div className="add-button__plus">{icon}</div>
            <span className="add-button__label">{title}</span>
        </div>
    );
};
