import { combineEpics, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { getType } from 'typesafe-actions';
import { FuncEpic } from '../../common/types';
import { showAlert } from '../actions/alert';
import {} from '../../common/constants';
import {
    createAncete,
    createAnceteError,
    createAnceteSuccess,
    loadAncete,
    loadAnceteError,
    loadAnceteSuccess,
    updateAncete,
    updateAnceteError,
    updateAnceteSuccess,
} from '../actions/ancete';

const loadAnceteEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(loadAncete)),
        switchMap(({ payload }) => {
            return from(deps.anceteDataProvider.loadAncete(payload)).pipe(
                switchMap((res) => {
                    return of(loadAnceteSuccess(res));
                }),
                catchError((err) => {
                    return of(loadAnceteError(err));
                }),
            );
        }),
    );
};

const updateAnceteEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(updateAncete)),
        switchMap(({ payload: { data, id } }) => {
            return from(deps.anceteDataProvider.updateAncete(id, data)).pipe(
                switchMap((res) => {
                    return of(
                        updateAnceteSuccess(res),
                        showAlert({
                            title: 'Успех!',
                            text: 'Анкета успешно обновлена',
                            type: 'success',
                        }),
                    );
                }),
                catchError((err) => {
                    return of(updateAnceteError(err));
                }),
            );
        }),
    );
};

const createAnceteEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(createAncete)),
        switchMap(({ payload }) => {
            return from(deps.anceteDataProvider.createAncete(payload)).pipe(
                switchMap((res) => {
                    return of(
                        createAnceteSuccess(res),
                        showAlert({
                            title: 'Успех!',
                            text: 'Анкета успешно создана',
                            type: 'success',
                        }),
                    );
                }),
                catchError((err) => {
                    return of(createAnceteError(err));
                }),
            );
        }),
    );
};

export const anceteEpics = combineEpics(loadAnceteEpic, updateAnceteEpic, createAnceteEpic);
