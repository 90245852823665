import {
    changePassword,
    changePasswordError,
    changePasswordSuccess,
    sendResetEmail,
    sendResetEmailError,
    sendResetEmailSuccess,
} from 'redux/actions/resetPassword';
import { ResetPasswordState } from 'redux/types/resetPassword';
import { getType } from 'typesafe-actions';
import { PageStatus } from '../../common/types';
import { Action } from '../store';

const defaultResetPasswordState: ResetPasswordState = {
    sendResetEmailStatus: PageStatus.LOADING,
    sendResetEmailResponse: {},
    sendResetEmailError: {},
    changePasswordStatus: PageStatus.LOADING,
    changePasswordResponse: {},
    changePasswordError: {},
};

export const resetPassword = (state: ResetPasswordState = defaultResetPasswordState, action: Action) => {
    switch (action.type) {
        case getType(sendResetEmail):
            return {
                ...state,
                sendResetEmailStatus: PageStatus.LOADING,
                sendResetEmailResponse: {},
                sendResetEmailError: {},
            };
        case getType(sendResetEmailSuccess):
            return {
                ...state,
                sendResetEmailResponse: action.payload,
                sendResetEmailStatus: PageStatus.LOADED,
            };
        case getType(sendResetEmailError):
            return {
                ...state,
                sendResetEmailError: action.payload,
                sendResetEmailStatus: PageStatus.ERROR,
            };
        case getType(changePassword):
            return {
                ...state,
                changePasswordStatus: PageStatus.LOADING,
                changePasswordResponse: {},
                changePasswordError: {},
            };
        case getType(changePasswordSuccess):
            return {
                ...state,
                changePasswordResponse: action.payload,
                changePasswordStatus: PageStatus.LOADED,
            };
        case getType(changePasswordError):
            return {
                ...state,
                changePasswordError: action.payload,
                changePasswordStatus: PageStatus.ERROR,
            };
        default: {
            return state;
        }
    }
};
