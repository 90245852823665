import moment from 'moment';

export const getDescriptionsArray = (info) => {
    return info.map((value) => {
        if (value.contract) {
            return `${value.description + '\n'} за период ${moment(value.start).format('DD MMMM')}-${moment(
                value.end,
            ).format('DD MMMM')} 一 ${value.already_paid} руб.`;
        }
        return `${value.description + '\n'} 一 ${value.already_paid} руб.`;
    });
};

export const getSumOfPayment = (info) => {
    return info.reduce((sum: number, current) => (sum += Number(current.already_paid)), 0);
};
