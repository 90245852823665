import React, { useEffect, useMemo } from 'react';
import { RegFooter, RegHeader } from '../../components';
import { ApplicationForm } from './ApplicationForm';
import './ApplicationEnrollment.scss';
import { mocks } from './mocks';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../redux/store';
import { postApplicationFormStart } from '../../redux/actions/applicationEnrollment';
import { useHistory } from 'react-router-dom';
import { getDefaultPhone } from './ApplicationForm/utils';
import { loadProfile } from '../../redux/actions/profile';
import { FetchStatus, PageStatus } from '../../common/types';
import { loadUser } from '../../redux/actions/user';
import { loadConstantsStart } from '../../redux/actions/constants';

export const ApplicationEnrollment: React.FC = () => {
    const studentsState = useAppSelector((state) => state.profile);
    const userState = useAppSelector((state) => state.user);
    const constants = useAppSelector((state) => state.constants);
    const dispatch = useDispatch();
    const history = useHistory();

    const isDataLoaded = useMemo(
        () =>
            studentsState.pageStatus === PageStatus.LOADED &&
            userState.pageStatus === PageStatus.LOADED &&
            constants.fetchStatus === FetchStatus.FETCHED,
        [studentsState.pageStatus, userState.pageStatus, constants.fetchStatus],
    );

    const handleSubmitForm = (value) => {
        dispatch(postApplicationFormStart(value));
        history.push('/');
    };

    useEffect(() => {
        dispatch(loadProfile(studentsState.pageStatus));
        if (userState.pageStatus !== PageStatus.LOADED) dispatch(loadUser());
        if (constants.fetchStatus !== FetchStatus.FETCHED) dispatch(loadConstantsStart());
    }, []);

    return (
        isDataLoaded && (
            <div className="registration custom-scroll">
                <div id="wrapper">
                    <RegHeader handleLinkAdress="/new/auth" />
                    <main className="content">
                        <ApplicationForm
                            children={studentsState.selectedProfile}
                            parent={{
                                lastName: userState.user.last_name,
                                firstName: userState.user.first_name,
                                patronymic: userState.user.patronymic,
                            }}
                            parentGender={userState.user.gender}
                            parentPhone={getDefaultPhone(userState.user.phone_numbers).phoneNumber}
                            parentCountryCode={getDefaultPhone(userState.user.phone_numbers).countryCode}
                            foreignLanguages={mocks.foreignLanguages}
                            parentEmail={userState.user.email}
                            ruleUrl={mocks.ruleUrl}
                            timezones={mocks.timezones}
                            handleSubmitForm={handleSubmitForm}
                            constants={constants.constants}
                        />
                    </main>
                    <RegFooter />
                </div>
            </div>
        )
    );
};
