import React, { useMemo } from 'react';
import { Col } from 'react-bootstrap';
import './PaymentResult.scss';
import { IPaymentProps } from './types';
import { connect } from 'react-redux';
import { State } from '../../../../redux/store';
import { loadPaymentInfo } from '../../../../redux/actions/paymentInfo';
import { PageStatus } from '../../../../common/types';
import Skeleton from 'react-loading-skeleton';
import { getDescriptionsArray, getSumOfPayment } from './utils';

export const PaymentResultSuccess: React.FC<IPaymentProps> = ({ pageStatus, info, loadPaymentInfo, location }) => {
    React.useEffect(() => {
        let orderId = location.query?.orderId;
        loadPaymentInfo(orderId);
    }, [loadPaymentInfo, location]);

    const paymentSum = useMemo(() => (info.length && getSumOfPayment(info)) || 0, [info]);

    const orderNumber = useMemo(() => (info.length && info[0].order_id) || undefined, [info]);

    const descriptionsArray = useMemo(() => (info.length && getDescriptionsArray(info)) || undefined, [info]);

    return (
        <Col className="px-0 position-relative">
            <div className="cabinet-content custom-scroll">
                <div className="cabinet-content__wrapper">
                    <div className="row">
                        <div className="col-12 col-xxl mb-5">
                            <div className="row mb-3">
                                <div className="col-12 cabinet-content__header">
                                    {/* <h4 className="cabinet-content__title">Оплата</h4> */}
                                </div>
                                <div className="col">
                                    {pageStatus === PageStatus.LOADED ? (
                                        <>
                                            {orderNumber ? (
                                                <div className="cabinet-content__text">
                                                    <p>
                                                        Оплата на сумму{' '}
                                                        <span className="cabinet-content__text--bold">
                                                            {paymentSum} руб.
                                                        </span>
                                                    </p>
                                                    <ul className="result-table">
                                                        {descriptionsArray?.map((value, index) => (
                                                            <li key={`Desc ${index}`}>{value}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            ) : null}
                                        </>
                                    ) : pageStatus === PageStatus.LOADING ? (
                                        <Skeleton height={30} width={200} />
                                    ) : null}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="text-result text-success">
                                        <p>
                                            <i className="icon-checkmark text-result__icon" /> Прошла успешно
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Col>
    );
};

export default connect(
    ({ paymentInfo, router }: State) => ({ ...paymentInfo, location: router.location }),
    (dispatch) => {
        return {
            loadPaymentInfo: (order: string) => {
                return dispatch(loadPaymentInfo(order));
            },
        };
    },
)(PaymentResultSuccess);
