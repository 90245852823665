import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './TimePicker.scss';
import { getTimeText } from './utils';
import {timeComparison} from "../../../helpers/courses";

export interface IDay {
    date: number,
    month: number,
}

export interface ITime {
    hours: number,
    minutes: number,
}

export interface ITimePickerItem {
    date: string;
    time: ITime;
}

interface ITimePickerProps {
    date: string,
    time: ITime,
    handleTimeClick?: (value: ITimePickerItem) => void;
    isSelectedInit?: boolean;
    isDisabled?: boolean;
    selectedConsultationLesson?: ITimePickerItem;
}

export const TimePicker: React.FC<ITimePickerProps> = ({
    date,
    time,
    isDisabled = false,
    handleTimeClick,
    isSelectedInit = false,
    selectedConsultationLesson
}) => {
    const [isSelected, setIsSelected] = useState(isSelectedInit);
    const isSelectedConsultationLesson = useMemo(() => {
        if (selectedConsultationLesson)
            return timeComparison(selectedConsultationLesson, {
                date: date,
                time: {
                    minutes: time.minutes,
                    hours: time.hours
                }
            });
        return false;
        }, [selectedConsultationLesson]);

    useEffect(() => {
        if (isSelectedConsultationLesson !== undefined && !isSelected)
            setIsSelected(isSelectedConsultationLesson);
    }, [isSelectedConsultationLesson, selectedConsultationLesson])

    useEffect(() => {
        if (isDisabled)
            setIsSelected(false);
    }, [isDisabled])

    const handleClick = useCallback(() => {
        if (!isDisabled) {
            if (handleTimeClick) {
                handleTimeClick({
                    date: date,
                    time: time,
                });
            }
            setIsSelected(state => !state);
        }
    }, [handleTimeClick, isDisabled])

    return (
        <div
            className={`time-picker${isSelected ? ' time-picker__selected' : ''}${isDisabled ? ' time-picker__disabled' : ''}`}
            onClick={handleClick}
        >
            {getTimeText(time)}
        </div>
    )
}