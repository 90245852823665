import { Input, TrialInputs } from './TrialSignUp.types';

const inputAdditionalInfoAssociations: Record<
    keyof TrialInputs,
    { placeholder: string; label: string; type: string }
> = {
    first_name: {
        placeholder: 'Введите имя',
        label: 'Имя',
        type: 'text',
    },
    last_name: {
        placeholder: 'Введите фамилию',
        label: 'Фамилия',
        type: 'text',
    },
    patronymic: {
        placeholder: 'Введите отчество',
        label: 'Отчество',
        type: 'text',
    },
    phone_number: {
        placeholder: 'Введите номер',
        label: 'Номер телефона',
        type: 'tel',
    },
    email: {
        placeholder: 'Введите e-mail',
        label: 'Адрес почты',
        type: 'email',
    },
    grade: {
        placeholder: 'Выберите параллель - класс',
        label: 'Класс',
        type: 'dropdown',
    },
};

export const convertInputsValuesToInputsData = (inputs: TrialInputs): Input[] =>
    Object.entries(inputs).map(([inputKey, inputValue]: [keyof TrialInputs, string]) => ({
        ...inputAdditionalInfoAssociations[inputKey],
        value: inputValue,
        key: inputKey,
    }));
