import { AxiosError } from 'axios';
import { IPenDocument } from 'redux/types/penDocument';
import { ActionType, createAction } from 'typesafe-actions';

export const getPenDocumentsAction = createAction('penDocuments/getPenDocumentsStart')<{user: number; student: number}>();
export const getPenDocumentsActionSuccess = createAction('penDocuments/getPenDocumentsSuccess')<IPenDocument[]>();
export const getPenDocumentsActionError = createAction('penDocuments/getPenDocumentsError')<AxiosError>();

export const postChangePenDocumentsAction = createAction('penDocuments/postChangePenDocumentsStart')<number>();
export const postChangePenDocumentsActionSuccess = createAction('penDocuments/postChangePenDocumentsSuccess')<number>();
export const postChangePenDocumentsActionError = createAction('penDocuments/postChangePenDocumentsError')<AxiosError>();

export const postRefusePenDocumentsAction = createAction('penDocuments/postRefusePenDocumentsStart')<number>();
export const postRefusePenDocumentsActionSuccess = createAction('penDocuments/postRefusePenDocumentsSuccess')<number>();
export const postRefusePenDocumentsActionError = createAction('penDocuments/postRefusePenDocumentsError')<AxiosError>();

export type PenDocumentsActions =
    | ActionType<typeof getPenDocumentsAction>
    | ActionType<typeof getPenDocumentsActionSuccess>
    | ActionType<typeof getPenDocumentsActionError>

    | ActionType<typeof postChangePenDocumentsAction>
    | ActionType<typeof postChangePenDocumentsActionSuccess>
    | ActionType<typeof postChangePenDocumentsActionError>
    
    | ActionType<typeof postRefusePenDocumentsAction>
    | ActionType<typeof postRefusePenDocumentsActionSuccess>
    | ActionType<typeof postRefusePenDocumentsActionError>; 
