import { ActionType, createAction } from 'typesafe-actions';

import {
    CreateMeetingPayload,
    IPostInitiatedMeetingPayload,
    IScheduleById,
    MeetingRequest,
    TeacherMeetingSlot,
} from '../types/meetings';

export const loadTeachers = createAction('@teachers/LOAD/START')();
export const loadTeachersSuccess = createAction('@teachers/LOAD/SUCCESS')<any>();
export const loadTeachersError = createAction('@teachers/LOAD/ERROR')<any>();

export const loadScheduleByIdStart = createAction('@initiatedMeeting/schedule/LOAD/START')<string>();
export const loadScheduleByIdSuccess = createAction('@initiatedMeeting/schedule/LOAD/SUCCESS')<IScheduleById>();
export const loadScheduleByIdError = createAction('@initiatedMeeting/schedule/LOAD/ERROR')<any>();

export const postInitiatedMeetingStart = createAction('@initiatedMeeting/POST/START')<IPostInitiatedMeetingPayload>();
export const postInitiatedMeetingSuccess = createAction('@initiatedMeeting/POST/SUCCESS')<any>();
export const postInitiatedMeetingError = createAction('@initiatedMeeting/POST/ERROR')<any>();

export const createMeeting = createAction('@meeting/CREATE/START')<CreateMeetingPayload>();
export const createMeetingSuccess = createAction('@meeting/CREATE/SUCCESS')<any>();
export const createMeetingError = createAction('@meeting/CREATE/ERROR')<any>();

export const changeMeeting = createAction('@meeting/CHANGE/START')<any>();
export const changeMeetingSuccess = createAction('@meeting/CHANGE/SUCCESS')<any>();
export const changeMeetingError = createAction('@meeting/CHANGE/ERROR')<any>();

export const deleteMeeting = createAction('@meeting/DELETE/START')<any>();
export const deleteMeetingSuccess = createAction('@meeting/DELETE/SUCCESS')<any>();
export const deleteMeetingError = createAction('@meeting/DELETE/ERROR')<any>();

export const loadTeacherSchedule = createAction('@teachers/LOAD/SCHEDULE/START')<any>();
export const loadTeacherScheduleSuccess = createAction('@teachers/LOAD/SCHEDULE/SUCCESS')<TeacherMeetingSlot[]>();
export const loadTeacherScheduleError = createAction('@teachers/LOAD/SCHEDULE/ERROR')<any>();

export const loadMeetingsRequests = createAction('@meeting/LOAD_MEETINGS_REQUESTS/START')();
export const loadMeetingsRequestsSuccess = createAction('@meeting/LOAD_MEETINGS_REQUESTS/SUCCESS')<MeetingRequest>();
export const loadMeetingsRequestsError = createAction('@meeting/LOAD_MEETINGS_REQUESTS/ERROR')<any>();

export type MeetingsActions =
    | ActionType<typeof loadTeachers>
    | ActionType<typeof loadTeachersSuccess>
    | ActionType<typeof loadTeachersError>
    | ActionType<typeof createMeeting>
    | ActionType<typeof createMeetingSuccess>
    | ActionType<typeof createMeetingError>
    | ActionType<typeof changeMeeting>
    | ActionType<typeof changeMeetingError>
    | ActionType<typeof changeMeetingSuccess>
    | ActionType<typeof deleteMeeting>
    | ActionType<typeof deleteMeetingError>
    | ActionType<typeof deleteMeetingSuccess>
    | ActionType<typeof loadTeacherSchedule>
    | ActionType<typeof loadTeacherScheduleError>
    | ActionType<typeof loadTeacherScheduleSuccess>
    | ActionType<typeof loadScheduleByIdStart>
    | ActionType<typeof loadScheduleByIdSuccess>
    | ActionType<typeof loadScheduleByIdError>
    | ActionType<typeof postInitiatedMeetingStart>
    | ActionType<typeof postInitiatedMeetingSuccess>
    | ActionType<typeof postInitiatedMeetingError>
    | ActionType<typeof loadMeetingsRequests>
    | ActionType<typeof loadMeetingsRequestsSuccess>
    | ActionType<typeof loadMeetingsRequestsError>;
