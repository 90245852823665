import React from 'react';
import classNames from 'classnames';
import moment from 'moment';

import ScheduleCard from 'components/General/ScheduleCard';

import './ScheduleItem.scss'

const ScheduleItem: React.FC<{
    time: number;
    subject: string;
    scheduleDate: string;
    description: string;
}> = ({ time, subject, scheduleDate, description }) => {
    const isNow = (timestamp: number) => {
        const now = (new Date()).getTime();
        const end = (new Date(time)).getTime() + 45 * 60000;
        return now > timestamp && now > end;
    };

    return (
        <div className="dashboard__item dashboard__schedule-item">
            <div className={classNames('card', 'schedule-card', {'schedule-card--current': isNow(time)})}>
                <ScheduleCard className={isNow(time) ? "shape-blue" : "shape-green"}>{isNow(time) ? "Текущий" : "Следующий"}</ScheduleCard>
                <div className="app__card-body d-inline-flex flex-row">
                    <div className="schedule-card__time"><span>{moment(time).format("HH:mm")}</span></div>
                    <div className="pt-3">
                        <h5 className="card-title schedule-card__title">{subject}</h5>
                        <p className="card-text schedule-card__text">{description}</p>
                        <footer className="card-footer">{moment(scheduleDate).format("DD MMMM HH:mm")}</footer>
                    </div>
                </div>
            </div>
        </div> 
    );
}

export default ScheduleItem