import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { connect, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';

import { FetchStatus, PageStatus } from '../../common/types';
import { MeetingsDateRadio, MeetingsTimeRadio } from '../../components';
import HeaderMeeting from '../../components/Header/HeaderMeeting/HeaderMeeting';
import { TextArea } from '../../components/TextArea';
import { createMeeting, loadTeachers, loadTeacherSchedule } from '../../redux/actions/meetings';
import { loadSelectedProfile, selectProfile } from '../../redux/actions/profile';
import { State, useAppSelector } from '../../redux/store';
import { MeetingsState } from '../../redux/types/meetings';

import { MeetingTeacher } from './MeetingTeacher/MeetingTeacher';

import './Meeting.scss';

interface MeetingProps extends MeetingsState {
    loadTeachers: () => {};
    loadTeacherSchedule: (teacherExternalId: string) => {};
    selectedProfilePageStatus: PageStatus;
}

const DEFAULT_MEETING_DURATION = 20;

export const Meeting: React.FC<MeetingProps> = ({
    loadTeachers,
    loadTeacherSchedule,
    createStatus,
    created,
    pageStatus,
    selectedProfilePageStatus,
}) => {
    const [selectedDate, setSelectedDate] = useState<any>(null);
    const [selectedTime, setSelectedTime] = useState<any>(null);
    const [meetingTheme, setMeetingTheme] = useState('');
    const [meetingThemeError, setMeetingThemeError] = useState(false);
    const dispatch = useDispatch();
    const { profile } = useAppSelector((store) => store.selectedProfile);
    const { user: students, pageStatus: studentsFetchStatus } = useAppSelector((store) => store.profile);
    const { meetingSlots } = useAppSelector((store) => store.meetings);

    const { teachers: lmsTeachers, fetchStatus: lmsTeacherFetchStatus } = useAppSelector((store) => store.courseGroups);
    const [selectedLmsTeacher, setSelectedLmsTeacher] = useState(null);
    const [isYetConsultationError, setYetConsultationError] = useState(false);

    const search = useLocation()?.search;
    const requestId = new URLSearchParams(search)?.get('requestID');
    const { meetingsRequests } = useAppSelector((store) => store.meetings);

    useEffect(() => {
        setYetConsultationError(createStatus === 'ERROR');
    }, [createStatus]);

    useEffect(() => {
        setYetConsultationError(false);
    }, [selectedLmsTeacher]);

    const selectedRequest = useMemo(
        () => (requestId ? meetingsRequests?.find((req) => req?.uuid === requestId) : null),
        [requestId, meetingsRequests],
    );

    const teachersOptions = useMemo(() => {
        if (requestId && meetingsRequests?.length && selectedRequest) {
            const requestTeacher = lmsTeachers?.find(
                (teacher) => teacher.externalId === selectedRequest.teacher_external_id,
            );
            return [
                {
                    id: requestTeacher?.externalId,
                    name: `${requestTeacher?.lastName} ${requestTeacher?.firstName} ${requestTeacher?.patronymic}`,
                    subject: requestTeacher?.courseTitle,
                },
            ];
        } else
            return lmsTeachers?.length
                ? lmsTeachers.map((teacher) => ({
                      id: teacher.externalId,
                      name: `${teacher.lastName} ${teacher.firstName} ${teacher.patronymic}`,
                      subject: teacher.courseTitle,
                  }))
                : [];
    }, [lmsTeachers, selectedRequest, requestId, meetingsRequests]);

    useEffect(() => {
        if (teachersOptions?.length === 1) setSelectedLmsTeacher(teachersOptions[0]);
    }, [teachersOptions]);

    useEffect(() => {
        loadTeachers();
    }, [loadTeachers]);

    const meetingThemeChangeCallback = useCallback(
        (e: any) => {
            if (e.target.value.length <= 100) {
                setMeetingThemeError(false);
                setMeetingTheme(e.target.value);
            } else {
                setMeetingThemeError(true);
            }
        },
        [meetingTheme, meetingThemeError],
    );

    const teacherChangeCallback = useCallback(
        (value: any) => {
            loadTeacherSchedule(value.id);
            setSelectedLmsTeacher(value);
            setSelectedDate(null);
            setSelectedTime(null);
        },
        [loadTeacherSchedule],
    );

    const dateChangeCallback = useCallback((value: any) => {
        setSelectedDate(value);
        setSelectedTime(null);
    }, []);

    const timeChangeCallback = useCallback((value: string) => {
        setSelectedTime(value);
    }, []);

    const createMeetingCallback = useCallback(() => {
        dispatch(
            createMeeting({
                startTime: moment(`${selectedDate?.date} ${selectedTime?.start_time}`).tz('Europe/Moscow').format(),
                teacherExternalId: selectedLmsTeacher?.id,
                subject: meetingTheme,
                duration:
                    moment(`${selectedDate?.date} ${selectedTime?.end_time}`).diff(
                        moment(`${selectedDate?.date} ${selectedTime?.start_time}`),
                        'minutes',
                    ) || DEFAULT_MEETING_DURATION,
                studentId: profile?.id,
                requestId: selectedRequest ? selectedRequest?.id : undefined,
            }),
        );
    }, [
        dispatch,
        selectedDate?.date,
        selectedTime?.start_time,
        selectedTime?.end_time,
        selectedLmsTeacher?.id,
        meetingTheme,
        profile?.id,
        selectedRequest,
    ]);

    useEffect(() => {
        if (selectedRequest?.student && studentsFetchStatus === PageStatus.LOADED) {
            const selectedStudent = students?.find((student) => student?.id === selectedRequest?.student);
            if (selectedStudent) {
                dispatch(selectProfile(selectedStudent));
                dispatch(loadSelectedProfile(PageStatus.LOADED, selectedStudent));
            }
        }
    }, [selectedRequest, studentsFetchStatus]);

    return (
        <>
            <Col style={{ width: '30%' }} className="p-2 position-relative meeting">
                <div className="cabinet-content custom-scroll">
                    <div className="cabinet-content__wrapper">
                        <HeaderMeeting />
                        <div className="row">
                            <div className="col-12 col-xxl mb-5">
                                <div className="row mb-3">
                                    <div className="col-12 cabinet-content__header">
                                        <h4 className="cabinet-content__title">Запись на консультацию</h4>
                                    </div>
                                    <div className="col">
                                        <div className="cabinet-content__text">
                                            <p>
                                                <span className="text-bold">
                                                    Добрый день! Сейчас вы находитесь на странице записи на
                                                    консультацию.
                                                </span>
                                            </p>
                                            <p>
                                                Для того чтобы назначить онлайн-консультацию с преподавателем,{' '}
                                                <span className="text-bold text-primary">выберите преподавателя,</span>{' '}
                                                который обучает вашего ребёнка, далее{' '}
                                                <span className="text-bold text-primary">выберите удобное время</span> и
                                                нажмите записаться. Время указано по часовому поясу Москвы.
                                            </p>
                                            <p>
                                                На главной странице у вас появится напоминание, а также ссылка на
                                                консультацию за 15 минут до начала.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        {pageStatus === PageStatus.LOADED &&
                                        selectedProfilePageStatus === PageStatus.LOADED &&
                                        profile &&
                                        profile?.lms_id ? (
                                            <>
                                                <div className="meeting__form">
                                                    {lmsTeacherFetchStatus === FetchStatus.FETCHED ? (
                                                        lmsTeachers?.length ? (
                                                            <MeetingTeacher
                                                                onChange={teacherChangeCallback}
                                                                content={teachersOptions}
                                                                value={selectedLmsTeacher}
                                                            />
                                                        ) : (
                                                            <span className="text-danger f-12 text-danger_no_teacher">
                                                                Преподаватели отсутствуют
                                                            </span>
                                                        )
                                                    ) : (
                                                        <div className={'meeting__form-skeleton'}>
                                                            <Skeleton width={200} height={100} />
                                                        </div>
                                                    )}
                                                    {!!meetingSlots?.length && !!selectedLmsTeacher && (
                                                        <div>
                                                            <MeetingsDateRadio
                                                                dateSlots={meetingSlots}
                                                                radioName={'meeting-date'}
                                                                title="Выберите дату:"
                                                                onChange={dateChangeCallback}
                                                                value={selectedDate}
                                                            />
                                                        </div>
                                                    )}
                                                    {!!selectedLmsTeacher && !!selectedDate && (
                                                        <div>
                                                            <MeetingsTimeRadio
                                                                radioName={'meeting-time'}
                                                                title="Выберите время:"
                                                                selectedDate={selectedDate?.date}
                                                                onChange={timeChangeCallback}
                                                                timeSlots={selectedDate?.time}
                                                                value={selectedTime}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                                <TextArea
                                                    value={meetingTheme}
                                                    onChange={meetingThemeChangeCallback}
                                                    id="meetingTheme"
                                                    wrapperClassName={'meeting__input'}
                                                    label="Тема собрания"
                                                    className={meetingThemeError ? 'meeting__input-error' : ''}
                                                    placeholder="Введите тему собрания"
                                                />
                                            </>
                                        ) : (
                                            <div className="meeting__form">
                                                <div>
                                                    {!profile?.lms_id && (
                                                        <p className="text-danger f-12">Ученик отсутствует на LMS.</p>
                                                    )}
                                                    <div className={'meeting__form-skeleton'}>
                                                        <Skeleton width={200} height={100} />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <button
                                            disabled={
                                                !(
                                                    selectedTime &&
                                                    selectedDate &&
                                                    meetingTheme &&
                                                    selectedLmsTeacher &&
                                                    !meetingThemeError
                                                ) || createStatus === 'FETCHING'
                                            }
                                            onClick={createMeetingCallback}
                                            className="btn btn-primary meeting__form-btn"
                                        >
                                            Записаться
                                        </button>
                                        {created && (
                                            <span className="text-success meeting__form-success-text">
                                                Запись сохранена!
                                            </span>
                                        )}
                                        {isYetConsultationError && (
                                            <span className="text-danger f-12">
                                                Вы уже создали консультацию с данным учителем.
                                            </span>
                                        )}
                                        {meetingThemeError ? (
                                            <span className="text-danger f-12">
                                                Максимальная длина сообщения не может превышать 100 символов
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </>
    );
};

export default connect(
    ({ meetings, selectedProfile }: State) => ({ ...meetings, selectedProfilePageStatus: selectedProfile.pageStatus }),
    (dispatch) => {
        return {
            loadTeachers: () => {
                return dispatch(loadTeachers());
            },
            loadTeacherSchedule: (teacherExternalId: string) => {
                return dispatch(loadTeacherSchedule(teacherExternalId));
            },
        };
    },
)(Meeting);
