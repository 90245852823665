import React, { useCallback, useEffect, useState } from 'react';

import { CoursesEnrollment } from './CoursesEnrollment';
import { Tabs } from '../../components/Tabs';
import './Courses.scss';
import { CoursesAttended } from './CoursesAttended';
import {
    loadAdditionalCoursesStart,
    loadIndividualCoursesStart,
    loadStudentCoursesStart,
} from '../../redux/actions/courses';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../redux/store';
import { Switch, useHistory } from 'react-router-dom';
import { PrivateRoute } from '../../components/PrivateRoute';

const options = [
    {
        title: 'Записаться',
        component: <CoursesEnrollment />,
        id: 1,
        path: '/courses',
    },
    {
        title: 'Посещаемые курсы',
        component: <CoursesAttended />,
        id: 2,
        path: '/courses/attended',
    },
];

export const Courses: React.FC = () => {
    const [selectedOption, setSelectedOption] = useState(1);
    const dispatch = useDispatch();
    const studentsState = useSelector((state: State) => state.profile);
    const history = useHistory();

    const changeSelectedOptionCallback = useCallback(
        (value: number) => {
            setSelectedOption(value);
            history.push(options[value - 1].path);
        },
        [setSelectedOption],
    );

    useEffect(() => {
        switch (history.location.pathname) {
            case '/courses':
                setSelectedOption(1);
                break;
            case '/courses/attended':
                setSelectedOption(2);
                break;
            default:
                break;
        }
    }, [history.location.pathname]);

    useEffect(() => {
        const payload = {
            student_id: studentsState.selectedProfile?.id,
        };
        dispatch(loadStudentCoursesStart(payload));
        dispatch(loadAdditionalCoursesStart(payload));
        dispatch(loadIndividualCoursesStart(payload));
    }, [studentsState.selectedProfile?.id]);

    return (
        <div className="cabinet-content custom-scroll position-relative col">
            <div className="courses-options">
                <Tabs
                    changeSelectedElementHandler={changeSelectedOptionCallback}
                    selectedElement={selectedOption}
                    elements={options}
                />
                <Switch>
                    <PrivateRoute path="/courses" component={CoursesEnrollment} exact />
                    <PrivateRoute path="/courses/attended" component={CoursesAttended} exact />
                </Switch>
            </div>
        </div>
    );
};
