import { FetchStatus } from '../../common/types';
import { Action } from '../store';
import { getType } from 'typesafe-actions';
import { ContractsState } from 'redux/types/contracts';
import {
    getContractsError,
    getContractsStart,
    getContractsSuccess,
    getSchoolInfoError,
    getSchoolInfoStart,
    getSchoolInfoSuccess,
    patchContractMethodSignError,
    patchContractMethodSignStart,
    patchContractMethodSignSuccess,
    patchContractReadyToSignError,
    patchContractReadyToSignStart,
    patchContractReadyToSignSuccess,
    patchContractSeenError,
    patchContractSeenStart,
    patchContractSeenSuccess,
    postContractCourierAdressDataError,
    postContractCourierAdressDataStart,
    postContractCourierAdressDataSuccess,
    postContractHaveQuestionsError,
    postContractHaveQuestionsStart,
    postContractHaveQuestionsSuccess,
    postCourierPaidError,
    postCourierPaidStart,
    postCourierPaidSuccess,
    postMissingKonturUrlError,
    postMissingKonturUrlStart,
    postMissingKonturUrlSuccess,
    postRefuseToSignError,
    postRefuseToSignStart,
    postRefuseToSignSuccess,
    resetDataForTestError,
    resetDataForTestStart,
    resetDataForTestSuccess,
} from 'redux/actions/contracts';

const contractsInitialState: ContractsState = {
    getStatusContracts: FetchStatus.FETCHING,
    getStatusSchoolInfo: FetchStatus.FETCHING,
    patchStatusContractSeen: FetchStatus.FETCHING,
    postStatusRefuseToSign: FetchStatus.FETCHING,
    patchStatusContractReadyToSign: FetchStatus.FETCHING,
    postStatusMissingKonturUrl: FetchStatus.FETCHING,
    postStatusHaveQuestions: FetchStatus.FETCHING,
    postStatusCourierPaid: FetchStatus.FETCHING,
    patchStatusContractMethodSign: FetchStatus.FETCHING,
    postStatusAdress: FetchStatus.FETCHING,
    contracts: {
        results: [],
    },
    error: null,
};

export const contractsReducer = (state: ContractsState = contractsInitialState, action: Action) => {
    switch (action.type) {
        case getType(getContractsStart): {
            return {
                ...state,
                getStatusContracts: FetchStatus.FETCHING,
            };
        }
        case getType(getContractsSuccess): {
            return {
                ...state,
                getStatusContracts: FetchStatus.FETCHED,
                error: null,
                contracts: action.payload,
            };
        }
        case getType(getContractsError): {
            return {
                ...state,
                getStatusContracts: FetchStatus.ERROR,
                error: action.payload,
            };
        }

        case getType(patchContractSeenStart): {
            return {
                ...state,
                patchStatusContractSeen: FetchStatus.FETCHING,
            };
        }
        case getType(patchContractSeenSuccess): {
            return {
                ...state,
                patchStatusContractSeen: FetchStatus.FETCHED,
                contracts: {
                    results: state.contracts.results.map((contract) =>
                        contract.id === action.payload.id
                            ? { ...contract, parent_seen: action.payload.parent_seen }
                            : contract,
                    ),
                },
                error: null,
            };
        }
        case getType(patchContractSeenError): {
            return {
                ...state,
                patchStatusContractSeen: FetchStatus.ERROR,
                error: action.payload,
            };
        }

        case getType(postRefuseToSignStart): {
            return {
                ...state,
                postStatusRefuseToSign: FetchStatus.FETCHING,
            };
        }
        case getType(postRefuseToSignSuccess): {
            return {
                ...state,
                postStatusRefuseToSign: FetchStatus.FETCHED,
                error: null,
            };
        }
        case getType(postRefuseToSignError): {
            return {
                ...state,
                postStatusRefuseToSign: FetchStatus.ERROR,
                error: action.payload,
            };
        }

        case getType(patchContractReadyToSignStart): {
            return {
                ...state,
                patchStatusContractReadyToSign: FetchStatus.FETCHING,
            };
        }
        case getType(patchContractReadyToSignSuccess): {
            return {
                ...state,
                patchStatusContractReadyToSign: FetchStatus.FETCHED,
                contracts: {
                    results: state.contracts.results.map((contract) =>
                        contract.id === action.payload.id
                            ? {
                                  ...contract,
                                  ready_to_sign: action.payload.ready_to_sign,
                                  contract_ready: action.payload.contract_ready,
                                  parent_seen: action.payload.parent_seen,
                              }
                            : contract,
                    ),
                },
                error: null,
            };
        }
        case getType(patchContractReadyToSignError): {
            return {
                ...state,
                patchStatusContractReadyToSign: FetchStatus.ERROR,
                error: action.payload,
            };
        }

        case getType(postContractHaveQuestionsStart): {
            return {
                ...state,
                postStatusHaveQuestions: FetchStatus.FETCHING,
            };
        }
        case getType(postContractHaveQuestionsSuccess): {
            return {
                ...state,
                postStatusHaveQuestions: FetchStatus.FETCHED,
                error: null,
            };
        }
        case getType(postContractHaveQuestionsError): {
            return {
                ...state,
                postStatusHaveQuestions: FetchStatus.ERROR,
                error: action.payload,
            };
        }

        case getType(postMissingKonturUrlStart): {
            return {
                ...state,
                postStatusMissingKonturUrl: FetchStatus.FETCHING,
            };
        }
        case getType(postMissingKonturUrlSuccess): {
            return {
                ...state,
                postStatusMissingKonturUrl: FetchStatus.FETCHED,
                error: null,
            };
        }
        case getType(postMissingKonturUrlError): {
            return {
                ...state,
                postStatusMissingKonturUrl: FetchStatus.ERROR,
                error: action.payload,
            };
        }

        case getType(patchContractMethodSignStart): {
            return {
                ...state,
                patchStatusContractMethodSign: FetchStatus.FETCHING,
            };
        }
        case getType(patchContractMethodSignSuccess): {
            return {
                ...state,
                patchStatusContractMethodSign: FetchStatus.FETCHED,
                contracts: {
                    results: state.contracts.results.map((contract) =>
                        contract.id === action.payload.id
                            ? {
                                  ...contract,
                                  want_to_sign_by_courier: action.payload.want_to_sign_by_courier,
                                  want_to_sign_by_edc: action.payload.want_to_sign_by_edc,
                              }
                            : contract,
                    ),
                },
                error: null,
            };
        }
        case getType(patchContractMethodSignError): {
            return {
                ...state,
                patchStatusContractMethodSign: FetchStatus.ERROR,
                error: action.payload,
            };
        }

        case getType(getSchoolInfoStart): {
            return {
                ...state,
                getStatusSchoolInfo: FetchStatus.FETCHING,
            };
        }
        case getType(getSchoolInfoSuccess): {
            return {
                ...state,
                getStatusSchoolInfo: FetchStatus.FETCHED,
                error: null,
                contracts: {
                    results: state.contracts.results?.map((contract, index) => ({
                        ...contract,
                        school_flag_edc: !!action.payload.length && action.payload[index]?.school?.signable_with_edc,
                        fetchedSchoolData: true,
                    })),
                },
            };
        }
        case getType(getSchoolInfoError): {
            return {
                ...state,
                getStatusSchoolInfo: FetchStatus.ERROR,
                error: action.payload,
            };
        }

        case getType(postContractCourierAdressDataStart): {
            return {
                ...state,
                postStatusAdress: FetchStatus.FETCHING,
            };
        }
        case getType(postContractCourierAdressDataSuccess): {
            return {
                ...state,
                postStatusAdress: FetchStatus.FETCHED,
                contracts: {
                    results: state.contracts.results.map((contract) =>
                        contract.student.id === action.payload.id
                            ? { ...contract, delivery_address: action.payload.delivery_address }
                            : contract,
                    ),
                },
                error: null,
            };
        }
        case getType(postContractCourierAdressDataError): {
            return {
                ...state,
                postStatusAdress: FetchStatus.ERROR,
                error: action.payload,
            };
        }

        case getType(postCourierPaidStart): {
            return {
                ...state,
                postStatusCourierPaid: FetchStatus.FETCHING,
            };
        }
        case getType(postCourierPaidSuccess): {
            return {
                ...state,
                postStatusCourierPaid: FetchStatus.FETCHED,
                error: null,
            };
        }
        case getType(postCourierPaidError): {
            return {
                ...state,
                postStatusCourierPaid: FetchStatus.ERROR,
                error: action.payload,
            };
        }

        case getType(resetDataForTestStart): {
            return {
                ...state,
            };
        }
        case getType(resetDataForTestSuccess): {
            return {
                ...state,
                contracts: {
                    results: state.contracts.results.map((contract) =>
                        contract.id === action.payload.id
                            ? {
                                  ...contract,
                                  ready_to_sign: false,
                                  parent_seen: false,
                                  delivery_address: '',
                                  want_to_sign_by_courier: false,
                                  want_to_sign_by_edc: false,
                              }
                            : contract,
                    ),
                },
                error: null,
            };
        }
        case getType(resetDataForTestError): {
            return {
                ...state,
                error: action.payload,
            };
        }

        default: {
            return state;
        }
    }
};
