import React from 'react';
import { LogoutIcon, SettingsBlackNoBg } from '@lms-elements/icons';

export const getMobileTooltipItems = ({
    handleSettingsClick,
    handleLogoutClick,
}: {
    handleSettingsClick: () => void;
    handleLogoutClick: () => void;
}) => [
    // TODO: вернуть SETTINGS при необходимости
    // {
    //     icon: <SettingsBlackNoBg />,
    //     onClick: handleSettingsClick,
    // },
    {
        icon: <LogoutIcon />,
        onClick: handleLogoutClick,
    },
];
