import React from 'react'
import axios from 'axios'
import './SpecialOffer.scss'
import { apiHost, State } from '../../redux/store'
import { connect, useDispatch, useStore } from 'react-redux'
import { showAlert } from '../../redux/actions/alert'
import { loadUser } from '../../redux/actions/user'
import Cookies from 'universal-cookie'

const SpecialOffer: React.FC = ({ user, isNavbarOpened, changeIsOpenedHandler }: any) => {
    const dispatch = useDispatch();

    const contractHandler = () => {
        const cookie = new Cookies();
        
        axios.post(`${apiHost}/wants_to_extend_contract/
        `, {}, {
            withCredentials: true,
            xsrfCookieName: "csrftoken",
            headers: {
                'X-CSRFToken': cookie.get("csrftoken")
            },
        }).then(res => {
            dispatch(loadUser())
            dispatch(showAlert({
                title: "Запрос успешно отправлен!",
                text: "В ближайшее время с вами свяжется менеджер",
                type: "success"
            }))
        }).catch(err => {
            dispatch(showAlert({
                title: "Ошибка!",
                text: "Что то пошло не так",
                type: "error"
            }))
        })
    }

    const onButtonPressHandler = () => {
        if (isNavbarOpened) {
            contractHandler();
        } else {
            changeIsOpenedHandler()
        }
    }

    if (user?.wants_to_extend_contract) {
        return null
    }

    return (
        <div onClick={onButtonPressHandler} className="cabinet-header__footer">
            <a className="cabinet-header__footer-link" href="#">
                {
                    isNavbarOpened ? <span className="cabinet-header__footer-text">Хочу продлить договор</span> : <i className="icon-fire"></i>
                }
            </a>
        </div>
    )
}

export default connect(
    ({ user }: State, ownProps: any) => ({ ...user, ...ownProps }),
    (dispatch) => {
        return {
            
        }
    }
)(SpecialOffer);