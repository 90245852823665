import { combineEpics, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { FuncEpic } from 'common/types';
import {
    createPaymentError,
    createPaymentStart,
    createPaymentSuccess,
    fetchDebtPaymentsError,
    fetchDebtPaymentsStart,
    fetchDebtPaymentsSuccess,
    fetchUpcomingPaymentsError,
    fetchUpcomingPaymentsStart,
    fetchUpcomingPaymentsSuccess,
    loadPaidPaymentContracts,
    loadPaidPaymentContractsError,
    loadPaidPaymentContractsSuccess,
    loadPaymentContracts,
    loadPaymentContractsError,
    loadPaymentContractsSuccess,
} from '../actions/payment';

const loadContractsEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(loadPaymentContracts)),
        switchMap(({ payload }) => {
            return from(deps.paymentDataProvider.loadContracts(payload.userId)).pipe(
                switchMap((contracts) => {
                    return of(loadPaymentContractsSuccess(contracts));
                }),
                catchError((err) => {
                    return of(loadPaymentContractsError(err));
                }),
            );
        }),
    );
};

const loadPaidContractsEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(loadPaidPaymentContracts)),
        switchMap(({ payload }) => {
            return from(deps.paymentDataProvider.loadPaidContracts(payload.userId)).pipe(
                switchMap((contracts) => {
                    return of(loadPaidPaymentContractsSuccess(contracts));
                }),
                catchError((err) => {
                    return of(loadPaidPaymentContractsError(err));
                }),
            );
        }),
    );
};

const createPaymentEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(createPaymentStart)),
        switchMap(({ payload }: ActionType<typeof createPaymentStart>) => {
            return from(deps.paymentDataProvider.createPayment(store$.value.payment.paymentAreasId[payload])).pipe(
                switchMap((response: any) => {
                    if (response.url) {
                        window.open(response.url, '_self');
                    }
                    return of(createPaymentSuccess(response));
                }),
                catchError((err) => {
                    return of(createPaymentError(err));
                }),
            );
        }),
    );
};

const fetchUpcomingPaymentsEpic: FuncEpic = (action$: any, store$, deps) =>
    action$.pipe(
        ofType(getType(fetchUpcomingPaymentsStart)),
        switchMap(({ payload }) =>
            from(deps.paymentDataProvider.fetchUpcomingPayments(payload)).pipe(
                switchMap((response) => of(fetchUpcomingPaymentsSuccess(response.data))),
                catchError((error) => of(fetchUpcomingPaymentsError(error))),
            ),
        ),
    );

const fetchDebtPaymentsEpic: FuncEpic = (action$: any, store$, deps) =>
    action$.pipe(
        ofType(getType(fetchDebtPaymentsStart)),
        switchMap(({ payload }) =>
            from(deps.paymentDataProvider.fetchDebtPayments(payload)).pipe(
                switchMap((response) => of(fetchDebtPaymentsSuccess(response.data))),
                catchError((error) => of(fetchDebtPaymentsError(error))),
            ),
        ),
    );

export const paymentEpics = combineEpics(
    loadContractsEpic,
    loadPaidContractsEpic,
    createPaymentEpic,
    fetchUpcomingPaymentsEpic,
    fetchDebtPaymentsEpic,
);
