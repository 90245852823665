import { ActionType, createAction } from 'typesafe-actions';
import { GetHWPreviewParams } from '../types/homework';
import { AssignmentProgressPreview } from '../types/schedule';

export const resetHomeworkState = createAction('homework/resetHomeworkState')();

export const getAssignmentProgressPreview = createAction(
    'homework/getAssignmentProgressPreview/start',
)<GetHWPreviewParams>();
export const getAssignmentProgressPreviewSuccess = createAction('homework/getAssignmentProgressPreview/success')<
    AssignmentProgressPreview[]
>();
export const getAssignmentProgressPreviewError = createAction('homework/getAssignmentProgressPreview/error')<any>();

export type HomeworkActions =
    | ActionType<typeof resetHomeworkState>
    | ActionType<typeof getAssignmentProgressPreview>
    | ActionType<typeof getAssignmentProgressPreviewSuccess>
    | ActionType<typeof getAssignmentProgressPreviewError>;
