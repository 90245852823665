import { ActionType, createAction } from 'typesafe-actions';
import { IGetContractsPayload } from '../types/contracts';

export const getContractsStart = createAction('contracts/getContracts/start')<IGetContractsPayload>();
export const getContractsSuccess = createAction('contracts/getContracts/success')<any>();
export const getContractsError = createAction('contracts/getContracts/error')<any>();

export const patchContractSeenStart = createAction('contracts/patchContractSeen/start')<any>();
export const patchContractSeenSuccess = createAction('contracts/patchContractSeen/success')<any>();
export const patchContractSeenError = createAction('contracts/patchContractSeen/error')<any>();

export const postRefuseToSignStart = createAction('contracts/postRefuseToSign/start')<any>();
export const postRefuseToSignSuccess = createAction('contracts/postRefuseToSign/success')<any>();
export const postRefuseToSignError = createAction('contracts/postRefuseToSign/error')<any>();

export const patchContractReadyToSignStart = createAction('contracts/patchContractReadyToSign/start')<any>();
export const patchContractReadyToSignSuccess = createAction('contracts/patchContractReadyToSign/success')<any>();
export const patchContractReadyToSignError = createAction('contracts/patchContractReadyToSign/error')<any>();

export const postContractHaveQuestionsStart = createAction('contracts/postContractHaveQuestions/start')<any>();
export const postContractHaveQuestionsSuccess = createAction('contracts/postContractHaveQuestions/success')<any>();
export const postContractHaveQuestionsError = createAction('contracts/postContractHaveQuestions/error')<any>();

export const postMissingKonturUrlStart = createAction('contracts/postMissingKonturUrl/start')<any>();
export const postMissingKonturUrlSuccess = createAction('contracts/postMissingKonturUrl/success')<any>();
export const postMissingKonturUrlError = createAction('contracts/postMissingKonturUrl/error')<any>();

export const patchContractMethodSignStart = createAction('contracts/patchContractMethodSign/start')<any>();
export const patchContractMethodSignSuccess = createAction('contracts/patchContractMethodSign/success')<any>();
export const patchContractMethodSignError = createAction('contracts/patchContractMethodSign/error')<any>();

export const getSchoolInfoStart = createAction('contracts/getSchoolInfo/start')<any>();
export const getSchoolInfoSuccess = createAction('contracts/getSchoolInfo/success')<any>();
export const getSchoolInfoError = createAction('contracts/getSchoolInfo/error')<any>();

export const postContractCourierAdressDataStart = createAction('contracts/postContractCourierAdressData/start')<any>();
export const postContractCourierAdressDataSuccess = createAction(
    'contracts/postContractCourierAdressData/success',
)<any>();
export const postContractCourierAdressDataError = createAction('contracts/postContractCourierAdressData/error')<any>();

export const postCourierPaidStart = createAction('contracts/postCourierPaid/start')<any>();
export const postCourierPaidSuccess = createAction('contracts/postCourierPaid/success')<any>();
export const postCourierPaidError = createAction('contracts/postCourierPaid/error')<any>();

export const resetDataForTestStart = createAction('contracts/resetDataForTest/start')<any>();
export const resetDataForTestSuccess = createAction('contracts/resetDataForTest/success')<any>();
export const resetDataForTestError = createAction('contracts/resetDataForTest/error')<any>();

export type ContractsActions =
    | ActionType<typeof getContractsStart>
    | ActionType<typeof getContractsSuccess>
    | ActionType<typeof getContractsError>
    | ActionType<typeof patchContractSeenStart>
    | ActionType<typeof patchContractSeenSuccess>
    | ActionType<typeof patchContractSeenError>
    | ActionType<typeof postRefuseToSignStart>
    | ActionType<typeof postRefuseToSignSuccess>
    | ActionType<typeof postRefuseToSignError>
    | ActionType<typeof patchContractReadyToSignStart>
    | ActionType<typeof patchContractReadyToSignSuccess>
    | ActionType<typeof patchContractReadyToSignError>
    | ActionType<typeof postContractHaveQuestionsStart>
    | ActionType<typeof postContractHaveQuestionsSuccess>
    | ActionType<typeof postContractHaveQuestionsError>
    | ActionType<typeof postMissingKonturUrlStart>
    | ActionType<typeof postMissingKonturUrlSuccess>
    | ActionType<typeof postMissingKonturUrlError>
    | ActionType<typeof getSchoolInfoStart>
    | ActionType<typeof getSchoolInfoSuccess>
    | ActionType<typeof getSchoolInfoError>
    | ActionType<typeof postContractCourierAdressDataStart>
    | ActionType<typeof postContractCourierAdressDataSuccess>
    | ActionType<typeof postContractCourierAdressDataError>
    | ActionType<typeof postCourierPaidStart>
    | ActionType<typeof postCourierPaidSuccess>
    | ActionType<typeof postCourierPaidError>
    | ActionType<typeof resetDataForTestStart>
    | ActionType<typeof resetDataForTestSuccess>
    | ActionType<typeof resetDataForTestError>
    | ActionType<typeof patchContractMethodSignStart>
    | ActionType<typeof patchContractMethodSignSuccess>
    | ActionType<typeof patchContractMethodSignError>;
