import React, { useCallback } from 'react';
import { AvatarWithTooltip } from '@lms-elements/atomic';

import { getTooltipItems } from './PageHeader.utils';
import './PageHeader.scss';
import Cookies from 'universal-cookie';
import { logout } from '../../redux/actions/login';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { removeAccessToken, removeRefreshToken } from '../../common/token';

interface Props {
    title?: string;
    user: {
        fullName: string;
        img: string;
    };
}

export const PageHeader: React.FC<Props> = ({ title, user }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const handleSettingsClick = useCallback(() => {
        history.push('/settings/parent');
    }, [history]);

    const handleLogoutClick = useCallback(() => {
        const cookie = new Cookies();
        removeAccessToken();
        removeRefreshToken();
        cookie.remove('students');
        cookie.remove('user');
        dispatch(logout());
        history.push(`/new/auth/login?path=${window.location.pathname}`);
    }, [dispatch, history]);

    const tooltipItems = getTooltipItems({ handleSettingsClick, handleLogoutClick });

    return (
        <div className="pageHeader__wrapper">
            {!!title && <h4 className="cabinet-content__title">{title}</h4>}

            <AvatarWithTooltip
                name={user.fullName}
                avatarSource={user.img}
                avatarAltText={user.fullName}
                tooltipItems={tooltipItems}
            />
        </div>
    );
};
