import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { getType } from 'typesafe-actions';

import { VERSION } from '../../common/constants';
import { FetchStatus, PageStatus } from '../../common/types';
import {
    LOAD_NEAREST_EVENT,
    LOAD_NEAREST_EVENT_ERROR,
    LOAD_NEAREST_EVENT_SUCCESS,
    loadMeetingUrl,
    loadMeetingUrlError,
    loadMeetingUrlSuccess,
} from '../actions/near';
import { Action } from '../store';
import { NearState } from '../types/near';

const defaultProfileState: NearState = {
    pageStatus: PageStatus.INITIAL,
    event: null,
    error: null,
    meetingUrlStatus: FetchStatus.INITIAL,
    meetingUrlError: null,
};

const reducer = (state: NearState = defaultProfileState, action: Action) => {
    switch (action.type) {
        case LOAD_NEAREST_EVENT: {
            return {
                ...state,
                pageStatus: PageStatus.LOADING,
            };
        }
        case LOAD_NEAREST_EVENT_SUCCESS: {
            return {
                ...state,
                pageStatus: PageStatus.LOADED,
                event: action.event,
            };
        }
        case LOAD_NEAREST_EVENT_ERROR: {
            return {
                ...state,
                pageStatus: PageStatus.ERROR,
                error: action.error,
            };
        }

        case getType(loadMeetingUrl): {
            return {
                ...state,
                meetingUrlStatus: FetchStatus.FETCHING,
            };
        }
        case getType(loadMeetingUrlSuccess): {
            return {
                ...state,
                meetingUrlStatus: FetchStatus.FETCHED,
                meetingUrlError: null,
                event: state.event.map((meet) =>
                    meet.id === action.payload?.assembly ? { ...meet, url: action.payload?.link } : meet,
                ),
            };
        }
        case getType(loadMeetingUrlError): {
            return {
                ...state,
                meetingUrlStatus: FetchStatus.ERROR,
            };
        }

        default: {
            return state;
        }
    }
};

export default persistReducer(
    {
        key: `nearest::${VERSION}`,
        storage: storage,
        blacklist: [],
        version: VERSION,
    },
    reducer,
);
