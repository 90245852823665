import { BaseRestDataProvider } from './BaseRestDataProvider';
import moment from 'moment';
import axios from 'axios';
import Cookies from 'universal-cookie';

export class RatingDataProvider extends BaseRestDataProvider {
    loadRating = (id: any) => {
        return axios
            .all([this.loadYearGrades(id), this.loadStudentCourses(id)])
            .then(([rating, courses]) => ({ rating, courses }));
    };

    loadYearGrades = async (id: any) => {
        return await axios.get(`${this.host}/year_grades/?student_id=${id}`).then((res) => res.data);
    };

    loadStudentCourses = async (id: any) => {
        return await axios.get(`${this.host}/current_courses/?student_id=${id}`).then((res) => res.data);
    };
}
