import moment from 'moment-timezone';

import { MeetingSlot, TeacherMeetingSlot } from '../../redux/types/meetings';

export const normalizeTeacherSlots = (teacherMeetingSlots: TeacherMeetingSlot[]): MeetingSlot[] => {
    return teacherMeetingSlots.reduce((prev, cur) => {
        const slotDate = moment(cur.datetimeStart).format('YYYY-MM-DD');
        const slotStartTime = moment(cur.datetimeStart).format('HH:mm');
        const slotMoscowStartTime = moment(cur.datetimeStart).tz('Europe/Moscow').format('HH:mm');
        const slotEndTime = moment(cur.datetimeEnd).format('HH:mm');

        const foundSlot = prev.find((item) => item.date === slotDate);

        if (foundSlot) {
            foundSlot.time.push({ start_time: slotStartTime, end_time: slotEndTime, startMoscowTime: slotMoscowStartTime });
        } else {
            prev.push({ date: slotDate, time: [{ start_time: slotStartTime, end_time: slotEndTime, startMoscowTime: slotMoscowStartTime }] });
        }

        return prev;
    }, [] as MeetingSlot[]);
};
