import { Action } from '../store';
import { getType } from 'typesafe-actions';
import { NotificationState } from '../types/notification';
import { closePaymentInfo, closeCuriousInfo } from '../actions/notification';
import { persistReducer } from 'redux-persist';
import { VERSION } from '../../common/constants';
import storage from 'redux-persist/lib/storage';

const initialState = {
    paymentInfo: true,
    curiousInfo: true,
};

const notification = (state: NotificationState = initialState, action: Action) => {
    switch (action.type) {
        case getType(closePaymentInfo): {
            return {
                ...state,
                paymentInfo: false,
            };
        }
        case getType(closeCuriousInfo): {
            return {
                ...state,
                curiousInfo: false,
            };
        }

        default: {
            return {
                ...state,
            };
        }
    }
};

export default persistReducer(
    {
        key: `notification::${VERSION}`,
        storage: storage,
        blacklist: [],
        version: VERSION,
    },
    notification,
);
