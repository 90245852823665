import axios, { AxiosResponse } from 'axios';
import { IPenDocument } from 'redux/types/penDocument';

import { BaseRestDataProvider } from './BaseRestDataProvider';

export class PenDocumentsDataProvider extends BaseRestDataProvider {
    getPenDocuments = (value: {student: number; user: number}): Promise<IPenDocument[]> => {
        return axios
            .get<unknown, AxiosResponse<IPenDocument[]>>(
                `${this.host}/report/pencil_generated_documents/?user=${value.user}&student=${value.student}`,
            )
            .then((res) => res?.data)
            .catch((res: { status: string }) => {
                throw res?.status;
            });
    };

    postChangePenDocument = (id: number): Promise<never> => {
        return axios
            .post<unknown, AxiosResponse<never>>(
                `${this.host}/report/pencil_generated_documents/${id}/paid/`,
            )
            .then((res) => res?.data)
            .catch((res: { status: string }) => {
                throw res?.status;
            });
    };
    
    postRefusePenDocument = (id: number): Promise<never> => {
        return axios
            .post<unknown, AxiosResponse<never>>(
                `${this.host}/report/pencil_generated_documents/${id}/refuse/`,
            )
            .then((res) => res?.data)
            .catch((res: { status: string }) => {
                throw res?.status;
            });
    };
}
