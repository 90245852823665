import { FetchStatus } from '../../common/types';
import { Action } from '../store';
import { getType } from 'typesafe-actions';
import {
    patchStudentInfoError,
    patchStudentInfoStart,
    patchStudentInfoSuccess,
    patchUserInfoError,
    patchUserInfoStart,
    patchUserInfoSuccess,
    regStudentError,
    regStudentStart,
    regStudentSuccess,
    regUserError,
    regUserStart,
    regUserSuccess,
} from '../actions/registration';
import { RegistrationState, RegistrationStudentState, StudentInfoState, UserInfoState } from '../types/registration';

const regInitialState: RegistrationState = {
    fetchStatus: FetchStatus.FETCHING,
    errorMsg: null,
    data: {
        email: '',
        id: null,
    },
};

const regStudentInitialState: RegistrationStudentState = {
    fetchStatus: FetchStatus.FETCHING,
    errorMsg: null,
    data: null,
};

const userInfoInitialState: UserInfoState = {
    fetchStatus: FetchStatus.FETCHING,
    error: null,
    data: null,
};

const studentInfoInitialState: StudentInfoState = {
    fetchStatus: FetchStatus.FETCHING,
    error: null,
    data: null,
};

export const studentInfo = (state: StudentInfoState = studentInfoInitialState, action: Action) => {
    switch (action.type) {
        case getType(patchStudentInfoStart): {
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHING,
                error: null,
            };
        }

        case getType(patchStudentInfoSuccess): {
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHED,
                error: null,
                data: action.payload,
            };
        }

        case getType(patchStudentInfoError): {
            return {
                ...state,
                fetchStatus: FetchStatus.ERROR,
                error: action.payload,
            };
        }

        default: {
            return state;
        }
    }
};

export const userInfo = (state: UserInfoState = userInfoInitialState, action: Action) => {
    switch (action.type) {
        case getType(patchUserInfoStart): {
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHING,
                error: null,
            };
        }

        case getType(patchUserInfoSuccess): {
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHED,
                error: null,
                data: action.payload,
            };
        }

        case getType(patchUserInfoError): {
            return {
                ...state,
                fetchStatus: FetchStatus.ERROR,
                error: action.payload,
            };
        }

        default: {
            return state;
        }
    }
};

export const registration = (state: RegistrationState = regInitialState, action: Action) => {
    switch (action.type) {
        case getType(regUserStart): {
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHING,
            };
        }

        case getType(regUserSuccess): {
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHED,
                data: {
                    email: action.payload?.email,
                    id: action.payload?.id,
                    errorMsg: null,
                },
            };
        }

        case getType(regUserError): {
            const status = action.payload?.response?.status;
            let errorMsg;
            if (status === 500) errorMsg = ['Не удалось связаться с сервером'];
            else if (action.payload?.response?.data?.message !== 'Validation error')
                errorMsg = [action.payload?.response?.data?.message?.detail];
            else
                errorMsg = Object.entries(action.payload?.response?.data?.extra?.fields).reduce(
                    (prev, [key, value]) => prev.concat(value),
                    [],
                );
            return {
                ...state,
                fetchStatus: FetchStatus.ERROR,
                errorMsg: errorMsg,
            };
        }

        default: {
            return state;
        }
    }
};

export const registrationStudent = (state: RegistrationStudentState = regStudentInitialState, action: Action) => {
    switch (action.type) {
        case getType(regStudentStart): {
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHING,
            };
        }

        case getType(regStudentSuccess): {
            return {
                ...state,
                fetchStatus: FetchStatus.FETCHED,
                data: action.payload,
                errorMsg: null,
            };
        }

        case getType(regStudentError): {
            const status = action.payload?.response?.status;
            let errorMsg;
            if (status === 500) errorMsg = ['Не удалось связаться с сервером'];
            else if (action.payload?.response?.data?.message !== 'Validation error')
                errorMsg = [action.payload?.response?.data?.message?.detail];
            else
                errorMsg = Object.entries(action.payload?.response?.data?.extra?.fields).reduce(
                    (prev, [key, value]) => prev.concat(value),
                    [],
                );
            return {
                ...state,
                fetchStatus: FetchStatus.ERROR,
                errorMsg: errorMsg,
            };
        }

        default: {
            return state;
        }
    }
};
