export enum DocTypeEnum {
    contract = 'Договор',
    certificate = 'Справка',
    order = 'Счёт',
    CONTRACT_PROLONGATION = 'contract_prolongation',
}

export enum DocReportTypeEnum {
    PDF = 'PDF',
    DOCX = 'DOCX',
}

export enum DocStatusEnum {
    recognized = 'Документ принят',
    need_correction = 'Повреждённый или непринятый файл',
    unrecognized = 'Ожидает обработки менеджера',
}
