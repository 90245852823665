import { combineEpics, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ActionType } from 'typesafe-actions';

import { FuncEpic } from '../../common/types';
import {
    LOAD_NEAREST_EVENT,
    loadMeetingUrl,
    loadMeetingUrlError,
    loadMeetingUrlSuccess,
    loadNearError,
    loadNearSuccess,
} from '../actions/near';

const nearEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(LOAD_NEAREST_EVENT),
        switchMap(() => {
            return from(deps.profileDataProvider.loadNearest()).pipe(
                switchMap((events) => {
                    return of(loadNearSuccess(events));
                }),
                catchError((err) => {
                    return of(loadNearError(err));
                }),
            );
        }),
    );
};

const loadMeetingUrlEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(loadMeetingUrl),
        switchMap(({ payload }: ActionType<typeof loadMeetingUrl>) => {
            return from(deps.profileDataProvider.loadMeetingUrl(payload)).pipe(
                switchMap((res) => {
                    return of(loadMeetingUrlSuccess({ ...res, assembly: payload.assembly }));
                }),
                catchError((err) => {
                    return of(loadMeetingUrlError(err));
                }),
            );
        }),
    );
};

export const nearEpics = combineEpics(nearEpic, loadMeetingUrlEpic);
