import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { VERSION } from '../../common/constants';
import { PageStatus } from '../../common/types';
import {
    LOAD_PROFILE,
    LOAD_PROFILE_ERROR,
    LOAD_PROFILE_SUCCESS,
    loadParentsError,
    loadParentsStart,
    loadParentsSuccess,
    PURGE_PROFILE,
    SELECT_PROFILE,
} from '../actions/profile';
import { Action } from '../store';
import { ProfileState } from '../types/profile';
import { getType } from 'typesafe-actions';

const defaultProfileState = {
    pageStatus: PageStatus.LOADING,
    user: null,
    error: null,
    selectedProfile: null,
    parents: null,
};

const reducer = (state: ProfileState = defaultProfileState, action: Action) => {
    switch (action.type) {
        case LOAD_PROFILE:
            return {
                ...state,
                pageStatus: PageStatus.LOADING,
            };
        case LOAD_PROFILE_SUCCESS:
            return {
                ...state,
                pageStatus: PageStatus.LOADED,
                user: action.profile,
                selectedProfile: !state.selectedProfile?.id
                    ? action.profile[0]
                    : {
                          ...(action.profile.find((profile) => profile.id === state?.selectedProfile?.id) ??
                              action.profile[0]),
                      },
            };
        case LOAD_PROFILE_ERROR:
            return {
                ...state,
                pageStatus: PageStatus.ERROR,
                error: action.error,
            };
        case SELECT_PROFILE:
            return {
                ...state,
                selectedProfile: action.profile,
            };
        case PURGE_PROFILE:
            return {
                ...defaultProfileState,
            };
        case getType(loadParentsStart):
            return {
                ...state,
                error: null,
                pageStatus: PageStatus.LOADING,
            };
        case getType(loadParentsSuccess):
            return {
                ...state,
                error: null,
                pageStatus: PageStatus.LOADED,
                parents: action.payload,
            };
        case getType(loadParentsError):
            return {
                ...state,
                error: action.payload,
                pageStatus: PageStatus.ERROR,
            };
        default: {
            return state;
        }
    }
};

export default persistReducer(
    {
        key: `profile::${VERSION}`,
        storage: storage,
        blacklist: [],
        version: VERSION,
    },
    reducer,
);
