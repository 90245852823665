import React, { useCallback, useState } from 'react';
import './Select.scss';

export const Select: React.FC<{
    options: Array<any>;
    value: any;
    onChange: (value: any) => void;
    placeholder?: string;
    title: string;
}> = ({ options, value, onChange, placeholder, title }) => {
    const [isOpened, setIsOpened] = useState(false);

    const selectChangeCallback = useCallback((el: any) => {
        setIsOpened(false);
        onChange(el);
    }, []);

    return (
        <div className="select__wrapper jcf-select-wrapper meeting__form-select jcf-select-wrapper-active">
            <div className="form-group form-group--not-empty">
                <div className="jcf-select" onClick={() => setIsOpened(!isOpened)}>
                    <div className={`jcf-select-opener ${isOpened && 'jcf-select-opener-active'}`}></div>
                    <div className="span">{value === null ? placeholder : `${value.name} - ${value.subject}`}</div>
                    <select name="select" className="jcf-hidden" id="select">
                        {options.map((el, index) => (
                            <option
                                onClick={() => selectChangeCallback(el)}
                                key={`option-${index}`}
                                selected
                                value={`${el.name} - ${el.subject}`}
                            >{`${el.name} - ${el.subject}`}</option>
                        ))}
                    </select>
                    <label htmlFor="select">{title}</label>
                </div>
                {isOpened && (
                    <div className="jcf-select-drop jcf-unselectable">
                        <div className="jcf-select-drop-content">
                            <span className="jcf-list">
                                <span className="jcf-list-content custom-scroll">
                                    <ul>
                                        {options.map((el, index) => (
                                            <li
                                                key={index}
                                                onClick={() => selectChangeCallback(el)}
                                                className={`jcf-option ${el === value && 'jcf-selected'}`}
                                            >
                                                <div className="select__value jcf-select-text">{`${el.name} - ${el.subject}`}</div>
                                            </li>
                                        ))}
                                    </ul>
                                </span>
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
