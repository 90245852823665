import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { inclineFirstname, inclineLastname, inclineMiddlename } from 'lvovich';
import { CustomSelect } from 'components/General/CustomSelect/Select';
import './ApplicationForm.scss';
import { Checkbox } from 'components/Checkbox';
import moment from 'moment';
import { AddButton } from '../../../components/AddButton';
import { checkCountryCode } from '../../Settings/utils';
import { IApplicationFormProps, IChildData, InputTypes, IValidation } from './ApplicationForm.types';
import { getDefaultPhone } from './utils';
import { convertToDateStr } from '../../../common/helpers';

const phoneRegExp: RegExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
const emailRegExp = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

const parentsOptions = ['Отец', 'Мать', 'Опекун'];

export const ApplicationForm: React.FC<IApplicationFormProps> = ({
    children,
    parent,
    parentPhone,
    foreignLanguages,
    ruleUrl,
    handleSubmitForm,
    parentEmail,
    constants,
    timezones,
    parentCountryCode,
}) => {
    const childrenName = useMemo(
        () =>
            inclineLastname(children.last_name, 'accusative') +
            ' ' +
            inclineFirstname(children.first_name, 'accusative') +
            ' ' +
            inclineMiddlename(children.patronymic, 'accusative'),
        [children],
    );

    const [childData, setChildData] = useState<IChildData>({
        phone_number: getDefaultPhone(children?.phone_numbers).phoneNumber,
        email: children.email,
        snils: children.snils,
        actual_living_place: children.actual_living_place,
        living_place: children.living_place,
        birthday: convertToDateStr(children.birthday),
        birth_certificate: children.birth_certificate,
        grade: 5,
    });

    const [childCountryCode, setChildCountryCode] = useState(getDefaultPhone(children?.phone_numbers)?.countryCode);

    const [selectedIndex, setSelectedIndex] = useState([-1]);

    const [timezone, setTimezone] = useState('MSK+0');
    const [mathLevel, setMathLevel] = useState('');
    const [relationDegree, setRelationDegree] = useState('');
    const [focus, setFocus] = useState(-1);
    const [names, setNames] = useState([
        { lastName: '', firstName: '', patronymic: '' },
        { lastName: '', firstName: '', patronymic: '' },
        { lastName: '', firstName: '', patronymic: '' },
    ]);
    const [registration, setRegistration] = useState([false, false, false]);
    const [countryCodes, setCountryCodes] = useState(['', '', '']);
    const [phones, setPhones] = useState(['', '', '']);
    const [emails, setEmails] = useState(['', '', '']);
    const [validationState, setValidationState] = useState<IValidation>({
        phones: phones,
        emails: emails,
        childPhone: !!childData?.phone_number || childData.phone_number?.length === 0,
        childCountryCode:
            childCountryCode &&
            !!checkCountryCode(
                childCountryCode[0] === '+' ? childCountryCode.slice(1) : childCountryCode,
                constants.COUNTRY_CODES,
            ),
        countryCodes: countryCodes,
        childEmail: !!childData.email.match(emailRegExp),
        snils: !!childData.snils,
        actual_living_place: !!childData.actual_living_place,
        living_place: !!childData.living_place,
        birthday: !!childData.birthday,
        birth_certificate: !!childData.birth_certificate,
        grade: !!childData.grade,
        timezone: !!timezone,
        mathLevel: !!mathLevel || childData.grade < 10,
        relationDegree: !!relationDegree || !selectedIndex.find((index) => index === 2),
    });
    const [isSubmit, setIsSubmit] = useState(false);

    const handleInputChange = useCallback(
        (type: string, indexValue: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target) {
                const newValue = event.target.value;
                switch (type) {
                    case InputTypes.lastName:
                    case InputTypes.firstName:
                    case InputTypes.patronymic:
                        setNames((prev) =>
                            prev.map((name, index) => (index === indexValue ? { ...name, [type]: newValue } : name)),
                        );
                        break;
                    case InputTypes.phone:
                        setPhones((prev) => prev.map((phone, index) => (index === indexValue ? newValue : phone)));
                        break;
                    case InputTypes.countryCode:
                        setCountryCodes((prev) => prev.map((code, index) => (index === indexValue ? newValue : code)));
                        break;
                    case InputTypes.email:
                        setEmails((prev) => prev.map((email, index) => (index === indexValue ? newValue : email)));
                        break;
                }
            }
        },
        [],
    );

    const handleChildDataChange = useCallback(
        (value, key) => {
            setChildData((prevState) => ({ ...prevState, [key]: value }));
        },
        [childData],
    );

    const onAddClick = useCallback(() => {
        setSelectedIndex((prev) => [...prev, -1]);
    }, [selectedIndex]);

    const [mainLanguage, setMainLanguage] = useState('');
    const [addLanguage, setAddLanguage] = useState('');

    const [yearsAdd, setYearsAdd] = useState('');

    const [checkedRules, setCheckedRules] = useState(false);
    const [adaptive, setAdaptive] = useState(false);

    const handleSelectParent = useCallback(
        (index: number) => (value: string) => {
            if (index === 0) {
                setNames((prev) =>
                    prev.map((el, indexNames) => {
                        if (indexNames === parentsOptions.indexOf(value)) return parent;
                        else if (
                            el.lastName === parent.lastName &&
                            el.firstName === parent.firstName &&
                            el.patronymic === parent.patronymic
                        )
                            return { lastName: '', firstName: '', patronymic: '' };
                        return el;
                    }),
                );
                setPhones((prev) =>
                    prev.map((el, indexPhones) => {
                        if (indexPhones === parentsOptions.indexOf(value)) return parentPhone;
                        else if (el === parentPhone) return '';
                        return el;
                    }),
                );
                setCountryCodes((prev) =>
                    prev.map((el, indexCodes) => {
                        if (indexCodes === parentsOptions.indexOf(value)) return parentCountryCode;
                        else if (el === parentCountryCode) return '';
                        return el;
                    }),
                );
                setEmails((prev) =>
                    prev.map((el, indexEmails) => {
                        if (indexEmails === parentsOptions.indexOf(value)) return parentEmail;
                        else if (el === parentEmail) return '';
                        return el;
                    }),
                );
            }
            setSelectedIndex((prev) =>
                prev.map((selected, indexSelected) =>
                    indexSelected === index ? parentsOptions.indexOf(value) : selected,
                ),
            );
        },
        [parentsOptions, names, phones, countryCodes, emails, selectedIndex],
    );

    useEffect(() => {
        setValidationState({
            phones: phones.map((phone) => (phone.match(phoneRegExp) ? phone : '')),
            emails: emails.map((email) => (email.match(emailRegExp) ? email : '')),
            childPhone: !!childData?.phone_number || childData?.phone_number?.length === 0,
            childCountryCode:
                childCountryCode &&
                !!checkCountryCode(
                    childCountryCode[0] === '+' ? childCountryCode.slice(1) : childCountryCode,
                    constants.COUNTRY_CODES,
                ),
            countryCodes: countryCodes.map((code) =>
                !!checkCountryCode(code[0] === '+' ? code.slice(1) : code, constants.COUNTRY_CODES) ? code : '',
            ),
            childEmail: !!childData.email.match(emailRegExp),
            snils: !!childData.snils,
            actual_living_place: !!childData.actual_living_place,
            living_place: !!childData.living_place,
            birthday: !!childData.birthday,
            birth_certificate: !!childData.birth_certificate,
            grade: !!childData.grade,
            timezone: !!timezone,
            mathLevel: !!mathLevel || childData.grade < 10,
            relationDegree: !!relationDegree || !selectedIndex.find((index) => index === 2),
        });
    }, [phones, emails, childData, mathLevel, timezone, relationDegree, selectedIndex, countryCodes, childCountryCode]);

    const isValid = useMemo(() => {
        const notEmptyLen = selectedIndex.every(
            (index) =>
                index !== -1 &&
                names[index].lastName &&
                names[index].firstName &&
                names[index].patronymic &&
                phones[index] &&
                emails[index] &&
                countryCodes[index],
        );
        return (
            adaptive &&
            checkedRules &&
            !!mainLanguage &&
            notEmptyLen &&
            selectedIndex.every((value) => validationState.phones[value] && validationState.emails[value]) &&
            Object.values(validationState).every((value) => !!value === true) &&
            !!addLanguage === !!yearsAdd &&
            validationState.childPhone &&
            validationState.childCountryCode
        );
    }, [
        mainLanguage,
        adaptive,
        checkedRules,
        selectedIndex,
        names,
        emails,
        phones,
        yearsAdd,
        addLanguage,
        validationState,
        countryCodes,
    ]);

    const handleSubmit = useCallback(() => {
        if (handleSubmitForm && isValid) {
            let parentData = [{}, {}, {}];
            selectedIndex.forEach((el) => {
                parentData[el] = {
                    first_name: names[el].firstName,
                    last_name: names[el].lastName,
                    patronymic: names[el].patronymic,
                    email: emails[el],
                    phone_numbers: [
                        {
                            default: true,
                            country_code: countryCodes[el],
                            phone_number: phones[el],
                        },
                    ],
                };
                if (selectedIndex[0] !== el) parentData[el] = { ...parentData[el], registration: registration[el] };
            });
            const trustee = relationDegree ? { ...parentData[2], relation_degree: relationDegree } : parentData[2];

            handleSubmitForm({
                father: parentData[0],
                mother: parentData[1],
                trustee: trustee,
                student: {
                    id: children.id,
                    phone_numbers: [
                        {
                            default: true,
                            phone_number: childData.phone_number,
                            country_code: childCountryCode,
                        },
                    ],
                    email: childData.email,
                    snils: childData.snils,
                    actual_living_place: childData.actual_living_place,
                    living_place: childData.living_place,
                    birthday: childData.birthday,
                    birth_certificate: childData.birth_certificate,
                    grade: childData.grade,
                    timezone: timezone,
                },
                primary_language: mainLanguage,
                secondary_language: {
                    language_name: addLanguage,
                    study_time: yearsAdd,
                },
                math_level: mathLevel,
            });
        }
        setIsSubmit(true);
    }, [
        handleSubmitForm,
        selectedIndex,
        names,
        emails,
        phones,
        mainLanguage,
        addLanguage,
        yearsAdd,
        isValid,
        childData,
        registration,
        countryCodes,
        childCountryCode,
        relationDegree,
    ]);

    const documentInfoInputs = useMemo(() => {
        return [
            {
                id: 'living_place',
                placeholder: 'Введите адрес',
                labelText: 'Место жительства',
            },
            {
                id: 'actual_living_place',
                placeholder: 'Введите адрес',
                labelText: 'Адрес фактического проживания',
            },
            {
                id: 'birthday',
                placeholder: 'Дата рождения',
                labelText: 'Дата рождения',
                type: 'date',
            },
            {
                id: 'snils',
                placeholder: 'Введите номер',
                labelText: 'СНИЛС',
            },
            {
                id: 'birth_certificate',
                placeholder: 'Введите номер',
                labelText: 'Свидетельство о рождении',
            },
        ].filter((data) => data);
    }, [childData]);

    return (
        <div className="form-container">
            <div className="application-form">
                <header>
                    <h1 className="application-form__title">Анкета на зачисление</h1>
                </header>
                <span className="application-form__text">
                    Прошу зачислить <strong>{childrenName}</strong> в класс обучения в ЧОУ «ОНЛАЙН ГИМНАЗИИ № 1» на
                    очно-заочную форму обучения. Организовать обучение на государственном языке Российской Федерации –
                    русском языке. Дата рождения <strong>{children.birthday}</strong>.
                </span>
                <div className="application-form__block-header-wrapper">
                    <p className="application-form__block-header-content">Контакты родителя</p>
                </div>
                <div className="application-form__parents">
                    {selectedIndex.map((value, index) => (
                        <div
                            className={`application-form__controls ${focus === index ? 'focus' : ''}`}
                            key={`Parent${index}`}
                        >
                            <CustomSelect
                                placeholder={'Выберите родителя/опекуна'}
                                options={parentsOptions.filter(
                                    (parent, index) => !selectedIndex.some((value) => value === index),
                                )}
                                value={value !== -1 ? parentsOptions[value] : null}
                                onChange={handleSelectParent.bind(null, index)()}
                                onFocus={setFocus.bind(null, index)}
                                error={value === -1 && isSubmit}
                            />
                            {value !== -1 && (
                                <div className="application-form__inputs">
                                    {value > -1 && (
                                        <>
                                            <div
                                                className={`application-form__formGroup${
                                                    isSubmit && !names[value].lastName
                                                        ? ` application-form__formGroup-error`
                                                        : ''
                                                }`}
                                            >
                                                <input
                                                    value={index === 0 ? parent.lastName : names[value].lastName}
                                                    onChange={
                                                        index === 0
                                                            ? () => {}
                                                            : handleInputChange.bind(null, InputTypes.lastName, value)()
                                                    }
                                                    name={`parentLastName${value}`}
                                                    id={`parentLastName${value}`}
                                                    className="form-control"
                                                    type="text"
                                                />
                                                <label htmlFor={`parentLastName${value}`}>Фамилия</label>
                                            </div>
                                            <div
                                                className={`application-form__formGroup${
                                                    isSubmit && !names[value].firstName
                                                        ? ` application-form__formGroup-error`
                                                        : ''
                                                }`}
                                            >
                                                <input
                                                    value={index === 0 ? parent.firstName : names[value].firstName}
                                                    onChange={
                                                        index === 0
                                                            ? () => {}
                                                            : handleInputChange.bind(
                                                                  null,
                                                                  InputTypes.firstName,
                                                                  value,
                                                              )()
                                                    }
                                                    name={`parentFirstName${value}`}
                                                    id={`parentFirstName${value}`}
                                                    className="form-control"
                                                    type="text"
                                                />
                                                <label htmlFor={`parentFirstName${value}`}>Имя</label>
                                            </div>
                                            <div
                                                className={`application-form__formGroup${
                                                    isSubmit && !names[value].patronymic
                                                        ? ` application-form__formGroup-error`
                                                        : ''
                                                }`}
                                            >
                                                <input
                                                    value={index === 0 ? parent.patronymic : names[value].patronymic}
                                                    onChange={
                                                        index === 0
                                                            ? () => {}
                                                            : handleInputChange.bind(
                                                                  null,
                                                                  InputTypes.patronymic,
                                                                  value,
                                                              )()
                                                    }
                                                    name={`parentPatronymic${value}`}
                                                    id={`parentPatronymic${value}`}
                                                    className="form-control"
                                                    type="text"
                                                />
                                                <label htmlFor={`parentPatronymic${value}`}>Отчество</label>
                                            </div>
                                        </>
                                    )}
                                    <div className="application-form__parents-phoneNumber__container">
                                        <div
                                            className={`application-form__parents-phoneNumber__code application-form__formGroup${
                                                isSubmit &&
                                                (!countryCodes[value] || !validationState.countryCodes[value])
                                                    ? ` application-form__formGroup-error`
                                                    : ''
                                            }`}
                                        >
                                            <input
                                                value={index === 0 ? parentCountryCode : countryCodes[value]}
                                                onChange={
                                                    index === 0
                                                        ? () => {}
                                                        : handleInputChange.bind(null, InputTypes.countryCode, value)()
                                                }
                                                name={`parentCountryCode${value}`}
                                                id={`parentCountryCode${value}`}
                                                className="form-control"
                                                type="text"
                                            />
                                            <label htmlFor={`parentCountryCode${value}`}>Код</label>
                                        </div>
                                        <div
                                            className={`application-form__formGroup${
                                                isSubmit && (!phones[value] || !validationState.phones[value])
                                                    ? ` application-form__formGroup-error`
                                                    : ''
                                            }`}
                                        >
                                            <input
                                                value={index === 0 ? parentPhone : phones[value]}
                                                onChange={
                                                    index === 0
                                                        ? () => {}
                                                        : handleInputChange.bind(null, InputTypes.phone, value)()
                                                }
                                                name={`parentPhone${value}`}
                                                id={`parentPhone${value}`}
                                                className="form-control"
                                                type="text"
                                            />
                                            <label htmlFor={`parentPhone${value}`}>Номер телефона</label>
                                        </div>
                                    </div>
                                    <div
                                        className={`application-form__formGroup${
                                            isSubmit && (!emails[value] || !validationState.emails[value])
                                                ? ` application-form__formGroup-error`
                                                : ''
                                        }`}
                                    >
                                        <input
                                            value={index === 0 ? parentEmail : emails[value]}
                                            onChange={
                                                index === 0
                                                    ? () => {}
                                                    : handleInputChange.bind(null, InputTypes.email, value)()
                                            }
                                            name={`parentEmail${value}`}
                                            id={`parentEmail${value}`}
                                            className="form-control"
                                            type="text"
                                        />
                                        <label htmlFor={`parentEmail${value}`}>Email</label>
                                    </div>
                                    {value === 2 && (
                                        <div
                                            className={`application-form__formGroup${
                                                isSubmit && !validationState.relationDegree
                                                    ? ` application-form__formGroup-error`
                                                    : ''
                                            }`}
                                        >
                                            <input
                                                value={relationDegree}
                                                onChange={(e) => setRelationDegree(e.target.value)}
                                                name="relationDegree"
                                                id="relationDegree"
                                                className="form-control"
                                                type="text"
                                            />
                                            <label htmlFor="relationDegree">Степень родства</label>
                                        </div>
                                    )}
                                    {index !== 0 && (
                                        <div className="application-form__registration-check">
                                            <Checkbox
                                                checked={registration[value]}
                                                onClick={(checked) =>
                                                    setRegistration((prevState) =>
                                                        prevState.map((el, index) => (index === value ? checked : el)),
                                                    )
                                                }
                                            />
                                            <span className="application-form__text">
                                                Создать личный кабинет для пользователя
                                            </span>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    ))}
                    {selectedIndex.length < 3 && selectedIndex[0] !== -1 && (
                        <AddButton title="Добавить родителя/опекуна" onAddClick={onAddClick} />
                    )}
                </div>
                <div className="application-form__block-header-wrapper">
                    <p className="application-form__block-header-content">Контакты ребенка</p>
                </div>
                <div className="application-form__child-contacts">
                    <div className="application-form__child-contacts-phoneNumber__container">
                        <div
                            className={`application-form__child-contacts-phoneNumber__code application-form__formGroup ${
                                isSubmit && !validationState.childCountryCode ? 'application-form__formGroup-error' : ''
                            }`}
                        >
                            <input
                                value={childCountryCode}
                                onChange={(e) => setChildCountryCode(e.target.value)}
                                name="childCountryCode"
                                id="childCountryCode"
                                className="form-control"
                                type="text"
                            />
                            <label htmlFor="childCountryCode">Код</label>
                        </div>
                        <div
                            className={`application-form__formGroup ${
                                isSubmit && !validationState.childPhone ? 'application-form__formGroup-error' : ''
                            }`}
                        >
                            <input
                                value={childData.phone_number}
                                onChange={(e) => handleChildDataChange(e.target.value, 'phone_number')}
                                name="childPhone"
                                id="childPhone"
                                className="form-control"
                                type="text"
                            />
                            <label htmlFor="childPhone">Номер телефона</label>
                        </div>
                    </div>
                    <div
                        className={`application-form__formGroup ${
                            isSubmit && !validationState.childEmail ? 'application-form__formGroup-error' : ''
                        }`}
                    >
                        <input
                            value={childData.email}
                            name="childEmail"
                            id="childEmail"
                            className="form-control"
                            type="text"
                        />
                        <label htmlFor="childEmail">Адрес почты</label>
                    </div>
                </div>
                <div className="application-form__block-header-wrapper">
                    <p className="application-form__block-header-content">Паспортные данные</p>
                </div>
                <div className="application-form__child-documents">
                    {documentInfoInputs.map((input, index) => (
                        <div
                            className={`application-form__formGroup ${
                                isSubmit && !validationState[input.id] ? 'application-form__formGroup-error' : ''
                            }`}
                            key={`documentInfo-${index}`}
                        >
                            <input
                                onChange={(e) => handleChildDataChange(e.target.value, input.id)}
                                type={input.type || 'text'}
                                className="form-control"
                                id={input.id}
                                name={input.id}
                                aria-describedby={input.id}
                                placeholder={input.placeholder}
                                value={childData[input.id] || ''}
                            />
                            <label className="settings__input-label" htmlFor={input.id}>
                                {input.labelText}
                            </label>
                        </div>
                    ))}
                </div>
                <div className="application-form__block-header-wrapper">
                    <p className="application-form__block-header-content">Школа и обучение</p>
                </div>
                <div className="application-form__child-education">
                    <div className="application-form__child-education-item">
                        <p>Выберите класс:</p>
                        <CustomSelect
                            options={new Array(7).fill(0).map((_, index) => `${5 + index}`)}
                            value={childData.grade ? `Класс: ${childData.grade}` : null}
                            onChange={(value) => handleChildDataChange(value, 'grade')}
                            error={!childData.grade && isSubmit}
                        />
                    </div>
                    <div className="application-form__child-education-item">
                        <p>Выберите часовой пояс:</p>
                        <CustomSelect
                            options={timezones}
                            value={timezone}
                            onChange={setTimezone}
                            error={!timezone && isSubmit}
                            withSearch={true}
                        />
                    </div>
                </div>
                {childData.grade >= 10 && (
                    <div className="application-form__math-level">
                        <span>Уровень изучения математики</span>
                        <CustomSelect
                            placeholder={'Выберите уровень'}
                            options={['Базовый', 'Углубленный']}
                            value={mathLevel}
                            onChange={setMathLevel}
                            error={!mathLevel && isSubmit}
                        />
                    </div>
                )}
                <div className="application-form__languages">
                    <div className="application-form__block-header-wrapper">
                        <p className="application-form__block-header-content">Изучаемые иностранные языки</p>
                    </div>
                    <div className={`application-form__controls ${focus === 3 ? 'focus' : ''}`}>
                        <div className="label-dropdown">
                            <span>Основной</span>
                            <CustomSelect
                                placeholder={'Выберите основной язык'}
                                options={foreignLanguages}
                                value={mainLanguage}
                                onChange={setMainLanguage}
                                onFocus={setFocus.bind(null, 3)}
                                error={!mainLanguage && isSubmit}
                            />
                        </div>
                    </div>
                    <div className={`application-form__controls ${focus === 4 ? 'focus' : ''}`}>
                        <div className="label-dropdown">
                            <span>Дополнительный</span>
                            <CustomSelect
                                placeholder={'Выберите доп. язык'}
                                options={foreignLanguages.filter((value) => value !== mainLanguage)}
                                value={addLanguage}
                                onChange={setAddLanguage}
                                onFocus={setFocus.bind(null, 4)}
                            />
                        </div>
                        {addLanguage && (
                            <div className="application-form__inputs">
                                <div
                                    className={`application-form__formGroup ${
                                        isSubmit && !yearsAdd ? 'application-form__formGroup-error' : ''
                                    } application-form__language-inputs`}
                                >
                                    <input
                                        placeholder="всего классов или лет"
                                        value={yearsAdd}
                                        onChange={(e) => {
                                            setYearsAdd(e.target.value);
                                        }}
                                        name="YearsAdd"
                                        id="YearsAdd"
                                        className="form-control"
                                        type="text"
                                    />
                                    <label htmlFor="YearsAdd">Изучался</label>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className={`application-form__rules${
                        isSubmit && !checkedRules ? ' application-form__rules-error' : ''
                    }`}
                >
                    <Checkbox checked={checkedRules} onClick={setCheckedRules} />
                    <span className="application-form__text">
                        С{' '}
                        <a target="_blank" href={ruleUrl}>
                            Уставом
                        </a>{' '}
                        ЧОУ «ОНЛАЙН ГИМНАЗИИ № 1», лицензией на право ведения образовательной деятельности и другими
                        документами , регламентирующими организацию и осуществление образовательной деятельности,
                        ОЗНАКОМЛЕН (А):
                    </span>
                </div>
                <div
                    className={`application-form__rules${
                        isSubmit && !adaptive ? ' application-form__rules-error' : ''
                    }`}
                >
                    <Checkbox checked={adaptive} onClick={setAdaptive} />
                    <span className="application-form__text">
                        Уведомляю об отсутствии потребности ребенка в обучении по адаптированной образовательной
                        программе и в создании специальных условий для организации обучения и воспитания обучающегося с
                        ограниченными возможностями здоровья и (или) ребенка-инвалида.
                    </span>
                </div>
                <span className="date">{moment().format('L')}</span>
                <button className="btn btn-primary btn-lg" type="button" onClick={handleSubmit}>
                    Отправить
                </button>
            </div>
        </div>
    );
};
