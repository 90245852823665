import React from 'react';
import { cn } from '@bem-react/classname';
import { Button, ButtonViewEnum } from '@lms-elements/atomic';
import { DownloadIcon, EditFile, Principal as PrincipalIcon, Toggle as ToggleIcon } from '@lms-elements/icons';

import { Popup } from 'components/Atomic';

import './NavBarDesktopButtons.scss';
import { INavBarDesktopButtonsProps } from './NavBarDesktopButtons.types';
import Skeleton from "react-loading-skeleton";

const CnNBDButtons = cn('nav-bar-desktop-buttons');

export const NavBarDesktopButtons: React.FC<INavBarDesktopButtonsProps> = (props) => {
    const {
        isOpened,
        smartLabel,
        isOpenedPopup,
        downloadLabel,
        needSmartButton,
        isOpenButtonEnable,
        needDownloadButton,
        needPrincipalButton,
        isShowSkeleton,
        onLetterSend,
        handleSmartClick,
        handlePopupClose,
        handleToggleClick,
        handleDownloadClick,
        handlePrincipalClick,
    } = props;
    return (
        <div className={CnNBDButtons({ isOpened })}>
            {(needSmartButton || needDownloadButton) && (
                <div className={CnNBDButtons('flex-box', { isOpened })}>
                    {needSmartButton && (
                        <div className={CnNBDButtons('button', { isOpened, isSmart: true })}>
                            <EditFile
                                className={CnNBDButtons('button-icon')}
                                onClick={handleSmartClick}
                                title={smartLabel}
                            />
                        </div>
                    )}
                    {needDownloadButton && (
                        <div className={CnNBDButtons('button', { isOpened, isDownload: true })}>
                            <DownloadIcon
                                className={CnNBDButtons('button-icon')}
                                onClick={handleDownloadClick}
                                title={downloadLabel}
                            />
                        </div>
                    )}
                </div>
            )}
            {needPrincipalButton && (
                <div className={CnNBDButtons('principal', { isOpened })}>
                    <div className={CnNBDButtons('principal-button', { isOpened })} onClick={() => (isShowSkeleton ? undefined : handlePrincipalClick())}
                    >
                        {isShowSkeleton ? (
                            <Skeleton height={40} width={180} style={{ paddingTop: 4 }} />
                        ) : (
                            <Button view={ButtonViewEnum.bordered} size="l">
                                Написать директору
                            </Button>
                        )}
                    </div>
                    {isShowSkeleton ? (
                        isOpened ? null: <Skeleton height={40} width={40} style={{paddingTop: 4}}/>
                    ) : (
                        <PrincipalIcon
                            className={CnNBDButtons('principal-icon', { isOpened })}
                            onClick={handlePrincipalClick}
                        />
                    )}
                    {isOpenedPopup && <Popup onCloseClick={handlePopupClose} onChangeSend={onLetterSend} />}
                </div>
            )}
            <div
                onClick={handleToggleClick}
                className={CnNBDButtons('close-button', {
                    isOnlyToggle: !needDownloadButton && !needSmartButton && isOpened,
                    hasPrincipal: needPrincipalButton,
                    isClosed: !isOpened,
                })}
            >
                <ToggleIcon className={CnNBDButtons('close-icon', { isDisabled: !isOpenButtonEnable, isOpened })} />
            </div>
        </div>
    );
};
