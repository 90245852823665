import { FetchStatus, PageStatus } from '../../common/types';
import { AssignmentProgressStatus } from '@lms-elements/atomic';
import { ReactElement } from 'react';

export interface ScheduleState {
    pageStatus: PageStatus;
    schedule: any;
    error: any;
    lmsSchedule: ScheduleData;
    lmsScheduleStatus: FetchStatus;
    lmsScheduleError: any;
    scheduleHomeworks: any;
    scheduleHomeworksStatus: FetchStatus;
    scheduleHomeworksError: any;
}

export interface AssignmentProgress {
    id: number;
    status: 'deferredAssignment' | 'onCheck' | 'assigned' | 'completed';
    assignment: number;
    student: number;
    deadlineForComplete: string;
    assignedDate: string;
    teacher: number;
    conference: number;
    isViewed: boolean;
}

export interface StudentGroup {
    id: number;
    groupTitle: string;
    studentsQuantity: number;
    assignmentQuantity: number;
    nextLessonTime: string;
    nextAssignmentDeadline: string;
    teacherFirstName: string;
    teacherLastName: string;
    students: {
        id: number;
        firstName: string;
        lastName: string;
        photoUrl: string;
    }[];
}

export type MarkScore = 5 | 4 | 3 | 2;

export interface MarkData {
    id: number;
    score?: MarkScore;
    comment?: string;
    weight?: number;
    author?: number;
    date?: string;
}

type AssignmentProgressMark = {
    id: number;
    score: MarkScore;
    weight: number;
    author?: {
        id: number;
        firstName: string;
        lastName: string;
        photoUrl: string;
    };
};

type TeacherMark = AssignmentProgressMark & { comment: string };

export enum AssignmentLevels {
    ADMINISTRATIVE = 'administrative',
    TEACHER = 'teacher',
}

export enum ExecutionTimes {
    IN_CLASS = 'in_class',
    AT_HOME = 'at_home',
}

export enum VerificationTypes {
    MANUAL = 'manual',
    AUTO = 'auto',
}

export interface AssignmentType {
    id: number;
    title: string;
    attemptsQuantity: number;
    verificationType: VerificationTypes[]; // тип проверки: ручная или автоматическая
    executionTime: ExecutionTimes[]; // время выполнения задания: дома или на уроке
    isMandatory: boolean; // обязательный или необязательный
    assignmentLevel: AssignmentLevels;
    weight?: number;
}

export interface LmsStudent {
    id?: number;
    externalId?: string;
    firstName: string;
    lastName: string;
    patronymic?: string;
    photo?: string;
    photoUrl: string;
    isAdaptation?: boolean;
    lastSeen?: null | string;
    online?: boolean;
    educationalPlan?: number;
}

export interface IHomeworkItem {
    id: string;
    status: AssignmentProgressStatus;
    title: string;
    description?: string;
    marks: AssignmentProgressMark[] | null;
    assignedDate?: string;
    type: AssignmentType;
    student: LmsStudent;
    subject?: {
        id: number;
        title: string;
    };
    lesson?: {
        id: number;
    };
    assignment: {
        id: number;
    };
    completeDate?: string;
    selectedQuestions: number[];
    courseGroup?: {
        id: number;
        title: string;
    };
    course?: {
        id: number;
        title: string;
    };
    teacherMark: TeacherMark[];
    deadlineForComplete?: string;
    deadlineForCheck?: string;
}

export enum FilterTypes {
    DEADLINE_FOR_CHECK = 'DEADLINE_FOR_CHECK',
    DEADLINE_FOR_COMPLETE = 'DEADLINE_FOR_COMPLETE',
    ASSIGNED = 'ASSIGNED',
}

export interface LmsParallel {
    id: number;
    title: string;
    titleShort: string;
}

export interface ScheduleEvent {
    id: number;
    lesson: {
        id: number;
        title: string;
    };
    type: string;
    subject: {
        id: number;
        title: string;
        shortName?: string;
    };
    courseGroup: {
        id: number;
        title: string;
    };
    parallels: LmsParallel[];
    date: string;
    datetimeStart: string;
    datetimeEnd: string;
}

export interface LoadLmsSchedulePayload {
    query?: {
        datetime_start: string;
        datetime_end: string;
        lesson_is_published?: boolean;
    };
    external_id: string;
}

export interface GetHomeworkBetweenDatesParams {
    external_id?: string;
    deadline_for_complete_after?: string;
    deadline_for_complete_before?: string;
    deadline_for_check_after?: string;
    deadline_for_check_before?: string;
    assigned_date_after?: string;
    assigned_date_before?: string;
    status?: string;
}

export interface ScheduleData {
    [key: string]: {
        date: string;
        data: ScheduleEvent[];
    };
}

export interface AssignmentProgressPreview {
    id: number;
    status: AssignmentProgressStatus;
    title?: string;
    subject: number;
    course: {
        id: number;
        title: string;
    };
    lesson: number;
    assignment: {
        id: number;
        title: string;
        description: string;
        type: AssignmentType;
    };
    courseGroup: {
        id: number;
        title: string;
    };
    assignedDate?: string;
    completeDate?: string;
    deadlineForComplete?: string;
    deadlineForCheck?: string;
}

export interface TaskListProps {
    tasks: AssignmentProgressPreview[];
    needShowLessonRecord?: boolean;
    title?: string;
    withMultipleColumns?: boolean;
}

export type IHomeworkItemProps = {
    id: number;
    isShort?: boolean;
    isForPupils?: boolean;
    isOnCheck?: boolean;
    hasRecord?: boolean;
    lessonTitle?: string;
    lessonDescription?: string;
    taskId: number;
    subject: number;
    assignmentCount?: number;
    onHomeworkClick?: (options: { subjectId: number; lessonId: number; taskId: number; groupId?: number }) => void;
    assignedDate: string;

    description: string;
    deadlineForComplete?: string;
    deadline?: string;
    title: string;
    lesson: number;
    type: AssignmentType;
    courseGroup: {
        id: number;
        title: string;
    };
    status: AssignmentProgressStatus;
    completeDate?: string;
    extraInfo?: { IconElement?: ReactElement; label: string; underTheDate?: boolean };
    withAttempts?: boolean;
};
