import React from 'react';
import {EMAILS} from "../../common/constants";

export const RegFooter: React.FC = () => {
    const currentYear = new Date().getFullYear()

    return (
        <footer className="footer">
            <div className="container footer__container">
                <a className="footer__link" href={`mailto:${EMAILS.INFO}`}>
                    {EMAILS.INFO}
                </a>
                <small className="footer__copyright">
                    &copy; {currentYear}.{' '}
                    <a className="footer__copyright" href={'https://og1.ru/user_agreement'}>
                        Все права защищены
                    </a>{' '}
                    |{' '}
                    <a className="footer__copyright" href={'https://og1.ru/privacy-policy'}>
                        политика конфиденциальности
                    </a>
                </small>
                <a className="footer__link" href="tel:88001007854">
                    <strong>8 (800) 100-78-54</strong>
                </a>
            </div>
        </footer>
    );
};
