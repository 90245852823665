import React, {useMemo} from 'react';
import './CourseTimePicker.scss';
import { getDayTitle, getSortedDates } from './utils';
import {ITimePickerItem, TimePicker} from './TimePicker';

interface ICourseTimePickerProps {
    dateStart: string,
    datesAvailable: string[],
    handleTimeClick?: (value: ITimePickerItem) => void;
    selectedConsultationLesson?: ITimePickerItem;
    disabledElements?: ITimePickerItem[];
}

export const monthsRu = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];

export const CourseTimePicker: React.FC<ICourseTimePickerProps> = ({
    dateStart,
    datesAvailable,
    handleTimeClick,
    selectedConsultationLesson,
    disabledElements
}) => {
    const datesAvailableSorted = useMemo(() => getSortedDates(datesAvailable),[datesAvailable]);

    return (
        <div className="course-time-picker__main">
            {
                Object.keys(datesAvailableSorted).map((date) => (
                    <div className="course-time-picker__date-time" key={date}>
                        <p className="course-time-picker__date">{getDayTitle(datesAvailableSorted[date].day, datesAvailableSorted[date].weekday)}</p>
                        <div className="course-time-picker__time">
                            {
                                datesAvailableSorted[date].time.map((time) => (
                                    <TimePicker
                                        date={date}
                                        time={time}
                                        key={`${date} ${time.hours} ${time.minutes}`}
                                        handleTimeClick={handleTimeClick}
                                        selectedConsultationLesson={selectedConsultationLesson}
                                    />
                                ))
                            }
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export const CourseTimePickerTest: React.FC<ICourseTimePickerProps> = ({
    dateStart,
    datesAvailable,
}) => {
    return (
        <div className='test-container'>
            <CourseTimePicker 
                dateStart={dateStart}
                datesAvailable={datesAvailable}
            />
        </div>
    )
}