import React, { Dispatch, SetStateAction } from 'react';

import { CourseTimePicker } from '../../../../../components/CourseTimePicker';
import './TimePickerWrapper.scss';
import { Checkbox } from '../../../../../components/Checkbox';
import { ITimePickerItem } from '../../../../../components/CourseTimePicker/TimePicker';
import { OverlayTrigger } from 'react-bootstrap';
import { Tooltip } from '../../../../../components/Tooltip';

interface ITimePickerWrapperProps {
    dateStart: string;
    datesAvailable: string[];
    handleClassesTimeClick: (value: ITimePickerItem) => void;
    selectedConsultationLesson?: ITimePickerItem;
    needForConsultation: boolean;
    handleNeedForConsultation: Dispatch<SetStateAction<boolean>>;
    comments?: any[];
    preselectedCourseId?: number;
}

export const TimePickerWrapper: React.FC<ITimePickerWrapperProps> = ({
    handleClassesTimeClick,
    dateStart,
    datesAvailable,
    selectedConsultationLesson,
    handleNeedForConsultation,
    needForConsultation,
    comments,
    preselectedCourseId,
}) => {
    return (
        <div className="course-time-picker__wrapper">
            <p className="course-time-picker__header">Выберите время занятий</p>
            <p className="course-time-picker__title">
                {!comments?.length
                    ? `Доступные опции`
                    : `На консультации учитель определил - необходимое кол-во занятий ученику надо взять 5 занятий`}
            </p>
            {!comments?.length && !preselectedCourseId && (
                <div className="individual-course-time-picker__consultation-wrapper">
                    <div className="individual-course-time-picker__consultation">
                        <Checkbox checked={needForConsultation} onClick={handleNeedForConsultation} />
                        <p className="individual-course-time-picker__subheader">Необходимо 1 занятие-консультация</p>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <div className="individual-course-time-picker__tooltip">
                                    <Tooltip>
                                        {`После  этого занятия учитель  оценит уровень знаний и сможет сказать сколько всего уроков потребуется`}
                                    </Tooltip>
                                </div>
                            }
                        >
                            <div className="individual-course-time-picker__tooltip-trigger">?</div>
                        </OverlayTrigger>
                    </div>
                </div>
            )}
            <div className="individual-course-time-picker__classes-wrapper">
                {!comments?.length && !preselectedCourseId && (
                    <p className="individual-course-time-picker__subheader">Занятия</p>
                )}
                <CourseTimePicker
                    handleTimeClick={handleClassesTimeClick}
                    dateStart={dateStart}
                    datesAvailable={datesAvailable}
                    selectedConsultationLesson={selectedConsultationLesson}
                />
                {!!comments?.length && (
                    <div className="individual-course-time-picker__comments-wrapper">
                        <p className="course-time-picker__title">
                            *Время выбрано автоматически, вы можете это изменить
                        </p>
                        <div className="individual-course-time-picker__comments-content">
                            <p className="course-time-picker__header">Программа занятий</p>
                            {comments.map((comment) => {
                                return <p className="course-time-picker__title">{comment}</p>;
                            })}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
