import React, { useEffect, useMemo, useState } from 'react';

import './CoursesAttended.scss';
import { CourseCard } from '../../../components/CourseCard';
import { CoursePaymentStatus } from '../../../redux/types/courses';
import { useSelector } from 'react-redux';
import { State } from '../../../redux/store';
import { FetchStatus } from '../../../common/types';
import { IndividualCourses } from '../CoursesEnrollment/Individual';
import { Button } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';

export const CoursesAttended: React.FC = () => {
    const studentCourses = useSelector((state: State) => state.studentCourses);
    const studentsState = useSelector((state: State) => state.profile);
    const [preselectedCourse, setPreselectedCourse] = useState(null);
    const { additionalCourses, individualCourses } = useSelector((state: State) => state);
    const isStudentCoursesFetched = useMemo(() => {
        return (
            studentCourses.fetchStatus === FetchStatus.FETCHED &&
            additionalCourses.fetchStatus === FetchStatus.FETCHED &&
            individualCourses.fetchStatus === FetchStatus.FETCHED
        );
    }, [studentCourses.fetchStatus, additionalCourses.fetchStatus, individualCourses.fetchStatus]);

    const history = useHistory();

    const coursesAttendedParams = useMemo(() => {
        if (isStudentCoursesFetched) {
            return [
                {
                    title: 'Дополнительные',
                    cards: studentCourses.data
                        .filter((course) => course.type === 'additional')
                        .map((course) => ({
                            courseName: course.displayname,
                            teacherName: course.teacher,
                            lessonsLeft:
                                additionalCourses?.data?.find(
                                    (additionalCourse) => +additionalCourse.course_id === course.id,
                                )?.remaining_lessons || 0,
                            status: course.status_pay,
                        })),
                },
                {
                    title: 'Индивидуальное образование',
                    cards: studentCourses.data
                        .filter((course) => course.type === 'individual')
                        .map((course) => ({
                            courseName: course.displayname,
                            teacherName: course.teacher,
                            lessonsLeft:
                                individualCourses?.data?.find(
                                    (individualCourse) => +individualCourse.course_id === course.id,
                                )?.remaining_lessons || 0,
                            status: course.status_pay,
                            onClick: () => setPreselectedCourse(course.id),
                        })),
                },
                // {
                //     title: 'Факультативы',
                //     cards: studentCourses.data
                //         .filter((course) => course.type === 'elective')
                //         .map((course) => ({
                //             courseName: course.displayname,
                //             teacherName: course.teacher,
                //             lessonsLeft: 0,
                //             status: CoursePaymentStatus.PAID,
                //         })),
                // },
            ];
        } else return null;
    }, [studentCourses, isStudentCoursesFetched, additionalCourses.data, individualCourses.data]);

    useEffect(() => {
        setPreselectedCourse(null);
    }, [studentsState.selectedProfile.id]);

    return (
        <div className="courses-attended">
            {!preselectedCourse ? (
                coursesAttendedParams ? (
                    coursesAttendedParams.map((params) => (
                        <div key={params.title} className="courses-attended__row">
                            <div className="courses-attended__row-header-text">
                                {params.title}{' '}
                                {!params.cards.length && (
                                    <Button variant="primary" onClick={() => history.push('/courses')}>
                                        Записаться
                                    </Button>
                                )}
                            </div>
                            <div className="courses-attended__row-cards">
                                {params.cards.map((card, index) => (
                                    <CourseCard
                                        courseName={card.courseName}
                                        teacherName={card.teacherName}
                                        lessonsLeft={card.lessonsLeft}
                                        status={card.status}
                                        onClick={card.onClick}
                                        key={index}
                                    />
                                ))}
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="courses-attended__skeleton-container">
                        {Array(5)
                            .fill(0)
                            .map((_, index) => (
                                <Skeleton
                                    key={`Skeleton-${index}`}
                                    width={500}
                                    height={150}
                                    style={{ marginTop: 20 }}
                                />
                            ))}
                    </div>
                )
            ) : (
                <IndividualCourses preselectedCourseId={preselectedCourse} />
            )}
        </div>
    );
};
