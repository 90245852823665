import { combineEpics, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { FuncEpic } from '../../common/types';
import {
    LOAD_SHEDULE,
    loadSheduleSuccess,
    loadSheduleError,
    LOAD_SHORT_SHEDULE,
    loadShortSheduleSuccess,
    loadShortSheduleError,
    LOAD_LONG_SHEDULE,
    loadLongSheduleSuccess,
    loadLongSheduleError,
    loadLmsSchedule,
    loadLmsScheduleSuccess,
    loadLmsScheduleError,
    getHomeworksBetweenDatesStart,
    getHomeworksBetweenDatesSuccess,
    getHomeworksBetweenDatesError,
    loadLmsScheduleRange,
    loadLmsScheduleRangeSuccess,
    loadLmsScheduleRangeError,
} from '../actions/shedule';
import { FilterTypes, IHomeworkItem } from '../types/schedule';
import { ActionType } from 'typesafe-actions';

const scheduleEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(LOAD_SHEDULE),
        switchMap(({ date }) => {
            return from(deps.scheduleDataProvider.loadSchedule(store$.value.profile.selectedProfile.id, date)).pipe(
                switchMap((shedule) => {
                    return of(loadSheduleSuccess(shedule));
                }),
                catchError((err) => {
                    return of(loadSheduleError(err));
                }),
            );
        }),
    );
};

const shortScheduleEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(LOAD_SHORT_SHEDULE),
        switchMap(() => {
            return from(deps.scheduleDataProvider.loadShortSchedule(store$.value.profile.selectedProfile.id)).pipe(
                switchMap((shedule) => {
                    return of(loadShortSheduleSuccess(shedule));
                }),
                catchError((err) => {
                    return of(loadShortSheduleError(err));
                }),
            );
        }),
    );
};
const longScheduleEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(LOAD_LONG_SHEDULE),
        switchMap(() => {
            return from(deps.scheduleDataProvider.loadLongSchedule(store$.value.profile.selectedProfile.id)).pipe(
                switchMap((shedule) => {
                    return of(loadLongSheduleSuccess(shedule));
                }),
                catchError((err) => {
                    return of(loadLongSheduleError(err));
                }),
            );
        }),
    );
};

const loadLmsScheduleEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(loadLmsSchedule),
        switchMap(({ payload }: ActionType<typeof loadLmsSchedule>) => {
            return from(
                deps.scheduleDataProvider.loadLmsSchedule(payload).then((scheduleData) => ({
                    scheduleData,
                    date: payload?.query?.datetime_start,
                })),
            ).pipe(
                switchMap((schedule) => {
                    return of(loadLmsScheduleSuccess(schedule));
                }),
                catchError((err) => {
                    return of(loadLmsScheduleError(err));
                }),
            );
        }),
    );
};

const loadLmsScheduleRangeEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(loadLmsScheduleRange),
        switchMap(({ payload }: ActionType<typeof loadLmsScheduleRange>) => {
            return from(deps.scheduleDataProvider.loadLmsSchedule(payload)).pipe(
                switchMap((schedule) => {
                    return of(loadLmsScheduleRangeSuccess(schedule));
                }),
                catchError((err) => {
                    return of(loadLmsScheduleRangeError(err));
                }),
            );
        }),
    );
};

const getHomeworksBetweenDatesEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getHomeworksBetweenDatesStart),
        switchMap(({ payload }) => {
            return from(
                Promise.all(
                    payload.map((param) => {
                        const { filterType, dateStart, dateEnd, statusFilters, externalId } = param;

                        return deps.scheduleDataProvider.getHomeworksBetweenDates(
                            externalId,
                            filterType,
                            dateStart,
                            dateEnd,
                            statusFilters,
                        );
                    }),
                ).then((responses) => {
                    return responses.reduce((result, res, index) => {
                        result[payload[index].filterType] = res;

                        return result;
                    }, {} as Record<FilterTypes, IHomeworkItem[]>);
                }),
            ).pipe(
                switchMap((res) => {
                    return of(getHomeworksBetweenDatesSuccess(res));
                }),
                catchError((err) => {
                    return of(getHomeworksBetweenDatesError(err));
                }),
            );
        }),
    );
};

export const scheduleEpics = combineEpics(
    scheduleEpic,
    shortScheduleEpic,
    longScheduleEpic,
    loadLmsScheduleEpic,
    getHomeworksBetweenDatesEpic,
    loadLmsScheduleRangeEpic,
);
