import React from 'react';
import { cn } from '@bem-react/classname';

import './Tooltip.scss';

const CnTooltip = cn('custom-tooltip');

export enum TooltipViewEnum {
    comment = 'comment',
    devices = 'devices',
    detailed = 'detailed',
    semester = 'semester',
}

export interface MarkMetaData {
    date: string;
    type: string;
    weight: number;
}

interface ITooltipProps {
    view?: TooltipViewEnum;
    metaData?: MarkMetaData;
}

export const Tooltip: React.FC<ITooltipProps> = ({ children, view = TooltipViewEnum.comment }) => {
    return (
        <div className={CnTooltip({ view })}>
            <div className={CnTooltip('comment')}>{children}</div>
            <div className={CnTooltip('pointer')} />
        </div>
    );
};
