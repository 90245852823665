import { useAppSelector } from '../../redux/store';
import React, { useEffect, useState } from 'react';
import { cn } from '@bem-react/classname';
import { Loader } from '@lms-elements/atomic';
import './PageLoader.scss';

const CnPageLoader = cn('pageLoader');

export const PageLoader: React.FC<{ showLoading?: boolean; zIndex?: number }> = React.memo(
    ({ showLoading, zIndex }) => {
        const { isLoading } = useAppSelector((store) => store.loader);

        const [isShow, setIsShow] = useState(true);

        useEffect(() => {
            setIsShow(false);
        }, [isLoading, showLoading]);

        if (isShow || isLoading || showLoading) {
            return (
                <div className={CnPageLoader()} style={{ zIndex }}>
                    <Loader />
                </div>
            );
        }

        return null;
    },
);
