import { PageStatus } from 'common/types';

import { LOAD_MARKS_ERROR, LOAD_MARKS, LOAD_MARKS_SUCCESS, LOAD_LAST_MARKS_ERROR, LOAD_LAST_MARKS, LOAD_LAST_MARKS_SUCCESS  } from '../actions/marks';
import { Action } from '../store';
import { MarksState } from '../types/marks';

const defaultMarksState = {
    pageStatus: PageStatus.INITIAL,
    marks: null,
    error: null,
    lastMarks: null,
};

export default (state: MarksState = defaultMarksState, action: Action) => {
    switch(action.type) {
        case LOAD_LAST_MARKS: {
            return {
                ...state,
                pageStatus: PageStatus.LOADING,
            };
        }
        case LOAD_LAST_MARKS_SUCCESS: {
            return {
                ...state,
                pageStatus: PageStatus.LOADED,
                lastMarks: action.marks
            };
        }
        case LOAD_LAST_MARKS_ERROR: {
            return {
                ...state,
                pageStatus: PageStatus.ERROR,
                error: action.error
            };
        }
        case LOAD_MARKS: {
            return {
                ...state,
                pageStatus:  PageStatus.LOADING
            };
        }
        case LOAD_MARKS_SUCCESS: {
            return {
                ...state,
                pageStatus: PageStatus.LOADED,
                marks: action.marks
            };
        }
        case LOAD_MARKS_ERROR: {
            return {
                ...state,
                pageStatus: PageStatus.ERROR,
                error: action.error
            };
        }
        default: {
            return state
        }
    }
};
