import { TypedUseSelectorHook, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import { ApplicationEnrollmentDataProvider } from 'api/ApplicationEnrollmentDataProvider';
import { ContractsDataProvider } from 'api/ContractsDataProvider';
import { CoursesDataProvider } from 'api/CoursesDataProvider';
import { DirectorAnswerDataProvider } from 'api/DirectorAnswerDataProvider';
import { DocumentsDataProvider } from 'api/DocumentsDataProvider';
import { HomeDataProvider } from 'api/HomeDataProvider';
import { LoginDataProvider } from 'api/LoginDataProvider';
import { MarksDataProvider } from 'api/MarksDataProvider';
import { MeetingsDataProvider } from 'api/MeetingsDataProvider';
import { PaymentDataProvider } from 'api/PaymentDataProvider';
import { PenDocumentsDataProvider } from 'api/PenDocumentsDataProvider';
import { ProfileDataProvider } from 'api/ProfileDataProvider';
import { RatingDataProvider } from 'api/RatingDataProvider';
import { RegistrationDataProvider } from 'api/RegistrationDataProvider';
import { ResetPasswordDataProvider } from 'api/ResetPasswordDataProvider';
import { ShceduleDataProvider } from 'api/ScheduleDataProvider';
import { TasksDataProvider } from 'api/TasksDataProvider';
import { RouterAction, RouterState } from 'connected-react-router';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { combineEpics, createEpicMiddleware, EpicMiddleware } from 'redux-observable';
import { persistStore } from 'redux-persist';

import { AlertActions } from './actions/alert';
import { ApplicationEnrollmentActions } from './actions/applicationEnrollment';
import { ContractsActions } from './actions/contracts';
import { CoursesActions } from './actions/courses';
import { DirectorAnswerActions } from './actions/directorAnswer';
import { DocumentsActions } from './actions/documents';
import { LoginActions } from './actions/login';
import { MarksActions } from './actions/marks';
import { MeetingsActions } from './actions/meetings';
import { NearestEventActions } from './actions/near';
import { PaymentActions } from './actions/payment';
import { PaymentInfoActions } from './actions/paymentInfo';
import { PenDocumentsActions } from './actions/penDocument';
import { ProfileActions } from './actions/profile';
import { RatingActions } from './actions/rating';
import { RegistrationActions } from './actions/registration';
import { ChangePasswordActions, SendResetEmailActions } from './actions/resetPassword';
import { SheduleActions } from './actions/shedule';
import { TasksActions } from './actions/tasks';
import { TrendsActions } from './actions/trends';
import { UserActions } from './actions/user';
import { applicationEnrollmentEpics } from './epics/applicationEnrollment';
import { contractsEpics } from './epics/contracts';
import { coursesEpics } from './epics/courses';
import { directorAnswerEpic } from './epics/directorAnswer';
import { documentsEpics } from './epics/documents';
import { loginEpics } from './epics/login';
import { marksEpics } from './epics/marks';
import { meetingsEpics } from './epics/meetings';
import { nearEpics } from './epics/near';
import { paymentEpics } from './epics/payment';
import { paymentInfoEpics } from './epics/paymentInfo';
import { penDocumentsEpic } from './epics/penDocument';
import { profileEpics } from './epics/profile';
import { ratingEpics } from './epics/rating';
import { registrationEpics } from './epics/registration';
import { resetPassword } from './epics/resetPassword';
import { scheduleEpics } from './epics/schedule';
import { tasksEpics } from './epics/tasks';
import { trendsEpics } from './epics/trends';
import { userEpics } from './epics/user';
import { authMiddleware } from './middleware/auth';
import { AlertState } from './types/alert';
import { ApplicationFormState } from './types/applicationEnrollment';
import { ContractsState } from './types/contracts';
import {
    BookCourseScheduleState,
    CourseGroupsState,
    CourseScheduleState,
    CoursesState,
    StudentCoursesState,
} from './types/courses';
import { DirectorAnswerState } from './types/directorAnswer';
import { DocumentsState } from './types/documents';
import { LoginState } from './types/login';
import { LongSheduleState } from './types/longShedule';
import { MarksState } from './types/marks';
import { MeetingsState } from './types/meetings';
import { NearState } from './types/near';
import { PaymentState } from './types/payment';
import { PaymentInfoState } from './types/paymentInfo';
import { PenDocumentState } from './types/penDocument';
import { ProfileState } from './types/profile';
import { RatingState } from './types/rating';
import { RegistrationState, RegistrationStudentState, StudentInfoState, UserInfoState } from './types/registration';
import { ResetPasswordState } from './types/resetPassword';
import { ScheduleState } from './types/schedule';
import { SelectedProfileState } from './types/selectedProfile';
import { ShortScheduleState } from './types/shortSchedule';
import { TasksState } from './types/tasks';
import { TrendsState } from './types/trends';
import { UserState } from './types/user';
import rootReducer from './reducers';
import { ConstantsState } from './types/constants';
import { ConstantsDataProvider } from '../api/ConstantsDataProvider';
import { ConstantsActions } from './actions/constants';
import { constantsEpics } from './epics/constants';
import { ProcessesActions } from './actions/processes';
import { ProcessesState } from './types/processes';
import { ProcessesDataProvider } from '../api/ProcessesDataProvider';
import { processesEpics } from './epics/processes';
import { UsersDataProvider } from '../api/UsersDataProvider';
import { UsersActions } from './actions/users';
import { usersEpics } from './epics/users';
import { UsersListState } from './types/users';
import { AnceteActions } from './actions/ancete';
import { AnceteState } from './types/ancete';
import { AnceteDataProvider } from '../api/AnceteDataProvider';
import { anceteEpics } from './epics/ancete';
import { LoaderActions } from './actions/loader';
import { LoaderState } from './types/loader';
import { NotificationActions } from './actions/notification';
import { NotificationState } from './types/notification';
import { HomeworkActions } from './actions/homework';
import { HomeworkState } from './types/homework';
import { homeworkEpics } from './epics/homework';
import { MetaActions } from "./actions/meta";
import { MetaState } from './types/meta';
import { MetaDataProvider } from "../api/MetaDataProvider";
import { metaEpics } from "./epics/meta";

//export const host = "http://localhost";
export const host = process.env.REACT_APP_HOST;

export const apiHost = `${host}/api`;
export const apiSSOHost = process.env.REACT_APP_SSO_HOST;

export type Action =
    | DocumentsActions
    | MarksActions
    | NearestEventActions
    | ProfileActions
    | SheduleActions
    | TasksActions
    | TrendsActions
    | UserActions
    | RouterAction
    | MeetingsActions
    | RatingActions
    | PaymentActions
    | PaymentInfoActions
    | AlertActions
    | LoginActions
    | RegistrationActions
    | ContractsActions
    | SendResetEmailActions
    | ChangePasswordActions
    | CoursesActions
    | ApplicationEnrollmentActions
    | DirectorAnswerActions
    | PenDocumentsActions
    | ConstantsActions
    | ProcessesActions
    | UsersActions
    | AnceteActions
    | LoaderActions
    | NotificationActions
    | HomeworkActions
    | MetaActions;

export interface State {
    router: RouterState;
    login: LoginState;
    registration: RegistrationState;
    userInfo: UserInfoState;
    studentInfo: StudentInfoState;
    registrationStudent: RegistrationStudentState;
    documents: DocumentsState;
    longSchedule: LongSheduleState;
    marks: MarksState;
    near: NearState;
    contracts: ContractsState;
    profile: ProfileState;
    schedule: ScheduleState;
    selectedProfile: SelectedProfileState;
    shortSchedule: ShortScheduleState;
    tasks: TasksState;
    trends: TrendsState;
    user: UserState;
    users: UsersListState;
    meetings: MeetingsState;
    rating: RatingState;
    payment: PaymentState;
    paymentInfo: PaymentInfoState;
    alert: AlertState;
    resetPassword: ResetPasswordState;
    courseGroups: CourseGroupsState;
    additionalCourses: CoursesState;
    individualCourses: CoursesState;
    courseSchedule: CourseScheduleState;
    bookCourseSchedule: BookCourseScheduleState;
    applicationForm: ApplicationFormState;
    directorAnswer: DirectorAnswerState;
    studentCourses: StudentCoursesState;
    penDocument: PenDocumentState;
    constants: ConstantsState;
    processes: ProcessesState;
    ancete: AnceteState;
    loader: LoaderState;
    notification: NotificationState;
    homework: HomeworkState;
    meta: MetaState;
}

export interface EpicDeps {
    homeDataProvider: HomeDataProvider;
    directorAnswerProvider: DirectorAnswerDataProvider;
    profileDataProvider: ProfileDataProvider;
    tasksDataProvider: TasksDataProvider;
    marksDataProvider: MarksDataProvider;
    scheduleDataProvider: ShceduleDataProvider;
    documentsDataProvider: DocumentsDataProvider;
    contractsDataProvider: ContractsDataProvider;
    meetingsDataProvider: MeetingsDataProvider;
    ratingDataProvider: RatingDataProvider;
    resetPasswordDataProvider: ResetPasswordDataProvider;
    paymentDataProvider: PaymentDataProvider;
    loginDataProvider: LoginDataProvider;
    registrationDataProvider: RegistrationDataProvider;
    coursesDataProvider: CoursesDataProvider;
    applicationEnrollmentDataProvider: ApplicationEnrollmentDataProvider;
    penDocumentsDataProvider: PenDocumentsDataProvider;
    constantsDataProvider: ConstantsDataProvider;
    processesDataProvider: ProcessesDataProvider;
    usersDataProvider: UsersDataProvider;
    anceteDataProvider: AnceteDataProvider;
    metaDataProvider: MetaDataProvider;
}

const createMiddleware = (epicMiddleware: EpicMiddleware<Action, Action, State, EpicDeps>) =>
    applyMiddleware(epicMiddleware, authMiddleware);

const composeEnhancers = composeWithDevTools({ serialize: true });

const epicMiddleware = createEpicMiddleware<Action, Action, State, EpicDeps>({
    dependencies: {
        homeDataProvider: new HomeDataProvider(apiHost),
        profileDataProvider: new ProfileDataProvider(apiHost),
        tasksDataProvider: new TasksDataProvider(apiHost),
        marksDataProvider: new MarksDataProvider(apiHost),
        scheduleDataProvider: new ShceduleDataProvider(apiHost),
        contractsDataProvider: new ContractsDataProvider(host),
        documentsDataProvider: new DocumentsDataProvider(apiHost),
        meetingsDataProvider: new MeetingsDataProvider(apiHost),
        ratingDataProvider: new RatingDataProvider(apiHost),
        resetPasswordDataProvider: new ResetPasswordDataProvider(apiHost),
        paymentDataProvider: new PaymentDataProvider(host),
        loginDataProvider: new LoginDataProvider(apiSSOHost),
        registrationDataProvider: new RegistrationDataProvider(host),
        coursesDataProvider: new CoursesDataProvider(host),
        applicationEnrollmentDataProvider: new ApplicationEnrollmentDataProvider(host),
        directorAnswerProvider: new DirectorAnswerDataProvider(apiHost),
        penDocumentsDataProvider: new PenDocumentsDataProvider(apiHost),
        constantsDataProvider: new ConstantsDataProvider(host),
        processesDataProvider: new ProcessesDataProvider(apiHost),
        usersDataProvider: new UsersDataProvider(host),
        anceteDataProvider: new AnceteDataProvider(host),
        metaDataProvider: new MetaDataProvider(host),
    },
});

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

export const store = createStore<unknown, Action, {}, {}>(
    rootReducer,
    composeEnhancers(createMiddleware(epicMiddleware), sentryReduxEnhancer),
);
export const persistor = persistStore(store);

epicMiddleware.run(
    combineEpics(
        contractsEpics,
        constantsEpics,
        profileEpics,
        loginEpics,
        coursesEpics,
        applicationEnrollmentEpics,
        registrationEpics,
        tasksEpics,
        scheduleEpics,
        nearEpics,
        marksEpics,
        trendsEpics,
        documentsEpics,
        userEpics,
        meetingsEpics,
        ratingEpics,
        paymentEpics,
        paymentInfoEpics,
        resetPassword,
        directorAnswerEpic,
        penDocumentsEpic,
        processesEpics,
        usersEpics,
        anceteEpics,
        homeworkEpics,
        metaEpics
    ),
);

export const useAppSelector: TypedUseSelectorHook<State> = useSelector;
