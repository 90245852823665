import React from 'react';

export const TextArea: React.FC<{
    label?: string;
    className?: string;
    onChange?: (e: any) => void;
    ariaDescribedby?: string;
    id?: string;
    placeholder?: string;
    icon?: any;
    dataDate?: string;
    disabledgulp?: string;
    wrapperClassName?: string;
    value?: string;
    defaultValue?: string;
}> = ({
    label,
    className = '',
    onChange,
    ariaDescribedby,
    id,
    placeholder,
    icon,
    dataDate,
    wrapperClassName = '',
    value = '',
    defaultValue,
}) => {
    return (
        <div className={`form-group ${wrapperClassName}`}>
            <textarea
                defaultValue={defaultValue}
                value={value}
                data-date={dataDate}
                className={`input form-control ${className}`}
                id={id}
                aria-describedby={ariaDescribedby}
                placeholder={placeholder}
                onChange={onChange}
            />
            <label htmlFor={id}>{label}</label>
            {icon}
        </div>
    );
};
