import React from 'react';
import { cn } from '@bem-react/classname';
import { Button, ButtonViewEnum, Logo } from '@lms-elements/atomic';

import { Popup } from 'components/Atomic';
import { MobileButton } from 'components/Navbar/MobileButton';
import { NavbarContent } from 'components/Navbar/NavbarContent';
import { NavBarStudent } from 'components/Navbar/NavBarStudent';

import './NavBarMobile.scss';
import { INavBarMobileProps } from './NavBarMobile.types';
import Skeleton from "react-loading-skeleton";

const CnNavBarMobile = cn('nav-bar-mobile');

export const NavBarMobile: React.FC<INavBarMobileProps> = (props) => {
    const {
        student,
        isMobile,
        currentPath,
        navbarItems,
        tooltipItems,
        isOpenedPopup,
        isMobileOpened,
        toggledStudents,
        onLetterSend,
        handleLogoClick,
        handlePopupClose,
        handleBurgerClick,
        handleStudentClick,
        handleSettingClick,
        handleTooltipClick,
        handlePrincipalClick,
        handleNavBarItemClick,
    } = props;
    const { id, firstName, lastName, photoUrl, grade } = student || {};

    const isShowSkeleton = !!navbarItems?.length;
    return (
        <div className={CnNavBarMobile()}>
            <div className={CnNavBarMobile('container')}>
                <div className={CnNavBarMobile('header')}>
                    <div className={'logo'} onClick={handleLogoClick}>
                        <Logo small={true} />
                    </div>
                    <div className={CnNavBarMobile('student')}>
                        {student && (
                            <NavBarStudent
                                id={id}
                                firstName={firstName}
                                lastName={lastName}
                                photoUrl={photoUrl}
                                grade={grade}
                                isInHeader
                            />
                        )}
                    </div>
                    <div className={CnNavBarMobile('burger')} onClick={handleBurgerClick}>
                        <div className={CnNavBarMobile('burger-item', { isLeft: isMobileOpened })} />
                        <div className={CnNavBarMobile('burger-item', { isRight: isMobileOpened })} />
                        {!isMobileOpened && <div className={CnNavBarMobile('burger-item')} />}
                    </div>
                </div>
                {isMobileOpened && (
                    <div className={CnNavBarMobile('content')}>
                        <div className={CnNavBarMobile('icons-container')}>
                            {tooltipItems?.map((item, index) => (
                                <div className={CnNavBarMobile('icon')} key={index} onClick={handleTooltipClick}>
                                    <MobileButton {...item} />
                                </div>
                            ))}
                        </div>
                        <NavbarContent
                            isShowSkeleton={isShowSkeleton}
                            students={toggledStudents}
                            navbarItems={navbarItems}
                            currentPath={currentPath}
                            isMobile={isMobile}
                            onNavbarItemClick={handleNavBarItemClick}
                            onStudentClick={handleStudentClick}
                            onSettingClick={handleSettingClick}
                        />
                        <div className={CnNavBarMobile('principal-button')}>
                            {isShowSkeleton ? (
                                <Skeleton height={40} width={180} />
                            ) : (
                                <Button view={ButtonViewEnum.bordered} size="l" onClick={handlePrincipalClick}>
                                    {`Написать директору`}
                                </Button>
                            )}
                        </div>
                    </div>
                )}
            </div>
            {isOpenedPopup && <Popup isMobile={isMobile} onCloseClick={handlePopupClose} onChangeSend={onLetterSend} />}
        </div>
    );
};
