import { ActionType, createAction } from 'typesafe-actions';

export const loadConstantsStart = createAction('@constants/load/start')();
export const loadConstantsSuccess = createAction('@constants/load/success')<any>();
export const loadConstantsError = createAction('@constants/load/error')<any>();

export type ConstantsActions =
    | ActionType<typeof loadConstantsStart>
    | ActionType<typeof loadConstantsSuccess>
    | ActionType<typeof loadConstantsError>;
