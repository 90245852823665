import axios, { AxiosResponse } from "axios";

import { IDirectorAnswer } from "components/DirectorModal/DirectorModal.types";

import { BaseRestDataProvider } from "./BaseRestDataProvider";

export class DirectorAnswerDataProvider extends BaseRestDataProvider {
    postDirectorMessage = (value: IDirectorAnswer): Promise<never> => {
        return axios
            .post<IDirectorAnswer, AxiosResponse<never>>(`${this.host}/mail_director/`, 
            {
                question_topic: value.theme,
                question: value.message
            })
            .then((res) => res?.data)
            .catch((res: { status: string }) => {
                throw res?.status;
            });
    }
}
