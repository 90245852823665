import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { combineReducers } from 'redux';
import { alert } from './alert';
import { applicationForm } from './applicationEnrollment';
import { directorAnswerReducer as directorAnswer } from './directorAnswer';
import documents from './documents';
import { login } from './login';
import { contractsReducer as contracts } from './contracts';
import { registration, userInfo, registrationStudent, studentInfo } from './registration';
import {
    additionalCourses,
    courseSchedule,
    bookCourseSchedule,
    individualCourses,
    studentCourses,
    courseGroups,
} from './courses';
import { constants } from './constants';
import longSchedule from './longSchedule';
import marks from './marks';
import meetings from './meetings';
import near from './near';
import { payment } from './payment';
import { paymentInfo } from './paymentInfo';
import { penDocumentReducer as penDocument } from './penDocument';
import profile from './profile';
import rating from './rating';
import { resetPassword } from './resetPassword';
import schedule from './schedule';
import selectedProfile from './selectedProfile';
import shortSchedule from './shortSchedule';
import tasks from './tasks';
import trends from './trends';
import user from './user';
import { users } from './users';
import processes from './processes';
import { ancete } from './ancete';
import { loader } from './loader';
import notification from './notification';
import { homework } from './homework';
import { meta } from './meta';

export const history = createBrowserHistory();

export default combineReducers({
    router: connectRouter(history),
    profile,
    tasks,
    schedule,
    contracts,
    constants,
    near,
    selectedProfile,
    marks,
    trends,
    documents,
    shortSchedule,
    longSchedule,
    user,
    users,
    meetings,
    login,
    registration,
    registrationStudent,
    userInfo,
    studentInfo,
    courseGroups,
    additionalCourses,
    individualCourses,
    studentCourses,
    courseSchedule,
    bookCourseSchedule,
    applicationForm,
    rating,
    payment,
    paymentInfo,
    resetPassword,
    alert,
    directorAnswer,
    penDocument,
    processes,
    ancete,
    loader,
    notification,
    homework,
    meta
});
