import React, { useCallback, useState } from 'react';
import { Field, Form, FormProps, FormSpy } from 'react-final-form';
import { cn } from '@bem-react/classname';
import { Trash } from '@lms-elements/icons';

import { Button, ButtonViewEnum } from '../Button';
import { TextareaField } from '../TextareaField';

import { INoteActionsProps } from './NoteActions.types';

import './NoteActions.scss';

const CnNoteActions = cn('note-actions');

export const NoteActions: React.FC<INoteActionsProps> = ({
    title = 'Создать заметку',
    isEdit = false,
    isLetter = false,
    onChangeEdit,
    onChangeSave,
    onChangeSend,
    onChangeDelete,
    onClickCancel,
    initialValue,
    name,
    placeholder,
    titlePlaceholder,
    id,
    titleName,
}) => {
    const [isDisable, setIsDisable] = useState(true);
    const validate = useCallback((value: string) => (value ? undefined : 'error'), []);
    const handleFormSubmit = useCallback(
        (values: FormProps) => {
            if (values.action === 'edit' && onChangeEdit) {
                onChangeEdit(values.note, id);
                onClickCancel();
                return;
            }
            if (values.action === 'save' && onChangeSave) {
                onChangeSave(values.note);
                onClickCancel();
                return;
            }
            if (values.action === 'send' && onChangeSend) {
                onChangeSend(values.title, values.select);
                onClickCancel();
                return;
            }
            if (values.action === 'delete' && onChangeDelete) {
                onChangeDelete(id);
                onClickCancel();
            }
        },
        [onChangeEdit, onChangeSave, onChangeSend, onChangeDelete, id, onClickCancel],
    );

    return (
        <div className={CnNoteActions()}>
            <div className={CnNoteActions('title', { isLetter: isLetter })}>{title}</div>
            <Form onSubmit={handleFormSubmit} initialValues={initialValue}>
                {({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit}>
                        <FormSpy
                            onChange={(props) => {
                                const values = Object.entries(props.values)
                                    .filter(entries => entries[0] === 'title' || entries[0] === 'select')
                                    .map(entries => entries[1])

                                if (values.length === 2 ? values.every(value => !!value) : false) {
                                    return setIsDisable(false);
                                }
                                setIsDisable(true)
                            }}
                        />
                        {isLetter && (
                            <div className={CnNoteActions('text-title')}>
                                <Field
                                    className={CnNoteActions('text-input')}
                                    name={titleName ? titleName : ''}
                                    component="input"
                                    validators={[validate]}
                                    placeholder={titlePlaceholder}
                                />
                            </div>
                        )}
                        <TextareaField isLetter isField name={name} placeholder={placeholder} validators={[validate]} />

                        <div className={CnNoteActions('buttons', { isLetter: isLetter })}>
                            {isEdit ? (
                                <div className={CnNoteActions('editButtons')}>
                                    <div
                                        className={CnNoteActions('edit-button')}
                                        onClick={() => form.change('action', 'edit')}
                                    >
                                        <Button
                                            type="submit"
                                            view={ButtonViewEnum.action}
                                            size="l"
                                            customClasses={CnNoteActions('edit')}
                                            disabled={isDisable}
                                        >
                                            Редактировать
                                        </Button>
                                    </div>
                                    <div className={CnNoteActions('cancel-button')}>
                                        <Button
                                            view={ButtonViewEnum.bordered}
                                            size="l"
                                            customClasses={CnNoteActions('cancel')}
                                            onClick={onClickCancel}
                                        >
                                            Отмена
                                        </Button>
                                    </div>
                                    <div
                                        className={CnNoteActions('delete-button')}
                                        onClick={() => form.change('action', 'delete')}
                                    >
                                        <Button
                                            type="submit"
                                            view={ButtonViewEnum.bordered}
                                            size="l"
                                            icon={<Trash />}
                                            customClasses={CnNoteActions('delete')}
                                        ></Button>
                                    </div>
                                </div>
                            ) : (
                                <div className={CnNoteActions('saveButtons', { isLetter: isLetter })}>
                                    {isLetter ? (
                                        <div
                                            className={CnNoteActions('send-button')}
                                            onClick={() => form.change('action', 'send')}
                                        >
                                            <Button
                                                type="submit"
                                                view={ButtonViewEnum.action}
                                                size="l"
                                                customClasses={CnNoteActions('send')}
                                                disabled={isDisable}
                                            >
                                                Отправить
                                            </Button>
                                        </div>
                                    ) : (
                                        <div
                                            className={CnNoteActions('save-button')}
                                            onClick={() => form.change('action', 'save')}
                                        >
                                            <Button
                                                type="submit"
                                                view={ButtonViewEnum.action}
                                                size="l"
                                                customClasses={CnNoteActions('save')}
                                            >
                                                Сохранить
                                            </Button>
                                        </div>
                                    )}
                                    <div>
                                        <Button
                                            view={ButtonViewEnum.bordered}
                                            size="l"
                                            customClasses={CnNoteActions('cancelCreate')}
                                            onClick={onClickCancel}
                                        >
                                            Отмена
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </form>
                )}
            </Form>
        </div>
    );
};
