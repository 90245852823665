import { ActionType, createAction } from 'typesafe-actions';
import { IEnrollmentCertificateRequestPayload, IFetchGymnasiumFromManagerPayload } from '../types/documents';

export const LOAD_DOCUMENTS = '@documents/LOAD_DOCUMENTS';
export const LOAD_DOCUMENTS_SUCCESS = '@document/LOAD_DOCUMENTS_SUCCESS';
export const LOAD_DOCUMENTS_ERROR = '@documents/LOAD_DOCUMENTS_ERROR';

export type LOAD_DOCUMENTS = typeof LOAD_DOCUMENTS;
export type LOAD_DOCUMENTS_SUCCESS = typeof LOAD_DOCUMENTS_SUCCESS;
export type LOAD_DOCUMENTS_ERROR = typeof LOAD_DOCUMENTS_ERROR;

export interface LoadDocumentsAction {
    type: LOAD_DOCUMENTS;
}

export const loadDocuments = (): LoadDocumentsAction => ({
    type: LOAD_DOCUMENTS,
});

export interface LoadDocumentsSuccessAction {
    type: LOAD_DOCUMENTS_SUCCESS;
    lastUploads: Array<any>;
    gymnasium: Array<any>;
    ownDocuments: Array<any>;
    typesDocuments: Array<any>;
    parentDocuments: Array<any>;
    gymnasiumFromManager: Array<any>;
}

export const loadDocumentsSuccess = ({
    lastUploads,
    gymnasium,
    ownDocuments,
    typesDocuments,
    parentDocuments,
    gymnasiumFromManager,
}: any): LoadDocumentsSuccessAction => ({
    type: LOAD_DOCUMENTS_SUCCESS,
    lastUploads,
    gymnasium,
    ownDocuments,
    typesDocuments,
    parentDocuments,
    gymnasiumFromManager,
});

export interface LoadDocumentsErrorAction {
    type: LOAD_DOCUMENTS_ERROR;
    error: any;
}

export const loadDocumentsError = (error: any): LoadDocumentsErrorAction => ({
    type: LOAD_DOCUMENTS_ERROR,
    error,
});

export const LOAD_DOCUMENTS_TYPES = '@documents/LOAD_DOCUMENTS_TYPES';
export const LOAD_DOCUMENTS_TYPES_SUCCESS = '@document/LOAD_DOCUMENTS_TYPES_SUCCESS';
export const LOAD_DOCUMENTS_TYPES_ERROR = '@documents/LOAD_DOCUMENTS_TYPES_ERROR';

export type LOAD_DOCUMENTS_TYPES = typeof LOAD_DOCUMENTS_TYPES;
export type LOAD_DOCUMENTS_TYPES_SUCCESS = typeof LOAD_DOCUMENTS_TYPES_SUCCESS;
export type LOAD_DOCUMENTS_TYPES_ERROR = typeof LOAD_DOCUMENTS_TYPES_ERROR;

export interface LoadDocumentsTypesAction {
    type: LOAD_DOCUMENTS_TYPES;
}

export const loadDocumentsTypes = (): LoadDocumentsTypesAction => ({
    type: LOAD_DOCUMENTS_TYPES,
});

export interface LoadDocumentsTypesSuccessAction {
    type: LOAD_DOCUMENTS_TYPES_SUCCESS;
    typesDocuments: Array<any>;
}

export const loadDocumentsTypesSuccess = ({ typesDocuments }: any): LoadDocumentsTypesSuccessAction => ({
    type: LOAD_DOCUMENTS_TYPES_SUCCESS,
    typesDocuments,
});

export interface LoadDocumentsTypesErrorAction {
    type: LOAD_DOCUMENTS_TYPES_ERROR;
    error: any;
}

export const loadDocumentsTypesError = (error: any): LoadDocumentsTypesErrorAction => ({
    type: LOAD_DOCUMENTS_TYPES_ERROR,
    error,
});

export const LOAD_PARENT_DOCUMENTS_TYPES = '@documents/LOAD_PARENT_DOCUMENTS_TYPES';
export const LOAD_PARENT_DOCUMENTS_TYPES_SUCCESS = '@document/LOAD_PARENT_DOCUMENTS_TYPES_SUCCESS';
export const LOAD_PARENT_DOCUMENTS_TYPES_ERROR = '@documents/LOAD_PARENT_DOCUMENTS_TYPES_ERROR';

export type LOAD_PARENT_DOCUMENTS_TYPES = typeof LOAD_PARENT_DOCUMENTS_TYPES;
export type LOAD_PARENT_DOCUMENTS_TYPES_SUCCESS = typeof LOAD_PARENT_DOCUMENTS_TYPES_SUCCESS;
export type LOAD_PARENT_DOCUMENTS_TYPES_ERROR = typeof LOAD_PARENT_DOCUMENTS_TYPES_ERROR;

export interface LoadParentDocumentsTypesAction {
    type: LOAD_PARENT_DOCUMENTS_TYPES;
}

export const loadParentDocumentsTypes = (): LoadParentDocumentsTypesAction => ({
    type: LOAD_PARENT_DOCUMENTS_TYPES,
});

export interface LoadParentDocumentsTypesSuccessAction {
    type: LOAD_PARENT_DOCUMENTS_TYPES_SUCCESS;
    typesDocuments: Array<any>;
}

export const loadParentDocumentsTypesSuccess = ({ typesDocuments }: any): LoadParentDocumentsTypesSuccessAction => ({
    type: LOAD_PARENT_DOCUMENTS_TYPES_SUCCESS,
    typesDocuments,
});

export interface LoadParentDocumentsTypesErrorAction {
    type: LOAD_PARENT_DOCUMENTS_TYPES_ERROR;
    error: any;
}

export const loadParentDocumentsTypesError = (error: any): LoadParentDocumentsTypesErrorAction => ({
    type: LOAD_PARENT_DOCUMENTS_TYPES_ERROR,
    error,
});

export const UPLOAD_DOCUMENTS = '@documents/UPLOAD_DOCUMENTS';
export const UPLOAD_DOCUMENTS_SUCCESS = '@document/UPLOAD_DOCUMENTS_SUCCESS';
export const UPLOAD_DOCUMENTS_ERROR = '@documents/UPLOAD_DOCUMENTS_ERROR';

export type UPLOAD_DOCUMENTS = typeof UPLOAD_DOCUMENTS;
export type UPLOAD_DOCUMENTS_SUCCESS = typeof UPLOAD_DOCUMENTS_SUCCESS;
export type UPLOAD_DOCUMENTS_ERROR = typeof UPLOAD_DOCUMENTS_ERROR;

export interface UploadDocumentsAction {
    type: UPLOAD_DOCUMENTS;
    file: any;
    upload_doc_type: number;
    forParent: boolean;
}

export const uploadDocuments = (file: any, upload_doc_type: number, forParent: boolean) => ({
    type: UPLOAD_DOCUMENTS,
    file,
    upload_doc_type,
    forParent
});

export interface UploadDocumentsSuccessAction {
    type: UPLOAD_DOCUMENTS_SUCCESS;
}

export const uploadDocumentsSuccess = () => ({
    type: UPLOAD_DOCUMENTS_SUCCESS,
});

export interface UploadDocumentsErrorAction {
    type: UPLOAD_DOCUMENTS_ERROR;
    error: any;
}
export const uploadDocumentsError = (error: any): UploadDocumentsErrorAction => ({
    type: UPLOAD_DOCUMENTS_ERROR,
    error,
});

export const DELETE_DOCUMENT = '@documents/DELETE_DOCUMENT';
export const DELETE_DOCUMENT_SUCCESS = '@documents/DELETE_DOCUMENT_SUCCESS';
export const DELETE_DOCUMENT_ERROR = '@documents/DELETE_DOCUMENT_ERROR';

export type DELETE_DOCUMENT = typeof DELETE_DOCUMENT;
export type DELETE_DOCUMENT_SUCCESS = typeof DELETE_DOCUMENT_SUCCESS;
export type DELETE_DOCUMENT_ERROR = typeof DELETE_DOCUMENT_ERROR;

export interface DeleteDocumentAction {
    type: DELETE_DOCUMENT;
    id: string;
}

export interface DeleteDocumentSuccessAction {
    type: DELETE_DOCUMENT_SUCCESS;
}

export interface DeleteDocumentErrorAction {
    type: DELETE_DOCUMENT_ERROR;
    error: any;
}

export const deleteDocument = (id: string): DeleteDocumentAction => ({
    type: DELETE_DOCUMENT,
    id,
});

export const deleteDocumentSuccess = (): DeleteDocumentSuccessAction => ({
    type: DELETE_DOCUMENT_SUCCESS,
});

export const deleteDocumentError = (error: any): DeleteDocumentErrorAction => ({
    type: DELETE_DOCUMENT_ERROR,
    error,
});

export const enrollmentCertificateRequestStart = createAction(
    '@enrollmentCertificate/request/start',
)<IEnrollmentCertificateRequestPayload>();
export const enrollmentCertificateRequestSuccess = createAction('@enrollmentCertificate/request/success')<any>();
export const enrollmentCertificateRequestError = createAction('@enrollmentCertificate/request/error')<any>();

export const fetchGymnasiumFromManagerStart = createAction(
    '@gymnasiumFromManager/fetch/start',
)<IFetchGymnasiumFromManagerPayload>();
export const fetchGymnasiumFromManagerSuccess = createAction('@gymnasiumFromManager/fetch/success')<any>();
export const fetchGymnasiumFromManagerError = createAction('@gymnasiumFromManager/fetch/error')<any>();

export type DocumentsActions =
    | DeleteDocumentAction
    | DeleteDocumentErrorAction
    | DeleteDocumentSuccessAction
    | UploadDocumentsAction
    | UploadDocumentsErrorAction
    | UploadDocumentsSuccessAction
    | LoadDocumentsAction
    | LoadDocumentsErrorAction
    | LoadDocumentsSuccessAction
    | LoadDocumentsTypesAction
    | LoadDocumentsTypesSuccessAction
    | LoadDocumentsTypesErrorAction
    | LoadParentDocumentsTypesSuccessAction
    | LoadParentDocumentsTypesAction
    | LoadParentDocumentsTypesErrorAction
    | ActionType<typeof enrollmentCertificateRequestStart>
    | ActionType<typeof enrollmentCertificateRequestSuccess>
    | ActionType<typeof enrollmentCertificateRequestError>
    | ActionType<typeof fetchGymnasiumFromManagerStart>
    | ActionType<typeof fetchGymnasiumFromManagerSuccess>
    | ActionType<typeof fetchGymnasiumFromManagerError>;
