import React, { useCallback, useEffect, useMemo, useState } from 'react';
import '../CoursesEnrollment.scss';
import './Additional.scss';
import { CustomSelect } from '../../../../components/General/CustomSelect/Select';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FetchStatus } from '../../../../common/types';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../../redux/store';
import {
    bookCourseScheduleInitial,
    bookCourseScheduleStart,
    loadCourseScheduleStart,
} from '../../../../redux/actions/courses';
import Skeleton from 'react-loading-skeleton';
import { getScheduleText } from '../../../../helpers/courses';
import { CourseScheduleStatus } from '../../../../redux/types/courses';
import { Toggle } from '../../../../components/Toggle/Toggle';
import { useRedirect } from 'hooks/useRedirect';
import { CourseSchedule } from '../../../../components/CourseSchedule';
import { BookCourseError } from '../BookCourseError';

export const AdditionalCourses: React.FC = () => {
    const [scheduleError, setScheduleError] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const history = useHistory();
    const dispatch = useDispatch();
    const studentsState = useSelector((state: State) => state.profile);
    const coursesState = useSelector((state: State) => state.additionalCourses);
    const bookCourseScheduleState = useSelector((state: State) => state.bookCourseSchedule);
    const courseScheduleState = useSelector((state: State) => state.courseSchedule);
    const [selectedCourseName, setSelectedCourseName] = useState(null);
    const [teacherName, setTeacherName] = useState(null);
    const [selectedCourseSchedule, setSelectedCourseSchedule] = useState([]);
    const isCoursesFetched: boolean = useMemo(() => coursesState.fetchStatus === FetchStatus.FETCHED, [
        coursesState.fetchStatus,
    ]);
    const isCourseScheduleFetched: boolean = useMemo(() => courseScheduleState.fetchStatus === FetchStatus.FETCHED, [
        courseScheduleState.fetchStatus,
    ]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [prepay, setPrepay] = useState(true);
    const [scheduleMonths, setScheduleMonths] = useState([]);
    const [selectedMonthIndex, setSelectedMonthIndex] = useState(0);

    useEffect(() => {
        let monthsArr = [];
        const newScheduleMonths = selectedCourseSchedule
            .filter((el) => {
                const month = parseInt(el.date.split('-')[1]);
                if (!monthsArr.includes(month) && el.status === CourseScheduleStatus.free) {
                    monthsArr.push(month);
                    return true;
                }
                return false;
            })
            .map((el) => parseInt(el.date.split('-')[1]));
        setScheduleMonths(newScheduleMonths);
    }, [selectedCourseSchedule]);

    const handleMonthChange = useCallback(
        (value: number) => {
            setSelectedMonthIndex((prevState) => {
                if (prevState + value <= scheduleMonths.length - 1 && prevState + value >= 0) return prevState + value;
                return prevState;
            });
        },
        [selectedMonthIndex, scheduleMonths],
    );

    const handleChangeSelect = (el) => {
        setSelectedCourseName(el);
    };

    const handleToggleClick = (e) => {
        setPrepay(!e.target.checked);
    };

    useEffect(() => {
        let selectedCourse = isCoursesFetched ? coursesState.data[0] : null;
        setSelectedCourseSchedule([]);
        setSelectedMonthIndex(0);
        setPrepay(true);
        if (isCoursesFetched && coursesState.data.length !== 0) {
            coursesState.data.forEach((obj) => {
                if (obj.fullname === selectedCourseName) selectedCourse = obj;
            });
            setTeacherName(`Преподаватель:  ${selectedCourse.teacher_firstname} ${selectedCourse.teacher_lastname}`);
            if (selectedCourseName) {
                dispatch(bookCourseScheduleInitial());
                dispatch(
                    loadCourseScheduleStart({
                        student_id: studentsState.selectedProfile.id,
                        course_id: selectedCourse.course_id,
                    }),
                );
            }
        }
    }, [coursesState.data, dispatch, isCoursesFetched, selectedCourseName, studentsState.selectedProfile]);

    useEffect(() => {
        setSelectedCourseName(null);
    }, [studentsState.selectedProfile]);

    useEffect(() => {
        let isScheduleError = true;
        const signUpBtn = document.getElementById('signup') as HTMLButtonElement;
        if (isCourseScheduleFetched && selectedCourseName) {
            setIsDisabled(false);
            let numberOfLessons = 0;
            let selectedCourse = isCoursesFetched ? coursesState.data[0] : null;
            coursesState.data.forEach((obj) => {
                if (obj.fullname === selectedCourseName) selectedCourse = obj;
            });
            let newSelectedCourseSchedule = [];
            Object.keys(courseScheduleState.data.list).map((key1) => {
                Object.keys(courseScheduleState.data.list[key1]).map((key2) => {
                    if (courseScheduleState.data.list[key1][key2].status === CourseScheduleStatus.free) {
                        numberOfLessons++;
                        isScheduleError = false;
                    }
                    const dispatchedObj = {
                        ...courseScheduleState.data.list[key1][key2],
                        dispatched_course_id: selectedCourse.course_id,
                    };
                    newSelectedCourseSchedule.push(dispatchedObj);
                });
            });
            setSelectedCourseSchedule(newSelectedCourseSchedule);
            setScheduleError(isScheduleError);
            signUpBtn.innerText = `Записаться за ${selectedCourse.cost}₽`;
            setTotalPrice(parseInt(selectedCourse.cost));
        }
        if (courseScheduleState.fetchStatus === FetchStatus.FETCHING || isScheduleError) {
            setIsDisabled(true);
            signUpBtn.innerText = 'Записаться';
        }
    }, [courseScheduleState, coursesState.data, isCourseScheduleFetched, isCoursesFetched, selectedCourseName]);

    useEffect(() => {
        dispatch(bookCourseScheduleInitial());
    }, []);

    const [flagRedirect, setFlagRedirect] = useState(false);

    useRedirect('/payment', bookCourseScheduleState.fetchStatus, flagRedirect);

    const handleSignUp = useCallback(() => {
        let datesArr = [];
        selectedCourseSchedule.forEach((el) => {
            if (el.status === CourseScheduleStatus.free) {
                datesArr.push({
                    date: el.date,
                    lesson_no: el.lesson_no,
                });
            }
        });
        dispatch(
            bookCourseScheduleStart({
                student_id: studentsState.selectedProfile.id,
                course_id: selectedCourseSchedule[0].dispatched_course_id,
                action: 'validate',
                prepay: prepay,
                cost: totalPrice,
                dates: datesArr,
                education_program: selectedCourseName,
            }),
        );
        setFlagRedirect(true);
    }, [dispatch, prepay, selectedCourseName, selectedCourseSchedule, studentsState.selectedProfile?.id, totalPrice]);

    const courseSchedule = (el, index) => {
        return (
            <div className="additional-courses__default-text default-text-schedule" key={index}>
                {getScheduleText(el)}
            </div>
        );
    };

    return (
        <div className="additional-courses">
            <div className="additional-courses-form">
                <div className="additional-courses-form__subject">
                    <div className="additional-courses-form__subject-text">Курс:</div>
                    {isCoursesFetched ? (
                        <CustomSelect
                            placeholder="Выберите курс"
                            options={coursesState.data.map((el) => el.fullname)}
                            availableOptions={coursesState.data.filter((el) => el.available === 'True')}
                            value={selectedCourseName ? selectedCourseName : null}
                            onChange={(el) => handleChangeSelect(el)}
                        />
                    ) : (
                        <Skeleton width={343} height={56} />
                    )}
                    <div className="additional-courses__default-text" id="additional-courses-teacher">
                        {isCoursesFetched ? (
                            coursesState.data.length === 0 ? (
                                'Нет доступных курсов'
                            ) : selectedCourseName === null ? (
                                ``
                            ) : (
                                teacherName
                            )
                        ) : (
                            <Skeleton />
                        )}
                    </div>
                </div>
            </div>
            {!scheduleError ? (
                selectedCourseName !== null && (
                    <div className="additional-courses-form__schedule">
                        <CourseSchedule
                            courseSchedule={selectedCourseSchedule}
                            isCourseScheduleFetched={isCourseScheduleFetched}
                            selectedMonth={scheduleMonths[selectedMonthIndex]}
                            handleMonthChange={handleMonthChange}
                            title={'Расписание'}
                        />
                    </div>
                )
            ) : (
                <div className="additional-courses-form__schedule-error">
                    <div className="additional-courses-form__schedule-header">
                        <div className="additional-courses-form__schedule-text text-error">Расписание</div>
                    </div>
                    <div className="additional-courses__default-text default-text-schedule sub-text-error">
                        К сожалению, время проведения курса <br />
                        не совпадает со свободным временем ученика
                    </div>
                    {isCourseScheduleFetched ? (
                        selectedCourseSchedule.map((el, index) => {
                            if (
                                el.status !== CourseScheduleStatus.free &&
                                el.info !== 'Другой пользователь записан на это время'
                            )
                                return courseSchedule(el, index);
                        })
                    ) : (
                        <Skeleton width={300} />
                    )}
                    <div className="additional-courses__default-text offer-text-schedule">
                        попробуйте выбрать другой курс,
                        <br />
                        или категорию “Индивидуальное образование”
                    </div>
                </div>
            )}
            <div className="additional-courses-buttons">
                {bookCourseScheduleState.fetchStatus === FetchStatus.ERROR && (
                    <BookCourseError
                        title={'Ошибка'}
                        reason={bookCourseScheduleState.error}
                        studentId={studentsState?.selectedProfile?.id}
                    />
                )}
                {!isDisabled && totalPrice > 10000 && (
                    <div className="courses-buttons__prepay">
                        <Toggle disabled={false} defaultChecked={false} handleToggleChange={handleToggleClick} />
                        <span className="courses-buttons__prepay-text">Рассрочка (50% первый платеж)</span>
                    </div>
                )}
                <div className="courses-buttons__signup">
                    <Button
                        variant="primary"
                        className="signup-btn"
                        id="signup"
                        disabled={isDisabled}
                        onClick={handleSignUp}
                    >
                        Записаться
                    </Button>
                    <Button variant="danger" className="cancel-btn" id="cancel" onClick={() => history.push('/')}>
                        Отмена
                    </Button>
                </div>
            </div>
        </div>
    );
};
