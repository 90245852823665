import { BaseRestDataProvider } from './BaseRestDataProvider';
import axios from 'axios';
const metaMock = {
    pages: {
        home: {},
        schedule: {},
        progress: {},
        performance: {},
    },
};

export class MetaDataProvider extends BaseRestDataProvider {
    loadMetaData = () => {
        return axios
            .post(`${this.host}/meta/lk/`)
            .then((res) => res.data);
    };
}
