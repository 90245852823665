import { ActionType, createAction } from 'typesafe-actions';
import { IUsersListPayload } from '../types/users';

export const loadUsersListStart = createAction('@usersList/LOAD/START')<IUsersListPayload>();
export const loadUsersListSuccess = createAction('@usersList/LOAD/SUCCESS')<any>();
export const loadUsersListError = createAction('@usersList/LOAD/ERROR')<any>();

export type UsersActions =
    | ActionType<typeof loadUsersListStart>
    | ActionType<typeof loadUsersListSuccess>
    | ActionType<typeof loadUsersListError>;
