import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { cn } from '@bem-react/classname';

import './Navbar.scss';
import { INavbarProps } from './Navbar.types';
import { NavBarDesktop } from './NavBarDesktop';
import { NavBarMobile } from './NavBarMobile';
const CnNavBar = cn('nav-bar');

const downloadLabel = 'Приложение "Онлайн Гимназия №1"';
const smartLabel = 'Умная ручка';

export const Navbar: React.FC<INavbarProps> = ({
    students,
    currentPath,
    navbarItems,
    tooltipItems,
    isOpenButtonEnable = true,
    isOpenedInit = true,
    isMobile = false,
    onNavbarItemClick,
    needDownloadButton,
    needSmartButton,
    needPrincipalButton,
    onDownloadClick,
    onSmartClick,
    onLogoClick,
    onToggleClick,
    onLetterSend,
}) => {
    const [isOpened, setIsOpened] = useState(true);
    const [isMobileOpened, setIsMobileOpened] = useState(false);
    const [isOpenedPopup, setIsOpenedPopup] = useState(false);
    const [studentId, setStudentId] = useState((students?.find((item) => item.selected) || students?.[0])?.id);
    const student = useMemo(() => students?.find((item) => item.id === studentId) || students?.[0], [
        studentId,
        students,
    ]);
    const toggledStudents = useMemo(() => students?.map((item) => ({ ...item, selected: item.id === student?.id })), [
        student,
        students,
    ]);

    const handleStudentClick = useCallback(
        (id: number) => {
            const student = students?.find((item) => item.id === id);
            if (student) {
                student?.onStudentClick(id);
            }
            setStudentId(id);
        },
        [students],
    );

    const handleSettingClick = useCallback(
        (id: number) => {
            const student = students?.find((item) => item.id === id);
            if (student && student.onSettingClick) {
                student.onSettingClick(id);
            }
            setIsMobileOpened(false);
        },
        [students],
    );

    const handleLogoClick = useCallback(() => {
        onLogoClick?.();
    }, [onLogoClick]);

    const handleDownloadClick = useCallback(() => {
        onDownloadClick?.();
    }, [onDownloadClick]);

    const handleSmartClick = useCallback(() => {
        onSmartClick?.();
    }, [onSmartClick]);

    const handlePrincipalClick = useCallback(() => {
        setIsMobileOpened(false);
        setIsOpenedPopup(true);
    }, []);

    const handlePopupClose = useCallback(() => setIsOpenedPopup(false), []);

    const handleToggleClick = useCallback(() => {
        if (isOpenButtonEnable) {
            setIsOpened((prev) => !prev);
            onToggleClick?.();
        }
    }, [isOpenButtonEnable, onToggleClick]);

    const handleBurgerClick = useCallback(() => {
        setIsMobileOpened((prev) => !prev);
    }, []);

    const handleNavBarItemClick = useCallback(
        (path) => {
            onNavbarItemClick?.(path);
            setIsMobileOpened(false);
        },
        [onNavbarItemClick],
    );

    const handleTooltipClick = useCallback(() => setIsMobileOpened(false), []);

    useEffect(() => {
        if (isOpenedInit && isOpened !== isOpenedInit && !isOpenButtonEnable) {
            setIsOpened(isOpenedInit);
        }
    }, [isOpenedInit, isOpened, isOpenButtonEnable]);

    return (
        <div className={CnNavBar('wrapper', { isMobile, isClosed: !isOpened })}>
            {isMobile ? (
                <NavBarMobile
                    student={student}
                    isMobile={isMobile}
                    currentPath={currentPath}
                    navbarItems={navbarItems}
                    tooltipItems={tooltipItems}
                    isOpenedPopup={isOpenedPopup}
                    isMobileOpened={isMobileOpened}
                    toggledStudents={toggledStudents}
                    onLetterSend={onLetterSend}
                    handleLogoClick={handleLogoClick}
                    handlePopupClose={handlePopupClose}
                    handleBurgerClick={handleBurgerClick}
                    handleStudentClick={handleStudentClick}
                    handleSettingClick={handleSettingClick}
                    handleTooltipClick={handleTooltipClick}
                    handlePrincipalClick={handlePrincipalClick}
                    handleNavBarItemClick={handleNavBarItemClick}
                />
            ) : (
                <NavBarDesktop
                    smartLabel={smartLabel}
                    isOpened={isOpened}
                    currentPath={currentPath}
                    downloadLabel={downloadLabel}
                    isOpenedPopup={isOpenedPopup}
                    needSmartButton={needSmartButton}
                    isOpenButtonEnable={isOpenButtonEnable}
                    needPrincipalButton={needPrincipalButton}
                    needDownloadButton={needDownloadButton}
                    student={student}
                    toggledStudents={toggledStudents}
                    navbarItems={navbarItems}
                    onLetterSend={onLetterSend}
                    handleLogoClick={handleLogoClick}
                    handlePopupClose={handlePopupClose}
                    handleSmartClick={handleSmartClick}
                    handleToggleClick={handleToggleClick}
                    handleDownloadClick={handleDownloadClick}
                    handleStudentClick={handleStudentClick}
                    handleSettingClick={handleSettingClick}
                    handlePrincipalClick={handlePrincipalClick}
                    handleNavBarItemClick={handleNavBarItemClick}
                />
            )}
        </div>
    );
};
