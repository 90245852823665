import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ILoginState } from '../redux/types/login';
import {
    getAccessToken,
    getRefreshToken,
    removeAccessToken,
    removeRefreshToken,
    setAccessToken,
    setRefreshToken,
} from '../common/token';
import { BaseRestDataProvider } from './BaseRestDataProvider';
import { apiSSOHost } from 'redux/store';
import { ssoPath } from './path/ssoPath';

axios.interceptors.request.use(
    (config) => {
        const token = getAccessToken();
        if (token) config.headers['Authorization'] = `Bearer ${token}`;
        config.withCredentials = true;
        return config;
    },
    (error) => {
        Promise.reject(error).catch((error: AxiosError) => {
            throw error;
        });
    },
);

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error: AxiosError<never>) => {
        const originalRequest = error.config as AxiosRequestConfig & { _retry: boolean };

        if (error.response?.status === 401 && originalRequest.url.includes(ssoPath.refresh)) {
            removeAccessToken();
            removeRefreshToken();
            window.location.href = window.location.origin;
            return;
        }

        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const refresh = getRefreshToken();

            return axios
                .post<
                    Promise<{ access: string; refresh?: string }>,
                    AxiosResponse<{ access: string; refresh?: string }>
                >(`${apiSSOHost}/${ssoPath.refresh}`, refresh ? { refresh } : undefined)
                .then((res) => {
                    if (res.status === 200) {
                        const { access, refresh } = res?.data || {};
                        if (access) {
                            setAccessToken(access);
                        }
                        if (refresh) {
                            setRefreshToken(refresh);
                        }
                        return axios(originalRequest);
                    }
                })
                .catch((error) => {
                    const redirectPath = window.location.pathname;
                    console.log('ref err');
                    window.open(
                        `/new/auth/login?path=${redirectPath.includes('new/auth') ? '/' : redirectPath}`,
                        '_self',
                    );
                    throw { ...error, response: { ...error.response, status: 403 } };
                });
        }

        return Promise.reject(error).catch((error: AxiosError) => {
            throw error;
        });
    },
);

export class LoginDataProvider extends BaseRestDataProvider {
    fetchLogin = (value: ILoginState): Promise<any> => {
        return axios
            .post(`${this.host}/${ssoPath.token}`, {
                username: value.username,
                password: value.password,
            })
            .then((res: AxiosResponse<any>) => {
                return res?.data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    };
}
