import { FetchStatus, PageStatus } from 'common/types';

import {
    getHomeworksBetweenDatesError,
    getHomeworksBetweenDatesStart,
    getHomeworksBetweenDatesSuccess,
    LOAD_SHEDULE,
    LOAD_SHEDULE_ERROR,
    LOAD_SHEDULE_SUCCESS,
    loadLmsSchedule,
    loadLmsScheduleError,
    loadLmsScheduleRange,
    loadLmsScheduleRangeError,
    loadLmsScheduleRangeSuccess,
    loadLmsScheduleSuccess,
} from '../actions/shedule';
import { Action } from '../store';
import { ScheduleState } from '../types/schedule';
import { getType } from 'typesafe-actions';
import { normalizeScheduleDayEvents, normalizeScheduleEvents } from '../../helpers/schedule';

const defaultScheduleState = {
    pageStatus: PageStatus.INITIAL,
    schedule: null,
    error: null,
    lmsSchedule: null,
    lmsScheduleStatus: FetchStatus.INITIAL,
    lmsScheduleError: null,
    scheduleHomeworks: null,
    scheduleHomeworksStatus: FetchStatus.INITIAL,
    scheduleHomeworksError: null,
};

export default (state: ScheduleState = defaultScheduleState, action: Action) => {
    switch (action.type) {
        case LOAD_SHEDULE: {
            return {
                ...state,
                pageStatus: PageStatus.LOADING,
            };
        }
        case LOAD_SHEDULE_SUCCESS: {
            return {
                ...state,
                pageStatus: PageStatus.LOADED,
                schedule: action.shedule,
            };
        }
        case LOAD_SHEDULE_ERROR: {
            return {
                ...state,
                pageStatus: PageStatus.ERROR,
                error: action.err,
            };
        }

        case getType(loadLmsSchedule):
            return {
                ...state,
                lmsScheduleStatus: FetchStatus.FETCHING,
                lmsScheduleError: null,
            };

        case getType(loadLmsScheduleSuccess):
            return {
                ...state,
                lmsSchedule: {
                    ...state.lmsSchedule,
                    [action.payload.date]: {
                        date: action.payload.date,
                        data: normalizeScheduleDayEvents(action.payload.scheduleData, action.payload.date) || [],
                    },
                },
                lmsScheduleStatus: FetchStatus.FETCHED,
                lmsScheduleError: null,
            };

        case getType(loadLmsScheduleError):
            return {
                ...state,
                lmsScheduleStatus: FetchStatus.ERROR,
                lmsScheduleError: action.payload,
            };

        case getType(loadLmsScheduleRange):
            return {
                ...state,
                lmsScheduleStatus: FetchStatus.FETCHING,
                lmsScheduleError: null,
            };

        case getType(loadLmsScheduleRangeSuccess):
            return {
                ...state,
                lmsSchedule: normalizeScheduleEvents(action.payload),
                lmsScheduleStatus: FetchStatus.FETCHED,
                lmsScheduleError: null,
            };

        case getType(loadLmsScheduleRangeError):
            return {
                ...state,
                lmsScheduleStatus: FetchStatus.ERROR,
                lmsScheduleError: action.payload,
            };

        case getType(getHomeworksBetweenDatesStart):
            return {
                ...state,
                scheduleHomeworksStatus: FetchStatus.FETCHING,
                scheduleHomeworksError: null,
            };

        case getType(getHomeworksBetweenDatesSuccess):
            return {
                ...state,
                scheduleHomeworks: action.payload,
                scheduleHomeworksStatus: FetchStatus.FETCHED,
                scheduleHomeworksError: null,
            };

        case getType(getHomeworksBetweenDatesError):
            return {
                ...state,
                scheduleHomeworksStatus: FetchStatus.ERROR,
                scheduleHomeworksError: action.payload,
            };

        default: {
            return state;
        }
    }
};
