import { BaseRestDataProvider } from "./BaseRestDataProvider";
import axios from "axios";


export class TasksDataProvider extends BaseRestDataProvider {
    loadTasks = async (student_id: string) => {
        return axios.all([
            this.fetchExpiredTasks(student_id),
            this.fetchCurrentTasks(student_id),
        ]).then(([expired, current]) => {
            return {
                current,
                expired
            }
        })
    }

    fetchCurrentTasks = (student_id: string) => {
        return axios.get(`${this.host}/tasks/current/?student_id=${student_id}`, {
            withCredentials: true
        }).then(res => res.data);
    }

    fetchExpiredTasks = (student_id: string) => {
        return axios.get(`${this.host}/tasks/expired/?student_id=${student_id}`, {
            withCredentials: true
        }).then(res => res.data);
    }
}