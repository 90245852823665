import React from 'react';
import book from '../../assets/img/book.svg';

interface IErrorBoundaryProps {}

interface IErrorBoundaryState {
    hasError: boolean;
    error: any;
    info: any;
}

export class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
    constructor(props: any) {
        super(props);

        this.state = {
            hasError: false,
            error: null,
            info: null,
        };
    }

    componentDidCatch(error: Error, info: React.ErrorInfo) {
        this.setState({
            hasError: true,
            error,
            info,
        });
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="cabinet-content col custom-scroll total-rating">
                    <div className="p-5 row">
                        <div id="totalRatingContent" className="col-12 col-xxl mb-5">
                            <h1>Что-то пошло не так :(</h1>
                            <img className="total-rating-img" src={book} alt="book" />
                            <p className={'mt-5'}>The error: {this.state.error.toString()}</p>
                        </div>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

export const withErrorBoundary = (props: any) => {
    return <ErrorBoundary>{props.children}</ErrorBoundary>;
};
