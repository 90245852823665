import { FetchStatus } from 'common/types';
import { ProcessesState } from 'redux/types/processes';
import {
    createContractExtension,
    createContractExtensionError,
    createContractExtensionSuccess,
    createTrialDay,
    createTrialDayError,
    createTrialDaySuccess,
    fetchProcessesError,
    fetchProcessesStart,
    fetchProcessesSuccess,
    fetchTrialDays,
    fetchTrialDaysError,
    fetchTrialDaysSuccess,
    ProcessesActions,
} from 'redux/actions/processes';
import { getType } from 'typesafe-actions';
import { persistReducer } from 'redux-persist';
import { VERSION } from '../../common/constants';
import storage from 'redux-persist/lib/storage';

const initialState: ProcessesState = {
    processes: [],
    trialDays: [],
    fetchProcessesStatus: FetchStatus.INITIAL,
    fetchProcessesError: null,
    fetchTrialDaysStatus: FetchStatus.INITIAL,
    fetchTrialDaysError: null,
    createTrialDayStatus: FetchStatus.INITIAL,
    createTrialDayError: null,
    createContractExtensionStatus: FetchStatus.INITIAL,
    createContractExtensionError: null,
    contractExtensionApplication: null,
};

const processes = (state: ProcessesState = initialState, action: ProcessesActions): ProcessesState => {
    switch (action.type) {
        case getType(fetchProcessesStart):
            return {
                ...state,
                fetchProcessesError: null,
                fetchProcessesStatus: FetchStatus.FETCHING,
            };
        case getType(fetchProcessesSuccess):
            return {
                ...state,
                processes: action.payload,
                fetchProcessesError: null,
                fetchProcessesStatus: FetchStatus.FETCHED,
            };
        case getType(fetchProcessesError):
            return {
                ...state,
                fetchProcessesError: action.payload,
                fetchProcessesStatus: FetchStatus.ERROR,
            };
        case getType(fetchTrialDays):
            return {
                ...state,
                fetchTrialDaysError: null,
                fetchTrialDaysStatus: FetchStatus.FETCHING,
            };
        case getType(fetchTrialDaysSuccess):
            return {
                ...state,
                trialDays: action.payload,
                fetchTrialDaysError: null,
                fetchTrialDaysStatus: FetchStatus.FETCHED,
            };
        case getType(fetchTrialDaysError):
            return {
                ...state,
                fetchTrialDaysError: action.payload,
                fetchTrialDaysStatus: FetchStatus.ERROR,
            };
        case getType(createTrialDay):
            return {
                ...state,
                createTrialDayError: null,
                createTrialDayStatus: FetchStatus.FETCHING,
            };
        case getType(createTrialDaySuccess):
            return {
                ...state,
                createTrialDayStatus: FetchStatus.FETCHED,
                createTrialDayError: null,
            };
        case getType(createTrialDayError):
            let error;
            if (action?.payload?.response?.data?.message !== 'Validation error')
                error = action?.payload?.response?.data?.message?.detail;
            else error = 'Ошибка валидации';
            return {
                ...state,
                createTrialDayError: error,
                createTrialDayStatus: FetchStatus.ERROR,
            };
        case getType(createContractExtension):
            return {
                ...state,
                createContractExtensionError: null,
                createContractExtensionStatus: FetchStatus.FETCHING,
            };
        case getType(createContractExtensionSuccess):
            return {
                ...state,
                createContractExtensionStatus: FetchStatus.FETCHED,
                contractExtensionApplication: action.payload,
                createContractExtensionError: null,
            };
        case getType(createContractExtensionError):
            return {
                ...state,
                createContractExtensionError: action.payload,
                createContractExtensionStatus: FetchStatus.ERROR,
            };
        default:
            return state;
    }
};

export default persistReducer(
    {
        key: `processes::${VERSION}`,
        storage: storage,
        blacklist: [],
        version: VERSION,
    },
    processes,
);
