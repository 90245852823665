import React from 'react';
import { useHistory } from 'react-router-dom';

import logo_icon from '../../assets/img/logo_icon.svg';
import logo_name from '../../assets/img/logo_name.svg';

import './RegHeader.scss';

interface IRegHeaderProps {
    handleLinkAdress?: string;
}

export const RegHeader: React.FC<IRegHeaderProps> = ({ handleLinkAdress = '' }) => {
    const history = useHistory();

    const handleLogoClick = () => {
        history.push('/');
    };

    return (
        <header className="header simple-header">
            <div className="container d-flex justify-content-between align-items-center">
                <div onClick={handleLogoClick} className="logo mr-4">
                    <div className="logo-group">
                        <img className="logo-group__icon" src={logo_icon} alt="gimnaziya1" />
                        <img className="logo-group__name" src={logo_name} alt="gimnaziya1" />
                    </div>
                </div>

                <a className="link-to-icon" href={handleLinkAdress}>
                    <span className="link-to-icon__text simple-header__back-text">Вернуться назад</span>
                </a>
            </div>
        </header>
    );
};
