import { AxiosError } from "axios";
import { FuncEpic } from "common/types";
import { checkAuthError } from "helpers/checkAuthError";
import { postDirectorAnswerAction, postDirectorAnswerActionError, postDirectorAnswerActionSuccess } from "redux/actions/directorAnswer";
import { combineEpics, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";

import { IDirectorAnswer } from "components/DirectorModal/DirectorModal.types";

const directorAnswer: FuncEpic = (action$: any, _store$, deps) => {
    return action$.pipe(
        ofType(postDirectorAnswerAction),
        switchMap((value: {payload: IDirectorAnswer; type: 'string'}) => {
            
            return from(deps.directorAnswerProvider.postDirectorMessage(value.payload)).pipe(
                switchMap(() => {
                    return of(postDirectorAnswerActionSuccess())
                }),
                catchError((err: AxiosError) => {
                    return of(postDirectorAnswerActionError(err))
                })
            )
        })
    )
}

export const directorAnswerEpic = combineEpics(directorAnswer);
