import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { getMarkColor } from 'helpers/getMarksColor';
import { PageStatus } from 'common/types';
import { State } from 'redux/store';
import MarkItem from 'components/Mark/MarkItem';
import { EmptyMarks } from 'components/EmptyMarks';
import { LoadingMark } from 'components/LoadingMark';

import './MarkList.scss';

const MarkList: React.FC<{
    pageStatus: PageStatus;
    lastMarks: Array<any> | null,
    error: any;
}> = ({ pageStatus, lastMarks }) => {
    if (pageStatus === PageStatus.LOADING) {
        return (
            <div className="dashboard__range dashboard__loading">
                <h5 className="mb-4">Последние оценки</h5>
                <div className="d-flex flex-wrap">
                    {[...Array(4)].map((_, index) => (
                        <div className="dashboard__item" key={index}>
                            <LoadingMark />
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    if (!lastMarks?.length) {
        return (
            <div className="dashboard__range">
                {pageStatus === PageStatus.ERROR && (
                    <h5 className="mb-4">Не удалось загрузить последние оценки</h5>
                )}
                <EmptyMarks />
            </div>
        );
    }

    return (
        <div className="dashboard__range">
            <h5 className="mb-4">Последние оценки</h5>
            <div className="d-flex flex-wrap">
                {lastMarks.map((el, index) => (
                    <MarkItem
                        key={index}
                        mark={el.grade}
                        subject={el.class}
                        goal={el.event_type}
                        markDate={moment(el.date_time || new Date()).format("DD.MM HH:mm")}
                        className={getMarkColor(el.grade)}/>
                ))}
            </div>
        </div>
    )
}

export default connect(
    ({marks}: State) => ({...marks}),
    () => ({})
)(MarkList)
