import axios, { AxiosError, AxiosResponse } from 'axios';
import { BaseRestDataProvider } from './BaseRestDataProvider';

export interface IResetPassword {
    uid: string;
    token: string;
    new_password: string;
}


export class ResetPasswordDataProvider extends BaseRestDataProvider {
    sendEmailWithLink = (email: string): Promise<any> => {
        return axios
            .post(
                `${this.host}/auth/users/reset_password/`,
                {
                    email: email
                }
            )
            .then((res: AxiosResponse<any>) => {
                return res?.data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    }

    createNewPassword = (value: IResetPassword): Promise<any> => {
        return axios
            .post(
                `${this.host}/auth/users/reset_password_confirm/`,
                {
                    uid: value.uid,
                    token: value.token,
                    new_password: value.new_password,   
                }
            )
            .then((res: AxiosResponse<any>) => {
                return res?.data;
            })
            .catch((error: {data: AxiosResponse<any>, error: AxiosError }) => {
                throw error;
            });
    }
}

