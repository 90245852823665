import { BaseRestDataProvider } from './BaseRestDataProvider';
import axios, { AxiosError, AxiosResponse } from 'axios';
import {
    CourseGroup,
    IBookCourseSchedule,
    ICourses,
    ICourseSchedule,
    LoadCourseGroupsPayload,
} from '../redux/types/courses';

export class CoursesDataProvider extends BaseRestDataProvider {
    loadAdditionalCourses = (value: ICourses): Promise<any> => {
        return axios
            .post(`${this.host}/api/moodle/additional_courses_list/`, value)
            .then((res: AxiosResponse) => {
                return res?.data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    };

    loadIndividualCourses = (value: ICourses): Promise<any> => {
        return axios
            .post(`${this.host}/api/moodle/individual_courses_list/`, value)
            .then((res: AxiosResponse) => {
                return res?.data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    };

    loadCourseSchedule = (value: ICourseSchedule): Promise<any> => {
        return axios
            .post(`${this.host}/api/moodle/course_schedule/`, value)
            .then((res: AxiosResponse) => {
                return res?.data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    };

    loadStudentCourses = (value: ICourses): Promise<any> => {
        return axios
            .get(`${this.host}/api/current_courses/?student_id=${value.student_id}`)
            .then((res: AxiosResponse) => {
                return res?.data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    };

    bookCourseSchedule = (value: IBookCourseSchedule): Promise<any> => {
        return axios
            .post(`${this.host}/api/moodle/book_course_schedule/`, value)
            .then((res: AxiosResponse) => {
                return res?.data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    };

    loadCourseGroups = (payload: LoadCourseGroupsPayload): Promise<CourseGroup[]> => {
        return axios
            .get(`${this.host}/api/lms/proxy/?path=course-groups/external/`, {
                params: { students: payload.students.join() },
            })
            .then((res: AxiosResponse) => {
                return res?.data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    };
}
