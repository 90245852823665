import React, { useState } from 'react';
import { cn } from '@bem-react/classname';

import { NavbarItem } from 'components/Navbar/NavbarItem';
import { IActiveNavBarElementData } from 'components/Navbar/NavbarItem/NavbarItem.types';
import { NavBarStudent } from 'components/Navbar/NavBarStudent';

import { NavbarContentProps } from './NavbarContent.types';

import './NavBarContent.scss';
import Skeleton from "react-loading-skeleton";
const CnNavbarContent = cn('nav-bar-content');

export const NavbarContent: React.FC<NavbarContentProps> = ({
    students,
    navbarItems,
    currentPath,
    isMobile = false,
    onNavbarItemClick,
    onStudentClick,
    onSettingClick,
    isOpened,
                                                                isShowSkeleton
}) => {
    const hasStudents = students && students.length > 0;
    const [activeElementData, setActiveElementData] = useState<IActiveNavBarElementData>();
    const [isView, setView] = useState(false);

    const promptElemStyle = { top: activeElementData?.positionX ?? 0, left: 10 };
    const isViewHoveredItem = isView && activeElementData.index != undefined;
    const navBarArray = isShowSkeleton ? Array(7).fill({}) : navbarItems;

    return (
        <div className={CnNavbarContent('container')}>
            <div className={CnNavbarContent({ isMobile, isDesktop: !isMobile, isOpened })}>
                {hasStudents && (
                    <>
                        <div className={CnNavbarContent('students', { isMobile })}>
                            {students?.map((student) => {
                                const { id, firstName, lastName, photoUrl, grade, selected, tariff } = student || {};
                                return (
                                    <NavBarStudent
                                        key={id}
                                        data-id={id}
                                        id={id}
                                        tariff={tariff}
                                        firstName={firstName}
                                        lastName={lastName}
                                        photoUrl={photoUrl}
                                        grade={grade}
                                        selected={selected}
                                        isMobile={isMobile}
                                        onSettingClick={onSettingClick}
                                        onStudentClick={onStudentClick}
                                    />
                                );
                            })}
                        </div>
                        <div className={CnNavbarContent('divider')} />
                    </>
                )}
                <div className={CnNavbarContent('nav-items', { isMobile, isHidden: !isOpened })}>
                    <>
                        {navBarArray.map((el, index) => {
                            return isShowSkeleton ? (
                                <div className={CnNavbarContent('skeleton-item')}>
                                    {!isMobile && <Skeleton width={32} height={32} /> }
                                    {(isOpened || isMobile) && <Skeleton width={150} height={32} />}
                                </div>
                            ) : (
                                <NavbarItem
                                    isMobile={isMobile}
                                    key={index}
                                    index={index}
                                    currentPath={currentPath}
                                    {...el}
                                    onNavbarItemClick={onNavbarItemClick}
                                    isOpened={isOpened}
                                    getActiveElementData={setActiveElementData}
                                    setHover={setView}
                                />
                            );
                        })}
                    </>
                </div>
            </div>
            {isViewHoveredItem && (
                <NavbarItem
                    style={promptElemStyle}
                    currentPath={currentPath}
                    {...navbarItems[activeElementData.index]}
                    onNavbarItemClick={onNavbarItemClick}
                    isOpened={isOpened}
                    isPrompt={true}
                />
            )}
        </div>
    );
};
