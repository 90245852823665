import { loadProfileSuccess, loadProfileError, LOAD_PROFILE, LOAD_SELECTED_PROFILE, loadSelectedProfileError, loadSelectedProfileSuccess, loadSelectedProfile } from '../actions/profile';
import { combineEpics, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, switchMap, mapTo } from "rxjs/operators";
import { loadMarks } from '../actions/marks';
import { loadDocuments } from '../actions/documents';
import { FuncEpic } from '../../common/types';
import { checkAuthError } from '../../helpers/checkAuthError';
import { getType } from 'typesafe-actions';
import { loadRating, loadRatingError, loadRatingSuccess } from '../actions/rating';

const loadRatingEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(loadRating)),
        switchMap(() => {
            return from(deps.ratingDataProvider.loadRating(store$.value.profile.selectedProfile.id)).pipe(
                switchMap((journal) => {
                    return of(loadRatingSuccess(journal));
                }),
                catchError(err => of(loadRatingError(err)))
            )
        })
    )
}


export const ratingEpics = combineEpics(loadRatingEpic)