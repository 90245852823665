import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { VERSION } from '../../common/constants';
import {  LOAD_META_SUCCESS } from '../actions/meta';
import { Action } from '../store';
import {MetaState} from "../types/meta";

const defaultMetaState = {};

const reducer = (state: MetaState = defaultMetaState, action: Action) => {
    switch (action.type) {
        case LOAD_META_SUCCESS:
            return action.meta
        default: {
            return state;
        }
    }
};

export const meta: any = persistReducer(
    {
        key: `meta::${VERSION}`,
        storage: storage,
        blacklist: [],
        version: VERSION,
    },
    reducer,
);
