import React from 'react';

import './LoadingMark.scss';

export const LoadingMark: React.FC = () => (
    <div className="loading-mark">
        <div className="card grade-card grade-card--pl card-pl">
            <div className="card-body d-inline-flex flex-row">
                <div className="grade-card__grade">
                    <div className="colorful-circle shape-grey" />
                </div>
                <div className="d-flex flex-column justify-content-between">
                    <div>
                        <div className="card-title grade-card__title card-pl__title" />
                        <p className="card-text grade-card__text card-pl__text" />
                    </div>
                    <footer className="card-footer grade-card__footer card-pl__footer" />
                </div>
            </div>
        </div>
    </div>
);
