import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { FetchStatus, PageStatus } from 'common/types';
import { getContractsStart } from 'redux/actions/contracts';
import { deleteDocument, loadDocuments, loadDocumentsTypes, loadParentDocumentsTypes } from 'redux/actions/documents';
import { State, useAppSelector } from 'redux/store';
import { IGymnasiumDoc, IGymnasiumFromManager } from 'redux/types/documents';

import { DeleteButton, DocumentList, DocumentType, PageTitle } from 'components';
import { DocumentTypeHeader } from 'components/Documents/DocumentType/DocumentTypeHeader';
import { NotificationCard } from 'components/Notification';
import { NotificationCardTypeEnum } from 'components/Notification/NotificationCard.types';
import {
    getParentTypesNotApproved,
    getTypesNotApproved,
    normalizeContracts,
    normalizeGymnasiumDocs,
    normalizeOwnDocs,
} from './utils';
import './Documents.scss';
import { loadConstantsStart } from '../../redux/actions/constants';
import Skeleton from 'react-loading-skeleton';
import { PageHeader } from '../../components/PageHeader';

const Documents: React.FC<{
    pageStatus: PageStatus;
    own: Array<any>;
    last: Array<any>;
    gymnasium: IGymnasiumDoc[];
    parentDocuments: Array<any>;
    gymnasiumFromManager: IGymnasiumFromManager[];
    types: Array<any>;
    profilePageStatus: PageStatus;
    loadDocuments: () => void;
    deleteDocument: (id: number) => void;
    selectedProfile: any;
    userPageStatus: PageStatus;
    students: any;
    fetchStatusEnrollmentCertReq: FetchStatus;
}> = ({
    pageStatus,
    own,
    gymnasium,
    profilePageStatus,
    loadDocuments,
    selectedProfile,
    userPageStatus,
    parentDocuments,
    // students,
    // fetchStatusEnrollmentCertReq,
    gymnasiumFromManager,
}) => {
    const dispatch = useDispatch();
    const { contracts, getStatusContracts } = useAppSelector((store) => store.contracts);
    const { fetchStatus: constantsFetchStatus } = useAppSelector((store) => store.constants);
    const { fetchStatusDocumentTypes, types, parentTypes } = useAppSelector((store) => store.documents);
    const { user } = useAppSelector((store) => store.user);
    const [uploadedDocuments, setUploadedDocuments] = useState([]);
    const { profile } = useAppSelector((store) => store.selectedProfile);
    // const [isOpenedFeedback, setIsOpenedFeedback] = useState(false);
    // const hideFeedbackTimeout = useRef(setTimeout(() => undefined, 0));
    // const [answerStatus, setAnswerStatus] = useState('');
    // const [isCertRequested, setIsCertRequested] = useState(false);
    // const [showSuccessNotification, setShowSuccessNotification] = useState(true);

    useEffect(() => {
        if (profilePageStatus === PageStatus.LOADED && user?.id && profile?.id) {
            dispatch(loadDocumentsTypes());
            dispatch(getContractsStart({ userId: user?.id }));
        }
    }, [dispatch, user?.id, profile?.id]);

    useEffect(() => {
        if (userPageStatus === PageStatus.LOADED) {
            dispatch(loadParentDocumentsTypes());
        }
    }, [dispatch, userPageStatus]);

    useEffect(() => {
        if (
            fetchStatusDocumentTypes === FetchStatus.FETCHED &&
            profilePageStatus === PageStatus.LOADED &&
            profile?.id
        ) {
            loadDocuments();
        }
    }, [fetchStatusDocumentTypes, loadDocuments, profile?.id]);

    useEffect(() => {
        if (constantsFetchStatus !== FetchStatus.FETCHED) {
            dispatch(loadConstantsStart());
        }
    }, []);

    const handleDeleteUploadedDocument = useCallback((indexDeleted: number) => {
        setUploadedDocuments((prev) => prev.filter((value, index) => index !== indexDeleted));
    }, []);

    const handleChangeInputFieldUploadedDoc = useCallback(
        (indexDoc: number, type: string) => (fieldValue: string) => {
            setUploadedDocuments((prev) =>
                prev.map((current, index) =>
                    index === indexDoc
                        ? {
                              ...current,
                              file_name: type === 'name' ? fieldValue : current.file_name,
                              valid: type === 'name' ? !!fieldValue.length : current.valid,
                              type: type === 'type' ? fieldValue : current.type,
                          }
                        : current,
                ),
            );
        },
        [],
    );

    // const enrollmentCertStudents = useMemo(() => {
    //     if (constants && students?.length && contracts?.results?.length)
    //         return students.filter((student) =>
    //             enrollmentCertReqCondition(contracts.results, constants['EDUCATION_COST'], student.id),
    //         );
    //     return [];
    // }, [students, constants, contracts]);

    // const [selectedStudent, setSelectedStudent] = useState('');

    // useEffect(() => {
    //     setSelectedStudent(
    //         enrollmentCertStudents && enrollmentCertStudents.length
    //             ? `${enrollmentCertStudents[0].last_name} ${enrollmentCertStudents[0].first_name}`
    //             : '',
    //     );
    // }, [enrollmentCertStudents]);

    const contractsGymnasium = useMemo(() => {
        if (getStatusContracts === FetchStatus.FETCHED) {
            return normalizeContracts(contracts.results, selectedProfile);
        }
        return [];
    }, [getStatusContracts, contracts, selectedProfile]);

    const gymnasiumDocs = useMemo(
        () =>
            normalizeGymnasiumDocs(
                gymnasium,
                own.filter((value) => value.uploaded_by === 'gimnasium'),
                gymnasiumFromManager,
                selectedProfile,
            ),
        [gymnasium, selectedProfile, own],
    );

    const ownDocs = useMemo(() => normalizeOwnDocs(own.filter((value) => value.uploaded_by !== 'gimnasium')), [own]);

    const typesNotAproved = useMemo(
        () =>
            getTypesNotApproved(
                own?.filter((value) => value?.uploaded_by !== 'gimnasium'),
                types,
            )?.filter(
                type => !!own?.find(
                    (document) => type?.name === document?.upload_doc_type.name,
                )
            ),
        [own, types],
    );

    const parentTypesNotAproved = useMemo(() => getParentTypesNotApproved(parentDocuments, parentTypes), [
        parentDocuments,
        parentTypes,
    ]);

    const uniqueOwnDocuments = useMemo(() => {
        if (ownDocs && ownDocs?.length) {
            const documents = ownDocs;
            let hashMap = {};
            for (let document of documents) {
                if (!hashMap[document.file_name]) {
                    hashMap[document.file_name] = document;
                }
            }

            return Object.values(hashMap);
        }

        return [];
    }, [ownDocs]);

    // const handleSelectedStudentChange = useCallback(
    //     (value) => {
    //         setSelectedStudent(value);
    //     },
    //     [setSelectedStudent],
    // );

    // const handleEnrollmentCertReq = useCallback(() => {
    //     setIsOpenedFeedback(false);
    //     setIsCertRequested(true);
    //     const [selectedLastName, selectedFirstName] = selectedStudent.split(' ');
    //     const student = students.find(
    //         (student) => student.last_name === selectedLastName && student.first_name === selectedFirstName,
    //     );
    //     if (student?.school?.prefix === 'ОГ') setShowSuccessNotification(false);
    //     else setShowSuccessNotification(true);
    //
    //     dispatch(
    //         enrollmentCertificateRequestStart({
    //             studentId: student.id,
    //             contractId: contracts.results.find((contract) => contract?.student?.id === student.id)?.id,
    //         }),
    //     );
    // }, [dispatch, selectedStudent, contracts]);

    // const handleCloseFeedback = useCallback(() => {
    //     setIsOpenedFeedback(false);
    //     setAnswerStatus('');
    //     clearTimeout(hideFeedbackTimeout.current);
    // }, [setIsOpenedFeedback, hideFeedbackTimeout, setAnswerStatus]);

    // useEffect(() => {
    //     if (
    //         fetchStatusEnrollmentCertReq === FetchStatus.FETCHED ||
    //         fetchStatusEnrollmentCertReq === FetchStatus.ERROR
    //     ) {
    //         setIsOpenedFeedback(true);
    //         clearTimeout(hideFeedbackTimeout.current);
    //         hideFeedbackTimeout.current = setTimeout(() => {
    //             setIsOpenedFeedback(false);
    //             setAnswerStatus('');
    //         }, 10000);
    //     }
    //     if (fetchStatusEnrollmentCertReq === FetchStatus.FETCHED) setAnswerStatus('success');
    //     if (fetchStatusEnrollmentCertReq === FetchStatus.ERROR) setAnswerStatus('error');
    // }, [dispatch, fetchStatusEnrollmentCertReq]);
    const firstNameLetter = user?.first_name ? ' ' + user?.first_name[0] + '.' : '';
    const patronymicLetter = user?.patronymic ? ' ' + user?.patronymic[0] + '.' : '';
    const fullName = user?.last_name
        ? `${user?.last_name}${firstNameLetter}${patronymicLetter}`
        : `${user?.first_name ?? ''}`;
    if (pageStatus === PageStatus.LOADED && constantsFetchStatus === FetchStatus.FETCHED) {
        return (
            <Col className="home-wrapper position-relative documents-wrapper">
                <div className="cabinet-content custom-scroll">
                    <div className="document-warpper cabinet-content__wrapper">
                        <div className="document__pageHeader-container">
                            <PageHeader
                                user={{
                                    fullName: fullName,
                                    img: user?.avatar,
                                }}
                                title="Документы"
                            />
                        </div>
                        <Row>
                            <Col className="mb-5">
                                {/*{!!enrollmentCertStudents &&*/}
                                {/*    !!enrollmentCertStudents.length &&*/}
                                {/*    !(*/}
                                {/*        fetchStatusEnrollmentCertReq === FetchStatus.FETCHED &&*/}
                                {/*        enrollmentCertStudents.length === 1*/}
                                {/*    ) && (*/}
                                {/*        <div className="enrollment-certificate__wrapper">*/}
                                {/*            {fetchStatusEnrollmentCertReq === FetchStatus.FETCHING ? (*/}
                                {/*                <LoadingButton className="enrollment-certificate__button" />*/}
                                {/*            ) : (*/}
                                {/*                <Button*/}
                                {/*                    onClick={handleEnrollmentCertReq}*/}
                                {/*                    className="enrollment-certificate__button"*/}
                                {/*                >*/}
                                {/*                    Запросить справку о зачислении*/}
                                {/*                </Button>*/}
                                {/*            )}*/}
                                {/*            {enrollmentCertStudents.length === 1 ? (*/}
                                {/*                <div className="form-group">*/}
                                {/*                    <input*/}
                                {/*                        type="text"*/}
                                {/*                        className="form-control"*/}
                                {/*                        value={selectedStudent}*/}
                                {/*                        disabled={true}*/}
                                {/*                    />*/}
                                {/*                </div>*/}
                                {/*            ) : (*/}
                                {/*                <CustomSelect*/}
                                {/*                    options={enrollmentCertStudents.map(*/}
                                {/*                        (student) => `${student.last_name} ${student.first_name}`,*/}
                                {/*                    )}*/}
                                {/*                    onChange={handleSelectedStudentChange}*/}
                                {/*                    value={selectedStudent}*/}
                                {/*                />*/}
                                {/*            )}*/}
                                {/*            <p className="enrollment-certificate__text">*/}
                                {/*                Создание справки занимает до одной недели в зависимости от учебного*/}
                                {/*                заведения*/}
                                {/*            </p>*/}
                                {/*        </div>*/}
                                {/*    )}*/}
                                {!!typesNotAproved.length ? (
                                    typesNotAproved.map((type) => {
                                        const document = own.find(
                                            (document) => type.name === document.upload_doc_type.name,
                                        );

                                        return <DocumentType key={type?.id} document={document} {...type} />;
                                    })
                                ) : (
                                    <NotificationCard
                                        type={NotificationCardTypeEnum.action}
                                        size="l"
                                        title="На данный момент документы не требуются"
                                        titleLarge
                                    />
                                )}
                                {!!uploadedDocuments.length && (
                                    <div className="uploaded-docs">
                                        <DocumentList
                                            delete={true}
                                            documents={uploadedDocuments}
                                            title="Текущая загрузка"
                                            icon={<DeleteButton />}
                                            onDelete={handleDeleteUploadedDocument}
                                            needInputName
                                            needDropdown
                                            dropdownOptions={types.map((type) => type.name)}
                                            onChangeField={handleChangeInputFieldUploadedDoc}
                                        />
                                    </div>
                                )}

                                {parentTypesNotAproved?.length ? (
                                    <Row className={'mb-3 mt-3'}>
                                        <PageTitle title="Документы родителя" />
                                    </Row>
                                ) : null}

                                {parentTypesNotAproved.map((type) => {
                                    const document = parentDocuments.find(
                                        (document) => type?.id === document.upload_doc_type?.id,
                                    );

                                    return (
                                        <DocumentType onlyParent={true} key={type?.id} document={document} {...type} />
                                    );
                                })}
                                {/*{isCertRequested &&*/}
                                {/*    isOpenedFeedback &&*/}
                                {/*    answerStatus !== '' &&*/}
                                {/*    (showSuccessNotification || answerStatus !== 'success') && (*/}
                                {/*        <div className="enrollment-certificate__feedback">*/}
                                {/*            <NotificationCard*/}
                                {/*                size="s"*/}
                                {/*                title={*/}
                                {/*                    answerStatus === 'success'*/}
                                {/*                        ? 'Запрос справки успешно отправлен!'*/}
                                {/*                        : 'Произошла ошибка!'*/}
                                {/*                }*/}
                                {/*                text={*/}
                                {/*                    answerStatus === 'success'*/}
                                {/*                        ? 'Создание справки занимает до одной недели, в зависимости от учебного заведения.'*/}
                                {/*                        : 'Пожалуйста, повторите попытку через некоторое время или обратитесь в поддержку.'*/}
                                {/*                }*/}
                                {/*                type={*/}
                                {/*                    answerStatus === 'success'*/}
                                {/*                        ? NotificationCardTypeEnum.feedback*/}
                                {/*                        : NotificationCardTypeEnum.alert*/}
                                {/*                }*/}
                                {/*                closeButton*/}
                                {/*                handleCloseButtonClick={handleCloseFeedback}*/}
                                {/*            />*/}
                                {/*        </div>*/}
                                {/*    )}*/}
                            </Col>
                            <aside className="col-12 col-xl right-sidebar">
                                <div className={'mb-3'}>
                                    <DocumentList
                                        delete={false}
                                        documents={gymnasiumDocs.concat(contractsGymnasium)}
                                        title="Документы от Гимназии"
                                        showStatus={false}
                                        needDownload
                                    />
                                </div>

                                <div className={'mb-3'}>
                                    <DocumentList
                                        delete={false}
                                        documents={uniqueOwnDocuments}
                                        title="Документы от Вас"
                                        showType={false}
                                        showStatus={false}
                                    />
                                </div>
                            </aside>
                        </Row>
                    </div>
                </div>
            </Col>
        );
    } else {
        return (
            <Col className="home-wrapper position-relative documents-wrapper">
                <div className="custom-scroll">
                    <div className="document-warpper  cabinet-content__wrapper">
                        <div className="document__pageHeader-container">
                            {!!user && (
                                <PageHeader
                                    user={{
                                        fullName: fullName,
                                        img: user?.avatar,
                                    }}
                                    title="Документы"
                                />
                            )}
                        </div>
                        <Row>
                            <Col className="mb-5">
                                <div>
                                    {types.length
                                        ? types.map((type, index) => (
                                              <div key={`type-${index}`}>
                                                  <DocumentTypeHeader name={type.name} description={type.description} />
                                                  <p>Ожидание загрузки...</p>
                                                  <Skeleton className="documentType-skeleton-content" />
                                              </div>
                                          ))
                                        : [0, 0, 0].map((_, index) => (
                                              <div key={`skeleton-type-${index}`} className="documentType-skeleton">
                                                  <Skeleton className="documentType-skeleton-header" />
                                                  <Skeleton className="documentType-skeleton-content" />
                                              </div>
                                          ))}
                                </div>
                            </Col>
                            <aside className="col-12 col-xl right-sidebar">
                                <div>
                                    <h4 className="mb-3">Документы от Гимназии</h4>
                                    <ul className="list-group list-group-flush document-list document-list--pl document-list--sidebar right-sidebar__list">
                                        <div className="document-skeleton">
                                            {[0, 0, 0, 0].map((_, index) => (
                                                <Skeleton
                                                    key={`gym-skeleton-${index}`}
                                                    className="document-skeleton-content"
                                                />
                                            ))}
                                        </div>
                                    </ul>
                                    <h4 className="mb-3">Документы от Вас</h4>
                                    <ul className="list-group list-group-flush document-list document-list--pl document-list--sidebar right-sidebar__list">
                                        <div className="document-skeleton">
                                            {[0, 0, 0, 0].map((_, index) => (
                                                <Skeleton
                                                    key={`own-skeleton-${index}`}
                                                    className="document-skeleton-content"
                                                />
                                            ))}
                                        </div>
                                    </ul>
                                </div>
                            </aside>
                        </Row>
                    </div>
                </div>
            </Col>
        );
    }
};

export default connect(
    ({ documents, profile, user }: State) => ({
        ...documents,
        students: profile.user,
        profilePageStatus: profile.pageStatus,
        selectedProfile: profile.selectedProfile?.id,
        userPageStatus: user.pageStatus,
    }),
    (dispatch) => {
        return {
            loadDocuments: () => {
                return dispatch(loadDocuments());
            },

            deleteDocument: (id: number) => {
                return dispatch(deleteDocument(String(id)));
            },
        };
    },
)(Documents);
