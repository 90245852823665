import { BaseRestDataProvider } from './BaseRestDataProvider';
import { IRegistration, IRegistrationStudent, IStudentInfo, IUserInfo } from '../redux/types/registration';
import axios, { AxiosError, AxiosResponse } from 'axios';

export class RegistrationDataProvider extends BaseRestDataProvider {
    regUser = (value: IRegistration): Promise<any> => {
        return axios
            .post(`${this.host}/api/auth/users/`, {
                email: value.email,
                password: value.password,
            })
            .then((res: AxiosResponse<any>) => {
                return res?.data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    };

    regStudent = (value: IRegistrationStudent): Promise<any> => {
        return axios
            .post(`${this.host}/api/students/`, {
                email: value.email,
            })
            .then((res: AxiosResponse<any>) => {
                return res?.data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    };

    patchUserInfo = (value: IUserInfo | FormData): Promise<any> => {
        return axios
            .patch(`${this.host}/api/users/`, value)
            .then((res: AxiosResponse) => {
                return res?.data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    };

    patchStudentInfo = (receivedValue: IStudentInfo | FormData): Promise<any> => {
        let value = receivedValue;
        if ((receivedValue as IStudentInfo)?.photo) value = (receivedValue as IStudentInfo)?.photo;
        return axios
            .patch(`${this.host}/api/students/${(receivedValue as IStudentInfo).id}/`, value)
            .then((res: AxiosResponse) => {
                return res?.data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    };
}
