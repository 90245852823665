export const getMarkType = (itemName, date) => {
    return itemName.indexOf(date) !== -1
        ? itemName.slice(0, itemName.indexOf(date) - 1) +
              itemName.slice(itemName.indexOf(date) + date.length, itemName.length)
        : itemName;
};

export const getFiles = (submissions) => {
    const fileMatrix = submissions?.length
        ? submissions.map((submission) =>
              submission?.files.map((file) => ({
                  title: file?.filename,
                  url: file?.url,
              })),
          )
        : null;
    return fileMatrix?.length ? fileMatrix.reduce((acc, cur) => acc.concat(cur)) : null;
};
