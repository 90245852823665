import { Middleware, Dispatch } from 'redux';
import { State, Action } from '../store';

export const authMiddleware: Middleware<State, State, Dispatch<Action>> = (): ((
    next: Dispatch<Action>,
) => (action: Action) => Action) => (next: Dispatch<Action>): ((action: Action) => Action) => (
    action: Action,
): Action => {
    return next(action);
};
