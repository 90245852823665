export enum PenStatusEnum {
    awaiting = 'awaiting',
    saidThatPaid = 'said_that_paid',
    paid = 'paid'
}
export interface INotificationPenProps {
    onClickChangeStatus: () => void;
    onClickCancel: () => void;
    billUrl: string;
    trackingUrl?: string;
    status: PenStatusEnum;
}
