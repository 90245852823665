import { FuncEpic } from '../../common/types';
import { combineEpics, ofType } from 'redux-observable';
import { ActionType, getType } from 'typesafe-actions';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { AxiosError } from 'axios';
import { checkAuthError } from '../../helpers/checkAuthError';
import {
    getContractsError,
    getContractsStart,
    getContractsSuccess,
    patchContractSeenError,
    patchContractSeenStart,
    patchContractSeenSuccess,
    patchContractReadyToSignStart,
    patchContractReadyToSignError,
    patchContractReadyToSignSuccess,
    getSchoolInfoError,
    getSchoolInfoStart,
    getSchoolInfoSuccess,
    postContractHaveQuestionsError,
    postContractHaveQuestionsStart,
    postContractHaveQuestionsSuccess,
    postContractCourierAdressDataError,
    postContractCourierAdressDataStart,
    postContractCourierAdressDataSuccess,
    resetDataForTestError,
    resetDataForTestStart,
    resetDataForTestSuccess,
    patchContractMethodSignError,
    patchContractMethodSignStart,
    patchContractMethodSignSuccess,
    postMissingKonturUrlError,
    postMissingKonturUrlStart,
    postMissingKonturUrlSuccess,
    postRefuseToSignError,
    postRefuseToSignStart,
    postRefuseToSignSuccess,
    postCourierPaidError,
    postCourierPaidStart,
    postCourierPaidSuccess,
} from 'redux/actions/contracts';

const getContractsEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(getContractsStart)),
        switchMap(({ payload }: ActionType<typeof getContractsStart>) => {
            return from(deps.contractsDataProvider.getContracts(payload.userId)).pipe(
                switchMap((response: any) => {
                    return of(getContractsSuccess(response));
                }),
                catchError((err: AxiosError) => {
                    return of(getContractsError(err));
                }),
            );
        }),
    );
};

const patchContractSeenEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(patchContractSeenStart)),
        switchMap((payload: any) => {
            return from(deps.contractsDataProvider.patchContractSeen(payload.payload)).pipe(
                switchMap((response: any) => {
                    return of(patchContractSeenSuccess(response));
                }),
                catchError((err: AxiosError) => {
                    return of(patchContractSeenError(err));
                }),
            );
        }),
    );
};

const postRefuseToSignEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(postRefuseToSignStart)),
        switchMap((payload: any) => {
            return from(deps.contractsDataProvider.postRefuseToSign(payload.payload)).pipe(
                switchMap((response: any) => {
                    return of(postRefuseToSignSuccess(response));
                }),
                catchError((err: AxiosError) => {
                    return of(postRefuseToSignError(err));
                }),
            );
        }),
    );
};

const patchContractReadyToSignEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(patchContractReadyToSignStart)),
        switchMap((payload: any) => {
            return from(deps.contractsDataProvider.patchContractReadyToSign(payload.payload)).pipe(
                switchMap((response: any) => {
                    return of(patchContractReadyToSignSuccess(response));
                }),
                catchError((err: AxiosError) => {
                    return of(patchContractReadyToSignError(err));
                }),
            );
        }),
    );
};

const postContractHaveQuestionsEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(postContractHaveQuestionsStart)),
        switchMap((payload: any) => {
            return from(deps.contractsDataProvider.postContractHaveQuestions(payload.payload)).pipe(
                switchMap((response: any) => {
                    return of(postContractHaveQuestionsSuccess(response));
                }),
                catchError((err: AxiosError) => {
                    return of(postContractHaveQuestionsError(err));
                }),
            );
        }),
    );
};

const postMissingKonturUrlEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(postMissingKonturUrlStart)),
        switchMap((payload: any) => {
            return from(deps.contractsDataProvider.postMissingKonturUrl(payload.payload)).pipe(
                switchMap((response: any) => {
                    return of(postMissingKonturUrlSuccess(response));
                }),
                catchError((err: AxiosError) => {
                    return of(postMissingKonturUrlError(err));
                }),
            );
        }),
    );
};

const patchContractMethodSignEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(patchContractMethodSignStart)),
        switchMap((payload: any) => {
            return from(deps.contractsDataProvider.patchContractMethodSign(payload.payload)).pipe(
                switchMap((response: any) => {
                    return of(patchContractMethodSignSuccess(response));
                }),
                catchError((err: AxiosError) => {
                    return of(patchContractMethodSignError(err));
                }),
            );
        }),
    );
};

const getSchoolInfoEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(getSchoolInfoStart)),
        switchMap((payload: { payload: any[] }) => {
            const arrayPromises: Promise<any>[] = [];
            payload.payload?.forEach((student) =>
                arrayPromises.push(deps.contractsDataProvider.getSchoolInfo(student.id)),
            );
            return from(Promise.all(arrayPromises)).pipe(
                switchMap((response: any) => {
                    return of(getSchoolInfoSuccess(response));
                }),
                catchError((err: AxiosError) => {
                    return of(getSchoolInfoError(err));
                }),
            );
        }),
    );
};

const postContractCourierAdressDataEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(postContractCourierAdressDataStart)),
        switchMap((payload: any) => {
            return from(deps.contractsDataProvider.postContractCourierAdressData(payload.payload)).pipe(
                switchMap((response: any) => {
                    return of(postContractCourierAdressDataSuccess(response));
                }),
                catchError((err: AxiosError) => {
                    return of(postContractCourierAdressDataError(err));
                }),
            );
        }),
    );
};

const postCourierPaidEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(postCourierPaidStart)),
        switchMap((payload: any) => {
            return from(deps.contractsDataProvider.postCourierPaid(payload.payload)).pipe(
                switchMap((response: any) => {
                    return of(postCourierPaidSuccess(response));
                }),
                catchError((err: AxiosError) => {
                    return of(postCourierPaidError(err));
                }),
            );
        }),
    );
};

const resetDataForTestEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(resetDataForTestStart)),
        switchMap((payload: any) => {
            return from(deps.contractsDataProvider.resetDataForTest(payload.payload)).pipe(
                switchMap((response: any) => {
                    return of(resetDataForTestSuccess(response));
                }),
                catchError((err: AxiosError) => {
                    return of(resetDataForTestError(err));
                }),
            );
        }),
    );
};

export const contractsEpics = combineEpics(
    getContractsEpic,
    patchContractSeenEpic,
    postRefuseToSignEpic,
    patchContractReadyToSignEpic,
    postContractHaveQuestionsEpic,
    patchContractMethodSignEpic,
    getSchoolInfoEpic,
    postContractCourierAdressDataEpic,
    postCourierPaidEpic,
    resetDataForTestEpic,
    postMissingKonturUrlEpic,
);
