import { AxiosError } from 'axios';
import { combineEpics, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ActionType, getType } from 'typesafe-actions';

import { FuncEpic, PageStatus } from '../../common/types';
import { checkAuthError } from '../../helpers/checkAuthError';
import {
    changeMeeting,
    changeMeetingError,
    changeMeetingSuccess,
    createMeeting,
    createMeetingError,
    createMeetingSuccess,
    deleteMeeting,
    deleteMeetingError,
    deleteMeetingSuccess,
    loadMeetingsRequests,
    loadMeetingsRequestsError,
    loadMeetingsRequestsSuccess,
    loadScheduleByIdError,
    loadScheduleByIdStart,
    loadScheduleByIdSuccess,
    loadTeachers,
    loadTeacherSchedule,
    loadTeacherScheduleError,
    loadTeacherScheduleSuccess,
    loadTeachersError,
    loadTeachersSuccess,
    postInitiatedMeetingError,
    postInitiatedMeetingStart,
    postInitiatedMeetingSuccess,
} from '../actions/meetings';
import { loadNear } from '../actions/near';

const loadMeetingsEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(loadTeachers)),
        switchMap(() => {
            return from(deps.meetingsDataProvider.loadTeachers()).pipe(
                switchMap((meetings) => {
                    return of(loadTeachersSuccess(meetings));
                }),
                catchError((err) => {
                    return of(loadTeachersSuccess([]));
                }),
            );
        }),
    );
};

const loadScheduleByIdEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(loadScheduleByIdStart)),
        switchMap(({ payload }: ActionType<typeof loadScheduleByIdStart>) => {
            return from(deps.meetingsDataProvider.loadScheduleById(payload)).pipe(
                switchMap((response: any) => {
                    return of(loadScheduleByIdSuccess(response));
                }),
                catchError((err: AxiosError) => {
                    return of(loadScheduleByIdError(err));
                }),
            );
        }),
    );
};

const postInitiatedMeetingEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(postInitiatedMeetingStart)),
        switchMap(({ payload }: ActionType<typeof postInitiatedMeetingStart>) => {
            return from(deps.meetingsDataProvider.postInitiatedMeeting(payload)).pipe(
                switchMap((response: any) => {
                    return of(postInitiatedMeetingSuccess(response));
                }),
                catchError((err: AxiosError) => {
                    return of(postInitiatedMeetingError(err));
                }),
            );
        }),
    );
};

const createMeetingEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(createMeeting)),
        switchMap(({ payload }: ActionType<typeof createMeeting>) => {
            return from(deps.meetingsDataProvider.createMeeting(payload)).pipe(
                switchMap((response) => {
                    return of(createMeetingSuccess(response), loadNear(PageStatus.LOADING), loadMeetingsRequests());
                }),
                catchError((err) => {
                    return of(createMeetingError(err));
                }),
            );
        }),
    );
};

const changeMeetingEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(changeMeeting)),
        switchMap(({ payload: { start_time, selectedMeeting, subject } }: ActionType<typeof changeMeeting>) => {
            return from(deps.meetingsDataProvider.changeMeeting(start_time, selectedMeeting, subject)).pipe(
                switchMap((response) => {
                    return of(changeMeetingSuccess(response), loadNear(PageStatus.LOADING));
                }),
                catchError((err) => {
                    return of(changeMeetingError(err));
                }),
            );
        }),
    );
};

const deleteMeetingEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(deleteMeeting)),
        switchMap(({ payload }: ActionType<typeof deleteMeeting>) => {
            return from(deps.meetingsDataProvider.deleteMeeting(payload)).pipe(
                switchMap((response) => {
                    return of(deleteMeetingSuccess(response), loadNear(PageStatus.LOADING), {
                        type: '@@router/LOCATION_CHANGE',
                        payload: {
                            location: {
                                pathname: '/',
                            },
                            action: 'PUSH',
                            isFirstRendering: false,
                        },
                    });
                }),
                catchError((err) => {
                    return of(deleteMeetingError(err));
                }),
            );
        }),
    );
};

const loadTeacherScheduleEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(loadTeacherSchedule)),
        switchMap(({ payload }: ActionType<typeof loadTeacherSchedule>) => {
            return from(deps.meetingsDataProvider.loadTeacherSchedule(payload)).pipe(
                switchMap((slots) => {
                    return of(loadTeacherScheduleSuccess(slots));
                }),
                catchError((err) => {
                    return of(loadTeacherScheduleError(err));
                }),
            );
        }),
    );
};

const loadMeetingsRequestsEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(loadMeetingsRequests)),
        switchMap(() => {
            return from(deps.meetingsDataProvider.loadMeetingsRequests()).pipe(
                switchMap((res) => {
                    return of(loadMeetingsRequestsSuccess(res));
                }),
                catchError((err) => {
                    return of(loadMeetingsRequestsError(err));
                }),
            );
        }),
    );
};

export const meetingsEpics = combineEpics(
    loadMeetingsEpic,
    createMeetingEpic,
    changeMeetingEpic,
    deleteMeetingEpic,
    loadTeacherScheduleEpic,
    loadScheduleByIdEpic,
    postInitiatedMeetingEpic,
    loadMeetingsRequestsEpic,
);
