import { IResetPassword } from "api/ResetPasswordDataProvider";
import { combineEpics, ofType } from "redux-observable";
import { sendResetEmail, sendResetEmailSuccess, sendResetEmailError, changePassword, changePasswordSuccess, changePasswordError } from "redux/actions/resetPassword";
import { from, of } from "rxjs";
import { catchError, switchMap, } from "rxjs/operators";
import { getType } from "typesafe-actions";
import { FuncEpic } from "../../common/types";
import { checkAuthError } from "../../helpers/checkAuthError";

const sendResetEmailEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(sendResetEmail)),
        switchMap((value : any) => {
            return from(deps.resetPasswordDataProvider.sendEmailWithLink(value.payload)).pipe(
                switchMap((response : any) => {
                    return of(sendResetEmailSuccess(response));
                }),
                catchError((error: any) => {
                    return of(sendResetEmailError(error));
                })
            )
        })
    )
}

const changePasswordEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(getType(changePassword)),
        switchMap((value : any) => {
            return from(deps.resetPasswordDataProvider.createNewPassword(value.payload)).pipe(
                switchMap(success => {
                    return of(changePasswordSuccess(success));
                }),
                catchError(error => {
                    return of(changePasswordError(error));
                })
            )
        })
    )
}

export const resetPassword = combineEpics(sendResetEmailEpic, changePasswordEpic);