export function getFilterParams(filter: any) {
    let searchParam = '';
    if (filter) {
        Object.keys(filter).forEach((key) => {
            const value = filter[key];
            if (!value) return;
            if (Array.isArray(value)) {
                value.forEach((valueItem) => {
                    searchParam += `&${key}=${valueItem}`;
                });
            } else {
                searchParam += `&${key}=${value}`;
            }
        });
    }

    return searchParam;
}
