import { BaseRestDataProvider } from './BaseRestDataProvider';
import axios, { AxiosError, AxiosResponse } from 'axios';

export class ContractsDataProvider extends BaseRestDataProvider {
    getContracts = (userId: number): Promise<any> => {
        return axios
            .get(`${this.host}/payment/contracts/?counterparty=${userId}`)
            .then((res: AxiosResponse<any>) => {
                return res?.data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    };

    patchContractSeen = (value: { id: number; value: boolean }): Promise<any> => {
        return axios
            .patch(`${this.host}/payment/contracts/${value.id}/`, {
                parent_seen: value.value,
            })
            .then((res: AxiosResponse<any>) => {
                return res?.data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    };

    postRefuseToSign = (id: number): Promise<any> => {
        return axios
            .post(`${this.host}/payment/contracts/${id}/refuse_to_sign_contract/`)
            .then((res: AxiosResponse<any>) => {
                return res?.data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    };

    patchContractReadyToSign = (value: { id: number; readyToSign: boolean }): Promise<any> => {
        return axios
            .patch(`${this.host}/payment/contracts/${value.id}/`, {
                parent_seen: value.readyToSign,
                ready_to_sign: value.readyToSign,
                contract_ready: value.readyToSign,
            })
            .then((res: AxiosResponse<any>) => {
                return res?.data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    };

    postContractHaveQuestions = (id: number): Promise<any> => {
        return axios
            .post(`${this.host}/payment/contracts/${id}/contract_questions/`)
            .then((res: AxiosResponse<any>) => {
                return res?.data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    };

    postMissingKonturUrl = (id: number): Promise<any> => {
        return axios
            .post(`${this.host}/payment/contracts/${id}/missing_kontur_url/`)
            .then((res: AxiosResponse<any>) => {
                return res?.data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    };

    patchContractMethodSign = (value: { id: number; edc: boolean; courier: boolean }): Promise<any> => {
        return axios
            .patch(`${this.host}/payment/contracts/${value.id}/`, {
                want_to_sign_by_edc: value.edc,
                want_to_sign_by_courier: value.courier,
            })
            .then((res: AxiosResponse<any>) => {
                return res?.data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    };

    getSchoolInfo = (id: number): Promise<any> => {
        return axios
            .get(`${this.host}/api/students/${id}/`)
            .then((res: AxiosResponse<any>) => {
                return res?.data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    };

    postContractCourierAdressData = (value: { id: number; adress: string }): Promise<any> => {
        return axios
            .post(`${this.host}/payment/contracts/${value.id}/set_delivery_address/`, {
                delivery_address: value.adress,
            })
            .then((res: AxiosResponse<any>) => {
                return res?.data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    };

    postCourierPaid = (id: number): Promise<any> => {
        return axios
            .post(`${this.host}/payment/contracts/${id}/courier_paid/`)
            .then((res: AxiosResponse<any>) => {
                return res?.data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    };

    resetDataForTest = (id: number): Promise<any> => {
        return axios
            .patch(`${this.host}/payment/contracts/${id}/`, {
                parent_seen: false,
                ready_to_sign: false,
                delivery_address: '',
                want_to_sign_by_courier: false,
                want_to_sign_by_edc: false,
                contract_ready: true,
                kontur_sign_url: 'https://www.google.com/',
            })
            .then((res: AxiosResponse<any>) => {
                return res?.data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    };
}
