import { AxiosError } from 'axios';
import { ActionType, createAction } from 'typesafe-actions';
import {
    CreateContractExtensionPayload,
    CreateTrialDayPayload,
    FillContractExtensionPayload,
    IProcess,
    TrialDay,
} from 'api/ProcessesDataProvider.types';

export const fetchProcessesStart = createAction('processes/fetchProcesses/start')();
export const fetchProcessesSuccess = createAction('processes/fetchProcesses/success')<Array<IProcess>>();
export const fetchProcessesError = createAction('processes/fetchProcesses/error')<AxiosError>();

export const fetchTrialDays = createAction('processes/fetchTrialDays/start')<number>();
export const fetchTrialDaysSuccess = createAction('processes/fetchTrialDays/success')<Array<TrialDay>>();
export const fetchTrialDaysError = createAction('processes/fetchTrialDays/error')<AxiosError>();

export const createTrialDay = createAction('processes/createTrialDay/start')<CreateTrialDayPayload>();
export const createTrialDaySuccess = createAction('processes/createTrialDay/success')();
export const createTrialDayError = createAction('processes/createTrialDay/error')<AxiosError>();

export const createContractExtension = createAction(
    'processes/createContractExtension/start',
)<CreateContractExtensionPayload>();
export const createContractExtensionSuccess = createAction('processes/createContractExtension/success')<string>();
export const createContractExtensionError = createAction('processes/createContractExtension/error')<AxiosError>();

export const fillContractExtension = createAction(
    'processes/fillContractExtension/start',
)<FillContractExtensionPayload>();

export type ProcessesActions =
    | ActionType<typeof fetchProcessesStart>
    | ActionType<typeof fetchProcessesSuccess>
    | ActionType<typeof fetchProcessesError>
    | ActionType<typeof fetchTrialDays>
    | ActionType<typeof fetchTrialDaysSuccess>
    | ActionType<typeof fetchTrialDaysError>
    | ActionType<typeof createTrialDay>
    | ActionType<typeof createTrialDaySuccess>
    | ActionType<typeof createTrialDayError>
    | ActionType<typeof createContractExtension>
    | ActionType<typeof createContractExtensionSuccess>
    | ActionType<typeof createContractExtensionError>
    | ActionType<typeof fillContractExtension>;
