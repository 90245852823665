import React, { useEffect } from 'react';
import { Col } from 'react-bootstrap';
import './PaymentResult.scss';
import { connect } from 'react-redux';
import { State } from '../../../../redux/store';
import { loadPaymentInfo } from '../../../../redux/actions/paymentInfo';
import { IPaymentProps } from './types';
import { PageStatus } from '../../../../common/types';
import Skeleton from 'react-loading-skeleton';

export const PaymentResultFail: React.FC<IPaymentProps> = ({ pageStatus, info, loadPaymentInfo, location }) => {
    useEffect(() => {
        let orderId = location.query?.orderId;
        loadPaymentInfo(orderId);
    }, [location]);

    return (
        <Col className="px-0 position-relative">
            <div className="cabinet-content custom-scroll">
                <div className="cabinet-content__wrapper">
                    <div className="row">
                        <div className="col-12 col-xxl mb-5">
                            <div className="row mb-3">
                                <div className="col-12 cabinet-content__header">
                                    <h4 className="cabinet-content__title">Оплата</h4>
                                </div>
                                <div className="col">
                                    {pageStatus === PageStatus.LOADED ? (
                                        <>
                                            {info.order_number ? (
                                                <div className="cabinet-content__text">
                                                    <p>
                                                        Оплата на сумму{' '}
                                                        <span className="cabinet-content__text--bold">
                                                            {info.sum} руб.
                                                        </span>
                                                        {info.contracts.length === 1 && (
                                                            <>
                                                                {' '}
                                                                по договору{' '}
                                                                <span className="cabinet-content__text--bold">
                                                                    № {info.contracts[0].number}
                                                                </span>
                                                            </>
                                                        )}
                                                        {info.contracts.length > 1 && (
                                                            <>
                                                                {' '}
                                                                по договорам{' '}
                                                                <span className="cabinet-content__text--bold">
                                                                    №{' '}
                                                                    {info.contracts.map(
                                                                        (contract: any) => contract.number + ' ',
                                                                    )}
                                                                </span>
                                                            </>
                                                        )}
                                                    </p>
                                                </div>
                                            ) : null}
                                        </>
                                    ) : pageStatus === PageStatus.LOADING ? (
                                        <Skeleton height={30} width={200} />
                                    ) : null}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="text-result text-danger">
                                        <p>
                                            <i className="icon-cancel text-result__icon" /> Ошибка оплаты
                                        </p>
                                    </div>
                                    {/*<div className="text-block">*/}
                                    {/*    <h6 className="text-block__title">Причина ошибки:</h6>*/}
                                    {/*    <p className="text-secondary">*/}
                                    {/*        Lorem ipsum dolor sit amet, consectetur adipisicing elit.*/}
                                    {/*    </p>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Col>
    );
};

export default connect(
    ({ paymentInfo, router }: State) => ({ ...paymentInfo, location: router.location }),
    (dispatch) => {
        return {
            loadPaymentInfo: (order: string) => {
                return dispatch(loadPaymentInfo(order));
            },
        };
    },
)(PaymentResultFail);
