import { getType } from 'typesafe-actions';

import {
    addToPayment,
    createPaymentError,
    createPaymentStart,
    createPaymentSuccess,
    fetchDebtPaymentsError,
    fetchDebtPaymentsStart,
    fetchDebtPaymentsSuccess,
    fetchUpcomingPaymentsError,
    fetchUpcomingPaymentsStart,
    fetchUpcomingPaymentsSuccess,
    loadPaidPaymentContracts,
    loadPaidPaymentContractsError,
    loadPaidPaymentContractsSuccess,
    loadPaymentContracts,
    loadPaymentContractsError,
    loadPaymentContractsSuccess,
    removeFromPayment,
} from 'redux/actions/payment';
import { Action } from 'redux/store';
import { PaymentState } from 'redux/types/payment';
import { PageStatus } from 'common/types';

const defaultPaymentState: PaymentState = {
    pageStatus: PageStatus.LOADING,
    contracts: null,
    paidContracts: null,
    error: null,
    paymentAreasId: {
        bills: [],
        addEquipmentBills: [],
    },
    totalSum: {
        bills: 0,
        addEquipmentBills: 0,
    },
    status: 'CREATE',
    urlSber: '',
    upcomingPayments: {
        results: [],
    },
    debtPayments: {
        results: [],
    },
};

export const payment = (state: PaymentState = defaultPaymentState, action: Action): PaymentState => {
    switch (action.type) {
        case getType(loadPaymentContracts): {
            return {
                ...state,
                pageStatus: PageStatus.LOADING,
                paymentAreasId: {
                    bills: [],
                    addEquipmentBills: [],
                },
                totalSum: {
                    bills: 0,
                    addEquipmentBills: 0,
                },
                status: 'CREATE',
            };
        }

        case getType(loadPaymentContractsSuccess): {
            return {
                ...state,
                pageStatus: PageStatus.LOADED,
                contracts: action.payload,
            };
        }

        case getType(loadPaymentContractsError): {
            return {
                ...state,
                pageStatus: PageStatus.ERROR,
                contracts: action.payload,
            };
        }

        case getType(loadPaidPaymentContracts): {
            return {
                ...state,
                pageStatus: PageStatus.LOADING,
                status: 'CREATE',
            };
        }

        case getType(loadPaidPaymentContractsSuccess): {
            return {
                ...state,
                pageStatus: PageStatus.LOADED,
                paidContracts: action.payload,
            };
        }

        case getType(loadPaidPaymentContractsError): {
            return {
                ...state,
                pageStatus: PageStatus.ERROR,
                paidContracts: action.payload,
            };
        }

        case getType(createPaymentStart): {
            return {
                ...state,
                pageStatus: PageStatus.LOADING,
            };
        }

        case getType(createPaymentSuccess): {
            return {
                ...state,
                pageStatus: PageStatus.LOADED,
                paymentAreasId: {
                    bills: [],
                    addEquipmentBills: [],
                },
                totalSum: {
                    bills: 0,
                    addEquipmentBills: 0,
                },
                urlSber: action.payload.url,
                status: 'SUCCESS',
            };
        }

        case getType(createPaymentError): {
            return {
                ...state,
                pageStatus: PageStatus.ERROR,
                error: action.payload,
                status: 'ERROR',
            };
        }

        case getType(addToPayment): {
            return {
                ...state,
                totalSum: {
                    ...state.totalSum,
                    [action.payload.type]: state.totalSum[action.payload.type] + action.payload.sum,
                },
                paymentAreasId: {
                    ...state.paymentAreasId,
                    [action.payload.type]: [...state.paymentAreasId[action.payload.type], action.payload.id],
                },
            };
        }

        case getType(removeFromPayment): {
            return {
                ...state,
                totalSum: {
                    ...state.totalSum,
                    [action.payload.type]: state.totalSum[action.payload.type] - action.payload.sum,
                },
                paymentAreasId: {
                    ...state.paymentAreasId,
                    [action.payload.type]: state.paymentAreasId[action.payload.type].filter(
                        (el) => el !== action.payload.id,
                    ),
                },
            };
        }

        case getType(fetchUpcomingPaymentsStart): {
            return {
                ...state,
                pageStatus: PageStatus.LOADING,
                error: null,
            };
        }

        case getType(fetchUpcomingPaymentsSuccess): {
            return {
                ...state,
                pageStatus: PageStatus.LOADED,
                upcomingPayments: action.payload,
                error: null,
            };
        }

        case getType(fetchUpcomingPaymentsError): {
            return {
                ...state,
                pageStatus: PageStatus.ERROR,
                error: action.payload,
            };
        }

        case getType(fetchDebtPaymentsStart): {
            return {
                ...state,
                pageStatus: PageStatus.LOADING,
                error: null,
            };
        }

        case getType(fetchDebtPaymentsSuccess): {
            return {
                ...state,
                pageStatus: PageStatus.LOADED,
                debtPayments: action.payload,
                error: null,
            };
        }

        case getType(fetchDebtPaymentsError): {
            return {
                ...state,
                pageStatus: PageStatus.ERROR,
                error: action.payload,
            };
        }

        default: {
            return state;
        }
    }
};
