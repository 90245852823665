import { FetchStatus } from 'common/types';
import {
    getPenDocumentsAction,
    getPenDocumentsActionError,
    getPenDocumentsActionSuccess,
    postChangePenDocumentsAction,
    postChangePenDocumentsActionError,
    postChangePenDocumentsActionSuccess,
    postRefusePenDocumentsAction,
    postRefusePenDocumentsActionError,
    postRefusePenDocumentsActionSuccess,
} from 'redux/actions/penDocument';
import { Action } from 'redux/store';
import { PenDocumentState } from 'redux/types/penDocument';
import { getType } from 'typesafe-actions';

import { PenStatusEnum } from 'components/Notification/NotificationPen/NotificationPen.types';

const penDocumentInitialState: PenDocumentState = {
    getStatusPenDocument: FetchStatus.INITIAL,
    postStatusChangePenDocument: FetchStatus.INITIAL,
    postStatusRefusePenDocument: FetchStatus.INITIAL,
    penDocuments: [],
    error: null,
};

export const penDocumentReducer = (
    state: PenDocumentState = penDocumentInitialState,
    action: Action,
): PenDocumentState => {
    switch (action.type) {
        case getType(getPenDocumentsAction):
            return {
                ...state,
                getStatusPenDocument: FetchStatus.FETCHING,
            };
        case getType(getPenDocumentsActionSuccess):
            return {
                ...state,
                getStatusPenDocument: FetchStatus.FETCHED,
                penDocuments: action.payload,
            };
        case getType(getPenDocumentsActionError):
            return {
                ...state,
                getStatusPenDocument: FetchStatus.ERROR,
                error: action.payload,
            };

        case getType(postChangePenDocumentsAction):
            return {
                ...state,
                postStatusChangePenDocument: FetchStatus.FETCHING,
            };
        case getType(postChangePenDocumentsActionSuccess):
            return {
                ...state,
                postStatusChangePenDocument: FetchStatus.FETCHED,
                penDocuments: state.penDocuments.map((doc) =>
                    doc.id === action.payload ? { ...doc, status: PenStatusEnum.saidThatPaid } : doc,
                ),
            };
        case getType(postChangePenDocumentsActionError):
            return {
                ...state,
                postStatusChangePenDocument: FetchStatus.ERROR,
                error: action.payload,
            };

        case getType(postRefusePenDocumentsAction):
            return {
                ...state,
                postStatusRefusePenDocument: FetchStatus.FETCHING,
            };
        case getType(postRefusePenDocumentsActionSuccess):
            return {
                ...state,
                postStatusRefusePenDocument: FetchStatus.FETCHED,
                penDocuments: state.penDocuments.map((doc) =>
                    doc.id === action.payload ? { ...doc, rejected: true } : doc,
                ),
            };
        case getType(postRefusePenDocumentsActionError):
            return {
                ...state,
                postStatusRefusePenDocument: FetchStatus.ERROR,
                error: action.payload,
            };
        default:
            return state;
    }
};
