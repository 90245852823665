import { IUserFromList } from '../../redux/types/users';
import { IParentData, IPhoneNumber } from './Settings.types';
import { convertToDateStr } from '../../common/helpers';
import { Grade } from './TrialSignUp';

const gradesAssociations = {
    5: Grade.fifth,
    6: Grade.sixth,
    7: Grade.seventh,
    8: Grade.eighth,
    9: Grade.ninth,
    10: Grade.tenth,
};

export const convertGradeNumberToString = (gradeNumber: number): Grade => gradesAssociations[gradeNumber] ?? '';

export const sortPhoneNumbers = (phoneNumbers: IPhoneNumber[]): IPhoneNumber[] =>
    phoneNumbers.sort((a, b) => (a.default > b.default ? -1 : a.default < b.default ? 1 : 0));

export const convertPhoneNumbersToString = (phoneNumbers: IPhoneNumber[]): string[] =>
    phoneNumbers.map((phone) => `${phone.country_code}${phone.phone_number}`);

export const normalizePhoneNumbers = (phoneNumbers: IPhoneNumber[]): string[] =>
    convertPhoneNumbersToString(sortPhoneNumbers(phoneNumbers));

export const getSettingsData = (selectedData: any) => {
    return {
        avatar: selectedData?.avatar,
        last_name: selectedData?.last_name,
        first_name: selectedData?.first_name,
        patronymic: selectedData?.patronymic,
        phone_numbers: normalizePhoneNumbers(selectedData?.phone_numbers || []),
        gender: selectedData?.gender,
        email: selectedData?.email,
        is_russian_citizen: selectedData?.is_russian_citizen,
        living_place: selectedData?.living_place,
        passport_series: selectedData?.passport_series,
        passport_number: selectedData?.passport_number,
        birthday: convertToDateStr(selectedData?.birthday),
        passport_authority: selectedData?.passport_authority,
        passport_date_issue: convertToDateStr(selectedData?.passport_date_issue),
        delivery_address: selectedData?.delivery_address,
        citizenship: selectedData?.citizenship,
        birth_certificate: selectedData?.birth_certificate,
        foreign_citizen_doc: selectedData?.foreign_citizen_doc,
    };
};

export const getParentsData = (users: IUserFromList[], parentsId: number[], currentUserId: number): IParentData[] => {
    const parents =
        users?.length && parentsId?.length && currentUserId !== undefined
            ? users.filter((user) => parentsId.includes(user.id) && user.id !== currentUserId)
            : null;

    return parents
        ? parents.map((parent) => ({
              last_name: parent.last_name,
              first_name: parent.first_name,
              patronymic: parent.patronymic,
          }))
        : null;
};

export const checkCountryCode = (receivedCode: string, countryCodesObj: Record<string, string>): string => {
    if (receivedCode) {
        const pureCode = receivedCode.startsWith('+') ? receivedCode.slice(1) : receivedCode;
        const countryCode = Object.values(countryCodesObj).filter((code) => pureCode.indexOf(code.slice(1)) === 0)[0];
        return countryCode ? countryCode.slice(1) : pureCode[0] === '8' ? '7' : null;
    }
    return null;
};

export const swapKeysToValues = (obj: Record<any, string>): Record<string, any> => {
    return Object.fromEntries(Object.entries(obj).map(([key, value]) => [value, key]));
};
