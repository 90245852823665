import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Navbar } from 'components/Navbar';
import { Cup, Folders, Home, Payment, Reporting, Schedule } from '@lms-elements/icons';
import defaultAvatar from 'assets/img/default-avatar.png';
import classNames from 'classnames';
import { FetchStatus, PageStatus } from 'common/types';
import { postDirectorAnswerAction, resetPostDirectorAnswerStatus } from 'redux/actions/directorAnswer';
import { useAppSelector } from 'redux/store';
import Cookies from 'universal-cookie';
import { ReactComponent as ContractsIcon } from '../../assets/img/contracts.svg';

import { DirectorModalNotification } from 'components/DirectorModal/DirectorModalNotification';

import { loadConstantsStart } from '../../redux/actions/constants';
import { logout } from '../../redux/actions/login';
import { fetchProcessesStart, fetchTrialDays } from '../../redux/actions/processes';
import { loadSelectedProfile, selectProfile } from '../../redux/actions/profile';
import { loadUser } from '../../redux/actions/user';

import { getMobileTooltipItems } from './Header.utils';

import './Header.scss';
import { TARIFF_CURIOUS_ID } from '../../common/constants';
import { ProgressSvg, ChooseCoursesSvg } from '../../assets';
import { removeAccessToken, removeRefreshToken } from '../../common/token';

const newLkPages = [
    '/new',
    '/new/performance',
    '/new/auth',
    '/new/registration',
    '/new/passwordRecovery',
    '/new/approver',
    '/new/contracts',
    '/new/additional-courses',
    '/new/questionnaire',
    '/new/finance',
    '/new/payment',
    '/new/choose-courses',
    '/new/flocktory',
];

const Header: React.FC = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { profile } = useAppSelector((store) => store.selectedProfile);
    const { pages } = useAppSelector((store) => store.meta) || {};
    const { user: students, pageStatus: studentsFetchStatus, selectedProfile } = useAppSelector(
        (store) => store.profile,
    );
    const { user: parent } = useAppSelector((store) => store.user);

    const { constants } = useAppSelector((store) => store.constants);

    useEffect(() => {
        if (newLkPages.some((page) => location.pathname.includes(page))) window.location.reload();
    }, [location.pathname]);

    const navbarItems = useMemo(() => {
        const basicItems = [
            {
                icon: <Home />,
                text: 'Обзор',
                path: '/new',
                type: 'home',
            },
        ];
        const schedule = {
            icon: <Schedule />,
            text: 'Расписание',
            path: '/schedule',
            type: 'schedule',
        };
        const progress = {
            icon: <img src={ProgressSvg} />,
            text: 'Прогресс',
            path: `/new/progress?=student${selectedProfile?.id}`,
            type: 'progress',
        };
        const performance = {
            icon: <Cup />,
            text: 'Успеваемость',
            path: `/new/performance?=student${selectedProfile?.id}`,
            type: 'performance',
        };
        const additionalCourses = {
            icon: <Reporting />,
            text: 'Доп. курсы',
            path: `/new/additional-courses?=student${selectedProfile?.id}`,
            type: 'additionalCourses',
        };
        // const courses = {
        //     icon: <Book />,
        //     text: 'Доп. курсы',
        //     path: '/courses',
        // },
        const finance = {
            icon: <Payment />,
            text: 'Финансы',
            path: '/new/finance',
            type: 'finance',
        };
        const payment = {
            icon: <Payment />,
            text: 'Оплата',
            path: '/new/payment',
            type: 'payment',
        };
        const documents = {
            icon: <Folders />,
            text: 'Документы',
            path: '/documents',
            type: 'documents',
        };
        const contracts = {
            icon: <ContractsIcon />,
            text: 'Договоры',
            path: `/new/contracts?=student${selectedProfile?.id}`,
            type: 'contracts',
        };
        const chooseCourses = {
            icon: <img src={ChooseCoursesSvg} />,
            text: 'Предметы по выбору',
            path: `/new/choose-courses?=student${selectedProfile?.id}`,
            type: 'chooseCourses',
        };
        const lmsItems = [
            schedule,
            progress,
            performance,
            // additionalCourses,
            chooseCourses,
            // courses
        ];
        const documentsItems = [finance, payment, documents, contracts];
        let item = [];
        if (selectedProfile?.tariff == TARIFF_CURIOUS_ID) {
            item = [...basicItems, progress, finance, payment];
        } else if (selectedProfile?.lms_id) {
            item = [...basicItems, ...lmsItems, ...documentsItems];
        } else {
            item = [...basicItems, progress, ...documentsItems];
        }
        let allowedPages = [];
        if (pages) {
            allowedPages = Object.keys(pages);
        }
        return item.filter((item) => allowedPages.includes(item.type));
    }, [selectedProfile, pages]);

    const handleStudentClick = useCallback(
        (studentId: number) => {
            const selectedStudent = students.find((student) => student.id === studentId);
            if (selectedStudent?.id !== selectedProfile?.id) {
                dispatch(selectProfile(selectedStudent));
                dispatch(loadSelectedProfile(PageStatus.INITIAL, selectedStudent));
            }
        },
        [students, selectedProfile],
    );

    const handleSettingClick = useCallback(
        (studentId: number) => {
            history.push(`/settings/${studentId}`);
        },
        [history],
    );

    const navbarStudents = useMemo(
        () =>
            students?.map((student) => ({
                id: student.id,
                firstName: student.first_name,
                lastName: student.last_name,
                photoUrl: student.photo || defaultAvatar,
                grade: student.grade ? `${student.grade} класс` : '',
                selected: selectedProfile?.id === student?.id,
                onStudentClick: handleStudentClick,
                onSettingClick: handleSettingClick,
                tariff: student.tariff,
            })),
        [students, studentsFetchStatus, selectedProfile, handleStudentClick],
    );

    const handleSettingsClick = useCallback(() => {
        history.push(`/settings/parent`);
    }, [history]);

    const handleLogoutClick = useCallback(() => {
        const cookie = new Cookies();
        removeAccessToken();
        removeRefreshToken();
        cookie.remove('students');
        cookie.remove('user');
        dispatch(logout());
        history.push(`/new/auth/login?path=${window.location.pathname}`);
    }, [dispatch, history]);

    const tooltipItems = getMobileTooltipItems({ handleSettingsClick, handleLogoutClick });

    /*DIRECTOR MODAL LOGIC*/

    const { postDirectorAnswerStatus } = useAppSelector((store) => store.directorAnswer);
    const [answerStatus, setAnswerStatus] = useState('');
    const autoHiddenTimeout = useRef(setTimeout(() => undefined, 0));

    const handleAnswerQuestionClick = useCallback(() => {
        setAnswerStatus('');
        clearTimeout(autoHiddenTimeout.current);
    }, []);

    const handleSubmitDirectorAnswer = useCallback(
        (title, text) => {
            dispatch(postDirectorAnswerAction({ message: text, theme: title }));
        },
        [dispatch],
    );

    useEffect(() => {
        if (profile?.id) {
            dispatch(fetchProcessesStart());
            dispatch(fetchTrialDays(profile.id));
        }
    }, [dispatch, profile?.id]);

    useEffect(() => {
        if (postDirectorAnswerStatus === FetchStatus.FETCHED) {
            setAnswerStatus('success');
            autoHiddenTimeout.current = setTimeout(() => {
                dispatch(resetPostDirectorAnswerStatus());
                setAnswerStatus('');
            }, 5000);
        }
        if (postDirectorAnswerStatus === FetchStatus.ERROR) {
            setAnswerStatus('error');
            autoHiddenTimeout.current = setTimeout(() => {
                dispatch(resetPostDirectorAnswerStatus());
                setAnswerStatus('');
            }, 5000);
        }
    }, [dispatch, postDirectorAnswerStatus]);

    useEffect(() => {
        if (!constants) dispatch(loadConstantsStart());
        if (!parent) dispatch(loadUser());
    }, []);

    const handleNavbarItemClick = useCallback((path: string) => history.push(path), [history]);

    const handleLogoClick = useCallback(() => history.push('/new'), [history]);

    return (
        <div className={classNames('page-header', { 'page-header_isMobile': window.innerWidth <= 768 })}>
            <Navbar
                navbarItems={navbarItems}
                onNavbarItemClick={handleNavbarItemClick}
                currentPath={location.pathname}
                isMobile={window.innerWidth <= 768}
                students={navbarStudents}
                onLogoClick={handleLogoClick}
                needPrincipalButton
                onLetterSend={handleSubmitDirectorAnswer}
                tooltipItems={tooltipItems}
            />
            {answerStatus !== '' && (
                <DirectorModalNotification
                    status={answerStatus as 'success' | 'error'}
                    onCancel={handleAnswerQuestionClick}
                />
            )}
        </div>
    );
};

export default Header;
