import axios, { AxiosError, AxiosResponse } from 'axios';

import { BaseRestDataProvider } from './BaseRestDataProvider';
import { FillContractExtensionPayload, FillTrialDayPayload, IProcess, TrialDay } from './ProcessesDataProvider.types';

export class ProcessesDataProvider extends BaseRestDataProvider {
    async fetchProcesses(): Promise<AxiosResponse<Array<IProcess>>> {
        try {
            return await axios.get(`${this.host}/processes/list/?my_children=true`);
        } catch (error) {
            throw error;
        }
    }

    async fetchTrialDays(studentId: number): Promise<AxiosResponse<Array<TrialDay>>> {
        try {
            return await axios.get<Array<TrialDay>>(`${this.host}/processes/trialdays/${studentId}/`);
        } catch (error) {
            throw error;
        }
    }

    async createTrialDay(studentId: number): Promise<AxiosResponse<unknown>> {
        try {
            return await axios.post<unknown>(`${this.host}/processes/create/trial_day_process/`, {
                student_id: studentId,
            });
        } catch (error) {
            throw error;
        }
    }

    async fillTrialDay({ processId, timeslotKey }: FillTrialDayPayload): Promise<AxiosResponse<unknown>> {
        return axios
            .post(`${this.host}/processes/${processId}/execute/`, {
                action_name: 'booking_to_trial_day',
                parameters: {
                    timeslot_key: timeslotKey,
                },
            })
            .then((res: AxiosResponse<any>) => {
                return res?.data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    }

    async createContractExtension(studentId: number): Promise<AxiosResponse<unknown>> {
        try {
            return await axios.post<unknown>(`${this.host}/processes/create/contract_extension_process/`, {
                student_id: studentId,
            });
        } catch (error) {
            throw error;
        }
    }

    fillContractExtension = ({
        processId,
        oldContractId,
        educationCost,
        schoolId,
    }: FillContractExtensionPayload): Promise<AxiosResponse<unknown>> => {
        return axios
            .post<unknown>(`${this.host}/processes/${processId}/execute/`, {
                action_name: 'select_education_cost_action',
                parameters: {
                    education_cost: educationCost,
                    old_contract_id: oldContractId,
                },
            })
            .then(() =>
                axios.post<unknown>(`${this.host}/processes/${processId}/execute/`, {
                    action_name: 'select_school_action',
                    parameters: {
                        school_id: schoolId,
                    },
                }),
            );
    };
}
