export enum NotificationCardTypeEnum {
    feedback = 'feedback',
    feedbackLight = 'feedback-light',
    alert = 'alert',
    alertLight = 'alert-light',
    action = 'action',
    actionLight = 'action-light',
    placeholder = 'placeholder',
    loading = 'loading',
    approve = 'approve',
    upload = 'upload',
    edit = 'edit',

    block = 'block',
    work = 'work'
}

export interface INotificationCardProps {
    type?: NotificationCardTypeEnum;
    size?: 's' | 'm' | 'l';
    closeButton?: boolean;
    title?: string;
    titleLarge?: boolean;
    text?: string;
    textLarge?: boolean;
    imageSrc?: string;
    qrLink? : string;
    handleCloseButtonClick?: () => void;
}
