import React, { useMemo, useRef } from 'react';
import { cn } from '@bem-react/classname';

import { TabsHeaderItem } from 'components/Tabs/TabsHeaderItem/TabsHeaderItem';

import { ITabsElement } from './types';

import './Tabs.scss';

const CnTabs = cn('tabs');

interface ITabsProps {
    elements: ITabsElement[];
    selectedElement: number;
    changeSelectedElementHandler: (newValue: number) => void;
}

export const Tabs: React.FC<ITabsProps> = ({ elements, selectedElement, changeSelectedElementHandler }) => {
    const correctSelectedElement = useMemo(() => {
        if (selectedElement < 0) {
            return 0;
        }

        return selectedElement;
    }, [selectedElement]);

    // const element = useRef(null);
    //
    // const selectedTab = useMemo(() => {
    //     const tab = elements.find((el) => el.id === correctSelectedElement);
    //
    //     if (tab) {
    //         return tab.component;
    //     }
    //
    //     return elements[0].component;
    // }, [elements, correctSelectedElement]);

    return (
        <div className={CnTabs()}>
            <div className={CnTabs('header')}>
                {elements.map((element) => (
                    <TabsHeaderItem
                        onClick={changeSelectedElementHandler}
                        key={element.id}
                        id={element.id}
                        isSelected={element.id === correctSelectedElement}
                        content={element.title}
                    />
                ))}
            </div>
            {/*<div ref={element} className={CnTabs('content')}>*/}
            {/*    {selectedTab}*/}
            {/*</div>*/}
        </div>
    );
};
