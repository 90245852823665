import moment from 'moment';
import React, { useMemo } from 'react';
import { MeetingSlot } from '../../redux/types/meetings';

export const MeetingsDateRadio: React.FC<{
    title: string;
    onChange: (value: any) => void;
    selectedMeetingStartTime?: string;
    radioName: string;
    dateSlots: MeetingSlot[];
    value: MeetingSlot;
}> = ({ title, onChange, radioName, selectedMeetingStartTime, dateSlots, value }) => {
    const render = useMemo(() => {
        return dateSlots.map((dateSlot, index) => {
            return (
                <li key={`slot-${index}`} onClick={() => onChange(dateSlot)}>
                    <label className="meeting-radio">
                        <input
                            value={dateSlot.date}
                            type="radio"
                            name={radioName}
                            checked={value?.date === dateSlot?.date}
                        />
                        <span
                            className={`meeting-radio__checkmark ${
                                selectedMeetingStartTime &&
                                moment(dateSlot.date).format('DD.MM.YYYY') ===
                                    moment(selectedMeetingStartTime).format('DD.MM.YYYY')
                                    ? 'meeting-radio__selected'
                                    : ''
                            }`}
                        >
                            {moment(dateSlot.date).format('DD MMMM')}
                        </span>
                    </label>
                </li>
            );
        });
    }, [dateSlots, selectedMeetingStartTime, radioName, value]);

    return (
        <>
            <div className="meeting__form-title">{title}</div>
            <ul className="meeting__date-list">{render}</ul>
        </>
    );
};
