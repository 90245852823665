import React from 'react';
import { cn } from '@bem-react/classname';
import logoSvg from 'assets/img/closed-logo.svg';
import logoWithTextSvg from 'assets/img/logo-text.svg';

import { NavBarDesktopButtons } from 'components/Navbar//NavBarDesktopButtons';
import { NavbarContent } from 'components/Navbar/NavbarContent';

import './NavBarDesktop.scss';
import { INavbarDesctopProps } from './NavBarDesktop.types';

const CnNavBarDesktop = cn('nav-bar-desktop');

export const NavBarDesktop: React.FC<INavbarDesctopProps> = (props) => {
    const {
        isOpened,
        toggledStudents,
        navbarItems,
        handleLogoClick,
        handleStudentClick,
        handleSettingClick,
        handleNavBarItemClick,
    } = props;

    const isShowSkeleton = !navbarItems?.length;

    return (
        <div className={CnNavBarDesktop({ isClosed: !isOpened })}>
            <div className={CnNavBarDesktop('section')}>
                <div className={CnNavBarDesktop('header')} onClick={handleLogoClick}>
                    <img
                        className={CnNavBarDesktop('header-image', { isOpened })}
                        src={isOpened ? logoWithTextSvg : logoSvg}
                        alt={''}
                    />
                </div>
                <div className={CnNavBarDesktop('divider')} />
                <NavbarContent
                    navbarItems={navbarItems}
                    isShowSkeleton={isShowSkeleton}
                    students={toggledStudents}
                    onStudentClick={handleStudentClick}
                    onSettingClick={handleSettingClick}
                    onNavbarItemClick={handleNavBarItemClick}
                    {...props}
                />
            </div>
            <div className={CnNavBarDesktop('divider')} />
            <NavBarDesktopButtons isShowSkeleton={isShowSkeleton} {...props} />
        </div>
    );
};
