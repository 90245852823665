import React, { useCallback, useEffect, useState } from 'react';

import './CoursesEnrollment.scss';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { IndividualCourses } from './Individual';
import { AdditionalCourses } from './Additional';
import { useSelector } from 'react-redux';
import { State } from '../../../redux/store';
import { incline } from 'lvovich';

export const CoursesEnrollment: React.FC = () => {
    const [selectedCourse, setSelectedCourse] = useState(1);
    const studentsState = useSelector((state: State) => state.profile);
    const [genCaseName, setGenCaseName] = useState(null);

    const handleButtonClick = useCallback(
        (e) => {
            setSelectedCourse(parseInt(e.target.value));
        },
        [selectedCourse],
    );

    useEffect(() => {
        setGenCaseName(
            incline(
                { first: studentsState.selectedProfile?.first_name, last: studentsState.selectedProfile?.last_name },
                'genitive',
            ),
        );
    }, [studentsState.selectedProfile]);

    const getCourseComponent = useCallback(
        (value: number) => {
            switch (value) {
                case 1:
                    return <AdditionalCourses />;
                case 2:
                    return <IndividualCourses />;
                case 3:
                    return <IndividualCourses />;
            }
        },
        [selectedCourse],
    );

    return (
        <div className="courses">
            <div className="courses-header">
                <div className="courses-header-text">Запись {`${genCaseName?.first} ${genCaseName?.last}`} на:</div>
                <ToggleButtonGroup type="radio" name="options" defaultValue={1}>
                    <ToggleButton
                        onClick={handleButtonClick}
                        className="courses-header-radio"
                        variant="outline-primary"
                        id="additional-courses"
                        value={1}
                    >
                        Доп. курсы
                    </ToggleButton>
                    <ToggleButton
                        onClick={handleButtonClick}
                        className="courses-header-radio"
                        variant="outline-primary"
                        id="individual-courses"
                        value={2}
                    >
                        Индивид. образование
                    </ToggleButton>
                    <ToggleButton
                        disabled={true}
                        className="courses-header-radio"
                        variant="outline-primary"
                        id="electives"
                        value={3}
                    >
                        Факультативы
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
            {getCourseComponent(selectedCourse)}
        </div>
    );
};
