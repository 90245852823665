import { IFullName } from '../../../common/types';

export interface IChildData {
    id?: number;
    phone_number?: string;
    email?: string;
    snils?: string;
    actual_living_place?: string;
    living_place?: string;
    birthday?: string;
    birth_certificate?: string;
    grade?: number;
    timezone?: string;
}

export interface IApplicationFormProps {
    children: any;
    parent: IFullName;
    parentGender: string;
    parentPhone: string;
    parentCountryCode: string;
    foreignLanguages: string[];
    ruleUrl: string;
    handleSubmitForm?: (value) => void;
    parentEmail: string;
    constants: Record<string, Record<string, string>>;
    timezones: string[];
}

export interface IValidation {
    phones: string[];
    emails: string[];
    childPhone: boolean;
    childCountryCode: boolean;
    childEmail: boolean;
    snils: boolean;
    countryCodes: string[];
    actual_living_place: boolean;
    living_place: boolean;
    birthday: boolean;
    birth_certificate: boolean;
    grade: boolean;
    timezone?: boolean;
    mathLevel?: boolean;
    relationDegree?: boolean;
}

export enum InputTypes {
    lastName = 'lastName',
    firstName = 'firstName',
    patronymic = 'patronymic',
    phone = 'phone',
    email = 'email',
    countryCode = 'countryCode',
}
